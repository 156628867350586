import React from 'react'
import { Translation } from 'react-i18next';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';

const Footer = ({ isMobile }) => {
  const desktopStyles = {
    wrapper: {
      display: 'flex',
      flex: 'none',
      height: 70,
      alignItems: 'center',
      margin: '50px 16px 0 16px',
      padding: 16
    },
    left: {
      flex: 1
    },
    center: {
      flex: 1,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#6d6d6d'
    },
    centerRow: {
      marginTop: 5,
    },
    right: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    socialLinks: {
      display: 'flex',
    },
    socialButton: {
      border: 'none', 
      background: 'transparent', 
      cursor: 'pointer', 
      padding: 0, 
      width: 35, 
      height: 35, 
      outline: 'none'
    },
    linkWrapper: {

    },
    link: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: 16,
      color: '#a9a9a9'
    },
    linkSmall: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: 12,
      color: '#a9a9a9'
    }
  }

  const mobileStyles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 'none',
      alignItems: 'center',
      margin: '50px 16px 0 16px',
      padding: 16
    },
    left: {
      flex: 1
    },
    center: {
      flex: 1,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#6d6d6d',
      marginTop: 16,
      marginBottom: 16,
    },
    centerRow: {
      marginTop: 5,
    },
    right: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    socialLinks: {
      display: 'flex',
    },
    socialButton: {
      border: 'none', 
      background: 'transparent', 
      cursor: 'pointer', 
      padding: 0, 
      width: 35, 
      height: 35, 
      outline: 'none'
    },
    linkWrapper: {

    },
    link: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: 16,
      color: '#a9a9a9'
    },
    linkSmall: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: 12,
      color: '#a9a9a9'
    }
  };

  const styles = isMobile ? mobileStyles : desktopStyles;

  const handleTwitterClick = () => {
    window.open("https://twitter.com/reex_pro");
  }

  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/reex.pro/");
  }

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/reex.pro/");
  }

  return (
    <Translation>
      { t => (
        <div style={styles.wrapper}>
          <div style={styles.left}>
            <div style={styles.socialLinks}>
              <motion.button
                onClick={handleTwitterClick}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
                style={styles.socialButton}
              >
                <img src="twitter.svg" alt="twitter" width="100%" height="100%" />
              </motion.button>
              <motion.button
                onClick={handleFacebookClick}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
                style={styles.socialButton}
              >
                <img src="facebook.svg" alt="facebook" width="100%" height="100%" />
              </motion.button>
              <motion.button
                onClick={handleInstagramClick}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
                style={styles.socialButton}
              >
                <img src="instagram.svg" alt="instagram" width="100%" height="100%" />
              </motion.button>
            </div>
          </div>
          <div style={styles.center}>
            <div>
              © {new Date().getFullYear()} Reex
            </div>
            {/* <div style={styles.centerRow}>
            <a href={`mailto:hola@reex.pro`} target="_blank" rel="noopener noreferrer" style={styles.linkSmall}>
              {t('Terms, conditions & privacy policy')}
            </a>
            </div> */}
          </div>
          <div style={styles.right}>
            <div style={styles.linkWrapper}>
              <a href={`mailto:hola@reex.pro`} target="_blank" rel="noopener noreferrer" style={styles.link}>
                <Icon icon="envelope" size={24} style={{ marginRight: 5 }} />
                hola@reex.pro
              </a>
            </div>
          </div>
        </div>
    )}
    </Translation>
  )
}

export default Footer
