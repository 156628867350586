import React, { useState, useEffect } from 'react';
import Header from '../EventView/components/Header';
import View from '../EventView/components/View';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import Comments from '../Comments/Comments';
import { useKeyPress } from "../../utils/hooks";
import { Translation } from 'react-i18next';
import UserAvatar from '../UserAvatar/UserAvatar';

const PreviousButton = ({ onClick, hide, index }) => {
  if (hide) return null;

  const styles = {
    button: {
      padding: '5px 20px',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 14,
      borderRadius: '50%',
      width: 45,
      height: 45,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 'calc(50% - 28px)',
      left: 10
    }
  };

  return (
    <motion.button
      onClick={() => { onClick(index-1)}}
      style={{ ...styles.button }}
      whileHover={{ scale: 1.5 }}
      whileTap={{ scale: 0.8 }}
    >
      <Icon size={18} icon="arrowLeft" color="#000" />
    </motion.button>
  );
}

const NextButton = ({ onClick, hide, index }) => {
  if (hide) return null;

  const styles = {
    button: {
      padding: '5px 20px',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 14,
      borderRadius: '50%',
      width: 45,
      height: 45,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 'calc(50% - 28px)',
      right: 10
    }
  };

  return (
    <motion.button
      onClick={() => { onClick(index+1) }}
      style={{ ...styles.button }}
      whileHover={{ scale: 1.5 }}
      whileTap={{ scale: 0.8 }}
    >
      <Icon size={18} icon="arrowRight" color="#000" />
    </motion.button>
  );
}

const Slideshow = ({ data, handleClose, handlePrevious, handleNext, hasBibnumbers, handleView, isMobile, showComments = true, min, max, index, HeaderComponent }) => {
  const [ isFullScreen, setIsFullScreen ] = useState(false);
  const _id = data && data._id;
  const views = data && data.views;
  const username = JSON.parse(localStorage.getItem('userInfo')).username;
  const hasView = views && views.find(v => v.username === username);
  const src = `https://moments.fra1.digitaloceanspaces.com/web/${_id}.jpeg`;
  const escapePress = useKeyPress("Escape");

  useEffect(() => {
    if (escapePress && handleClose) {
      handleClose();
    }
  }, [escapePress, handleClose]);
  
  useEffect(() => {
    if (!_id) {
      handleClose();
    }
  }, [_id]);

  // If pressed key is our target key then set to true
  const downHandler = ({ key }) => {
    if (key === 'ArrowLeft' && min !== index) {
      handlePrevious(index-1);
    } else if (key === 'ArrowRight' && max !== index) {
      handleNext(index+1);
    }
  }

  const handleFullScreen = () => {
    setIsFullScreen(true);
  }

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  }

  const desktopStyles = {
    wrapper: {
      zIndex: 999,
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'rgb(236, 255, 245)',
      display: 'flex',
      flexDirection: 'column',
    },
    contentWrapper: {
      display: 'flex',
      flex: 1,
      overflow: 'hidden',
    },
    viewWrapper: {
      display: 'flex',
      flex: 1,
      position: 'relative',
    },
    commentsWrapper: {
      width: 400,
      flex: 'none',
      display: 'flex',
      borderLeft: '1px solid #efefef',
      flexDirection: 'column'
    },
    buttonWrapper: {
      position: 'absolute',
      top: 5,
      right: 5,
      borderRadius: 4,
      background: 'rgba(0,0,0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      fontWeight: 600,
      fontSize: 14,
      boxShadow: 'none',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      color: '#000',
      padding: 5,
      background: 'transparent',
    },
    historyWrapper: {
      background: '#fff',
      padding: 15
    },
    historyHint: {
      color: '#29b969',
      fontSize: 12,
      marginBottom: 5,
    },
    historyTitle: {
      fontWeight: 600,
    },
    history: {
      marginTop: 5,
      flex: 'none',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 80px)',
      gridAutoFlow: 'column',
      alignItems: 'center',
      gridGap: 5,
      background: '#fff',
      position: 'relative',
      overflowX: 'auto',
      overflowY: 'hidden',
      boxSizing: 'border-box',
    },
    historyPlaceholder: {
      color: '#616161',
      fontSize: 12,
      marginTop: 5,
    }
  };

  const mobileStyles = {
    wrapper: {
      zIndex: 999,
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'rgb(236, 255, 245)',
      display: 'flex',
      flexDirection: 'column',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    viewWrapper: {
      display: 'flex',
      position: 'relative',
    },
    commentsWrapper: {
      flex: 1,
      display: 'flex',
      borderLeft: '1px solid #efefef',
      flexDirection: 'column'
    },
    buttonWrapper: {
      position: 'absolute',
      top: 5,
      right: 5,
      borderRadius: 4,
      background: 'rgba(0,0,0, 0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      fontWeight: 600,
      fontSize: 14,
      boxShadow: 'none',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      color: '#000',
      padding: 5,
      background: 'transparent',
    },
    historyWrapper: {
      background: '#fff',
      padding: 15
    },
    historyHint: {
      color: '#29b969',
      fontSize: 12,
      marginBottom: 5,
    },
    historyTitle: {
      fontWeight: 600,
    },
    history: {
      marginTop: 5,
      flex: 'none',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 80px)',
      gridAutoFlow: 'column',
      alignItems: 'center',
      gridGap: 5,
      background: '#fff',
      position: 'relative',
      overflowX: 'auto',
      overflowY: 'hidden',
      boxSizing: 'border-box',
    },
    historyPlaceholder: {
      color: '#616161',
      fontSize: 12,
      marginTop: 5,
    }
  };

  useEffect(() => {
    if (handleView && _id) {
      handleView({_id, hasView});
    }
  }, [_id])

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [index, min, max]); 

  const renderAvatars = (data, config = {}) => {
    return data.map(user => <UserAvatar key={user.username} style={{ margin: 5 }} detailed={true} {...user} {...config} />);
  }

  const styles = isMobile ? mobileStyles : desktopStyles;

  if (!_id) return null;

  if (isFullScreen) {
    return (
      <div style={styles.wrapper}>
        <div style={styles.buttonWrapper}>
          <motion.button
            onClick={handleCloseFullScreen}
            style={styles.button}
          >
            <Icon size={22} icon="close" />
          </motion.button>
        </div>
        <div style={{flex: 1, overflow: 'auto'}}>
          <img
            alt={_id}
            src={src}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={styles.wrapper}>
      <HeaderComponent data={data} handleClose={handleClose} hasBibnumbers={hasBibnumbers} />
      <div style={styles.contentWrapper}>
        <div style={styles.viewWrapper}>
          <View src={src} data={data} onFullScreen={handleFullScreen} />
          <PreviousButton onClick={handlePrevious} hide={min === index} index={index} />
          <NextButton onClick={handleNext} hide={max === index} index={index} />
        </div>
        {showComments && 
          <div style={styles.commentsWrapper}>
            <Translation>
              {t => (
                <div style={styles.historyWrapper}>
                <div style={styles.historyHint}>
                  {t('tag your photos and those of your friends to display them on the profile clicking the button')}
                  <span style={{position: 'absolute', marginLeft: 3}}><Icon size={16} icon="tag" /></span>
                </div>
                <div style={styles.historyTitle}>{t('players on this photo')}</div>
                  {data.users && data.users.length > 0 ?
                    <div style={styles.history}>
                      {renderAvatars(data.users, { size: 60 })}
                    </div>
                  : <div style={styles.historyPlaceholder}>{t('no one is tagged on this photo')}</div>
                  }
                </div>
              )}
            </Translation>
            <Comments data={data} isMobile={isMobile} />
          </div>
        }
      </div>
    </div>
  );
}

Slideshow.defaultProps = {
  HeaderComponent: Header
}

export default Slideshow;