import React from 'react';
import Header from './components/Header';
import TabsContainer from '../Tabs/TabsContainer';
import Tab from '../Tabs/Tab';
import ProfileGallery from './components/ProfileGallery';
import ProfileEventGallery from './components/ProfileEventGallery';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { baseUrl } from '../../config';
import { Translation } from 'react-i18next';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this._dispatchToken = AppDispatcher.register(this._registerToActions);
    this.state = {
      userPhotos: null,
      userTaggedPhotos: null,
      userEvents: null,
      photographerPortfolio: null
    }
  }

  _registerToActions = (action) => {
    switch (action.actionType) {
      case 'UPDATE_EVENT_PICTURE':
        this.obtainDatas();
        break;
      default:
        break;
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.userInfo !== this.props.userInfo) {
      this.obtainDatas();
    }
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this._dispatchToken);
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex',
          overflow: 'auto'
        },
        tabsWrapper: {
          overflow: 'auto',
        },
        tab: {
          button: {
            active: {
              color: '#00d663',
              boxShadow: 'none',
              borderBottom: '2px solid'
            },
            deactive: {
            }
          },
          content: {
            minHeight: 0
          }
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto'
        },
        tabsWrapper: {
          flex: 'none',
          padding: 2,
        },
        tab: {
          button: {
            active: {
              color: '#00d663',
              boxShadow: 'none',
              borderBottom: '2px solid',
              fontSize: 10
            },
            deactive: {
              fontSize: 10
            }
          },
          content: {
            minHeight: 0
          }
        }
      }
    }
  }

  componentDidMount() {
    this.obtainDatas();
  }

  obtainDatas = () => {
    const { userInfo } = this.props;

    if (userInfo.role === "photographer") {
      this.obtainPhotographerPortfolio(userInfo);
    }
    this.obtainUserPhotos(userInfo);
    this.obtainUserTaggedPhotos(userInfo);
    this.obtainUserEvents(userInfo);
  }

  obtainPhotographerPortfolio = (userInfo) => {
    fetch(`${baseUrl}/users/${userInfo.username}/images/portfolio`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        photographerPortfolio: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainUserPhotos = (userInfo) => {
    fetch(`${baseUrl}/users/${userInfo.username}/images/mine`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        userPhotos: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainUserTaggedPhotos = (userInfo) => {
    fetch(`${baseUrl}/users/${userInfo.username}/images/tagged`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        userTaggedPhotos: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainUserEvents = (userInfo) => {
    fetch(`${baseUrl}/users/${userInfo.username}/events`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        userEvents: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  render() {
    const { userPhotos, userTaggedPhotos, userEvents, photographerPortfolio } = this.state;
    const { userInfo, isExternal, isMobile, data } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    
    if (!userPhotos || !userTaggedPhotos || !userEvents) return null;

    if (userInfo.role === 'photographer' && photographerPortfolio) {
      return (
        <Translation>
          {t => (
            <div style={styles.wrapper}>
              <Header userInfo={userInfo} isExternal={isExternal} isMobile={isMobile} />
              <TabsContainer wrapperStyle={styles.tabsWrapper} selected={data ? data.tab : null}>
                <Tab label={t("Portfolio")} count={`${(photographerPortfolio && photographerPortfolio.length) || 0}/30`} style={styles.tab}>
                  <ProfileGallery portfolio={true} data={photographerPortfolio} isMobile={isMobile} isExternal={isExternal} userInfo={userInfo} />
                </Tab>
                <Tab label={t("Photos")} count={(userPhotos && userPhotos.length) || 0} style={styles.tab}>
                  <ProfileGallery data={userPhotos} isMobile={isMobile} userInfo={userInfo} selected={data} />
                </Tab>
                <Tab label={t("Matches")} count={(userEvents && userEvents.length) || 0} style={styles.tab}>
                  <ProfileEventGallery data={userEvents} isMobile={isMobile} userInfo={userInfo} />
                </Tab>
              </TabsContainer>
            </div>
          )}
        </Translation>
      );
    }

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <Header userInfo={userInfo} isExternal={isExternal} isMobile={isMobile} />
            <TabsContainer wrapperStyle={styles.tabsWrapper}>
              <Tab label={t("Tagged")} count={(userTaggedPhotos && userTaggedPhotos.length) || 0} style={styles.tab}>
                <ProfileGallery data={userTaggedPhotos} isMobile={isMobile} userInfo={userInfo}/>
              </Tab>
              <Tab label={t("Matches")} count={(userEvents && userEvents.length) || 0} style={styles.tab}>
                <ProfileEventGallery data={userEvents} isMobile={isMobile} userInfo={userInfo} />
              </Tab>
            </TabsContainer>
          </div>
        )}
      </Translation>
    );
  }
}

export default Profile;
