import React, { useState, useRef } from 'react';
import Icon from '../Icon/Icon';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
} from '@material/react-menu';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { useOnClickOutside } from '../../utils/hooks';
import { motion } from 'framer-motion';
import UserAvatar from '../UserAvatar/UserAvatar';
import ReactCountryFlag from "react-country-flag"
import { Translation } from 'react-i18next';

const MemberBox = (props) => {
  const { _id, actions, id, nationality, position, width, username, ...rest } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));
  
  const styles = {
    wrapper: {
      display: 'flex',
      padding: 2.5,
      boxSizing: 'border-box',
      minWidth: 0,
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      width,
    },
    roundedButton: {
      padding: 20,
      borderRadius: '50%',
      boxShadow: 'none',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      width: 50,
      height: 30,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      flex: 'none',
      position: 'absolute',
      top: 0,
      right: 0
    },
    nationality: {
      position: 'absolute',
      bottom: 85,
      left: 25,
      pointerEvents: 'none',
    },
    nationalityText: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -15,
      fontSize: 14,
      fontWeight: 600,
      color: '#000',
      textAlign: 'center'
    },
    username: {
      fontSize: 20,
    },
    name: {
      fontSize: 14,
    }
  }

  const handleShowMenu = event => {
    setMenuOpen(true);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleItemClick = option => {
    AppDispatcher.dispatch({
      actionType: option.value,
      data: { _id, userId: username, id, username, positionId: position._id, ...rest }
    });
  };

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className="mdc-menu-desktop"
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleItemClick(actions[index])}
          >
            <MenuList>
              <div ref={ref}>
                {actions.map(option => (
                  <MenuListItem key={option.value}>
                    <MenuListItemGraphic graphic={<Icon size={18} icon={option.icon} color={option.label === 'Remove' && '#f44336'} wrapperStyle={{marginRight: 5}}/>} />
                    <MenuListItemText primaryText={t(option.label)} className={option.label === 'Remove' && 'mdc-text-red'}/>
                  </MenuListItem>
                ))}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };

  const renderNationality = () => {
    if (!nationality) return null;

    return (
      <div style={styles.nationality}>
        <ReactCountryFlag
          countryCode={nationality}
          svg
          style={{
            width: '3em',
            height: '3em',
          }}
          title={nationality}
        />
        <span style={styles.nationalityText}>{nationality}</span>
      </div>
    );
  }

  const renderActions = () => {
    if (!actions) return null;

    return (
      <motion.button
        onClick={handleShowMenu}
        style={styles.roundedButton}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.8 }}
      >
        <Icon size={18} icon="showMore" color="#000" />
      </motion.button>
    );
  }

  return (
    <div style={styles.wrapper}>
      {isMenuOpen && renderMenu()}
      {renderActions()}
      <UserAvatar 
        {...props} 
        size={200} 
        detailed 
        usernameStyle={styles.username} 
        nameStyle={styles.name}
        bibnumber={id}
      />
      {renderNationality()}
    </div>
  )
}

export default MemberBox
