import React from 'react';
import { baseUrl } from '../../config';
import Comment from './Comment';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { Translation } from 'react-i18next';

const Comments = ({ data, isMobile }) => {
  const { comments, _id } = data;

  const styles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#fff'
    },
    comments: {
      flex: 1,
      overflow: 'auto',
      padding: 15,
      boxSizing: 'border-box'
    },
    header: {
      padding: '0 15px',
      fontWeight: 600
    },
    bottom: {
      flex: 'none',
      height: 70,
      padding: 15,
      boxSizing: 'border-box',
      borderTop: '1px solid #efefef',
      display: 'flex',
      alignItems: 'center'
    },
    form: {
      flex: 1,
      display: 'flex',
    },
    textArea: {
      flex: 1,
      background: 'transparent',
      border: 'none',
      color: '#000',
      maxHeight: 80,
      outline: 0,
      padding: 0,
      resize: 'none',
      fontSize: 16,
      height: 44
    },
    button: {
      fontWeight: 600,
      color: '#00d663',
      fontSize: 16,
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 0,
      background: 'transparent',
      border: 'none',
      padding: '5px 10px',
      cursor: 'pointer'
    },
    placeholder: {
      color: '#616161'
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const comment = form.elements && form.elements[0] && form.elements[0].value;

    if (!comment) return null;

    form.reset()
    handleComment({ comment });
  }

  const handleComment = ({ comment }) => {
    const body = { comment };
    fetch(`${baseUrl}/images/${_id}/comment`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE'
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  const handleUncomment = ({ commentId }) => {
    const body = { commentId };
    fetch(`${baseUrl}/images/${_id}/uncomment`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE'
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  const onKeyPress = e => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      e.target.form.dispatchEvent(new Event("submit", { cancelable: true }));
    }
  }

  const renderComments = () => {
    if (!comments || comments.length === 0) {
      return (
        <Translation>
          {t => (
            <div style={styles.placeholder}>
              {t('Be the first to comment this photo!')}
            </div>
          )}
        </Translation>
      );
    }

    return comments.map(comment => <Comment key={comment._id} {...comment} isMobile={isMobile} onRemove={handleUncomment} />);
  }

  return (
    <Translation>
      {t => (
        <div style={styles.wrapper}>
          <div style={styles.header}>
            {t('Comments')}:
          </div>
          <div style={styles.comments}>
            {renderComments()}
          </div>
          <div style={styles.bottom}>
            <form style={styles.form} method="POST" onSubmit={onSubmit}>
              <textarea 
                style={styles.textArea} 
                placeholder={t("Add a comment..." )}
                autoCorrect="off" autoComplete="off" onKeyPress={onKeyPress}></textarea>
              <button style={styles.button} type="submit">{t('Publish')}</button>
            </form>
          </div>
        </div>
      )}
    </Translation>
  )
}

export default Comments;
