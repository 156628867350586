import React, { Component } from 'react';
import { FixedSizeList as List } from 'react-window';
import User from './components/User';
import EventType from './components/EventType';
import Event from './components/Event';
import Report from './components/Report';
import Promotion from './components/Promotion';
import Federation from './components/Federation';
import Season from './components/Season';
import './Admin.css';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import { SizeMe } from 'react-sizeme';
import AdminActions from './actions/AdminActions';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { motion } from 'framer-motion';
import Dialog from '../Dialog/Dialog';
import FormUser from '../Form/FormUser';
import FormEventType from '../Form/FormEventType';
import FormEvent from '../Form/FormEvent';
import FormEventPicture from '../Form/FormEventPicture';
import FormFederationPicture from '../Form/FormFederationPicture';
import FormEventTypePicture from '../Form/FormEventTypePicture';
import FormEventPhotographer from '../Form/FormEventPhotographer';
import FormOrganization from '../Form/FormOrganization';
import FormSeason from '../Form/FormSeason';
import FormFederation from '../Form/FormFederation';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import { Translation } from 'react-i18next';
import { baseUrl } from '../../config'; 
import FormSeasonCategory from '../Form/FormSeasonCategory';
import FormSeasonCategoryClub from '../Form/FormSeasonCategoryClub';
import SeasonCategory from './components/SeasonCategory';
import SeasonCategoryClub from './components/SeasonCategoryClub';
import Select from 'react-select';

class Admin extends Component {
  constructor() {
    super();
    this._dispatchToken = AppDispatcher.register(this._registerToActions);
    this.state = {
      view: null,
      search: '',
      userType: null,
    };
  }

  _registerToActions = (action) => {
    const { data } = this.state;

    switch (action.actionType) {
      case 'FETCH_USERS':
        this.setState({ users: action.data });
        break;
      case 'FETCH_EVENT_TYPES':
        this.setState({ eventTypes: action.data });
        break;
      case 'FETCH_EVENTS':
        this.setState({ events: action.data });
        break;
      case 'FETCH_ORGANIZATIONS':
        this.setState({ organizations: action.data });
        break;
      case 'FETCH_REPORTED':
        this.setState({ reported: action.data });
        break;
      case 'FETCH_PROMOTIONS':
        this.setState({ promotions: action.data });
        break;
      case 'FETCH_FEDERATIONS':
        this.setState({ federations: action.data });
        break;
      case 'FETCH_SEASONS':
        this.setState({ seasons: action.data });
        if (data && data._id === action.data._id) {
          this.setState({
            data: action.data
          });
        }
        break;
      case 'RELOAD_REPORTED':
        AdminActions.fetchReported();
        break;
      case 'RELOAD_PROMOTIONS':
        AdminActions.fetchPromotions();
        break;
      case 'CHANGE_VIEW':
        if (action.view === 'admin') {
          this.setState({
            view: null
          });
        }
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this._dispatchToken);
  }

  fetchInitialData = () => {
    AdminActions.fetchUsers();
    AdminActions.fetchEventTypes();
    AdminActions.fetchEvents();
    AdminActions.fetchOrganizations();
    AdminActions.fetchReported();
    AdminActions.fetchPromotions();
    AdminActions.fetchFederations();
    AdminActions.fetchSeasons();
  }

  getStyles() {
    const { isMobile } = this.props;
    
    return {
      background: {
        height: isMobile ? 'auto' : 'calc(100% - 40px)',
        width: '100%',
        display: isMobile ? 'block' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      },
      wrapper: {
        flex: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 200px) )',
        gap: 10,
        justifyContent: 'center',
        padding: 20,
      },
      box: {
        width: 200,
        height: 200,
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 4,
        background: '#00d663',
        color: '#000',
        cursor: 'pointer',
        marginBottom: isMobile ? 20 : 0
      },
      disabledBox: {
        width: 200,
        height: 200,
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 4,
        background: '#00d663',
        color: '#000',
        opacity: '.5',
        marginBottom: isMobile ? 20 : 0
      },
      label: {
        marginTop: 20,
        fontSize: 18,
        fontWeight: 600
      },
      dialog: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
      header: {
        background: '#00d663',
        color: '#000',
        flex: 'none',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: isMobile ? 14 : 16,
        position: 'relative'
      },
      photoWrapper: {
        flex: 1,
        overflow: 'hidden',
      },
      photo: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      },
      close: {
        position: 'absolute',
        right: 10
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      },
      view: {
        wrapper: {
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        },
        headerWrapper: {
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        header: {
          fontSize: isMobile ? 24 : 36,
          fontWeight: 600,
          margin: '0 15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        search: {
          background: 'transparent',
          border: 'none',
          borderBottom: '2px solid #000',
          marginLeft: 10,
          fontSize: isMobile ? 12 : 18,
          width: '50%',
          height: isMobile ? 30 : 40,
        },
        left: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          textTransform: 'uppercase'
        },
        toolbar: {
          display: 'flex',
          marginBottom: 10,
          padding: 10
        },
        content: {
          width: '100%',
          flex: 1,
          overflow: 'auto'
        },
        actions: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: 15
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none'
        },
        grid: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
          gridTemplateRows: 'repeat(auto-fill, 150px)',
          gridGap: 15,
          justifyContent: 'center',
        },
      }
    }
  }

  handleSearchChange = (text) => {
    this.setState({
      search: text,
    });
  }

  handleUserChange = (value) => {
    this.setState({
      userType: value
    });
  }

  handleEventTypesClick = () => {
    this.setState({
      view: 'eventTypes'
    });
  }

  handleEventsClick = () => {
    this.setState({
      view: 'events'
    });
  }

  handleOrganizationsClick = () => {
    this.setState({
      view: 'organizations'
    });
  }

  handleUsersClick = () => {
    this.setState({
      view: 'users'
    });
  }

  handleReportedClick = () => {
    this.setState({
      view: 'reported'
    });
  }

  handlePromotionsClick = () => {
    this.setState({
      view: 'promotions'
    });
  }

  handleFederationsClick = () => {
    this.setState({
      view: 'federations'
    });
  }

  handleSeasonsClick = () => {
    this.setState({
      view: 'seasons'
    });
  }

  handleBack = () => {
    const { view } = this.state;

    if (view === 'seasonCategories') {
      this.setState({
        view: 'seasons',
        data: null
      });
    } if (view === 'seasonCategoryTeams') {
      this.setState({
        view: 'seasonCategories',
        category: null
      });
    } else {
      this.setState({
        view: null
      });
    }
  }

  renderHeader = (view) => {
    const { data, category, eventTypes } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;
    let viewLabel;

    switch (view) {
      case 'users':
        viewLabel = 'Users';
        break;
      case 'events':
        viewLabel = 'Matches';
        break;
      case 'organizations':
        viewLabel = 'Organizers';
        break;
      case 'eventTypes':
        viewLabel = 'Categories';
        break;
      case 'reported':
        viewLabel = 'Reported';
        break;
      case 'promotions':
        viewLabel = 'Promotions';
        break;
      case 'federations':
        viewLabel = 'Federations';
        break;
      case 'seasons':
        viewLabel = 'Seasons';
        break;
      case 'seasonCategories':
        viewLabel = 'Categories';
        break;
      case 'seasonCategoryTeams':
        viewLabel = 'clubs';
        break;
      default:
        break;
    }

    return (
      <div style={styles.headerWrapper}>
        <div style={styles.header}>
          <div style={styles.left}>
            <Button onClick={this.handleBack} background="#f3f3f3">
              <Icon size={isMobile ? 36 : 48} color='#000' icon="back" />
            </Button>
            {data ? `${data.name}: ${category ? `${eventTypes.find(t => t._id === category.type).name}: ` : ''} ` : null}
            <Translation>
              {t => t(viewLabel)}
            </Translation>
          </div>
        </div>
      </div>
    );
  }

  renderEventTypesContent = () => {
    const { isMobile } = this.props;
    const { eventTypes } = this.state;
    const styles = this.getStyles().view;

    return (
      <div style={styles.content}>
        <SizeMe monitorWidth monitorHeight>
          {({ size }) =>
            <List
              height={size.height}
              itemCount={Object.keys(eventTypes).length}
              itemSize={isMobile ? 100 : 70}
              width={size.width}
              itemData={{
                data: eventTypes,
                onClick: this.handleAddEventTypeClick,
                onUploadClick: this.handleAddEventTypePictureClick,
                isMobile: isMobile
              }}
            >
              {EventType}
            </List>
          }
        </SizeMe>
      </div>
    );
  }

  renderEventContent = () => {
    const { events } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;

    return (
      <div style={styles.content}>
        <SizeMe monitorWidth monitorHeight>
          {({ size }) =>
            <List
              height={size.height}
              itemCount={Object.keys(events).length}
              itemSize={isMobile ? 150 : 100}
              width={size.width}
              itemData={{
                data: events,
                onClick: this.handleAddEventClick,
                onUploadClick: this.handleAddPictureClick,
                onPhotographerClick: this.handleAddPhotographerClick,
                isMobile: isMobile
              }}
            >
              {Event}
            </List>
          }
        </SizeMe>
      </div>
    );
  }

  renderOrganization = (organization) => {
    const { isMobile } = this.props;
    return (
      <OrganizationAvatar key={organization.name} {...organization} isMobile={isMobile} detailed={true} size={100} />
    );
  }

  renderOrganizationContent = () => {
    const { organizations } = this.state;
    const styles = this.getStyles().view;

    return (
      <div style={styles.content}>
        <div style={styles.grid}>
          {organizations.map(d => this.renderOrganization(d))}
        </div>
      </div>
    );
  }

  renderPromotionsContent = () => {
    const { promotions } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;

    return (
      <div style={styles.content}>
        <SizeMe monitorWidth monitorHeight>
          {({ size }) =>
            <List
              height={size.height}
              itemCount={Object.keys(promotions).length}
              itemSize={100}
              width={size.width}
              itemData={{
                data: promotions,
                onAcceptClick: this.handleAcceptPromotion,
                onRejectClick: this.handleRejectPromotion,
                isMobile: isMobile
              }}
            >
              {Promotion}
            </List>
          }
        </SizeMe>
      </div>
    );
  }

  renderReportedContent = () => {
    const { reported } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;

    return (
      <div style={styles.content}>
        <SizeMe monitorWidth monitorHeight>
          {({ size }) =>
            <List
              height={size.height}
              itemCount={Object.keys(reported).length}
              itemSize={100}
              width={size.width}
              itemData={{
                data: reported,
                onClick: this.handleShowReport,
                onAcceptClick: this.handleAcceptReport,
                onRejectClick: this.handleRejectReport,
                isMobile: isMobile
              }}
            >
              {Report}
            </List>
          }
        </SizeMe>
      </div>
    );
  }

  getUsersFiltered = (search = this.state.search, users = this.state.users, userType = this.state.userType) => {
    if (!search && !userType) {
      return users;
    } else {
      let filteredUsers = users.filter((u) => {
        if (!userType || userType.value === 'all') return u;

        if (userType.value === 'players') return u.fake;
        if (userType.value === 'profiles') return !u.fake;
      });

      if (!search) return filteredUsers;

      return filteredUsers.filter(user => {
        let match = false;
        const fullName = `${user.name} ${user.surname}`;
        if (user.username && user.username.toUpperCase().includes(search.toUpperCase())) {
          match = true;
        } else if (user.alias && user.alias.toUpperCase().includes(search.toUpperCase())) {
          match = true;
        } else if (fullName && fullName.toUpperCase().includes(search.toUpperCase())) {
          match = true;
        } else if (user.email && user.email.toUpperCase().includes(search.toUpperCase())) {
          match = true;
        }
  
        return match;
      });
    }
  }

  renderUserContent = () => {
    const { search } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;
    const users = this.getUsersFiltered();
    const counters = {
      all: this.state.users ? this.state.users.length : 0,
      players: this.state.users ? this.state.users.filter(u => u.fake).length : 0,
      profiles: this.state.users ? this.state.users.filter(u => !u.fake).length : 0,
    };

    return (
      <Translation>
        {t => (
          <>
            <div style={styles.toolbar}>
              <input 
                type="search" 
                style={styles.search} 
                value={search} 
                onChange={(e) => this.handleSearchChange(e.currentTarget.value)}
                placeholder={t("Search by username, alias, name, surname or email...")}
              />
              <div style={{flex: 1, marginLeft: 10}}>
                <Select
                  defaultValue={{ label: `${t("All")} (${counters.all})`, value: "all"}}
                  options={[
                    { label: `${t("All")} (${counters.all})`, value: "all"},
                    { label: `${t("Players")} (${counters.players})`, value: "players"},
                    { label: `${t("Profiles")} (${counters.profiles})`, value: "profiles"},
                  ] }
                  onChange={(e) => this.handleUserChange(e)}
                />  
              </div>
            </div>
            <div style={styles.content}>
              <SizeMe monitorWidth monitorHeight>
                {({ size }) =>
                  <List
                    height={size.height}
                    itemCount={Object.keys(users).length}
                    itemSize={80}
                    width={size.width}
                    itemData={{
                      data: users,
                      onClick: this.handleAddUserClick,
                      isMobile: isMobile
                    }}
                  >
                    {User}
                  </List>
                }
              </SizeMe>
            </div>
          </>
        )}
      </Translation>
    );
  }

  renderFederationsContent = () => {
    const { federations } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;

    return (
      <Translation>
        {t => (
          <div style={styles.content}>
            <SizeMe monitorWidth monitorHeight>
              {({ size }) =>
                <List
                  height={size.height}
                  itemCount={Object.keys(federations).length}
                  itemSize={100}
                  width={size.width}
                  itemData={{
                    data: federations,
                    onClick: this.handleAddFederationClick,
                    onUploadClick: this.handleAddFederationPictureClick,
                    isMobile: isMobile
                  }}
                >
                  {Federation}
                </List>
              }
            </SizeMe>
          </div>
        )}
      </Translation>
    );
  }

  renderSeasonsContent = () => {
    const { seasons } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;

    return (
      <Translation>
        {t => (
          <div style={styles.content}>
            <SizeMe monitorWidth monitorHeight>
              {({ size }) =>
                <List
                  height={size.height}
                  itemCount={Object.keys(seasons).length}
                  itemSize={80}
                  width={size.width}
                  itemData={{
                    data: seasons,
                    onClick: this.handleAddSeasonClick,
                    onCategoriesClick: this.handleSeasonCategoriesClick,
                    isMobile: isMobile
                  }}
                >
                  {Season}
                </List>
              }
            </SizeMe>
          </div>
        )}
      </Translation>
    );
  }

  renderSeasonCategoryContent = () => {
    const { data, eventTypes } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;

    if (!data) return null;

    return (
      <Translation>
        {t => (
          <div style={styles.content}>
            <SizeMe monitorWidth monitorHeight>
              {({ size }) =>
                <List
                  height={size.height}
                  itemCount={Object.keys(data.categories).length}
                  itemSize={isMobile ? 100 : 70}
                  width={size.width}
                  itemData={{
                    data: data.categories,
                    types: eventTypes,
                    onRemoveClick: this.handleRemoveSeasonCategoryClick,
                    onTeamsClick: this.handleSeasonCategoryTeamsClick,
                    isMobile: isMobile
                  }}
                >
                  {SeasonCategory}
                </List>
              }
            </SizeMe>
          </div>
        )}
      </Translation>
    );  
  }

  renderSeasonCategoryClubContent = () => {
    const { data, category, organizations } = this.state;
    const { isMobile } = this.props;
    const styles = this.getStyles().view;
    if (!data) return null;

    const currentCategory = data.categories.find(c => c._id === category._id)
    return (
      <Translation>
        {t => (
          <div style={styles.content}>
            <SizeMe monitorWidth monitorHeight>
              {({ size }) =>
                <List
                  height={size.height}
                  itemCount={Object.keys(currentCategory.organizers).length}
                  itemSize={isMobile ? 100 : 100}
                  width={size.width}
                  itemData={{
                    data: currentCategory.organizers,
                    organizations: organizations,
                    onRemoveClick: this.handleRemoveSeasonCategoryClubClick,
                    isMobile: isMobile
                  }}
                >
                  {SeasonCategoryClub}
                </List>
              }
            </SizeMe>
          </div>
        )}
      </Translation>
    );  
  }

  renderContent = (view) => {
    switch (view) {
      case 'users':
        return this.renderUserContent();
      case 'events':
        return this.renderEventContent();
      case 'organizations':
        return this.renderOrganizationContent();
      case 'eventTypes':
        return this.renderEventTypesContent();
      case 'reported':
        return this.renderReportedContent();
      case 'promotions':
        return this.renderPromotionsContent();
      case 'federations':
        return this.renderFederationsContent();
      case 'seasons':
        return this.renderSeasonsContent();
      case 'seasonCategories':
        return this.renderSeasonCategoryContent();
      case 'seasonCategoryTeams':
        return this.renderSeasonCategoryClubContent();
      default:
        return null;
    }
  }

  renderActionButtons = (view) => {
    const styles = this.getStyles().view;

    switch (view) {
      case 'users':
        return (
          <div style={styles.actions}>
            <motion.button
              onClick={() => {this.handleAddUserClick() }}
              style={styles.button}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Translation>
                {t => t('ADD USER')}
              </Translation>
            </motion.button>
          </div>
        );
      case 'events':
        return (
          <div style={styles.actions}>
            <motion.button
              onClick={() => { this.handleAddEventClick() }}
              style={styles.button}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Translation>
                {t => t('ADD EVENT')}
              </Translation>
            </motion.button>
          </div>
        );
      case 'organizations':
        return (
          <div style={styles.actions}>
            <motion.button
              onClick={() => { this.handleAddOrganizationClick() }}
              style={styles.button}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Translation>
                {t => t('ADD ORGANIZATION')}
              </Translation>
            </motion.button>
          </div>
        );
      case 'eventTypes':
        return (
          <div style={styles.actions}>
            <motion.button
              onClick={() => { this.handleAddEventTypeClick() }}
              style={styles.button}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Translation>
                {t => t('ADD CATEGORY')}
              </Translation>
            </motion.button>
          </div>
        );
      case 'federations':
        return (
          <div style={styles.actions}>
            <motion.button
              onClick={() => { this.handleAddFederationClick() }}
              style={styles.button}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Translation>
                {t => t('ADD FEDERATION')}
              </Translation>
            </motion.button>
          </div>
        );
        case 'seasons':
          return (
            <div style={styles.actions}>
              <motion.button
                onClick={() => { this.handleAddSeasonClick() }}
                style={styles.button}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              >
                <Translation>
                  {t => t('ADD SEASON')}
                </Translation>
              </motion.button>
            </div>
          );
        case 'seasonCategories':
          return (
            <div style={styles.actions}>
              <motion.button
                onClick={() => { this.handleAddSeasonCategoryClick() }}
                style={styles.button}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              >
                <Translation>
                  {t => t('ADD CATEGORY')}
                </Translation>
              </motion.button>
            </div>
          );
        case 'seasonCategoryTeams':
          return (
            <div style={styles.actions}>
              <motion.button
                onClick={() => { this.handleAddSeasonCategoryClubClick() }}
                style={styles.button}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              >
                <Translation>
                  {t => t('ADD CLUB')}
                </Translation>
              </motion.button>
            </div>
          );
      default:
        return null;
    }
  }

  handleCloseDialog = ({ reload } = {}) => {
    if (reload) {
      this.fetchInitialData();
    }

    this.setState({
      dialog: null
    });
  }

  handleShowReport = (data) => {
    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: this.renderReportPicture,
        data
      }
    });
  }

  handleAcceptReport = (data) => {
    AdminActions.handleAcceptReport(data);
  }

  handleRejectReport = (data) => {
    AdminActions.handleRejectReport(data);
  }

  handleAcceptPromotion = (data) => {
    AdminActions.handleAcceptPromotion(data);
  }

  handleRejectPromotion = (data) => {
    AdminActions.handleRejectPromotion(data);
  }

  renderReportPicture = (data) => {
    const styles = this.getStyles();

    if (!data || !data.data) return null;
    return (
      <div style={styles.dialog}>
        <div style={styles.header}>
          Reported picture
          <div style={styles.close}>
            <motion.button
              type="button"
              onClick={this.handleCloseDialog}
              style={styles.closeButton}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Icon size={18} icon="close" color="#000" />
            </motion.button>
          </div>
        </div>
        <div style={styles.photoWrapper}>
          <img src={data.data.src} style={styles.photo} alt="Reported" />
        </div>
      </div>
    );
  }

  handleAddUserClick = (data) => {
    const { isMobile } = this.props;
    const header = data ? `EDIT USER ${data.username}` : undefined;

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormUser data={data} header={header} handleClose={this.handleCloseDialog}  isMobile={isMobile}/>
      }
    });
  }

  handleAddEventTypeClick = (data) => {
    const { isMobile } = this.props;
    const header = data ? `EDIT CATEGORY` : undefined;

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormEventType data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile}/>
      }
    });
  }

  handleAddEventClick = (data) => {
    const { eventTypes, seasons, organizations } = this.state;
    const { isMobile } = this.props;
    const header = data ? `EDIT EVENT` : undefined;

    const parsedTypes = eventTypes && eventTypes.map(t => ({label: t.name, value: t._id}));
    const parsedSeasons = seasons && seasons.map(s => ({label: s.name, value: s._id, types: s.types, categories: s.categories }));
    const parsedOrganizations = organizations && organizations.filter(o => o.club).map(o => ({label: o.name, value: o._id}));
    const participants = data && data.organizers.filter(organizer => organizer.mode === 'participant').map(organizer => organizer._id);

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => (
          <FormEvent 
            data={data ? {...data, participants} : null} 
            header={header} 
            eventTypes={parsedTypes} 
            seasons={parsedSeasons} 
            organizations={parsedOrganizations} 
            handleClose={this.handleCloseDialog} 
            isMobile={isMobile}
          />
        )
      }
    });
  }

  handleAddOrganizationClick = (data) => {
    const { isMobile } = this.props;
    const header = data ? 'EDIT ORGANIZATION' : 'ADD ORGANIZATION';

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganization data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleAddEventTypePictureClick = (data) => {
    const { isMobile } = this.props;
    const header = data ? `ADD PICTURE` : null;

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormEventTypePicture data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleAddPictureClick = (data) => {
    const { isMobile } = this.props;
    const header = data ? `ADD PICTURE` : null;

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormEventPicture data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleAddFederationPictureClick = (data) => {
    const { isMobile } = this.props;
    const header = data ? `ADD PICTURE` : null;

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormFederationPicture data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleSeasonCategoriesClick = (data) => {
    this.setState({
      view: 'seasonCategories',
      data
    });
  }

  handleAddPhotographerClick = (data) => {
    const { users } = this.state;
    const { isMobile } = this.props;
    const header = data ? `EDIT PHOTOGRAPHERS` : null;

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormEventPhotographer data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} users={users} />
      }
    });
  }

  handleAddFederationClick = (data) => {
    const { isMobile } = this.props;
    const header = data ? `EDIT FEDERATION` : null;

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormFederation data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleAddSeasonClick = (data) => {
    const { federations } = this.state;
    const { isMobile } = this.props;
    const header = data ? `EDIT SEASON` : null;

    const parsedFederations = federations && federations.map(f => ({label: f.name, value: f._id}));

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormSeason data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} federations={parsedFederations} />
      }
    });
  }

  handleAddSeasonCategoryClick = () => {
    const { eventTypes, data } = this.state;
    const { isMobile } = this.props;
    const header = data ? `EDIT CATEGORY` : null;

    const parsedTypes = eventTypes && eventTypes.map(f => ({label: f.name, value: f._id}));
    const successCallback = ({data}) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_SEASONS',
        data
      });
    }

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormSeasonCategory data={data} header={header} handleClose={this.handleCloseDialog} handleSuccess={successCallback} isMobile={isMobile} types={parsedTypes} />
      }
    });
  }

  handleAddSeasonCategoryClubClick = () => {
    const { organizations, data, category } = this.state;
    const { isMobile } = this.props;
    const header = data ? `EDIT CLUB` : null;

    const parsedOrganizers = organizations && organizations.filter(f => f.club).map(f => ({label: f.name, value: f._id}));
    const successCallback = ({data}) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_SEASONS',
        data
      });
    }

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormSeasonCategoryClub data={{...data, category}} header={header} handleClose={this.handleCloseDialog} handleSuccess={successCallback} isMobile={isMobile} organizers={parsedOrganizers} />
      }
    });
  }

  handleSeasonCategoryTeamsClick = (category) => {
    this.setState({
      view: 'seasonCategoryTeams',
      category
    });
  }

  handleRemoveSeasonCategoryClick = (data) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete category from this season",
        message: "Are you sure you want to delete the category and the clubs inside? You can't undo this action.",
        onClose: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemoveSeasonCategory(data); },
      }
    });
  }

  handleRemoveSeasonCategoryClubClick = (organizerId) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete club from this category",
        message: "Are you sure you want to delete the club from this category? You can't undo this action.",
        onClose: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemoveSeasonCategoryClub(organizerId); },
      }
    });
  }

  handleRemoveSeasonCategoryClub = (organizerId) => {
    const { category, data } = this.state;
    const url = `${baseUrl}/seasons/${data._id}/organizer`;
    const body = { typeId: category.type, organizerId };

    fetch(url, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_SEASONS',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleRemoveSeasonCategory = (category) => {
    const { data } = this.state;
    const url = `${baseUrl}/seasons/${data._id}/type`;
    const body = { typeId: category.type };

    fetch(url, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_SEASONS',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  render() {
    const { view, users, events, organizations, eventTypes, reported, promotions, federations, seasons, dialog } = this.state;
    const styles = this.getStyles();

    if (view) {
      return (
        <div style={styles.view.wrapper}>
          {dialog && <Dialog {...dialog} />}

          {this.renderHeader(view)}
          {this.renderContent(view)}
          {this.renderActionButtons(view)}
        </div>
      );
    }

    return (
      <Translation>
        {t => (
          <div style={styles.background}>
            <div style={styles.wrapper}>
              <div style={eventTypes ? styles.box : styles.disabledBox} onClick={eventTypes && this.handleEventTypesClick}>
                <Icon size={56} icon="calendarBlank" />
                <span style={styles.label}>{t('Categories')}</span>
              </div>
              <div style={events ? styles.box : styles.disabledBox} onClick={events && this.handleEventsClick}>
                <Icon size={56} icon="calendar" />
                <span style={styles.label}>{t('Matches')}</span>
              </div>
              <div style={users ? styles.box : styles.disabledBox} onClick={users && this.handleUsersClick}>
                <Icon size={56} icon="userEmpty" />
                <span style={styles.label}>{t('Users')}</span>
              </div>
              <div style={organizations ? styles.box : styles.disabledBox} onClick={organizations && this.handleOrganizationsClick}>
                <Icon size={56} icon="organization" />
                <span style={styles.label}>{t('Organizers')}</span>
              </div>
              <div style={reported ? styles.box : styles.disabledBox} onClick={reported && this.handleReportedClick}>
                <Icon size={56} icon="report" />
                <span style={styles.label}>{t('Reported')}</span>
              </div>
              <div style={promotions ? styles.box : styles.disabledBox} onClick={promotions && this.handlePromotionsClick}>
                <Icon size={56} icon="userUp" />
                <span style={styles.label}>{t('Promotions')}</span>
              </div>
              <div style={federations ? styles.box : styles.disabledBox} onClick={federations && this.handleFederationsClick}>
                <Icon size={56} icon="federation" />
                <span style={styles.label}>{t('Federations')}</span>
              </div>
              <div style={seasons ? styles.box : styles.disabledBox} onClick={seasons && this.handleSeasonsClick}>
                <Icon size={56} icon="calendarSport" />
                <span style={styles.label}>{t('Seasons')}</span>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default Admin;
