import React from 'react'
import { Translation } from 'react-i18next';
import { formatNumberShortcuts } from '../../utils/number';

const Stats = ({ data, style }) => {
  
  const styles = {
    wrapper: {
      flex: 1,
      borderRadius: 25,
      padding: 10,
      boxSizing: 'border-box',
      overflow: 'hidden',
      position: 'relative',
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
      ...style
    },
    item: {
      marginBottom: 5,
      display: 'flex',
      alignItems: 'center',
    },
    iconWrapper: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: {
      color: '#B1B1B1',
      fontSize: 20,
    },
    number: {
      fontWeight: 600,
      fontSize: 40
    }
  };

  if (!data) return null;

  return (
    <Translation>
      {t => (
        <div style={styles.wrapper}>
          <div style={styles.item}>
            <div style={styles.iconWrapper}>
              <img src="photos.svg" alt="photos" />
            </div>
            <div style={{...styles.textWrapper, alignItems: 'flex-start'}}>
              <div style={styles.label}>{t('photos')}</div>
              <div style={styles.number}>{formatNumberShortcuts(data.images)}</div>
            </div>
          </div>
          <div style={styles.item}>
            <div style={{...styles.textWrapper, alignItems: 'flex-end'}}>
              <div style={styles.label}>{t('users')}</div>
              <div style={styles.number}>{formatNumberShortcuts(data.users)}</div>
            </div>
            <div style={styles.iconWrapper}>
              <img src="user.svg" alt="user" />
            </div>
          </div>
          <div style={styles.item}>
            <div style={styles.iconWrapper}>
              <img src="field.svg" alt="field" />
            </div>
            <div style={{...styles.textWrapper, alignItems: 'flex-start'}}>
              <div style={styles.label}>{t('matches')}</div>
              <div style={styles.number}>{formatNumberShortcuts(data.events)}</div>
            </div>
          </div>
          <div style={styles.item}>
            <div style={{...styles.textWrapper, alignItems: 'flex-end'}}>
              <div style={styles.label}>{t('clubs')}</div>
              <div style={styles.number}>{formatNumberShortcuts(data.organizers)}</div>
            </div>
            <div style={styles.iconWrapper}>
              <img src="shield.svg" alt="shield" />
            </div>
          </div>
        </div>
      )}
    </Translation>
  )
}

export default Stats
