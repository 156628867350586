import React, { Component } from 'react';
import AppStore from './stores/AppStore.js';
import AppActions from './actions/AppActions.js';
import './App.css';
import Welcome from './components/Welcome/Welcome.js';
import AppBar from './components/AppBar/AppBar.js';
import Admin from './components/Admin/Admin.js';
import Profile from './components/Profile/Profile.js';
import AppDispatcher from './components/AppDispatcher/AppDispatcher.js';
import EventView from './components/EventView/EventView.js';
import ContextBar from './components/ContextBar/ContextBar.js';
import Dialog from './components/Dialog/Dialog.js';
import DropzonePicturesContainer from './components/Dropzone/DropzonePicturesContainer.js';
import DropzoneZipContainer from './components/Dropzone/DropzoneZipContainer.js';
import ConfirmationScreen from './components/Screen/ConfirmationScreen.js';
import InvolveScreen from './components/Screen/InvolveScreen.js';
import { motion } from 'framer-motion';
import Icon from './components/Icon/Icon.js';
import Explore from './components/Explore/Explore.js';
import Trending from './components/Explore/Trending.js';
import Search from './components/Explore/Search.js';
import LastUpdated from './components/Explore/LastUpdated.js';
import Year from './components/Explore/Year.js';
import Categories from './components/Explore/Categories.js';
import CategoryDetail from './components/Explore/CategoryDetail.js';
import Organization from './components/Organization/Organization.js';
import Organizations from './components/Explore/Organizations.js';
import EventNotFound from './components/Explore/EventNotFound';
import Photographers from './components/Explore/Photographers.js';

const AppContext = React.createContext();

class App extends Component {
  constructor() {
    super();
    this.store = AppStore;
    this.state = {
      ...this.getDataStore(),
      ...this.getUserFilter()
    };
    this._dispatchToken = AppDispatcher.register(this._registerToActions);
  }

  _registerToActions = (action) => {
    const { selectedEvent, view } = this.state;

    switch (action.actionType) {
      case 'RELOAD_USER':
        this.getUserData();
        break;
      case 'RELOAD_EXTERNAL_USER':
        this.getExternalUserData(action.userId);
        break;
      case 'SELECT_YEAR':
        this.getYearData(action.data);
        break;
      case 'SELECT_EVENT':
        this.getEventData(action._id);
        break;
      case 'SHOW_PICTURE':
        if (!selectedEvent && view !== 'profile' && view !== 'externalProfile') {
          const { eventId, _id } = action.data;
          this.getEventData(eventId, _id);
        }
        break;
      case 'ADD_IMAGES':
        this.uploadEvent();
        break;
      case 'UPDATE_EVENT_PICTURE':
        if (!action.data) {
          this.uploadEvent();
        }
        break;
      case 'REMOVE_EVENT_PICTURE':
        this.uploadEvent();
        break;
      case 'REMOVE_EVENT_PICTURES':
        this.uploadEvent();
        break;
      case 'UPLOAD_PICTURE':
        this.handleRenderUploadDialog(action.origin);
        break;
      case 'UPLOAD_ZIP':
        this.handleRenderUploadZipDialog(action.origin);
        break;
      case 'GO_TO_USER_PROFILE':
        this.getExternalUserDataAndGoToProfile(action.userId, action.data);
        break;
      case 'SET_FILTER':
        this.setState({
          isFilteringEvent: true
        });
        break;
      case 'UNSET_FILTER':
        this.setState({
          isFilteringEvent: false
        });
        break;
      case 'FINISH_ZIP_UPLOAD':
        this.setState({
          dialog: this.getFinishZipDialog()
        });
        break;
      case 'ERROR_ZIP_UPLOAD':
        this.setState({
          dialog: this.getErrorZipDialog()
        });
        break;
      case 'ERROR_PORTFOLIO_UPLOAD':
        this.setState({
          dialog: this.getErrorPortfolioDialog()
        });
        break;
      case 'ERROR_IMAGES_UPLOAD':
        this.setState({
          dialog: this.getErrorZipDialog()
        });
        break;
      case 'UPDATE_FILTER':
        this.setState({
          filter: {
            ...this.state.filter,
            ...action.filter
          }
        });
        
        const keys = Object.keys(action.filter);
        keys.forEach(key => localStorage.setItem(key, action.filter[key]));
        break;
      default:
        break;
    }
  }

  getDataStore = () => {
    return {
      logged: this.store.isLoggedIn(),
      userInfo: this.store.userInfo(),
      currentYear: this.store.currentYear(),
      currentYearData: this.store.currentYearData(),
      selectedEvent: this.store.selectedEvent(),
      externalUserInfo: this.store.externalUserInfo(),
      view: this.store.view(),
      viewData: this.store.viewData(),
      updated: this.store.updated(),
      isFilteringEvent: this.store.view() === 'event' && this.state && this.state.isFilteringEvent
    };
  }

  componentDidMount() {
    this.store.addChangeListener(this._onChange);

    this.getUserData();
    this.getUserFilter();
    this.checkParams();
  }

  componentWillUnmount() {
    this.store.removeChangeListener(this._onChange);
    AppDispatcher.unregister(this._dispatchToken);
  }

  uploadEvent = () => {
    const { selectedEvent } = this.state;

    if (selectedEvent) {
      AppActions.getEventData({ data: selectedEvent });
    }
  }

  checkParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const confirmationToken = urlParams.get('confirmation');
    const involveToken = urlParams.get('involve');

    if (confirmationToken) {
      this.setState({ confirmationToken });
    } else if (involveToken) {
      this.setState({ involveToken });
    }
  }

  handleFinishConfirmation = () => {
    window.history.replaceState({}, document.title, "/");
    this.setState({ confirmationToken: null });
  }

  handleFinishInvolve = () => {
    window.history.replaceState({}, document.title, "/");
    this.setState({ involveToken: null });
  }

  getUserData = () => {
    const token = localStorage.getItem('token');
    const userInfo = localStorage.getItem('userInfo');

    if (token && userInfo) {
      AppActions.getUserData({
        userId: JSON.parse(userInfo).username,
        token
      });
    }
  }

  getUserFilter = () => {
    const federationId = localStorage.getItem('federationId') || null;
    const seasonId = localStorage.getItem('seasonId') || null;
    const categoryId = localStorage.getItem('categoryId') || null;

    return { filter: { federationId, seasonId, categoryId } };
  }

  getExternalUserData = (userId, viewData) => {
    const onSuccess = (data) => {
      AppDispatcher.dispatch({
        actionType: 'GET_EXTERNAL_USER_INFO',
        data: viewData,
        externalUserInfo: data
      });
    }
    AppActions.getExternalUserData({
      userId,
      onSuccess
    });
  }

  getExternalUserDataAndGoToProfile = (userId, viewData) => {
    const onSuccess = (data) => {
      AppDispatcher.dispatch({
        actionType: 'GET_EXTERNAL_USER_INFO_AND_GO_TO_PROFILE',
        view: 'externalProfile',
        data: viewData,
        externalUserInfo: data
      });
    }
    AppActions.getExternalUserData({
      userId,
      viewData,
      onSuccess
    });
  }

  getYearData = (data) => {
    if (data) {
      AppActions.getYearData({
        data: {
          year: data
        }
      });
    }
  }

  getEventData = (_id, selectedImageId) => {
    if (_id) {
      AppActions.getEventData({
        data: { _id, selectedImageId }
      });
    }
  }

  _onChange = () => {
    this.setState(this.getDataStore());
  }

  getStyles = () => {
    const { isMobile } = this.props;

    return {
      wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        backgroundSize: 'cover',
        background: 'rgb(253,253,253)',
        overflow: 'hidden',
      },
      view: {
        overflow: 'auto',
        flex: 1,
        display: 'flex'
      },
      dialog: {
        wrapper: {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          background: '#fff',
        },
        title: {
          flex: 'none',
          padding: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 600,
          fontSize: 24,
          position: 'relative'
        },
        content: {
          flex: 1,
          padding: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 20,
          textAlign: 'center',
        },
        closeButtonWrapper: {
          position: 'absolute',
          right: 5
        },
        closeButton: {
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          background: 'transparent'
        }
      }
    }
  }

  renderView = () => {
    const { isMobile, isTablet } = this.props;
    const { view, viewData, updated, userInfo, filter, selectedMonth, selectedEvent, externalUserInfo, logged, confirmationToken, involveToken } = this.state;

    if (confirmationToken) {
      return <ConfirmationScreen confirmationToken={confirmationToken} onFinish={this.handleFinishConfirmation} />;
    }

    if (involveToken) {
      return <InvolveScreen token={involveToken} onFinish={this.handleFinishInvolve} />;
    }

    if (!filter) return null;

    switch (view) {
      case 'event':
        return <EventView data={selectedEvent} selectedMonth={selectedMonth} isMobile={isMobile} isTablet={isTablet} userInfo={userInfo} />
      case 'admin':
        return <Admin isMobile={isMobile} />;
      case 'profile':
        return <Profile userInfo={userInfo} isMobile={isMobile} data={viewData} />;
      case 'externalProfile':
        return <Profile userInfo={externalUserInfo} isExternal={true} isMobile={isMobile} data={viewData} />;
      case 'organizations':
        return <Organizations userInfo={userInfo} isMobile={isMobile} updated={updated} filter={filter} />;
      case 'organization':
        return <Organization userInfo={userInfo} isMobile={isMobile} data={viewData} />;
      case 'photographers':
        return <Photographers userInfo={userInfo} isMobile={isMobile} updated={updated} />;
      case 'year':
        return <Year userInfo={userInfo} isMobile={isMobile} updated={updated}  filter={filter}/>;
      case 'search':
        return <Search userInfo={userInfo} isMobile={isMobile} updated={updated}/>;
      case 'trending':
        return <Trending userInfo={userInfo} isMobile={isMobile} updated={updated}/>;
      case 'lastUpdated':
        return <LastUpdated userInfo={userInfo} isMobile={isMobile} updated={updated} filter={filter} />;
      case 'categories':
        return <Categories userInfo={userInfo} isMobile={isMobile} updated={updated}/>;
      case 'category':
        return <CategoryDetail userInfo={userInfo} isMobile={isMobile} data={viewData} updated={updated}/>;
      case 'eventNotFound':
        return <EventNotFound isMobile={isMobile} />;
      default:
        return logged ? <Explore isMobile={isMobile} userInfo={userInfo} updated={updated} filter={filter} /> : <Welcome isMobile={isMobile} />;
    }
  }

  handleCloseDialog = ({ reload } = {}) => {
    if (reload) {
      this.fetchInitialData();
    }

    this.setState({
      dialog: null
    });
  }

  handleRenderUploadDialog = (origin) => {
    let dialog;

    switch (origin) {
      case 'event':
        dialog = this.getEventUploadDialog();
        break;
      case 'portfolio':
        dialog = this.getPortfolioUploadDialog();
        break;
      default:
        break;
    }

    this.setState({ dialog });
  }

  getEventUploadDialog = () => {
    const { isMobile } = this.props;
    const { selectedEvent, userInfo } = this.state;
    const header = `UPLOAD PICTURES TO EVENT`;

    return {
      fullScreen: true,
      onClickOutside: this.handleCloseDialog,
      children: () => <DropzonePicturesContainer origin="event" userInfo={userInfo} event={selectedEvent} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} description="Drag 'n' drop some pictures here, or click to select pictures from your computer (max 30 pictures). If you need to upload many files, use the zip method instead." />
    }
  }
  
  getPortfolioUploadDialog = () => {
    const { isMobile } = this.props;
    const { userInfo } = this.state;
    const header = `UPLOAD PICTURES TO PORTFOLIO`;

    return {
      fullScreen: true,
      onClickOutside: this.handleCloseDialog,
      children: () => <DropzonePicturesContainer origin="portfolio" userInfo={userInfo} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} description="Drag 'n' drop some pictures here, or click to select pictures from your computer (max 30 pictures)."/>
    }
  }

  handleRenderUploadZipDialog = (origin) => {
    let dialog;

    switch (origin) {
      case 'event':
        dialog = this.getEventUploadZipDialog();
        break;
      default:
        break;
    }

    this.setState({ dialog });
  }
  
  getEventUploadZipDialog = () => {
    const { isMobile } = this.props;
    const { selectedEvent, userInfo } = this.state;
    const header = `UPLOAD ZIP TO EVENT`;

    return {
      fullScreen: true,
      onClickOutside: this.handleCloseDialog,
      children: () => <DropzoneZipContainer origin="event" userInfo={userInfo} event={selectedEvent} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} description="Drag 'n' drop a zip file here (max 600mb)" />
    }
  }

  getFinishZipDialog = () => {
    return {
      onClickOutside: this.handleCloseDialog,
      children: this.renderSuccessDialog
    }
  }

  getErrorZipDialog = () => {
    return {
      onClickOutside: this.handleCloseDialog,
      children: this.renderErrorDialog
    }
  }

  getErrorPortfolioDialog = () => {
    return {
      onClickOutside: this.handleCloseDialog,
      children: this.renderErrorPortfolioDialog
    }
  }

  renderSuccessDialog = () => {
    const styles = this.getStyles().dialog;

    return (
      <div style={styles.wrapper}>
        <div style={{ ...styles.title, background: '#00d663'}}>
          We have your zip!
          <span style={styles.closeButtonWrapper}>
            <motion.button
              type="button"
              onClick={this.handleCloseDialog}
              style={styles.closeButton}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Icon size={24} icon="close" color="#000" />
            </motion.button>
          </span>
        </div>
        <div style={styles.content}>You will receive an email notifying you about the upload</div>
      </div>
    );
  }

  renderErrorDialog = () => {
    const styles = this.getStyles().dialog;

    return (
      <div style={styles.wrapper}>
        <div style={{ ...styles.title, background: '#f44336'}}>
          Oops! Something went wrong
          <span style={styles.closeButtonWrapper}>
            <motion.button
              type="button"
              onClick={this.handleCloseDialog}
              style={styles.closeButton}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Icon size={24} icon="close" color="#000" />
            </motion.button>
          </span>
        </div>
        <div style={styles.content}>Please, try again later, and if the problem persists, contact with our team.</div>
      </div>
    );
  }

  renderErrorPortfolioDialog = () => {
    const styles = this.getStyles().dialog;

    return (
      <div style={styles.wrapper}>
        <div style={{ ...styles.title, background: '#f44336' }}>
          Oops! Something went wrong
          <span style={styles.closeButtonWrapper}>
            <motion.button
              type="button"
              onClick={this.handleCloseDialog}
              style={styles.closeButton}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Icon size={24} icon="close" color="#000" />
            </motion.button>
          </span>
        </div>
        <div style={styles.content}>You have exceeded the maximum of 30 pictures</div>
      </div>
    );
  }

  render() {
    const { isMobile } = this.props;
    const { logged, userInfo, selectedEvent, view, dialog, isFilteringEvent, updated } = this.state;
    const styles = this.getStyles();

    return (
      <AppContext.Provider value={{ ...this.state, ...this.props }}>
        <div style={styles.wrapper} id="body-portal">
          {dialog && <Dialog {...dialog} />}
          {logged && <AppBar logged={logged} userInfo={userInfo} isMobile={isMobile} updated={updated} />}
          <div style={styles.view}>{this.renderView()}</div>
          {logged && <ContextBar userInfo={userInfo} event={selectedEvent} view={view} logged={logged} isMobile={isMobile} isFilteringEvent={isFilteringEvent} />}
        </div>
      </AppContext.Provider>
    );
  }
}

export { AppContext };

export default App;
