import AppDispatcher from '../../AppDispatcher/AppDispatcher.js';
import { baseUrl } from '../../../config'; 

const AdminActions = {
  fetchUsers: () => {
    fetch(`${baseUrl}/users`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_USERS',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  fetchEventTypes: () => {
    fetch(`${baseUrl}/types`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_EVENT_TYPES',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  fetchEvents: () => {
    fetch(`${baseUrl}/events`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_EVENTS',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  fetchOrganizations: () => {
    fetch(`${baseUrl}/organizers`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_ORGANIZATIONS',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  fetchReported: () => {
    fetch(`${baseUrl}/images/report/list`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_REPORTED',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  fetchPromotions: () => {
    fetch(`${baseUrl}/users/promote/pending`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_PROMOTIONS',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  fetchFederations: () => {
    fetch(`${baseUrl}/federations`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_FEDERATIONS',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  fetchSeasons: () => {
    fetch(`${baseUrl}/seasons`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'FETCH_SEASONS',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  handleAcceptReport: ({ _id }) => {
    fetch(`${baseUrl}/images/${_id}/report/accept`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'RELOAD_REPORTED'
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  handleRejectReport: ({ _id }) => {
    fetch(`${baseUrl}/images/${_id}/report/reject`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'RELOAD_REPORTED'
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  handleAcceptPromotion: ({ _id }) => {
    const body = { status: 'accepted' };
    fetch(`${baseUrl}/users/promote/${_id}/solve`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'RELOAD_PROMOTIONS'
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  handleRejectPromotion: ({ _id }) => {
    const body = { status: 'denied' };
    fetch(`${baseUrl}/users/promote/${_id}/solve`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'RELOAD_PROMOTIONS'
      });
    }).catch((err) => {
      console.log(err);
    });
  }
};

export default AdminActions;
