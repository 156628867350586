import React, { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring';
import { useTrackVisibility } from '../../utils/hooks';

const Carousel = ({ ItemComponent, items, itemKey, itemStyle, itemProps, style, height, speed = 3000 }) => {
  const [play, setPlay] = useState(false);
  const [index, setIndex] = useState(0);
  const [ref, { isVisible }] = useTrackVisibility();
  
  const MAX_INDEX = items && items.length - 1;

  useEffect(() => {
    setPlay(isVisible);
  },[isVisible])

  useEffect(() => {
    let timer;
    if (play) {
      timer = setTimeout(() => {
        setIndex(currentIndex => {
          if (currentIndex >= MAX_INDEX) {
            return 0;
          } else {
            return currentIndex + 1;
          }
        });
      }, speed);
    } else {
      if (timer) {
        clearTimeout(timer)
      }
    }
    return () => clearTimeout(timer);
  }, [play, index]);

  const styles = {
    wrapper: {
      flex: 1,
      borderRadius: 25,
      overflow: 'hidden',
      position: 'relative',
      height,
      ...style
    },
    item: {
      position: 'absolute', 
      top: 0, 
      bottom: 0, 
      left: 0, 
      right: 0,
      display: 'flex',
      ...itemStyle
    }
  };

  if (!items) return null;

  const fadingTextPropsTransition = useTransition(items[index], item => item[itemKey], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 220, friction: 120 },
  });

  return (
    <div style={styles.wrapper} ref={ref}>
      {fadingTextPropsTransition.map(({ item, props, key }) => (
        <animated.div
          key={key}
          style={{ ...props, ...styles.item }}
        >
          <ItemComponent {...item} {...itemProps} />
        </animated.div>
      ))}
    </div>
  )
}

export default Carousel;
