import React, { Component } from 'react'
import Aside from './Aside';
import _ from 'lodash';
import { baseUrl } from '../../config';
import Spinner from 'react-spinkit';
import UserAvatar from '../UserAvatar/UserAvatar';
import EventBox from '../EventBox/EventBox';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import { Translation } from 'react-i18next';
import Footer from '../Footer/Footer';

class Trending extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: false
    };
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 40,
          padding: 20,
        },
        categoriesBlockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        },
        title: {
          fontWeight: 600,
          fontSize: 32,
          flex: 'none',
          marginBottom: 20,
          display: 'flex'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 24,
          flex: 'none',
          marginBottom: 20
        },
        eventsWrapper: {
          flex: 1,
          display: 'flex'
        },
        iconWrapper: {
          width: 45,
          height: 45,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginRight: 10
        },
        icon: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        input: {
          border: 'none',
          background: 'transparent',
          borderBottom: '2px solid #17d86a',
          fontSize: 30,
          width: '100%',
          outline: 'none'
        },
        section: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column'
        },
        sectionHeader: {
          flex: 'none',
          fontSize: 26
        },
        sectionRow: {
          flex: 'none',
          display: 'flex',
        },
        usersRow: {
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 60px)',
          gridTemplateRows: 'repeat(auto-fit, 80px)',
          alignItems: 'center',
          gridGap: 15,
          padding: 15,
          position: 'relative',
          overflow: 'auto',
          boxSizing: 'border-box'
        },
        organizersRow: {
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 100px)',
          gridTemplateRows: 'repeat(auto-fit, 100px)',
          gridGap: 15,
          padding: 15,
          position: 'relative',
          overflow: 'auto',
          justifyContent: 'center',
          boxSizing: 'border-box'
        },
        eventsRow: {
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
          gridAutoRows: 300,
          gridGap: 25
        },
        resultsWrapper: {
          flex: 1,
        },
        placeholder: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontWeight: 600,
          fontSize: 32,
          opacity: 0.2,
          marginTop: 50
        },
        placeholderIcon: {
          width: 200,
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
          filter: 'grayscale(1)'
        },
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 40
        },
        categoriesBlockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        },
        title: {
          fontWeight: 600,
          fontSize: 26,
          flex: 'none',
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 20,
          flex: 'none',
          marginBottom: 20
        },
        eventsWrapper: {
          flex: 1,
          display: 'flex'
        },
        iconWrapper: {
          width: 45,
          height: 45,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginRight: 10
        },
        icon: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        input: {
          border: 'none',
          background: 'transparent',
          borderBottom: '2px solid #17d86a',
          fontSize: 18,
          width: '100%',
          outline: 'none'
        },
        section: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column'
        },
        sectionHeader: {
          flex: 'none',
          fontSize: 26
        },
        sectionRow: {
          flex: 'none',
          display: 'flex',
        },
        usersRow: {
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 60px)',
          gridTemplateRows: 'repeat(auto-fit, 80px)',
          alignItems: 'center',
          gridGap: 15,
          padding: 15,
          position: 'relative',
          overflow: 'auto',
          boxSizing: 'border-box',
          justifyContent: 'center',
        },
        eventsRow: {
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 100%)',
          gridAutoRows: 300,
          gridGap: 25
        },
        resultsWrapper: {
          flex: 1,
        },
        organizersRow: {
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 100px)',
          gridTemplateRows: 'repeat(auto-fit, 100px)',
          alignItems: 'center',
          gridGap: 15,
          padding: 15,
          position: 'relative',
          overflow: 'auto',
          boxSizing: 'border-box',
          justifyContent: 'center',
        },
        placeholder: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontWeight: 600,
          fontSize: 24,
          textAlign: 'center',
          opacity: 0.2,
          marginTop: 50
        },
        placeholderIcon: {
          width: 150,
          height: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
          filter: 'grayscale(1)'
        },
      }
    }
  }

  handleChange = _.debounce((search) => {
    if (!search) return null;

    const body = { filter: search };

    this.setState({ loading: true });
    fetch(`${baseUrl}/filter`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      if (data) {
        this.setState({
          data,
          loading: false
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }, 500)

  renderOrganizers = (data) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (data.length === 0) return null;

    return (
      <Translation>
        {t => (
          <div style={styles.section}>
            <h1 style={styles.sectionHeader}>
              {t('Organizers')} ({data.length})
            </h1>
            <div style={styles.organizersRow}>
              { data.map(organizer => <OrganizationAvatar key={organizer._id} {...organizer} detailed={true} />) }
            </div>
          </div>
        )}
      </Translation>
    );
  }

  renderUsers = (data) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (data.length === 0) return null;

    return (
      <Translation>
        {t => (
          <div style={styles.section}>
            <h1 style={styles.sectionHeader}>
              {t('Users')} ({data.length})
            </h1>
            <div style={styles.usersRow}>
              {data.map(user => <UserAvatar key={user.username} {...user} detailed={true} />)}
            </div>
          </div>
        )}
      </Translation>
    );
  }

  renderEvents = (data) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (data.length === 0) return null;

    return (
      <Translation>
        {t => (
          <div style={styles.section}>
            <h1 style={styles.sectionHeader}>
              {t('Events')} ({data.length})
            </h1>
            <div style={styles.eventsRow}>
              { data.map(event => <EventBox key={event._id} {...event} />) }
            </div>
          </div>
        )}
      </Translation>
    );
  }

  renderContent = () => {
    const { data } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <Translation>
        {t => (
          <div style={styles.blockWrapper}>
            <div style={styles.title}>
              <div style={styles.iconWrapper}><img style={styles.icon} src="search.svg" alt="search" /></div>
              {t('search')}
            </div>
            <input placeholder={t("Search events, users, organizers...")} style={styles.input} type="text" onChange={(e) => this.handleChange(e.currentTarget.value)} />
            <div style={styles.eventsWrapper}>
              {data && (data.users.length > 0 || data.organizers.length > 0 || data.events > 0) ?
                (
                  <div style={styles.resultsWrapper}>
                    {this.renderUsers(data.users)}
                    {this.renderOrganizers(data.organizers)}
                    {this.renderEvents(data.events)}
                  </div> 
                )
                : (
                  <div style={styles.placeholder}>
                    <div style={styles.placeholderIcon}><img style={styles.icon} src="search.svg" alt="search" /></div>
                    {t("Your current search doesn't match any result")}
                  </div>
                )
              }
            </div>
          </div>
        )}
      </Translation>
    );
  }

  renderSpinner = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.overlay}>
        <Spinner name="folding-cube" color="#00d663" />
      </div>
    );
  }

  render() {
    const { isMobile, userInfo, updated } = this.props;
    const { loading } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        <Aside isMobile={isMobile} userInfo={userInfo} updated={updated} />
        {loading && this.renderSpinner()}
        <div style={styles.content}>
          {this.renderContent()}
          <Footer isMobile={isMobile} />
        </div>
      </div>
    )
  }
}

export default Trending;