import React from 'react';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import Link from './Link';
import get from 'lodash/get';


const Aside = (props) => {
  const { isMobile, options } = props;

  const getStyles = () => {
    return {
      desktop: {
        aside: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box'
        },
        linksWrapper: {
          flex: 1,
          display: 'flex',
          boxSizing: 'border-box',
          padding: 10
        },
      },
      mobile: {
        aside: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          padding: 15,
        },
        linksWrapper: {
          flex: 1,
          display: 'flex',
          boxSizing: 'border-box'
        },
      }
    }
  }

  const styles = isMobile ? getStyles().mobile : getStyles().desktop;

  const renderExploreLinks = () => {
    return (
      <div style={styles.linksWrapper}>
        {options.map(action => renderLink(action))}
      </div>
    );
  }

  const renderLink = (data) => {
    return (
      <Link
        key={data.id}
        {...data}
        bubble={get(props, data.bubble)}
        onClick={() => handleLinkClick(data)}
        isMobile={isMobile}
      />
    );
  }

  const handleLinkClick = ({ id }) => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: id
    });
  }

  if (isMobile) return null;

  return (
    <div style={styles.aside}>
      {renderExploreLinks()}
    </div>
  )
}

Aside.defaultProps = {
  options: [
    { id: 'trending', icon: 'fire.svg', color: '#ff6242', label: 'Trending' },
    { id: 'photographers', icon: 'camera.svg', color: '#77849D', label: 'Photographers' },
    { id: 'organizations', icon: 'competitions.svg', color: '#ffb000', label: 'Competitions' },
    { id: 'lastUpdated', icon: 'watch.svg', color: '#000000', label: 'Updated', bubble: 'updated.events' },
    { id: 'year', icon: 'telescope.svg', color: '#666666', label: 'Explore' },
    { id: 'search', icon: 'search.svg', color: '#00b8f0', label: 'Search' },
  ]
};

export default Aside
