import React, { useState } from 'react';
import UserAvatar from '../UserAvatar/UserAvatar';

const CustomUserOption = (props) => {
  const [isOver, setIsOver] = useState(false);

  const { data, innerRef, innerProps } = props;

  const style = {
    wrapper: {
      display: 'flex',
      padding: 8,
      boxSizing: 'border-box',
      alignItems: 'center',
      background: isOver ? '#daf8e7' : 'transparent'
    },
    thumbWrapper: {
      marginRight: 5,
      flex: 'none',
      width: 25,
      height: 25,
      overflow: 'hidden',
      borderRadius: '50%'
    },
    thumb: {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    }
  }

  const handleMouseEnter = () => {
    setIsOver(true);
  }

  const handleMouseOut = () => {
    setIsOver(false);
  }

  return (
    <div style={style.wrapper} ref={innerRef} {...innerProps} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut}>
      <UserAvatar horizontal={true} thumb={data.thumb} size={30} username={data.label} disabled={true} />
    </div>
  );
}

export default CustomUserOption;