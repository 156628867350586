import React from 'react';

const List = ({ data, ItemComponent, NoContentComponent, style, itemStyle, ...rest}) => {
  const styles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      minHeight: 0,
      flexDirection: 'column',
      overflow: 'auto',
      ...style,
    },
  };

  const renderContent = () => {
    if (data && data.length === 0 && NoContentComponent) return NoContentComponent;

    if (!ItemComponent) return null;

    return data.map((item, index) => <ItemComponent key={index} {...rest} style={itemStyle} {...item} />);
  };


  return (
    <div style={styles.wrapper}>
      {renderContent()}
    </div>
  )
}

export default List;
