import React from 'react';
import { Tooltip } from 'react-tippy';
import Icon from '../../Icon/Icon';
import moment from 'moment';
import { motion } from 'framer-motion';
import { Translation } from 'react-i18next';

class Promotion extends React.Component {
  getStyles() {
    const { style, data: { onClick, isMobile } } = this.props;

    return {
      wrapper: {
        ...style,
        display: 'flex',
        padding: 10,
        boxSizing: 'border-box',
        cursor: onClick ? 'pointer' : 'default',
        alignItems: 'center'
      },
      avatarWrapper: {
        width: isMobile ? 30 : 50,
        height: isMobile ? 30 : 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
      },
      circle: {
        background: '#00d663',
        borderRadius: '50%',
        width: isMobile ? 30 : 50,
        height: isMobile ? 30 : 50,
        overflow: 'hidden'
      },
      photo: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      },
      nameWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      },
      main: {
        flex: 1,
        fontWeight: 600,
        fontSize: isMobile ? 12 : 16,
        alignItems: 'center',
        display: 'flex'
      },
      description: {
        flex: 1,
        fontWeight: 100,
        color: '#6f6f6f',
        fontSize: isMobile ? 10 : 14,
        alignItems: 'center',
        display: 'flex'
      },
      box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: isMobile ? 50 : 100
      },
      button: {
        background: '#00d663',
        color: '#000',
        padding: '5px 10px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'auto'
      },
      status: {
        fontWeight: 600,
        color: '#20ce78',
        textTransform: 'uppercase',
        fontSize: 14,
        marginLeft: 3
      }
    }
  }

  handleAcceptClick = (e) => {
    e.stopPropagation();
    const { data, index } = this.props;
    const { onAcceptClick } = data;
    const current = data.data[index];

    onAcceptClick(current);
  }

  handleRejectClick = (e) => {
    e.stopPropagation();
    const { data, index } = this.props;
    const { onRejectClick } = data;
    const current = data.data[index];

    onRejectClick(current);
  }

  render() {
    const { data, index } = this.props;
    const { onAcceptClick, onRejectClick, isMobile } = data;
    const styles = this.getStyles();
    const current = data.data[index];
    const { username, role, thumb, dateTime } = current;

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <div style={styles.avatarWrapper}>
              <div style={styles.circle}>
                {thumb && <img style={styles.photo} src={thumb} alt={username} />}
              </div>
            </div>
            <div style={styles.nameWrapper}>
              <div style={styles.description}>{moment(dateTime).format('DD/MM/YY HH:mm')}</div>
              <div style={styles.main}>{username}</div>
              <div style={styles.description}>{t('Promotion request')}: <span style={styles.status}>{role}</span></div>
            </div>
            <div style={styles.box}>
              <Tooltip
                title={t("Accept promotion")}
                position={'bottom'}
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <motion.button
                  type="button"
                  onClick={onAcceptClick ? this.handleAcceptClick : null}
                  style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Icon icon="thumbsUp" size={isMobile ? 16 : 24} color="#00d663" />
                </motion.button>
              </Tooltip>
            </div>
            <div style={styles.box}>
              <Tooltip
                title={t("Reject promotion")}
                position={'bottom'}
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <motion.button
                  type="button"
                  onClick={onRejectClick ? this.handleRejectClick : null}
                  style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Icon icon="thumbsDown" size={isMobile ? 16 : 24} color="#f44336" />
                </motion.button>
              </Tooltip>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default Promotion;
