import React from 'react';
import { motion } from 'framer-motion';
import Icon from '../../Icon/Icon';
import moment from 'moment';
import AppDispatcher from '../../AppDispatcher/AppDispatcher';
import { Tooltip } from 'react-tippy';
import { baseUrl } from '../../../config'; 
import { AppContext } from '../../../App';
import OrganizationAvatar from '../../OrganizationAvatar/OrganizationAvatar';
import { Translation } from 'react-i18next';

const modes = {
  gold: { color: '#FFD54F' },
  silver: { color: '#D7D7D7' },
  bronze: { color: '#AD8A56' },
  participant: {color: 'transparent'}
};

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      showReportOverlay: false,
      showReportFeedback: null,
    };
    this.listRef = React.createRef();
  }

  componentWillUnmount() {
    this.removeListeners();
  }
  
  getStyles() {
    const { data } = this.props;
    const { organizer } = data;

    return {
      desktop: {
        wrapper: {
          display: 'flex',
          alignItems: 'center',
          height: 54,
          background: '#fff',
          color: '#000',
          boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
          padding: 16,
          boxSizing: 'border-box',
          zIndex: 1
        },
        gap: {
          flex: 1
        },
        infoWrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        },
        info: {
          display: 'flex'
        },
        author: {
          fontWeight: 600
        },
        date: {
          opacity: 1,
          marginRight: 20,
          color: '#b5b5b5'
        },
        buttonValue: {
          marginRight: 3
        },
        flatButton: {
          padding: '5px 20px',
          fontWeight: 600,
          fontSize: 14,
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          color: '#00d663',
          alignItems: 'center'
        },
        avatar: {
          width: 40,
          height: 40,
          borderRadius: '50%',
          overflow: 'hidden',
          background: '#00d663',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textTransform: 'uppercase',
          fontWeight: 600,
          color: '#000',
          padding: 2,
          boxSizing: 'border-box',
          cursor: 'pointer',
          marginRight: 10
        },
        picture: {
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          borderRadius: '50%'
        },
        overlay: {
          position: 'absolute',
          top: 65,
          right: 55,
          width: 200,
          background: '#fff',
          boxShadow: '0 0 13px 1px rgba(0, 0, 0, 0.10)',
          borderRadius: 4,
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
        },
        sponsorWrapper: {
          display: 'flex',
          textAlign: 'right',
          flexDirection: 'column',
          marginRight: 10,
        },
        sponsorLabel: {
          flex: 'none',
          fontSize: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
        sponsorName: {
          fontWeight: 600,
          fontSize: 12,
          flex: 1,
        },
        sponsorMedal: {
          width: 10,
          height: 10,
          borderRadius: '50%',
          marginRight: 5,
          background: organizer && modes[organizer.mode].color,
          display: 'inline-block',
        }
      },
      mobile: {
        wrapper: {
          display: 'flex',
          height: 90,
          background: '#fff',
          color: '#000',
          boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
          padding: 5,
          boxSizing: 'border-box',
          flexDirection: 'column',
          zIndex: 1
        },
        main: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        },
        sub: {
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column'
        },
        gap: {
          flex: 1
        },
        infoWrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        },
        info: {
          display: 'flex',
          alignItems: 'center'
        },
        author: {
          fontWeight: 600
        },
        date: {
          opacity: 1,
          fontsize: 12,
          color: '#b5b5b5'
        },
        buttonValue: {
          marginRight: 3
        },
        flatButton: {
          padding: '5px 20px',
          fontWeight: 600,
          fontSize: 14,
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          color: '#00d663',
          alignItems: 'center'
        },
        avatar: {
          width: 40,
          height: 40,
          borderRadius: '50%',
          overflow: 'hidden',
          background: '#00d663',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textTransform: 'uppercase',
          fontWeight: 600,
          color: '#000',
          padding: 2,
          boxSizing: 'border-box',
          cursor: 'pointer',
          marginRight: 10
        },
        picture: {
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          borderRadius: '50%'
        },
        overlay: {
          position: 'absolute',
          top: 45,
          right: 10,
          left: 10,
          background: '#fff',
          boxShadow: '0 0 13px 1px rgba(0, 0, 0, 0.10)',
          borderRadius: 4,
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
        },
        sponsorWrapper: {
          display: 'flex',
          textAlign: 'right',
          alignItems: 'center',
        },
        sponsorLabel: {
          flex: 'none',
          fontSize: 8,
          display: 'flex',
          alignItems: 'center',
          marginRight: 5,
        },
        sponsorName: {
          fontWeight: 600,
          fontSize: 10,
          flex: 1,
        },
        sponsorMedal: {
          width: 7,
          height: 7,
          borderRadius: '50%',
          marginRight: 5,
          background: organizer && modes[organizer.mode].color,
          display: 'inline-block',
        }
      }
    }
  }

  handleUsersClick = () => {
    const { data } = this.props;

    if (data.users && data.users.length > 0) {
      AppDispatcher.dispatch({
        actionType: 'SHOW_USERS_PICTURE_OVERLAY',
        data: data
      });
    }
  }

  handleBibnumbersClick = () => {
    const { data } = this.props;

    if (data.bibnumbers && data.bibnumbers.length > 0) {
      AppDispatcher.dispatch({
        actionType: 'SHOW_BIBNUMBERS_PICTURE_OVERLAY',
        data: data
      });
    }
  }

  handleLikesClick = () => {
    const { data } = this.props;

    if (data.likes && data.likes.length > 0) {
      AppDispatcher.dispatch({
        actionType: 'SHOW_LIKES_PICTURE_OVERLAY',
        data: data
      });
    }
  }

  handleTagClick = () => {
    const { data } = this.props;

    AppDispatcher.dispatch({
      actionType: 'SHOW_TAG_PICTURE_OVERLAY',
      data: data
    });
  }

  handleTagBibnumberClick = () => {
    const { data } = this.props;

    AppDispatcher.dispatch({
      actionType: 'SHOW_TAG_BIBNUMBER_PICTURE_OVERLAY',
      data: data
    });
  }

  handleDownload = () => {
    const { data } = this.props;
    const src = `https://moments.fra1.digitaloceanspaces.com/web/${data._id}.jpeg`;
    const a = document.createElement("a");

    a.href = src;
    a.target = `_blank`;

    if (document.createEvent) {
      let event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      a.dispatchEvent(event);
    } else {
      a.click();
    }
  }

  handleRemoveClick = () => {
    const { data } = this.props;

    AppDispatcher.dispatch({
      actionType: 'REMOVE_PICTURE',
      data: data
    });
  }

  handleLikeClick = () => {
    const { data } = this.props;

    AppDispatcher.dispatch({
      actionType: 'LIKE_PICTURE',
      data: data
    });
  }

  handleUserClick = () => {
    const { data } = this.props;
    const username = JSON.parse(localStorage.getItem('userInfo')).username;
    if (data.userId === username) {
      AppDispatcher.dispatch({
        actionType: 'CHANGE_VIEW',
        view: 'profile'
      });
    } else {
      AppDispatcher.dispatch({
        actionType: 'GO_TO_USER_PROFILE',
        userId: data.userId
      });
    }
  }

  handleReportClick = () => {
    const { showReportOverlay } = this.state;
    this.setState({
      showReportOverlay: !showReportOverlay,
      showReportFeedback: null,
    });

    if (showReportOverlay) {
      this.removeListeners();
    } else {
      this.addListeners();
    }
  }

  removeListeners = () => {
    document.removeEventListener('mouseup', this.handleListener);
  };

  addListeners = () => {
    document.addEventListener('mouseup', this.handleListener);
  };

  handleListener = e => {
    if (this.listRef.current && !this.listRef.current.contains(e.target)) {
      this.handleReportClick();
    }
  };

  handleReportOptionClick = (report) => {
    const { data } = this.props;
    const body = { report };
    fetch(`${baseUrl}/images/${data._id}/report`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.setState({
        reportFeedback: 'Thank you!'
      });
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE'
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  checkIfLiked = () => {
    const { data } = this.props;
    const { likes } = data;
    const username = JSON.parse(localStorage.getItem('userInfo')).username;

    return likes && likes.find(u => u.username === username);
  }

  renderReportOverlay = ({ isMobile }) => {
    const { reportFeedback } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (reportFeedback) {
      return (
        <div style={styles.overlay} ref={this.listRef}>
          <Translation>
            {t => t(reportFeedback)}
          </Translation>
        </div>
      );
    }

    return (
      <Translation>
        {t => (
          <div style={styles.overlay} ref={this.listRef}>
            <motion.button
              onClick={() => this.handleReportOptionClick('complaint')}
              style={{...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
              whileHover={{ color: '#1fb162' }}
            >
              {t('Abusive or harmful')}
            </motion.button>
            <motion.button
              onClick={() => this.handleReportOptionClick('duplicate')}
              style={{...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
              whileHover={{ color: '#1fb162' }}
            >
              {t('Duplicated')}
            </motion.button>
            <motion.button
              onClick={() => this.handleReportOptionClick('quality')}
              style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600 }}
              whileHover={{ color: '#1fb162' }}
            >
              {t('Poor quality')}
            </motion.button>
          </div>
        )}
      </Translation>
    );
  }

  renderDesktopContent = () => {
    const { showReportOverlay } = this.state;
    const { data, handleClose, hasBibnumbers } = this.props;
    const { alias, userId, dateTime, likes, users, bibnumbers, comments, thumb, visits, organizer, hideAuthor } = data;
    const isLiked = this.checkIfLiked();
    const styles = this.getStyles().desktop;
    const username = JSON.parse(localStorage.getItem('userInfo')).username;
    const organizerClickData = organizer && organizer.mode !== 'participant' ? {showEvents: true } : null;

    if (!data || !data._id) return null;

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            {!hideAuthor && <div style={styles.avatar} onClick={this.handleUserClick}>
              <img src={thumb} alt="username" style={styles.picture} />
            </div>}
            <div style={styles.infoWrapper}>
              {!hideAuthor && <motion.button
                onClick={this.handleUserClick}
                style={Object.assign({}, styles.flatButton, { padding: 0, background: 'transparent' })}
                whileHover={{ color: '#1fb162' }}
              >
                <div style={styles.author}>{alias}</div>
              </motion.button>}
              <div style={styles.info}>
                <div style={styles.date}>{moment(dateTime).format('DD/MM/YYYY HH:mm')}</div>
                <motion.button
                  onClick={this.handleLikesClick}
                  style={Object.assign({}, styles.flatButton, { padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 })}
                  whileHover={{ color: '#1fb162' }}
                >
                  <Icon size={18} icon="heartEmpty" />
                  {likes.length}
                </motion.button>
                <motion.button
                  onClick={this.handleUsersClick}
                  style={Object.assign({}, styles.flatButton, { padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 })}
                  whileHover={{ color: '#1fb162' }}
                >
                  <Icon size={18} icon="userEmpty" />
                  {users.length}
                </motion.button>
                <div style={{ ...styles.flatButton, cursor: 'default', padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 }}>
                  <Icon size={18} icon="eyeEmpty" />
                  {visits}
                </div>
                <div style={{ ...styles.flatButton, cursor: 'default', padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 }}>
                  <Icon size={18} icon="chatEmpty" />
                  {comments.length}
                </div>
                {hasBibnumbers && 
                  <motion.button
                    onClick={this.handleBibnumbersClick}
                    style={Object.assign({}, styles.flatButton, { padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 })}
                    whileHover={{ color: '#1fb162' }}
                  > 
                    <Icon size={18} icon="idCard" />
                    {bibnumbers.length}
                  </motion.button>
                }
              </div>
            </div>
            {organizer && organizer.mode !== 'participant' &&
              <div style={styles.sponsorWrapper}>
                <div style={styles.sponsorLabel}>
                  <span style={styles.sponsorMedal} />
                  {t('Photo Sponsor')}
                </div>
                <div style={styles.sponsorName}>{organizer.name}</div>
              </div>
            }
            {organizer && <div style={styles.avatar}>
              <OrganizationAvatar {...organizer} clickData={organizerClickData} size={40} style={{ padding: 5, boxSizing: 'content-box' }} />
            </div>}
            <Tooltip
              title={t("Like picture")}
              position={'bottom'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={this.handleLikeClick}
                style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000', marginRight: 5 })}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={24} icon={isLiked ? 'heart' : 'heartEmpty'} color={isLiked ? '#f44336' : '#000'} />
              </motion.button>
            </Tooltip>
            <Tooltip
              title={t("Tag users")}
              position={'bottom'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={this.handleTagClick}
                style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000', marginRight: 5 })}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={22} icon="tag" color="#000" />
              </motion.button>
            </Tooltip>
            {hasBibnumbers && 
              <Tooltip
                title={t("Tag bibnumers")}
                position={'bottom'}
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <motion.button
                  onClick={this.handleTagBibnumberClick}
                  style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000', marginRight: 5 })}
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Icon size={22} icon="idCard" color="#000" />
                </motion.button>
              </Tooltip>
            }
            <Tooltip
              title={t("Download picture")}
              position={'bottom'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={this.handleDownload}
                style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000' })}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={22} icon="download" color="#000" />
              </motion.button>
            </Tooltip>
            {userId === username &&
              <Tooltip
                title={t("Remove picture")}
                position={'bottom'}
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <motion.button
                  onClick={this.handleRemoveClick}
                  style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000', marginRight: 5 })}
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Icon size={22} icon="trash" color="#000" />
                </motion.button>
              </Tooltip>
            }
            <Tooltip
              title={t("Report picture")}
              position={'bottom'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={this.handleReportClick}
                style={Object.assign({}, styles.flatButton, { color: '#000', padding: '5px 10px 5px 0', background: 'transparent' })}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={24} icon="report" />
              </motion.button>
            </Tooltip>
            <Tooltip
              title={t("Close")}
              position={'bottom'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={() => handleClose()}
                style={Object.assign({}, styles.flatButton, { color: '#000', padding: '5px 0 5px 10px', background: 'transparent', borderLeft: '1px solid rgba(0, 0, 0, 0.14)' })}
                whileHover={{ color: '#1fb162' }}
              >
                <Icon size={24} icon="close" />
              </motion.button>
            </Tooltip>
            {showReportOverlay && this.renderReportOverlay({isMobile: false})}
          </div>
        )}
      </Translation>
    );
  }

  renderMobileContent = () => {
    const { showReportOverlay } = this.state;
    const { data, handleClose, hasBibnumbers } = this.props;
    const { alias, userId, dateTime, likes, users, bibnumbers, comments, thumb, visits, organizer, hideAuthor } = data;
    const isLiked = this.checkIfLiked();
    const styles = this.getStyles().mobile;
    const username = JSON.parse(localStorage.getItem('userInfo')).username;
    const organizerClickData = organizer && organizer.mode !== 'participant' ? {showEvents: true } : null;

    if (!data || !data._id) return null;
    
    return (
      <div style={styles.wrapper}>
        <div style={styles.main}>
          {!hideAuthor && <div style={styles.avatar} onClick={this.handleUserClick}>
            <img src={thumb} alt="username" style={styles.picture} />
          </div>}
          {organizer && <div style={styles.avatar}>
            <OrganizationAvatar {...organizer} clickData={organizerClickData} size={40} style={{ padding: 5, boxSizing: 'content-box' }} />
          </div>}
          <div style={{flex: 1}}></div>
          <motion.button
            onClick={this.handleLikeClick}
            style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000', marginRight: 5 })}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.8 }}
          >
            <Icon size={24} icon={isLiked ? 'heart' : 'heartEmpty'} color={isLiked ? '#f44336' : '#000'} />
          </motion.button>
          <motion.button
            onClick={this.handleTagClick}
            style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000', marginRight: 5 })}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.8 }}
          >
            <Icon size={22} icon="tag" color="#000" />
          </motion.button>
          {hasBibnumbers && <motion.button
            onClick={this.handleTagBibnumberClick}
            style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000', marginRight: 5 })}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.8 }}
          >
            <Icon size={22} icon="idCard" color="#000" />
          </motion.button>}
          <motion.button
            onClick={this.handleDownload}
            style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000' })}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.8 }}
          >
            <Icon size={22} icon="download" color="#000" />
          </motion.button>
          {userId === username &&
            <motion.button
              onClick={this.handleRemoveClick}
              style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000' })}
              whileHover={{ scale: 1.5 }}
              whileTap={{ scale: 0.8 }}
            >
              <Icon size={22} icon="trash" color="#000" />
            </motion.button>
          }
          <motion.button
            onClick={this.handleReportClick}
            style={Object.assign({}, styles.flatButton, { color: '#000', padding: '5px 10px 5px 0', background: 'transparent' })}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.8 }}
          >
            <Icon size={22} icon="report" />
          </motion.button>
          <motion.button
            onClick={handleClose}
            style={Object.assign({}, styles.flatButton, { color: '#000', padding: '5px 0 5px 10px', background: 'transparent', borderLeft: '1px solid rgba(0, 0, 0, 0.14)' })}
            whileHover={{ color: '#1fb162' }}
          >
            <Icon size={24} icon="close" />
          </motion.button>
        </div>
        <div style={styles.sub}>
          <div style={{display: 'flex'}}>
            {!hideAuthor && <motion.button
              onClick={this.handleUserClick}
              style={Object.assign({}, styles.flatButton, { padding: 0, background: 'transparent', marginRight: 10 })}
              whileHover={{ color: '#1fb162' }}
            >
              <div style={styles.author}>{alias}</div>
            </motion.button>}
            {organizer && organizer.mode !== 'participant' &&
              <div style={styles.sponsorWrapper}>
                <div style={styles.sponsorLabel}>
                  <span style={styles.sponsorMedal} />
                  <Translation>
                  {t => t('Photo Sponsor')}
                  </Translation>
                </div>
                <div style={styles.sponsorName}>{organizer.name}</div>
              </div>
            }
          </div>
          <div style={styles.info}>
            <div style={styles.date}>{moment(dateTime).format('DD/MM/YYYY HH:mm')}</div>
            <div style={styles.gap}></div>
            <motion.button
              onClick={this.handleLikesClick}
              style={Object.assign({}, styles.flatButton, { padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 })}
              whileHover={{ color: '#1fb162' }}
            >
              <Icon size={18} icon="heartEmpty" />
              {likes.length}
            </motion.button>
            <motion.button
              onClick={this.handleUsersClick}
              style={Object.assign({}, styles.flatButton, { padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 })}
              whileHover={{ color: '#1fb162' }}
            >
              <Icon size={18} icon="userEmpty" />
              {users.length}
            </motion.button>
            <div style={{ ...styles.flatButton, cursor: 'default', padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 }}>
              <Icon size={18} icon="eyeEmpty" />
              {visits}
            </div>
            <div style={{ ...styles.flatButton, cursor: 'default', padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 }}>
              <Icon size={18} icon="chatEmpty" />
              {comments.length}
            </div>
            {hasBibnumbers && 
              <motion.button
                onClick={this.handleBibnumbersClick}
                style={Object.assign({}, styles.flatButton, { padding: 0, background: 'transparent', color: '#b5b5b5', fontWeight: 600, marginRight: 5 })}
                whileHover={{ color: '#1fb162' }}
              >
                <Icon size={18} icon="idCard" />
                {bibnumbers.length}
              </motion.button>
            }
          </div>
        </div>
        {showReportOverlay && this.renderReportOverlay({ isMobile: true })}
      </div>
    );
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ isMobile }) => {
          return isMobile ? this.renderMobileContent() : this.renderDesktopContent()
        }}
      </AppContext.Consumer>
    );
  }
}

export default Header;
