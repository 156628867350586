import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import { baseUrl } from '../../config'; 
import Select from "react-select";
import CustomUserOption from '../Options/CustomUserOption';
import handleResponse from '../../utils/handleResponse';
import { Translation } from 'react-i18next';

const MySelect = ({ onChange, onBlur, id, label, components, options, value, error, touched, placeholder }) => {
  const handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    onChange(id, value);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    onBlur(id, true);
  };

  const getOption = () => {
    if (!value || !options) return '';

    return options.find(o => o.value === value);
  }

  return (
    <Translation>
      {t => (
        <>
          <label htmlFor={id}>{t(label)}</label>
          <Select
            id={id}
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            value={getOption()}
            placeholder={t(placeholder)}
            components={components}
          />
          {!!error && touched && (
            <div style={{ color: "#00d663", marginTop: ".5rem" }}>
              {t(error)}
            </div>
          )}
        </>
      )}
    </Translation>
  );
}

class FormOrganizationMember extends Component {
  constructor() {
    super();
    this.state = {
      users: null
    };
  }

  componentDidMount() {
    this.obtainUsers();
  }

  obtainUsers = () => {
    const url = `${baseUrl}/users`;
    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((users) => {
      if (users) {
        this.setState({ 
          users: users.map(user => ({ value: user.username, label: `${user.name} ${user.surname} (${user.username})`, thumb: user.thumb }))
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getStyles = () => {
    const { isMobile } = this.props;

    return {
      wrapper: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        background: '#fff',
      },
      form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      },
      fields: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        overflow: 'auto',
        padding: 20,
      },
      field: {
        marginBottom: 10,
        flexDirection: 'column',
        display: 'flex'
      },
      label: {
        marginBottom: 10,
        paddingLeft: 2
      },
      input: {
        border: '1px solid transparent',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40
      },
      checkbox: {
        fontSize: 16,
      },
      inputError: {
        border: '1px solid #00d663',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40
      },
      feedback: {
        marginBottom: 10,
        color: '#f44336',
        fontSize: 16,
        textAlign: 'center',
      },
      inputFeedback: {
        color: '#00d663',
        fontSize: 12,
        marginTop: 5
      },
      header: {
        background: '#00d663',
        color: '#000',
        flex: 'none',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: isMobile ? 16 : 20,
        position: 'relative'
      },
      buttons: {
        flex: 'none',
        height: 50,
        background: '#f9f8f8',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: isMobile ? 5 : 20
      },
      mainButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      gap: {
        flex: 1
      },
      secondaryButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      button: {
        background: '#00d663',
        color: '#fff',
        padding: isMobile ? '5px 15px' : '5px 30px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none'
      },
      close: {
        position: 'absolute',
        right: 10
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      }
    }
  }

  customUserOption = (props) => {
    return (
      <CustomUserOption {...props} />
    );
  }

  handleSubmit = (values,  actions) => {
    const { handleClose, data, organizationId } = this.props;
    const url = data ? `${baseUrl}/organizers/${organizationId}/members/${data._id}` : `${baseUrl}/organizers/${organizationId}/members`;
    const body = {
      ...values,
      positionId: (values.positionId && values.positionId.value) || values.positionId,
      userId: (values.userId && values.userId.value) || values.userId
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => {
      return handleResponse(response);
    }).then(([ok, status, data]) => {
      if (!ok) {
        throw new Error(data || 'Oops! Something went wrong');
      }

      handleClose({ reload: true });
    }).catch((error) => {
      actions.setSubmitting(false);
      actions.setFieldError('response', error.message);
    });
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      userId: Yup.string().required('You must select a member'),
      positionId: Yup.string().required('You must select a position'),
      id: Yup.string()
    });
  }

  getInitialValues = () => {
    const { data } = this.props;

    return {
      userId: '',
      positionId: '',
      id: '',
      ...data
    }
  }

  render() {
    const { users } = this.state;
    const { header, handleClose, positions } = this.props;
    const styles = this.getStyles();

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <Formik
              initialValues={this.getInitialValues()}
              onSubmit={this.handleSubmit}
              validationSchema={this.getValidationSchema()}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  setFieldTouched
                } = props;
                return (
                  <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.header}>
                      {t(header)}
                      {handleClose &&
                        <div style={styles.close}>
                          <motion.button
                            type="button"
                            onClick={handleClose}
                            style={styles.closeButton}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.8 }}
                          >
                            <Icon size={18} icon="close" color="#000" />
                          </motion.button>
                        </div>
                      }
                    </div>

                    <div style={styles.fields}>
                      {errors.response && (
                        <div style={styles.feedback}>{errors.response}</div>
                      )}
                      <div style={styles.field}>
                        <MySelect
                          value={values.userId}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.userId}
                          touched={touched.userId}
                          options={users}
                          components={{ Option: this.customUserOption }}
                          id="userId"
                          label="Member"
                          placeholder="Select a member"
                        />
                      </div>

                      <div style={styles.field}>
                        <MySelect
                          value={values.positionId}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.positionId}
                          touched={touched.positionId}
                          options={positions && positions.map(p => ({label: p.name, value: p._id}))}
                          id="positionId"
                          label="Position"
                          placeholder="Select a position"
                        />
                      </div>

                      <div style={styles.field}>
                        <label htmlFor="id" style={styles.label}>
                          {t('Identifier (optional)')}
                        </label>
                        <input
                          id="id"
                          placeholder={t("Enter id")}
                          type="string"
                          value={values.id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.id && touched.id ? styles.inputError : styles.input}
                        />
                        {errors.id && touched.id && (
                          <div style={styles.inputFeedback}>{t(errors.id)}</div>
                        )}
                      </div>
                    </div>

                    <div style={styles.buttons}>
                      <div style={styles.mainButtons}>
                        <motion.button
                          type="button"
                          onClick={handleReset}
                          style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                          disabled={!dirty || isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('RESET')}
                        </motion.button>
                        <motion.button
                          type="submit"
                          style={Object.assign({}, styles.button, { color: '#000', background: '#00d066' })}
                          disabled={isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('SUBMIT')}
                        </motion.button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}
      </Translation>
    )
  }
}

FormOrganizationMember.defaultProps = {
  header: 'ADD EVENT'
};

export default FormOrganizationMember;