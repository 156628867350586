import React, { useState, useRef } from 'react';
import UserAvatar from '../UserAvatar/UserAvatar';
import Icon from '../Icon/Icon';
import moment from 'moment';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { Tooltip as CustomTooltip } from 'react-tippy';
import { motion } from 'framer-motion';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
} from '@material/react-menu';
import { useOnClickOutside } from '../../utils/hooks';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import { Translation } from 'react-i18next';

const EventBoxRanking = ({ beginDate, endDate, _id, stats, name, photo, type, style, actions, photographers, visible, organizers, isOnEventScreen, isMobile, position, colors }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));
  const handleMouseEnter = () => {
    setIsOver(true);
  }

  const handleMouseOut = () => {
    setIsOver(false);
  }

  const desktopStyles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      padding: 2.5,
      boxSizing: 'border-box',
      minHeight: 250,
      minWidth: 0,
      ...style
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      borderRadius: 5,
      border: '1px solid #efefef',
      position: 'relative',
    },
    contentWrapper: {
      position: 'relative',
      flex: 'none',
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      boxSizing: 'border-box',
    },
    header: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10
    },
    label: {
      flex: 'none',
      marginBottom: 5,
      color: '#888888',
    },
    imageWrapper: {
      flex: 1,
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
    },
    image: {
      width: isOver ? '110%' : '100%',
      height: isOver ? '110%' : '100%',
      objectFit: 'cover',
      transition: 'all 0.5s ease',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'absolute',
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: '#e6e4e4',
      zIndex: 1
    },
    
    checkbox: {
      position: 'absolute',
      top: 10,
      left: 10
    },
    bottom: {
      padding: 5,
      flex: 'none',
      display: 'flex',
      flexDirection: 'column'
    },
    stats: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 10,
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 16,
      flex: 1,
    },
    stat: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      opacity: 0.5,
      fontSize: 14,
      flex: 'none',
      marginTop: 10,
      textTransform: 'capitalize'
    },
    name: {
      fontWeight: 600,
      fontSize: 18,
      marginBottom: 10,
    },
    roundedButton: {
      padding: 0,
      borderRadius: '50%',
      boxShadow: 'none',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      width: 30,
      height: 30,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none'
    },
    badge: {
      padding: '2px 5px',
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 3,
      background: '#F44336',
      color: '#fff',
      cursor: 'pointer',
    },
    position: {
      flex: 'none',
      marginBottom: 20,
      display: 'flex',
      fontSize: 48,
      fontWeight: 600,
      boxSizing: 'border-box',
    }
  };

  const mobileStyles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      padding: 2.5,
      boxSizing: 'border-box',
      minHeight: 250,
      minWidth: 0,
      ...style
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      borderRadius: 5,
      border: '1px solid #efefef',
      position: 'relative',
      flexDirection: 'column',
    },
    contentWrapper: {
      position: 'relative',
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      boxSizing: 'border-box',
    },
    header: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5
    },
    label: {
      flex: 'none',
      marginBottom: 5,
      color: '#888888',
    },
    imageWrapper: {
      flex: 'none',
      height: 250,
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
    },
    image: {
      width: isOver ? '110%' : '100%',
      height: isOver ? '110%' : '100%',
      objectFit: 'cover',
      transition: 'all 0.5s ease',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'absolute',
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: '#e6e4e4',
      zIndex: 1
    },
    bottom: {
      padding: 5,
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    stats: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 12
    },
    stat: {
      flex: 'none',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      opacity: 0.5,
      fontSize: 10,
      flex: 'none',
      marginTop: 10,
      textTransform: 'capitalize'
    },
    name: {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 10,
    },
    roundedButton: {
      padding: 0,
      borderRadius: '50%',
      boxShadow: 'none',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      width: 30,
      height: 30,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none'
    },
    badge: {
      padding: '2px 5px',
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 3,
      background: '#F44336',
      color: '#fff',
      cursor: 'pointer',
    },
    position: {
      flex: 'none',
      marginBottom: 5,
      display: 'flex',
      fontSize: 26,
      fontWeight: 600,
      boxSizing: 'border-box',
      alignItems: 'center'
    }
  };

  const styles = isMobile ? mobileStyles : desktopStyles;

  const handleTypeClick = () => {
    AppDispatcher.dispatch({
      actionType: `CHANGE_VIEW`,
      view: 'category',
      data: type
    });
  };

  const handleItemClick = option => {
    AppDispatcher.dispatch({
      actionType: option.value,
      data: {
        _id,
        name,
        photographers,
        type,
        beginDate,
        endDate,
        visible
      }
    });
  };

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className="mdc-menu-desktop"
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleItemClick(actions[index])}
          >
            <MenuList>
              <div ref={ref}>
                {actions.map(option => (
                  <MenuListItem key={option.value}>
                    <MenuListItemGraphic graphic={<Icon size={18} icon={option.icon} color={option.label === 'Remove' && '#f44336'} wrapperStyle={{marginRight: 5}}/>} />
                    <MenuListItemText primaryText={t(option.label)} className={option.label === 'Remove' && 'mdc-text-red'}/>
                  </MenuListItem>
                ))}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };
  
  const handleShowMenu = event => {
    setMenuOpen(true);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const renderDate = () => {
    if (beginDate === endDate) {
      return moment(beginDate).format('dddd, DD MMM YY');
    }

    return `${moment(beginDate).format('dddd, DD MMM')} - ${moment(endDate).format('dddd, DD MMM YY')}`;
  };

  const handleClick = () => {
    AppDispatcher.dispatch({
      actionType: 'SELECT_EVENT',
      _id
    });
  }

  const handleNewsClick = () => {
    AppDispatcher.dispatch({
      actionType: 'SORT_NEWS'
    });
  }

  const renderOrganizers = () => {
    if (!organizers || organizers.length === 0) return null;

    return organizers.map(o => <OrganizationAvatar key={o._id} {...o} size={isMobile ? 30 : 50} style={{padding: 5, boxSizing: 'content-box'}}/>);
  }

  const renderActions = () => {
    if (!actions) return null;

    return (
      <div style={{ ...styles.header, borderBottom: '1px solid #eaeaea', justifyContent: 'center' }}>
        <motion.button
          onClick={handleShowMenu}
          style={styles.roundedButton}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <Icon size={18} icon="showMore" color="#000" />
        </motion.button>
      </div>
    );
  }

  const renderNewsButton = () => {
    if (stats && stats.unviews > 0) {
      if (!isOnEventScreen) {
        return (
          <Translation>
            {t => (
              <div style={styles.badge} className="blink-1">
                {stats.unviews} {t('new')}
              </div>
            )}
          </Translation>
        );
      }

      if (isMobile) {
        return (
          <Translation>
            {t => (
              <div style={styles.badge} onClick={handleNewsClick} className="blink-1">
                {stats.unviews} {t('new')}
              </div>
            )}
          </Translation>
        );
      }

      return (
        <Translation>
          {t => (
            <div style={styles.badge} className="blink-1">
              <CustomTooltip
                title={t('Click to show new first')}
                position="bottom"
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <span onClick={handleNewsClick}>{stats.unviews} new</span>
              </CustomTooltip>
            </div>
          )}
        </Translation>
      );
    }
  }

  const getPositionColor = () => {
    switch (position) {
      case 1: return colors.gold;
      case 2: return colors.silver;
      case 3: return colors.bronze;
      default: return 'blue';
    }
  }

  const getPositionIcon = () => {
    const color = getPositionColor();
    switch (position) {
      case 1:
        return <Icon size={isMobile ? 20 : 32} icon="awardTrophy" color={color} style={{ marginLeft: 10 }} />;
      case 2:
      case 3:
        return <Icon size={isMobile ? 20 : 32} icon="awardMedal" color={color} style={{ marginLeft: 10 }} />;
      default:
        return null;
    }
  }

  return (
    <Translation>
      {t => (
        <div style={styles.wrapper}>
          {isMenuOpen && renderMenu()}
          <div style={styles.card}>
            <div style={styles.imageWrapper} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut}>
              {renderNewsButton()}
              {photo && <img src={photo} style={styles.image} loading="lazy" alt={name} />}
              <div style={styles.background} />
            </div>
            <div style={styles.contentWrapper}>
              {renderActions()}
              <div style={{ ...styles.position }}>#{position} {getPositionIcon()}</div>
              <div style={styles.label}>{t('Name')}</div>
              <div style={styles.name}>
                {name}
              </div>
              <div style={styles.label}>{t('Category')}</div>
              {type && type.thumb && 
                <div style={styles.header}>
                {<UserAvatar thumb={type.thumb} username={type.name} horizontal={true} size={isMobile ? 30 : 50} style={{ padding: 5, flex: 1 }} onClick={handleTypeClick}/>}
                </div>
              }
              {organizers && organizers.length > 0 &&
                <>
                  <div style={styles.label}>{t('Organizer/s')}</div>
                  <div style={styles.header}>
                    {renderOrganizers()}              
                  </div>
                </>
              }
              <div style={styles.bottom}>
                <div style={styles.stats}>
                  <div style={styles.stat}>
                    <Icon size={isMobile ? 18 : 24} icon="cameraEmpty" />
                    {stats.photos}
                  </div>
                  <div style={styles.stat}>
                    <Icon size={isMobile ? 18 : 24} icon="heartEmpty" />
                    {stats.likes}
                  </div>
                  <div style={styles.stat}>
                    <Icon size={isMobile ? 18 : 24} icon="userEmpty" />
                    {stats.users}
                  </div>
                  <div style={styles.stat}>
                    <Icon size={isMobile ? 18 : 24} icon="eyeEmpty" />
                    {stats.visits}
                  </div>
                </div>
                <div style={styles.date}>
                  {renderDate()}
                </div>
              </div>

            </div>
          </div>
        </div>
      )}
    </Translation>
  )
}

EventBoxRanking.defaultProps = {
  colors: {
    bronze: '#cd7f32',
    silver: '#9E9E9E',
    gold: '#FFB300'
  }
}

export default EventBoxRanking;
