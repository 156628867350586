import React from 'react';
import { motion } from 'framer-motion';
import { getReadableColor } from '../../utils/color';
import Icon from '../Icon/Icon';

const MotionButton = ({
  onClick,
  children,
  style,
  background = '#00d663',
  dense = true,
  whileHover = { scale: 1.2 },
  icon,
}) => {
  const color = (background && getReadableColor({ color: background })) || '#000';
  const styles = {
    button: {
      background,
      color,
      padding: '5px 30px',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 14,
      borderRadius: 4,
      boxShadow: dense && 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      ...style,
    },
  }

  const handleClick = (e) => {
    if (onClick) onClick(e);
  }

  return (
    <motion.button
      onClick={handleClick}
      style={styles.button}
      whileHover={whileHover}
    >
      {icon && <Icon icon={icon} />}
      {children}
    </motion.button>
  )
}



export default MotionButton;
