import React, { useState } from 'react'
import { Translation } from 'react-i18next';

const Link = ({ icon, label, onClick, color, isMobile, bubble, selected}) => {
  const [isOver, setIsOver] = useState(false);
  const handleMouseEnter = () => {
    setIsOver(true);
  }

  const handleMouseOut = () => {
    setIsOver(false);
  }

  const desktopStyles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      padding: 5
    },
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: isOver || selected ? color : 'transparent',
      opacity: .2,
      clipPath: isOver || selected ? 'circle(200% at 20px 8px)' : 'circle(0 at 20px 8px)',
      transition: 'clip-path ease-in 0.4s',
    },
    iconWrapper: {
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    icon: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    label: {
      marginTop: icon ? 10 : 0,
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'lowercase'
    },
    bubble: {
      background: '#f44336',
      fontSize: 10,
      fontWeight: 600,
      position: 'absolute',
      top: -5,
      right: -5,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 15,
      height: 15,
      boxSizing: 'border-box',
      padding: '0 4px',
      borderRadius: 25,
    }
  }

  const mobileStyles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden'
    },
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: isOver || selected ? color : 'transparent',
      opacity: .2,
      clipPath: isOver || selected ? 'circle(200% at 20px 8px)' : 'circle(0 at 20px 8px)',
      transition: 'clip-path ease-in 0.4s',
    },
    iconWrapper: {
      width: 25,
      height: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    icon: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    label: {
      marginTop: icon ? 10 : 0,
      fontSize: 10,
      fontWeight: 600,
      textTransform: 'lowercase'
    },
    bubble: {
      background: '#f44336',
      fontSize: 10,
      fontWeight: 600,
      position: 'absolute',
      top: -3,
      right: -3,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 15,
      height: 15,
      boxSizing: 'border-box',
      padding: '0 4px',
      borderRadius: 25,
    }
  }

  const styles = isMobile ? mobileStyles : desktopStyles;

  return (
    <div style={styles.wrapper} onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut}>
      <div style={styles.background} />
      {icon ? <div style={styles.iconWrapper}>
        {bubble ? <div style={styles.bubble} className="blink-1">!</div> : null}
        <img style={styles.icon} src={icon} alt={label} />
      </div> : null}
      <div style={styles.label}>
        <Translation>
          {t => t(label)}
        </Translation>
      </div>
    </div>
  )
}

export default Link;
