import moment from 'moment';

/**
 * Comprueba si una fecha esta contenida en un rango
 * @param   {Object}                arg
 * @param   {string|Moment}         arg.date               - Fecha
 * @param   {Array.<string|Moment>} arg.range              - Rango [beginDate, endDate]
 * @param   {string}                [arg.granularity]      - Granularidad para comparar, por defecto milisegundos
 * @param   {string}                [arg.inclusivity='[]'] - Indicadores para incluir o excluir los extremos de los rangos. Ej.: (), [), (], []
 * @returns {boolean}
 */
const isDateBetweenDateRange = ({ date, range, granularity, inclusivity = '[]' }) => {
  const [beginDate, endDate] = range;
  return moment(date).isBetween(beginDate, endDate, granularity, inclusivity);
};

export {
  isDateBetweenDateRange
}