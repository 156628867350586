import React from 'react';
import Header from './components/Header';
import Link from './components/Link';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { baseUrl } from '../../config';
import Spinner from 'react-spinkit';
import Dialog from '../Dialog/Dialog';
import EventBox from '../EventBox/EventBox';
import FormEventPicture from '../Form/FormEventPicture';
import FormOrganizationEvent from '../Form/FormOrganizationEvent';
import FormOrganizationEventPhotographer from '../Form/FormOrganizationEventPhotographer';
import FormOrganizationEventOrganization from '../Form/FormOrganizationEventOrganization';
import FormOrganizationPosition from '../Form/FormOrganizationPosition';
import FormOrganizationOwner from '../Form/FormOrganizationOwner';
import FormOrganizationMember from '../Form/FormOrganizationMember';
import FormOrganizationCreateMember from '../Form/FormOrganizationCreateMember';
import FormOrganizationMembership from '../Form/FormOrganizationMembership';
import PositionBox from '../PositionBox/PositionBox';
import MemberBox from '../MemberBox/MemberBox';
import MembershipBox from '../MembershipBox/MembershipBox';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import Cropper from 'react-easy-crop';
import MotionButton from '../Button/MotionButton';
import Icon from '../Icon/Icon';
import { motion } from 'framer-motion';
import getCroppedImg from '../../utils/crop';
import { Translation } from 'react-i18next';
import moment from 'moment';

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this._dispatchToken = AppDispatcher.register(this._registerToActions);
    this.state = this.getInitialData();
    this.handleUploadCropImage = this.handleUploadCropImage.bind(this);
  }

  getInitialData = () => {
    const { data: { showEvents } } = this.props;

    return {
      loading: true,
      data: null,
      events: null,
      eventTypes: null,
      dialog: null,
      view: showEvents ? 'events' : 'members',
      image: null,
      crop: { x: 0, y: 0 },
      croppedAreaPixels: null,
      zoom: 1,
      aspect: 1 / 1,
      rotation: 0,
      hint: null,
      changeView: true
    };
  }

  _registerToActions = (action) => {
    switch (action.actionType) {
      case 'RELOAD_ORGANIZATION':
        this.obtainDatas();
        break;
      case 'ADD_PHOTO':
        this.handleAddEventPicture(action.data);
        break;
      case 'UPDATE_PHOTOGRAPHERS':
        this.handleUpdatePhotographers(action.data);
        break;
      case 'ADD_ORGANIZATION':
        this.handleAddOrganization(action.data);
        break;
      case 'EDIT':
        this.handleAddEvent(action.data);
        break;
      case 'REMOVE':
        this.handleRemoveEventConfirmation(action.data);
        break;
      case 'EDIT_POSITION':
        this.handleAddPosition(action.data);
        break;
      case 'REMOVE_POSITION':
        this.handleRemovePositionConfirmation(action.data);
        break;
      case 'EDIT_MEMBER':
        this.handleAddMember(action.data);
        break;
      case 'EDIT_MEMBER_PROFILE':
        this.handleCreateMember(action.data);
        break;
      case 'EDIT_MEMBER_PHOTO':
        this.handleUploadPhoto(action.data);
        break;
      case 'REMOVE_MEMBER':
        this.handleRemoveMemberConfirmation(action.data);
        break;
      case 'REMOVE_OWNER':
        this.handleRemoveOwnerConfirmation(action.data);
        break;
      case 'REMOVE_MEMBERSHIP':
        this.handleRemoveMembershipConfirmation(action.data);
        break;
      default:
        break;
    }
  }

  handleRemoveEventConfirmation = (data) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete event",
        message: "Are you sure you want to delete the event? This event will be deleted immediately. You can't undo this action.",
        onClose: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemoveEvent(data); },
      }
    });
  }

  handleRemoveEvent = (data) => {
    const url = `${baseUrl}/events/${data._id}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.obtainDatas();
    }).catch((err) => {
      console.log(err);
    });
  }

  handleRemovePositionConfirmation = (data) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete position",
        message: "Are you sure you want to delete the position? This position will be deleted immediately. You can't undo this action.",
        onClose: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemovePosition(data); },
      }
    });
  }

  handleRemovePosition = (data) => {
    const { data: { _id } } = this.state;
    const url = `${baseUrl}/organizers/${_id}/positions/${data._id}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.obtainDatas();
    }).catch((err) => {
      console.log(err);
    });
  }

  handleRemoveMemberConfirmation = (data) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete member",
        message: "Are you sure you want to delete this member? This member will be deleted from this organization immediately. You can't undo this action.",
        onClose: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemoveMember(data); },
      }
    });
  }

  handleRemoveOwnerConfirmation = (data) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete owner",
        message: "Are you sure you want to delete this owner? This owner will be deleted from this organization immediately. You can't undo this action.",
        onClose: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemoveOwner(data); },
      }
    });
  }

  handleRemoveMember = (data) => {
    const { data: { _id } } = this.state;
    const url = `${baseUrl}/organizers/${_id}/members/${data._id}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.obtainDatas();
    }).catch((err) => {
      console.log(err);
    });
  }

  handleRemoveOwner = (data) => {
    const { data: { _id } } = this.state;
    const url = `${baseUrl}/organizers/${_id}/owner/${data.userId}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.obtainDatas();
    }).catch((err) => {
      console.log(err);
    });
  }

  handleRemoveMembershipConfirmation = (data) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete organization",
        message: "Are you sure you want to delete this organization? This organization will be deleted from this organization immediately. You can't undo this action.",
        onClose: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemoveMembership(data); },
      }
    });
  }

  handleRemoveMembership = (data) => {
    const { data: { _id } } = this.state;
    const url = `${baseUrl}/organizers/${_id}/membership/${data._id}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.obtainDatas();
    }).catch((err) => {
      console.log(err);
    });
  }

  handleAddEventPicture = (data) => {
    const { isMobile } = this.props;
    const header = data ? `ADD PICTURE` : null;

    this.setState({
      dialog: {
        children: () => <FormEventPicture data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleUpdatePhotographers = (data) => {
    const { isMobile } = this.props;
    const header = data ? `UPDATE PHOTOGRAPHERS` : null;
    const dialogProps = isMobile ? { fullScreen: true } : { contentStyle: { height: '50%' } }
    this.setState({
      dialog: {
        ...dialogProps,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganizationEventPhotographer data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleAddOrganization = (data) => {
    const { isMobile } = this.props;
    const header = data ? `ADD ORGANIZATION` : null;
    const dialogProps = isMobile ? { fullScreen: true } : { contentStyle: { } }
    this.setState({
      dialog: {
        ...dialogProps,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganizationEventOrganization data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleRemoveOrganization = ({_id, organizerId}) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete organization",
        message: "Are you sure you want to delete the selected organization?",
        onClose: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemoveOrganizationConfirm({ _id, organizerId }); },
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
      }
    });
  }

  handleRemoveOrganizationConfirm = ({_id, organizerId}) => {
    const url = `${baseUrl}/events/${_id}/organizers`;

    fetch(url, {
      method: "DELETE",
      body: JSON.stringify({organizerId}),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.obtainDatas();
    }).catch((err) => {
      console.log(err);
    });
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this._dispatchToken);
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
        },
        columns: {
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
        },
        eventWrapper: {
          padding: 20,
          flex: 1,
          overflow: 'auto',
        },
        contentWrapper: {
          padding: 20,
          flex: 1,
        },
        title: {
          fontWeight: 600,
          fontSize: 26,
          flex: 'none',
          marginBottom: 20,
          alignItems: 'center',
          textTransform: 'uppercase',
          textAlign: 'center',
        },
        content: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        events: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 260px)',
          gridAutoRows: 300,
          gridGap: 15,
        },
        positions: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        members: {
          flex: 1,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        },
        membersGroup: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 30,
        },
        membersGroupLabel: {
          marginBottom: 15,
          fontWeight: 600,
          fontSize: 20,
          color: '#868686'
        },
        spinner: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex'
        },
        actionButton: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        row: {
          display: 'flex',
          alignItems: 'center',
          padding: 16,
          paddingBottom: 0,
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto'
        },
        columns: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        eventWrapper: {
          padding: 20,
          flex: 1,
        },
        contentWrapper: {
          flex: 1,
          padding: 20
        },
        events: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 100%)',
          gridAutoRows: 300,
        },
        positions: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        members: {
          flex: 1,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        },
        membersGroup: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 30,
        },
        membersGroupLabel: {
          marginBottom: 15,
          fontWeight: 600,
          fontSize: 20,
          color: '#868686'
        },
        title: {
          fontWeight: 600,
          fontSize: 26,
          flex: 'none',
          marginBottom: 20,
          alignItems: 'center',
          textTransform: 'uppercase',
          textAlign: 'center',
        },
        spinner: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex'
        },
        actionButton: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        row: {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
        }
      }
    }
  }

  componentDidMount() {
    this.obtainDatas();
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const { events, members, changeView } = this.state;
    const prevId = prevProps.data && prevProps.data._id;
    const newId = data && data._id;

    if (prevId !== newId) {
      this.setState(this.getInitialData());
      this.obtainDatas();
    }
    
    if ((prevState.events !== events || prevState.members !== members) && members && events && changeView) {
      if (!data.showEvents) {
        this.checkView();
      }

      this.checkHint();
    }
  }
  
  checkView = () => {
    const { events, members } = this.state;

    if (events.length === 0 && members && Object.keys(members).length > 0) {
      this.setState({
        view: 'members',
        changeView: false
      });
    } else if (members && Object.keys(members).length === 0 && events.length > 0) {
      this.setState({
        view: 'events',
        changeView: false
      });
    } else if (members && Object.keys(members).length > 0 && events.length > 0) {
      this.setState({
        view: 'members',
        changeView: false
      });
    }
  }

  checkHint = () => {
    const { events, members } = this.state;

    if (events.length === 0 && members && Object.keys(members).length > 0) {
      this.setState({
        hint: 'events'
      });
    } else if (members && Object.keys(members).length === 0 && events.length > 0) {
      this.setState({
        hint: 'members'
      });
    } else if (members && Object.keys(members).length === 0 && events.length === 0) {
      this.setState({
        hint: 'full'
      });
    }
  }

  obtainDatas = () => {
    this.obtainEventTypes();
    this.obtainSeasons();
    this.obtainOrganizations();
    this.obtainOrganization();
    this.obtainOrganizationEvents();
    this.obtainOrganizationPositions();
    this.obtainOrganizationMembers();
    this.obtainOrganizationMembership();
  }

  obtainOrganizations = () => {
    fetch(`${baseUrl}/organizers`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        organizations: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainSeasons = () => {
    fetch(`${baseUrl}/seasons`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        seasons: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainEventTypes = () => {
    fetch(`${baseUrl}/types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        eventTypes: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainOrganization = () => {
    const { data: { _id } } = this.props;

    this.setState({ loading: true });
    fetch(`${baseUrl}/organizers/${_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        data,
        loading: false
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainOrganizationEvents = () => {
    const { data: { _id } } = this.props;

    fetch(`${baseUrl}/organizers/${_id}/events`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        events: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainOrganizationPositions = () => {
    const { data: { _id } } = this.props;

    fetch(`${baseUrl}/organizers/${_id}/positions`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        positions: orderBy(data, 'order', 'asc')
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainOrganizationMembers = () => {
    const { data: { _id } } = this.props;
    fetch(`${baseUrl}/organizers/${_id}/members`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        members: groupBy(data, 'position.name')
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  obtainOrganizationMembership = () => {
    const { data: { _id } } = this.props;

    fetch(`${baseUrl}/organizers/${_id}/membership`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.setState({
        membership: data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleAddEvent = (data) => {
    const { data: { _id } } = this.props;
    const { seasons, eventTypes, organizations } = this.state;
    const { isMobile } = this.props;
    const header = data ? 'EDIT EVENT' : 'ADD EVENT';
    const parsedTypes = eventTypes && eventTypes.map(t => ({label: t.name, value: t._id}));
    const parsedSeasons = seasons && seasons.map(s => ({label: s.name, value: s._id, types: s.types}));
    const parsedOrganizations = organizations && organizations.filter(o => o.club).map(o => ({label: o.name, value: o._id}));
    const participants = data && data.organizers.filter(organizer => organizer.mode === 'participant').map(organizer => organizer._id);
    const currentOrganizer = data && data.organizers.find(organizer => organizer._id === _id);
    const mode = currentOrganizer && currentOrganizer.mode;
    this.setState({
      dialog: {
        onClickOutside: this.handleCloseDialog,
        children: () => (
          <FormOrganizationEvent 
            organizerId={_id} 
            data={data ? {...data, participants, mode} : null} 
            header={header} 
            eventTypes={parsedTypes} 
            seasons={parsedSeasons} 
            organizations={parsedOrganizations} 
            handleClose={this.handleCloseDialog} 
            isMobile={isMobile} 
          />
        )
      }
    });
  }

  handleAddPosition = (data) => {
    const { isMobile } = this.props;
    const header = 'ADD POSITION';

    this.setState({
      dialog: {
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganizationPosition organizationId={this.state.data._id} data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleAddMember = (data) => {
    const { positions } = this.state;
    const { isMobile } = this.props;
    const header = data && data._id ? 'EDIT MEMBER' : 'ADD MEMBER';

    this.setState({
      dialog: {
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganizationMember organizationId={this.state.data._id} data={data} positions={positions} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleUploadPhoto = (data) => {
    this.setState({
      uploadingPhotoUser: data
    });
    this.refs.fileInput.click();
  }

  handleUploadChange = (e) => {
    const { isMobile } = this.props;

    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      if (file) {
        const objectUrl = URL.createObjectURL(file);
        this.setState({
          image: objectUrl,
          dialog: {
            fullScreen: isMobile,
            contentStyle: isMobile ? { justifyContent: 'center' } : null,
            onClickOutside: this.handleCloseDialog,
            children: this.renderCropper
          }
        });
      }
    }
  } 

  onCropChange = crop => {
    this.setState({ crop })
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }

  handleUploadCancel = () => {
    this.setState({
      image: null,
      uploadingPhotoUser: null,
      dialog: null,
    });
  }

  async handleUploadCropImage() {
    const { image, croppedAreaPixels, rotation, uploadingPhotoUser } = this.state;

    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )

      if (croppedImage) {
        const url = `${baseUrl}/users/${uploadingPhotoUser.username}/photo`;
        let newData = new FormData();
        newData.append('file', croppedImage);

        fetch(url, {
          method: "POST",
          body: newData,
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          }
        }).then(() => {
          this.handleCloseDialog({ reload: true });
        }).catch((err) => {
          console.log(err);
        });
      }
    } catch (e) {
      console.error(e)
    }
  }

  handleIncreaseZoom = () => {
    const { zoom } = this.state;

    if (zoom < 3) {
      this.setState({
        zoom: zoom + 0.1
      });
    }
  }

  handleDecreaseZoom = () => {
    const { zoom } = this.state;

    if (zoom > 1) {
      this.setState({
        zoom: zoom - 0.1
      });
    }
  }

  renderCropper = () => {
    const { image, crop, zoom, rotation, aspect } = this.state
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={{display: 'flex', flexDirection: 'column', background: '#fff', padding: 16}}>
        <div style={{position: 'relative', height: 300}}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            rotation={rotation}
            cropShape="round"
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
          <div style={{position: 'absolute', right: 5, bottom: 5, background: '#fff', borderRadius: 4}}>
            <motion.button
              onClick={this.handleIncreaseZoom}
              style={styles.actionButton}
            >
              <Icon size={24} icon="add" color="#000" />
            </motion.button>
            <motion.button
              onClick={this.handleDecreaseZoom}
              style={styles.actionButton}
            >
              <Icon size={24} icon="subtract" color="#000" />
            </motion.button>
          </div>
        </div>
        <div style={{marginTop: 10, display: 'flex', justifyContent: 'space-evenly'}}>
          <MotionButton 
            background="#ffffff" 
            onClick={this.handleUploadCancel}
          >
            <Translation>
              {t => t('Cancel')}
            </Translation>
          </MotionButton>
          <MotionButton
            onClick={this.handleUploadCropImage}
          >
            <Translation>
              {t => t('Submit')}
            </Translation>
          </MotionButton>
        </div>
      </div>
    )
  }

  handleCreateMember = (data) => {
    const { positions } = this.state;
    const { isMobile } = this.props;
    const header = data && data._id ? 'EDIT MEMBER PROFILE' : 'CREATE MEMBER';

    this.setState({
      dialog: {
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganizationCreateMember organizationId={this.state.data._id} data={data} positions={positions} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleAddOwner = (data) => {
    const { isMobile } = this.props;
    const header = 'ADD OWNER';

    this.setState({
      dialog: {
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganizationOwner organizationId={this.state.data._id} data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleAddMembership = (data) => {
    const { positions } = this.state;
    const { isMobile } = this.props;
    const header = 'ADD ORGANIZATION';

    this.setState({
      dialog: {
        contentStyle: {minHeight: 300},
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganizationMembership organizationId={this.state.data._id} data={data} positions={positions} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  handleRemoveHint = () => {
    this.setState({
      hint: null
    });
  }

  handleCloseDialog = ({ reload = false} = {}) => {
    if (reload) {
      this.obtainDatas();
    }

    this.setState({
      dialog: null
    });
  }

  renderSpinner = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.spinner}>
        <Spinner name="folding-cube" color="#00d663" />
      </div>
    );
  }

  renderEventBox = (event) => {
    const { isMobile, eventActions } = this.props;
    const isExternal = this.isExternal();

    return (
      <EventBox key={event._id} {...event} isMobile={isMobile} actions={!isExternal && eventActions} onRemoveOrganization={!isExternal && this.handleRemoveOrganization}/>
    );
  }

  renderPositionBox = (position) => {
    const { isMobile, positionActions } = this.props;
    const isExternal = this.isExternal();

    return (
      <PositionBox key={position._id} {...position} isMobile={isMobile} actions={!isExternal && positionActions} />
    );
  }

  renderMemberBox = (member) => {
    const { isMobile, memberActions } = this.props;
    const isExternal = this.isExternal();

    return (
      <MemberBox key={member._id} {...member} isMobile={isMobile} actions={!isExternal && memberActions} width={250}/>
    );
  }

  renderOwnerBox = (owner) => {
    const { isMobile, ownerActions } = this.props;
    const isExternal = this.isExternal();

    return (
      <MemberBox key={owner._id} {...owner} isMobile={isMobile} actions={!isExternal && ownerActions} width={250} />
    );
  }

  renderMembershipBox = (organization) => {
    const { isMobile, membershipActions } = this.props;
    const isExternal = this.isExternal();

    return (
      <MembershipBox key={organization._id} {...organization} isMobile={isMobile} actions={!isExternal && membershipActions} width={200} />
    );
  }

  getOwner = () => {
    const { userInfo } = this.props;
    const { data } = this.state;

    return data && data.users && data.users.find(u => u.owner && u.userId.username === userInfo.username);
  }

  isExternal = () => {
    const owner = this.getOwner();
    
    return !!!owner;
  }

  handleChangeView = (view) => {
    this.setState({ view });
  }

  renderView = () => {
    const { view } = this.state;

    switch(view) {
      case 'owners': return this.renderOwners();
      case 'members': return this.renderMembers();
      case 'positions': return this.renderPositions();
      case 'events':
      default:
        return this.renderEvents();
    }
  }

  renderPositions = () => {
    const { isMobile } = this.props;
    const { positions } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.contentWrapper}>
        <div style={styles.positions}>
          {positions && positions.map(e => this.renderPositionBox(e))}
        </div>
      </div>
    );
  }

  renderUserGroup = (members) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const orderedMembers = orderBy(members, (o) => parseInt(o.id), 'asc');

    return (
      <div style={styles.members}>
        {orderedMembers && orderedMembers.map(e => this.renderMemberBox(e))}
      </div>
    );
  }

  renderUsers = () => {
    const { isMobile } = this.props;
    const { members, positions } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!members || !positions) return null;

    return (
      <div style={styles.contentWrapper}>
        {positions.map(groupKey => {
          if (!members[groupKey.name]) return null;

          return (
            <div key={groupKey.name} style={styles.membersGroup}>
              <div style={styles.membersGroupLabel}>{groupKey.name}</div>
              {this.renderUserGroup(members[groupKey.name])}
            </div>
          )
        })}
      </div>
    );
  }

  renderMembership = () => {
    const { isMobile } = this.props;
    const { membership } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!membership || membership.length === 0) return null;

    const orderedMemberships = orderBy(membership, 'name', 'asc');

    return (
      <div style={styles.contentWrapper}>
        <div style={styles.members}>
          {orderedMemberships.map(e => this.renderMembershipBox(e))}
        </div>
      </div>
    );
  }

  renderMembers = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.content}>
        {this.renderMembership()}
        {this.renderUsers()}
      </div>
    );
  }

  renderOwners = () => {
    const { isMobile } = this.props;
    const { data: { users } } = this.state
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.content}>
        <div style={styles.contentWrapper}>
          <div style={styles.members}>
            {users.map(user => this.renderOwnerBox(user.userId))}
          </div>
        </div>
      </div>
    );
  }

  renderEvents = () => {
    const { isMobile } = this.props;
    const { events } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.eventWrapper}>
        <div style={styles.events}>
          {events && events.map(e => this.renderEventBox(e))}
        </div>
      </div>
    );
  }

  renderMenu = () => {
    const { isMobile } = this.props;
    const { view } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const isExternal = this.isExternal();

    return (
      <div style={styles.row}>
        <Link label="matches" color="#00d663" onClick={() => this.handleChangeView('events')} isMobile={isMobile} selected={view === 'events'}/>
        <Link label="squad" color="#00d663" onClick={() => this.handleChangeView('members')} isMobile={isMobile} selected={view === 'members'}/>
        {!isExternal ? <Link label="positions" color="#00d663" onClick={() => this.handleChangeView('positions')} isMobile={isMobile} selected={view === 'positions'}/> : null}
        {!isExternal ? <Link label="owners" color="#00d663" onClick={() => this.handleChangeView('owners')} isMobile={isMobile} selected={view === 'owners'} /> : null}
      </div>
    );
  }

  render() {
    const { userInfo, isMobile } = this.props;
    const { data, loading, eventTypes, dialog, hint } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const fromUserProfile = this.props.data.fromUserProfile;

    if (loading) {
      return (
        <div style={styles.wrapper}>
          {this.renderSpinner()}
        </div>
      );
    }

    return (
      <div style={styles.wrapper}>
        {dialog && <Dialog {...dialog} />}
        <input ref="fileInput" type="file" id="upload" style={{ display: 'none' }} onChange={this.handleUploadChange} />
        {this.renderMenu()}
        <div style={styles.columns}>
          <Header 
            data={data} 
            userInfo={userInfo} 
            isExternal={this.isExternal()} 
            isMobile={isMobile} 
            onAddEvent={eventTypes && this.handleAddEvent}
            onAddPosition={this.handleAddPosition}
            onAddOwner={this.handleAddOwner}
            onAddMember={this.handleAddMember}
            onCreateMember={this.handleCreateMember}
            onAddMembership={this.handleAddMembership}
            onRemoveHint={this.handleRemoveHint}
            hint={hint}
            fromUserProfile={fromUserProfile}
          />
          {this.renderView()}
        </div>
      </div>
    );
  }
}

Organization.defaultProps = {
  eventActions: [
    { label: 'Update my photographers', value: 'UPDATE_PHOTOGRAPHERS', icon: 'userCamera' },
    { label: 'Add organization', value: 'ADD_ORGANIZATION', icon: 'organization' },
    { label: 'Add photo', value: 'ADD_PHOTO', icon: 'cameraEmpty' },
    { label: 'Edit', value: 'EDIT', icon: 'edit', },
    { label: 'Remove', value: 'REMOVE', icon: 'trash' },
  ],
  positionActions: [
    { label: 'Edit', value: 'EDIT_POSITION', icon: 'edit', },
    { label: 'Remove', value: 'REMOVE_POSITION', icon: 'trash' },
  ],
  memberActions: [
    { label: 'Edit', value: 'EDIT_MEMBER', icon: 'edit', },
    { label: 'Edit profile', value: 'EDIT_MEMBER_PROFILE', icon: 'edit', },
    { label: 'Edit photo', value: 'EDIT_MEMBER_PHOTO', icon: 'cameraEmpty' },
    { label: 'Remove', value: 'REMOVE_MEMBER', icon: 'trash' },
  ],
  ownerActions: [
    { label: 'Remove', value: 'REMOVE_OWNER', icon: 'trash' },
  ],
  membershipActions: [
    { label: 'Remove', value: 'REMOVE_MEMBERSHIP', icon: 'trash' },
  ]
};

export default Organization;
