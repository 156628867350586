import React, { useState, useRef } from 'react';
import Icon from '../Icon/Icon';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
} from '@material/react-menu';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { useOnClickOutside } from '../../utils/hooks';
import { motion } from 'framer-motion';
import { Translation } from 'react-i18next';

const PositionBox = ({name, order, _id, actions}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));
  
  const styles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      padding: 2.5,
      boxSizing: 'border-box',
      minWidth: 0,
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      borderRadius: 5,
      border: '1px solid #efefef',
      position: 'relative',
      alignItems: 'center',
      overflow: 'hidden',
    },
    roundedButton: {
      padding: 20,
      borderRadius: '50%',
      boxShadow: 'none',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      width: 50,
      height: 30,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      flex: 'none',
    },
    order: {
      flex: 'none',
      marginRight: 10,
      background: '#00d663',
      padding: '10px 20px',
      fontWeight: 600,
      fontSize: 22,
    },
    name: {
      flex: 1,
      fontWeight: 600,
      fontSize: 22,
      padding: 10
    }
  }

  const handleShowMenu = event => {
    setMenuOpen(true);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleItemClick = option => {
    AppDispatcher.dispatch({
      actionType: option.value,
      data: { _id, name, order, }
    });
  };

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className="mdc-menu-desktop"
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleItemClick(actions[index])}
          >
            <MenuList>
              <div ref={ref}>
                {actions.map(option => (
                  <MenuListItem key={option.value}>
                    <MenuListItemGraphic graphic={<Icon size={18} icon={option.icon} color={option.label === 'Remove' && '#f44336'} wrapperStyle={{marginRight: 5}}/>} />
                    <MenuListItemText primaryText={t(option.label)} className={option.label === 'Remove' && 'mdc-text-red'}/>
                  </MenuListItem>
                ))}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };

  const renderActions = () => {
    if (!actions) return null;

    return (
      <motion.button
        onClick={handleShowMenu}
        style={styles.roundedButton}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.8 }}
      >
        <Icon size={18} icon="showMore" color="#000" />
      </motion.button>
    );
  }

  return (
    <div style={styles.wrapper}>
      {isMenuOpen && renderMenu()}
      <div style={styles.card}>
        <span style={styles.order}>{order}</span> <span style={styles.name}>{name}</span>
        {renderActions()}
      </div>
    </div>
  )
}

export default PositionBox
