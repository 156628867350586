import React from 'react';
import TabButtonWrapper from './TabButtonWrapper';
import { isObject } from '../../utils/object';

class Tabs extends React.Component {
  getStyles() {
    const { wrapperStyle, style, selectStyle } = this.props;

    return {
      tabs: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: 16,
        ...wrapperStyle
      },
      tabsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: 0
      },
      tabList: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        ...style
      },
      select: {
        margin: '0 5px',
        paddingBottom: 4,
        borderBottom: '2px solid rgb(3, 169, 244)',
        ...selectStyle,
      },
    };
  }

  renderButtons = () => {
    const { children, handleClick, selected } = this.props;
    const styles = this.getStyles();

    if (children && isObject(children) && children.hasOwnProperty('0')) {
      return (
        <ul style={styles.tabList}>
          {
            children.map((c, i) => {
              const { container, component, children, owner, props, width, height, ...rest } = c.props;
              return (
                <TabButtonWrapper
                  key={i}
                  ref={i}
                  tabId={i}
                  handleClick={handleClick}
                  selected={selected}
                  {...rest}
                />
              );
            })
          }
        </ul>
      );
    } else if (children) {
      return (
        <ul style={styles.tabList}>
          {
            React.Children.map(children, (c, i) => {
              const { container, component, children, owner, props, width, height, ...rest } = c.props;
              return (
                <TabButtonWrapper
                  key={i}
                  ref={i}
                  tabId={i}
                  handleClick={handleClick}
                  selected={selected}
                  {...rest}
                />
              );
            })
          }
        </ul>
      );
    }
  }

  renderControls = () => {
    return this.renderButtons();
  }

  renderContent = () => {
    const { children, handleClick, getTabCounters, selected, hideUnselected } = this.props;
    const styles = this.getStyles();

    if (children && isObject(children) && children.hasOwnProperty('0')) {
      return (
        <div style={styles.tabsContainer}>
          {
            children.map((c, i) => {
              if (hideUnselected && selected !== i) {
                return null;
              }

              return React.cloneElement(c, {
                tabId: i,
                ref: i,
                handleClick: handleClick,
                getTabCounters: getTabCounters
              });
            })
          }
        </div>
      );
    } else if (children) {
      return (
        <div style={styles.tabsContainer}>
          {
            React.Children.map(children, (c, i) => {
              if (hideUnselected && selected !== i) {
                return null;
              }

              return React.cloneElement(c, {
                tabId: i,
                ref: i,
                handleClick: handleClick,
                getTabCounters: getTabCounters,
                selected: selected
              });
            })
          }
        </div>
      );
    }
  }

  render() {
    const styles = this.getStyles();

    return (
      <div style={styles.tabs}>
        {this.renderControls()}
        {this.renderContent()}
      </div>
    );
  }
}

export default Tabs;
