import React, { Fragment } from 'react';
import Icon from '../Icon/Icon';
import moment from 'moment';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import EventBox from '../EventBox/EventBox';
import NewPicturesHeader from './components/NewPicturesHeader';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import { FixedSizeList as List } from 'react-window';
import { motion } from 'framer-motion';
import CustomUserOption from '../Options/CustomUserOption';
import { baseUrl } from '../../config';
import Dialog from '../Dialog/Dialog';
import { SizeMe } from 'react-sizeme';
import Slideshow from '../Slideshow/Slideshow';
import UserAvatar from '../UserAvatar/UserAvatar';
import EventGallery from './components/EventGallery';
import { Translation } from 'react-i18next';
import TagOrganizer from './components/TagOrganizer';

const UserTaggedBox = ({ index, data, style }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const username = userInfo.username;
  const currentData = data[index];

  const row = {
    display: 'flex',
    borderBottom: '1px solid #ededed',
    boxSizing: 'border-box',
    padding: 5,
    alignItems: 'center',
  };

  const roundedButton = {
    padding: 0,
    borderRadius: '50%',
    boxShadow: 'none',
    background: 'rgba(255, 255, 255, .3)',
    cursor: 'pointer',
    outline: 'none',
    width: 45,
    height: 45,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    flex: 'none',
  };

  const handleUntagClick = (e) => {
    e.stopPropagation();
    AppDispatcher.dispatch({
      actionType: 'UNTAG_USER',
      userId: currentData.username
    });
  }

  return (
    <Fragment>
      <div style={{ ...style, ...row }}>
        <UserAvatar {...currentData} horizontal={true} style={{flex: 1}}/>
        {
          currentData.who === username || currentData.username === username || userInfo.role === "admin" ?
          <motion.button
            onClick={handleUntagClick}
            style={{ ...roundedButton }}
            whileHover={{ color: '#1fb162' }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button> : null
        }
      </div>
    </Fragment>
  );
}

const BibnumberBox = ({ index, data, style }) => {
  const username = JSON.parse(localStorage.getItem('userInfo')).username;

  const button = {
    padding: 16,
    fontWeight: 600,
    fontSize: 14,
    boxShadow: 'none',
    border: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    boxSizing: 'border-box',
    borderBottom: '1px solid #ededed',
    position: 'relative',
    background: '#fff',
    flex: 1,
  };

  const row = {
    display: 'flex',
    borderBottom: '1px solid #ededed',
  };

  const roundedButton = {
    padding: 0,
    borderRadius: '50%',
    boxShadow: 'none',
    background: 'rgba(255, 255, 255, .3)',
    cursor: 'pointer',
    outline: 'none',
    width: 45,
    height: 52,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    position: 'absolute',
    right: 0
  };

  const handleBibnumberUntagClick = (e) => {
    e.stopPropagation();
    AppDispatcher.dispatch({
      actionType: 'UNTAG_BIBNUMBER',
      bibnumber: data[index].bibnumber
    });
  }

  return (
    <Fragment>
      <div style={{ ...style, ...row  }}>
        <div style={{ ...button }}>
          {data[index].bibnumber}
        </div>
        {
          data[index].who === username &&
          <motion.button
            onClick={handleBibnumberUntagClick}
            style={{ ...roundedButton }}
            whileHover={{ color: '#1fb162' }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        }
      </div>
    </Fragment>
  );
}

class EventView extends React.Component {
  constructor(props) {
    super(props);
    this._dispatchToken = AppDispatcher.register(this._registerToActions);
    this.state = this.getInitialData();
    this.gridRef = React.createRef();
    this.eventGalleryRef = React.createRef();
  }

  getInitialData = () => {
    const { columnCount } = this.getEventSizes();
    const currentPhotos = this.props.data && this.props.data.detail ? _.orderBy(this.props.data.detail, [p => p.likes.length, 'dateTime'], ['desc', 'desc']) : null;
    const currentOrder = currentPhotos ? currentPhotos.map(p => p._id) : null;
    const currentModal = this.props.data && this.props.data.selectedImageId && currentPhotos ? currentPhotos.findIndex(p => p._id === this.props.data.selectedImageId) : null; 

    return {
      currentModal: currentModal,
      currentOrder: currentOrder,
      filterPhotographer: null,
      filterParticipant: null,
      filterBibnumber: '',
      filter: null,
      sort: 'likes',
      isSelecting: false,
      selection: {},
      chunkPictures: currentOrder ? _.chunk(currentOrder, columnCount) : null
    };
  }

  _registerToActions = (action) => {
    switch (action.actionType) {
      case 'LIKE_PICTURE':
        this.handleLike(action.data);
        break;
      case 'REMOVE_PICTURE':
        this.handleConfirmRemove(action.data);
        break;
      case 'SHOW_TAG_PICTURE_OVERLAY':
        this.toggleTagging(action.data);
        break;
      case 'SHOW_TAG_BIBNUMBER_PICTURE_OVERLAY':
        this.toggleBibnumberTagging(action.data);
        break;
      case 'SHOW_BIBNUMBERS_PICTURE_OVERLAY':
        this.toggleBibnumbers(action.data);
        break;
      case 'SHOW_USERS_PICTURE_OVERLAY':
        this.toggleUsers(action.data);
        break;
      case 'SHOW_LIKES_PICTURE_OVERLAY':
        this.toggleLikes(action.data);
        break;
      case 'ADD_IMAGES':
        this.toggleNewPicturesModal(action.data);
        break;
      case 'UNTAG_USER':
        this.handleUntag(action.userId)
        break;
      case 'UNTAG_BIBNUMBER':
        this.handleBibnumberUntag(action.bibnumber)
        break;
      case 'IDENTIFY_USER':
        this.toggleIdentify();
        break;
      case 'FILTER_PICTURES':
        this.toggleFiltering();
        break;
      case 'SORT_NEWS':
        this.setState({ sort: 'news' });
        break;
      case 'SORT_TRENDING':
        this.setState({ sort: 'trending' });
        break;
      case 'SORT_DATE':
        this.setState({ sort: 'date' });
        break;
      case 'SORT_DATE_ASC':
        this.setState({ sort: 'dateAsc' });
        break;
      case 'SORT_LIKES':
        this.setState({ sort: 'likes' });
        break;
      case 'SORT_VISITS':
        this.setState({ sort: 'visits' });
        break;
      case 'SORT_COMMENTS':
        this.setState({ sort: 'comments' });
        break;
      case 'SORT_TAGS':
        this.setState({ sort: 'tags' });
        break;
      case 'DOWNLOAD_PICTURES':
        this.handleDownload();
        break;
      case 'HANDLE_SELECT_MODE':
        this.handleSelection();
        break;
      case 'DOWNLOAD_SELECTED_PICTURES':
        this.handleDownloadSelected();
        break;
      case 'REMOVE_SELECTED_PICTURES':
        this.handleConfirmRemoveSelected();
        break;
      case 'SHOW_PICTURE':
        const index = this.getPictureIndex(action.data);
        this.toggleModal(index);
        break;
      case 'SELECT_PICTURE':
        this.handleSelect(action.data._id)
        break;
      default:
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let sortChanged = false;
    let filterChanged = false;
    if (prevState.sort !== this.state.sort) {
      sortChanged = true;
    }
    if (!_.isEqual(prevState.filter, this.state.filter)) {
      filterChanged = true;
    }

    if (sortChanged || filterChanged) {
      //si cambia la ordenación o el filtro, cambiar el array y chunk de ids
      const { currentModal } = this.state;
      const currentPhotos = this.getCurrentPhotos({ sort: true });
      const { columnCount } = this.getEventSizes();
      const currentOrder = currentPhotos && currentPhotos.map(p => p._id);

      this.setState({
        chunkPictures: _.chunk(currentOrder, columnCount)
      });

      if (!Number.isInteger(currentModal)) {
        this.setState({
          currentOrder: currentPhotos && currentPhotos.map(p => p._id)
        });
      }
    }

    if (prevProps.data && this.props.data && prevProps.data._id !== this.props.data._id) {
        this.setState({
          ...this.getInitialData()
        });

        if (this.gridRef && this.gridRef.current) {
          this.gridRef.current.scrollTo({ scrollLeft: 0, scrollTop: 0 });
        } else if (this.eventGalleryRef && this.eventGalleryRef.current) {
          this.eventGalleryRef.current.scrollTop = 0;
        }
    }

    if (filterChanged || sortChanged) {
      if (this.gridRef.current) {
        this.gridRef.current.scrollTo({ scrollLeft: 0, scrollTop: 0 });
      } else if (this.eventGalleryRef && this.eventGalleryRef.current) {
        this.eventGalleryRef.current.scrollTop = 0;
      }
    }
  }

  getPictureIndex = (data) => {
    const { currentOrder } = this.state;
    return currentOrder.findIndex(d => d === data._id);
  }

  handleSelect = (_id) => {
    const { selection } = this.state;
    const newSelection = { ...selection };

    if (newSelection[_id]) {
      newSelection[_id] = false;
    } else {
      newSelection[_id] = true;
    }

    this.setState({
      selection: newSelection
    });
  }

  handleSelection = () => {
    const { isSelecting } = this.state;

    this.setState({
      isSelecting: !isSelecting
    });
  }

  stopOverlayPropagation = (e) => {
    e.stopPropagation();
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this._dispatchToken);
  }

  handleDownload = () => {
    const { data } = this.props;
    const url = `${baseUrl}/events/${data._id}/download?token=${localStorage.getItem('token')}`;

    const a = document.createElement("a");
    a.href = url;

    if (document.createEvent) {
      let event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      a.dispatchEvent(event);
    } else {
      a.click();
    }
  }

  handleDownloadSelected = () => {
    const { selection, currentOrder } = this.state;
    const url = `${baseUrl}/images/download`;
    let parsedSelection;
    if (selection && Object.keys(selection).length) {
      parsedSelection = Object.keys(selection).filter((key) => {
        return selection[key] !== false;
      });
    } else {
      parsedSelection = currentOrder;
    }
    const body = { ids: parsedSelection };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(body)
    }).then((response) => {
      if (response.ok) {
        return response.blob();
      }
    }).then((data) => {
      const objectUrl = URL.createObjectURL(data);
      const a = document.createElement("a");

      a.href = objectUrl;
      a.download = `reex-selection-${+new Date()}.zip`;

      if (document.createEvent) {
        let event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        a.dispatchEvent(event);
      } else {
        a.click();
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  handleView = ({ _id, hasView }) => {
    fetch(`${baseUrl}/images/${_id}/view`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE',
        data
      });
      if (!hasView) {
        AppDispatcher.dispatch({
          actionType: 'DECREMENT_EVENT_UNVIEWS',
          data
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  handleConfirmRemoveSelected = () => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete pictures",
        message: "Are you sure you want to delete the selected pictures? You can't undo this action.",
        onClose: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemoveSelected(); },
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
      }
    });
  }

  handleRemoveSelected = () => {
    const { selection } = this.state;
    const url = `${baseUrl}/images/remove`;
    const parsedSelection = Object.keys(selection).filter((key) => {
      return selection[key] !== false;
    });
    const body = { ids: parsedSelection };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(body)
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 403) {
        this.setState({
          dialog: {
            type: "error",
            title: "Error",
            message: "You cannot delete an image that is not yours",
            onClose: this.handleCloseDialog,
            onClickOutside: this.handleCloseDialog,
            onAccept: () => { this.handleCloseDialog(); }
          }
        });
      } else {
        throw new Error();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'REMOVE_EVENT_PICTURES'
      });
      this.setState({
        selection: {},
        dialog: {
          type: "success",
          title: "Success!",
          message: "The pictures has been removed",
          onClose: this.handleCloseDialog,
          onClickOutside: this.handleCloseDialog,
          onAccept: () => { this.handleCloseDialog();}
        }
      });
    }).catch((err) => {
      this.setState({
        dialog: {
          type: "error",
          title: "Error",
          message: "Oops! Something went wrong",
          onClose: this.handleCloseDialog,
          onClickOutside: this.handleCloseDialog,
          onAccept: () => { this.handleCloseDialog(); }
        }
      });
    });
  }

  handleLike = (data) => {
    const url = `${baseUrl}/images/${data._id}/like`;

    fetch(url, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE'
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleCloseDialog = () => {
    this.setState({
      dialog: null
    });
  }

  handleConfirmRemove = (data) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete picture",
        message: "Are you sure you want to delete the picture? This picture will be deleted immediately. You can't undo this action.",
        onClose: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onAccept: () => { this.handleCloseDialog(); this.handleRemove(data); },
      }
    });
  }

  handleRemove = (data) => {
    const url = `${baseUrl}/images/${data._id}`;

    fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.toggleModal();
      AppDispatcher.dispatch({
        actionType: 'REMOVE_EVENT_PICTURE',
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex'
        },
        eventData: {
          width: 350,
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          background: '#fff',
        },
        eventGallery: {
          flex: 1,
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          overflowY: 'auto',
          paddingLeft: 5,
        },
        eventPlaceholderWrapper: {
          overflow: 'hidden',
          flex: 1,
          background: '#fff',
          padding: 2,
          display: 'flex',
          flexDirection: 'column'
        },
        eventPlaceholder: {
          overflow: 'auto',
          flex: 1,
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        },
        eventPlaceholderImage: {
          width: 200
        },
        eventPlaceholderText: {
          textAlign: 'center',
          color: '#000',
          fontWeight: 600
        },
        tagOverlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          padding: '40px 10%',
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column'
        },
        overlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          padding: '40px 30%',
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column'
        },
        overlayTitle: {
          background: '#00d663',
          fontWeight: 600,
          flex: 'none',
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative'
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          boxSizing: 'border-box',
          background: '#fff',
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        },
        searchWrapper: {
          flex: 'none',
          padding: 20,
          display: 'flex',
          flexDirection: 'column'
        },
        searchTitle: {
          flex: 'none',
          marginBottom: 30,
          fontWeight: 600
        },
        searchRow: {
          flex: 'none',
          marginBottom: 10
        },
        searchLabel: {
          marginBottom: 10,
          position: 'relative',
          display: 'flex',
          alignItems: 'center'
        },
        searchLabelLink: {
          position: 'absolute',
          right: 0,
          fontWeight: 600,
          fontSize: 14,
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          color: '#00d663'
        },
        searchList: {
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        },
        searchInput: {
          fontSize: 16,
          outline: 'none',
          flex: 1,
          height: 38,
          border: '1px solid #ced4da',
          width: '100%',
          borderRadius: 4,
          padding: 10,
          boxSizing: 'border-box',
        },
        searchItem: {
          flex: 'none',
          padding: 16,
          boxSizing: 'border-box'
        },
        floatingButton: {
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 2
        },
        roundedButton: {
          padding: 0,
          borderRadius: '50%',
          boxShadow: 'none',
          background: 'rgba(255, 255, 255, .3)',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none'
        },
        input: {
          border: 'none',
          fontSize: 16,
          outline: 'none'
        },
        filterWrapper: {
          padding: 5,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '100%',
        },
        filterChip: {
          flex: 'none',
          padding: '5px 5px',
          display: 'flex',
          background: 'linear-gradient(45deg, #00d662, #24d8ea)',
          color: '#000',
          fontSize: 14,
          alignItems: 'center',
          borderRadius: 15,
          marginRight: 5,
          marginBottom: 5,
          fontWeight: 600,
          position: 'relative',
          minWidth: 200,
        },
        filterLabel: {
          fontWeight: 500,
          marginRight: 5,
          marginLeft: 5,
          display: 'flex',
          flexDirection: 'column',
          fontSize: 14,
          flex: 1
        },
        filterType: {
          textTransform: 'uppercase',
          fontWeight: 600,
          fontSize: 10,
          fontStyle: 'italic',
          opacity: 0.5
        },
        filterUsername: {
          fontWeight: 600,
        },
        filterName: {

        },
        filterButton: {
          textTransform: 'uppercase',
          fontSize: 10,
          position: 'absolute',
          right: 10,
          top: 5,
          cursor: 'pointer',
          color: '#3F51B5'
        },
        chartWrapper: {
          flex: .5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          position: 'relative'
        },
        chartTitle: {
          position: 'absolute',
          top: 5,
          left: 10,
          fontWeight: 600,
          fontSize: 20,
          color: '#000'
        },
        chartPlaceholder: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          display: 'flex',
          opacity: .3,
          color: '#000',
          fontSize: 20
        },
        tagsTitle: {
          background: '#fff',
          flex: 'none',
          padding: '15px 15px 0 15px',
          fontWeight: 600,
          textAlign: 'center',
        },
        tagsWrapper: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 75px)',
          gridTemplateRows: 'repeat(auto-fit, 80px)',
          alignItems: 'center',
          gridGap: 5,
          padding: 15,
          background: '#fff',
          position: 'relative',
          overflow: 'auto',
          boxSizing: 'border-box',
        },
        mapWrapper: {
          flex: 1,
          display: 'flex',
          background: '#fff',
          position: 'relative',
          overflow: 'hidden'
        },
        bibnumberWrapper: {
          padding: 10,
          display: 'flex',
          flex: 'none',
          boxSizing: 'border-box',
          alignItems: 'center',
          justifyContent: 'center'
        },
        bibnumber: {
          margin: '0 5px',
          color: '#000',
          fontWeight: 600,
          background: 'linear-gradient(115deg, #ffec07, #ffb206)',
          borderRadius: 4,
          padding: '0px 5px'
        },
        historyWrapper: {
          marginTop: 5,
          fontSize: 14,
          fontWeight: 'bold'
        },
        history: {
          marginTop: 5,
          display: 'flex',
          flexWrap: 'wrap',
        },
        organizations: {
          display: 'flex',
          marginTop: 10,
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          overflowY: 'auto',
        },
        eventData: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        },
        eventGallery: {
          flex: 'none',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden'
        },
        eventPlaceholderWrapper: {
          overflow: 'hidden',
          flex: 1,
          background: '#fff',
          padding: 2,
          display: 'flex',
          flexDirection: 'column'
        },
        eventPlaceholder: {
          overflow: 'auto',
          flex: 1,
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        },
        eventPlaceholderImage: {
          width: 200
        },
        eventPlaceholderText: {
          textAlign: 'center',
          color: '#000',
          fontWeight: 600
        },
        tagOverlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
        },
        overlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
        },
        overlayTitle: {
          background: '#00d663',
          fontWeight: 600,
          flex: 'none',
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative'
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flex: 1,
          boxSizing: 'border-box',
          background: '#fff',
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        },
        searchWrapper: {
          flex: 'none',
          padding: 20,
          display: 'flex',
          flexDirection: 'column'
        },
        searchTitle: {
          flex: 'none',
          marginBottom: 30,
          fontWeight: 600
        },
        searchRow: {
          flex: 'none',
          marginBottom: 10
        },
        searchLabel: {
          marginBottom: 10,
          position: 'relative',
          display: 'flex',
          alignItems: 'center'
        },
        searchLabelLink: {
          position: 'absolute',
          right: 0,
          fontWeight: 600,
          fontSize: 14,
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          color: '#00d663'
        },
        searchList: {
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        },
        searchItem: {
          flex: 'none',
          padding: 16,
          boxSizing: 'border-box'
        },
        searchInput: {
          fontSize: 16,
          outline: 'none',
          flex: 1,
          height: 38,
          border: '1px solid #ced4da',
          width: '100%',
          borderRadius: 4,
          padding: 10,
          boxSizing: 'border-box',
        },
        floatingButton: {
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 2
        },
        roundedButton: {
          padding: 0,
          borderRadius: '50%',
          boxShadow: 'none',
          background: 'rgba(255, 255, 255, .3)',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none'
        },
        input: {
          border: 'none',
          fontSize: 16,
          outline: 'none'
        },
        filterWrapper: {
          padding: 5,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          overflowX: 'auto',
          overflowY: 'hidden',
        },
        filterChip: {
          flex: 'none',
          padding: '5px 5px',
          display: 'flex',
          background: 'linear-gradient(45deg, #00d662, #24d8ea)',
          color: '#000',
          fontSize: 10,
          alignItems: 'center',
          borderRadius: 15,
          marginRight: 5,
          marginBottom: 5,
          fontWeight: 600,
          position: 'relative',
        },
        filterLabel: {
          fontWeight: 500,
          marginRight: 5,
          marginLeft: 5,
          display: 'flex',
          flexDirection: 'column',
          fontSize: 14,
        },
        filterType: {
          textTransform: 'uppercase',
          fontWeight: 600,
          fontSize: 10,
          fontStyle: 'italic',
          opacity: 0.5
        },
        filterUsername: {
          fontWeight: 600,
        },
        filterName: {

        },
        filterButton: {
          textTransform: 'uppercase',
          fontSize: 10,
          position: 'absolute',
          right: 10,
          top: 5,
          cursor: 'pointer',
          color: '#3F51B5'
        },
        chartWrapper: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          position: 'relative'
        },
        chartTitle: {
          position: 'absolute',
          top: 5,
          left: 10,
          fontWeight: 600,
          fontSize: 16,
          color: '#000'
        },
        chartPlaceholder: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          display: 'flex',
          opacity: .3,
          color: '#000',
          fontSize: 16
        },
        tagsTitle: {
          background: '#fff',
          flex: 'none',
          padding: '10px 10px 0 10px',
          fontWeight: 600,
          fontSize: 12
        },
        tagsWrapper: {
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 80px)',
          gridAutoFlow: 'column',
          alignItems: 'center',
          gridGap: 5,
          padding: 15,
          background: '#fff',
          position: 'relative',
          overflowX: 'auto',
          overflowY: 'hidden',
          boxSizing: 'border-box',
        },
        mapWrapper: {
          flex: 1,
          display: 'flex',
          background: '#fff',
          position: 'relative',
          overflow: 'hidden'
        },
        bibnumberWrapper: {
          padding: 10,
          display: 'flex',
          flex: 'none',
          boxSizing: 'border-box',
          alignItems: 'center',
          justifyContent: 'center'
        },
        bibnumber: {
          margin: '0 5px',
          color: '#000',
          fontWeight: 600,
          background: 'linear-gradient(115deg, #ffec07, #ffb206)',
          borderRadius: 4,
          padding: '0px 5px'
        },
        tab: {
          button: {
            active: {
              color: '#00d663',
              boxShadow: 'none',
              borderBottom: '2px solid'
            },
            deactive: {
            }
          },
          content: {
            minHeight: 0
          }
        },
        historyWrapper: {
          marginTop: 5,
          fontSize: 14,
          fontWeight: 'bold'
        },
        history: {
          marginTop: 5,
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 80px)',
          gridAutoFlow: 'column',
          alignItems: 'center',
          gridGap: 5,
          background: '#fff',
          position: 'relative',
          overflowX: 'auto',
          overflowY: 'hidden',
          boxSizing: 'border-box',
        },
        organizations: {
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
        }
      }
    }
  }

  handleBack = () => {
    AppDispatcher.dispatch({
      actionType: 'BACK_TO_MONTH_VIEW'
    });
  }

  toggleIdentify = () => {
    const { currentIdentify } = this.state;

    this.setState({
      currentIdentify: !currentIdentify
    });
  }

  toggleTagging = (data) => {
    const { currentTagging } = this.state;

    if (!currentTagging) {
      this.getAllOrganizations();
    }

    this.setState({
      currentTagging: !currentTagging ? data : null
    });
  }

  toggleBibnumberTagging = (data) => {
    const { currentBibnumberTagging } = this.state;

    this.setState({
      currentBibnumberTagging: !currentBibnumberTagging ? data : null
    });
  }

  toggleFiltering = (data) => {
    const { currentFiltering } = this.state;

    this.setState({
      currentFiltering: !currentFiltering
    });
  }

  toggleUsers = (data) => {
    const { currentUsers } = this.state;

    this.setState({
      currentUsers: !currentUsers ? data : null
    });
  }

  toggleBibnumbers = (data) => {
    const { currentBibnumbers } = this.state;

    this.setState({
      currentBibnumbers: !currentBibnumbers ? data : null
    });
  }

  toggleLikes = (data) => {
    const { currentLikes } = this.state;

    this.setState({
      currentLikes: !currentLikes ? data : null
    });
  }

  getAllOrganizations = () => {
    const { data: { organizers } } = this.props;
    const participants = organizers.filter(o => o.mode === 'participant');
    const [local, visitor] = participants;
    Promise.all([
      this.getOrganization(local),
      this.getOrganization(visitor),
    ]).then(responses => {
      if (responses) {
        const [local, visitor] = responses;

        this.setState({
          organizations: { local, visitor }
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getOrganization = async (participant) => {
    const { _id } = participant;
    const response = await fetch(`${baseUrl}/organizers/${_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  }

  renderEventData = () => {
    const { data, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.eventData}>
        {this.renderEventBox(data)}
        {this.renderEventUsers(data)}
      </div>
    );
  }

  handleUnsubscribe = () => {
    const { data } = this.props;

    fetch(`${baseUrl}/events/${data._id}/unsubscribe`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      if (data) {
        AppDispatcher.dispatch({
          actionType: 'UPDATE_EVENT_USERS',
          data
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  renderBibnumber = ({ users }) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    const username = JSON.parse(localStorage.getItem('userInfo')).username;

    if (users) {
      const myData = users.find(u => u.username === username);

      if (myData) {
        return (
          <Translation>
            {t => (
              <div style={styles.bibnumberWrapper}>
                {t('You are the')}
                <span style={styles.bibnumber}> {myData.bibnumber} </span> 
                {t('on this event!')}
                <motion.button
                  onClick={this.handleUnsubscribe}
                  style={{ ...styles.roundedButton, position: 'absolute', right: 0 }}
                  whileHover={{ color: '#1fb162' }}
                >
                  <Icon size={14} icon="close" color="#000" />
                </motion.button>
              </div>
            )}
          </Translation>
        );
      }
    }
  }

  renderEventUsers = ({ tags }) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!tags || tags.length === 0) {
      return null;
    }

    return (
      <Fragment>
        <div style={styles.tagsTitle}>
          <Translation>
            {t => t('Users on this event (click to filter)')}
          </Translation>
        </div>
        <div style={styles.tagsWrapper}>
          {this.renderAvatars(tags, { onClick: this.handleFilterParticipant})}
        </div>
      </Fragment>
    );
  }

  handleRemoveFilter = (key) => {
    const { filter } = this.state;
    const newFilter = { ...filter };

    delete newFilter[key];

    this.setState({
      selection: {},
      filter: newFilter,
      [key]: '',
    });

    AppDispatcher.dispatch({
      actionType: 'UNSET_FILTER'
    });
    // this.handleSelection();
  }

  handleFollow = (user) => {
    const url = `${baseUrl}/users/follow/${user.username}`;

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'RELOAD_USER'
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  checkIfFollowing = (user) => {
    const { userInfo } = this.props;

    return userInfo.following && userInfo.following.find((following) => following.username === user.username);
  }

  parseFilterLabel = (label) => {
    const labels = {
      filterBibnumber: 'Bibnumber',
      filterParticipant: 'Tagged',
      filterPhotographer: 'Upload by'
    };

    return labels[label];
  }

  renderFilterBar = (filter) => {
    const { isMobile, userInfo } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const filters = Object.keys(filter).map((f) => {
      if (!filter[f]) return null;
      const [name, surname] = filter[f].label.split(' ');
      const user = {
        name,
        surname,
        username: filter[f].value,
        thumb: filter[f].thumb
      };
      const showFollowButton = !this.checkIfFollowing(user) && (userInfo.username !== user.username);
      return (
        <div key={f} style={styles.filterChip}>
          <UserAvatar {...user} size={55} />
          <div style={styles.filterLabel}>
            <span style={styles.filterType}>
              <Translation>
                {t => t(this.parseFilterLabel(f))}
              </Translation>
            </span>
            <span style={styles.filterUsername}>{user.username}</span>
            <span style={styles.filterName}>{user.name} {user.surname}</span>
            {showFollowButton && <span style={styles.filterButton} onClick={() => {this.handleFollow(user)}}><Translation>{t => t('Follow')}</Translation></span>}
          </div>
          <motion.button
            onClick={() => this.handleRemoveFilter(f)}
            style={{ ...styles.roundedButton, width: 18, height: 18, marginLeft: 5, background: 'transparent' }}
            whileHover={{ color: '#1fb162' }}
          >
            <Icon size={12} icon="close" color="#000" />
          </motion.button>
        </div>
      );
    });

    return (
      <div style={styles.filterWrapper}>
        {filters}
      </div>
    );
  }

  getGalleryItemData = ({ rowIndex, columnIndex}) => {
    const { data: { detail } } = this.props;
    const { chunkPictures } = this.state;
    const itemId = chunkPictures && chunkPictures[rowIndex] ? chunkPictures[rowIndex][columnIndex] : null;
    const item = detail.find(d => d._id === itemId);

    if (!item) return null;
    
    return {
      ...item
    }
  }

  getEventSizes = () => {
    const { isMobile, isTablet } = this.props;

    if (isTablet) {
      return {
        columnCount: 2
      }
    }

    if (isMobile) {
      return {
        columnCount: 1
      }
    }

    return {
      columnCount: 3
    }
  }

  renderEventGallery = () => {
    const { filter, isSelecting, chunkPictures, currentOrder } = this.state;
    const { isMobile, data: { detail } } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const { columnCount } = this.getEventSizes();
    if (currentOrder.length === 0) return this.renderPlaceholderGallery();
    
    const parsedFilter = _.omitBy(filter, _.isNil);

    return (
      <div style={styles.eventGallery}>
        {parsedFilter && Object.keys(parsedFilter).length > 0 && this.renderFilterBar(parsedFilter)}
        <EventGallery 
          ref={this.gridRef}
          columnCount={columnCount}
          data={isMobile ? currentOrder.map(id => detail.find(photo => photo._id === id)) : chunkPictures}
          getGalleryItemData={this.getGalleryItemData}
          isSelecting={isSelecting}
          isMobile={isMobile}
          handleView={this.handleView}
        />
      </div>
    );
  }

  filterPhotos = (photos, filter) => {
    if (!filter) return photos;

    let filteredPhotos = photos.slice();

    if (filter.filterPhotographer) {
      filteredPhotos = filteredPhotos.filter(p => p.userId === filter.filterPhotographer.value);
    }

    if (filter.filterParticipant) {
      filteredPhotos = filteredPhotos.filter(p => p.users && p.users.some(u => u.username === filter.filterParticipant.value));
    }

    if (filter.filterBibnumber) {
      filteredPhotos = filteredPhotos.filter(p => p.bibnumbers && p.bibnumbers.some(u => u.bibnumber === filter.filterBibnumber));
    }

    return filteredPhotos;
  }

  toggleModal = (index) => {
    const { currentModal } = this.state;

    this.setState({
      currentModal: !Number.isInteger(currentModal) ? index : null,
      currentTagging: null,
      currentLikes: null,
      currentUsers: null,
      currentBibnumbers: null,
      currentBibnumberTagging: null
    });
  }

  selectModalPicture = (index) => {
    this.setState({
      currentModal: index
    });
  }

  selectNewPictureModal = (index) => {
    this.setState({
      currentNewPicturesModalIndex: index
    });
  }

  toggleNewPicturesModal = (data) => {
    const { currentNewPicturesModal } = this.state;

    this.setState({
      currentModal: null,
      currentNewPicturesModalIndex: !currentNewPicturesModal ? 0 : null,
      currentNewPicturesModal: !currentNewPicturesModal ? data : null
    });
  }

  renderPlaceholderGallery = () => {
    const { filter } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    const parsedFilter = _.omitBy(filter, _.isNil);

    if (Object.keys(parsedFilter).length > 0) {
      return (
        <div style={styles.eventPlaceholderWrapper}>
          {parsedFilter && this.renderFilterBar(parsedFilter)}
          <div style={styles.eventPlaceholder}>
            <div style={styles.eventPlaceholderImage}><img src="empty-gallery.svg" alt="Empty gallery" /></div>
            <div style={styles.eventPlaceholderText}>
              <Translation>
                {t => t("Your current filter doesn't match any result")}
              </Translation>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={styles.eventPlaceholder}>
        <div style={styles.eventPlaceholderImage}><img src="empty-gallery.svg" alt="Empty gallery" /></div>
        <div style={styles.eventPlaceholderText}>
          <Translation>
            {t => t("There is not pictures yet...")}
          </Translation>
          <br />
          <Translation>
            {t => t("be the first!")}
          </Translation>
        </div>
      </div>
    );
  }

  renderEventBox = (data) => {
    const { isMobile } = this.props;

    return <EventBox {...data} isMobile={isMobile} style={{ height: isMobile ? 350 : 500, flex: 'none' }} isOnEventScreen={true}/>
  }

  parseUsers = (users) => {
    return users.map((user) => {
      return { value: user.username, label: `${user.name} ${user.surname} (${user.alias})`, thumb: user.thumb }
    });
  }

  loadOptions = _.debounce((inputValue, callback) => {
    const body = {
      filter: inputValue
    };

    fetch(`${baseUrl}/users/find`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      callback(this.parseUsers(data));
    }).catch((err) => {
      console.log(err);
    });
  }, 200);

  renderTagOverlay = () => {
    const { isMobile, data: { detail } } = this.props;
    const { organizations, currentTagging } = this.state;
    const current = detail.find(d => d._id === currentTagging._id);
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <Translation>
        {t => (
          <div style={styles.tagOverlayWrapper} onClick={this.toggleTagging}>
            <div style={styles.overlayTitle}>
              {t("Tag players")}
              <motion.button
                onClick={this.toggleTagging}
                style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
              >
                <Icon size={18} icon="close" color="#000" />
              </motion.button>
            </div>
            <div style={{...styles.overlay, flex: 'none'}} onClick={this.stopOverlayPropagation}>
              <div style={styles.searchWrapper}>
                <AsyncSelect
                  value={null}
                  cacheOptions
                  loadOptions={this.loadOptions}
                  onInputChange={this.handleInputChange}
                  onChange={this.handleTag}
                  placeholder={t("Search players to tag...")}
                  components={{ Option: this.customOption }}
                  styles={{
                    control: (styles, state) => ({
                      ...styles,
                      boxShadow: state.isFocused ? "0 0 0 0.1rem #00d663" : 0,
                      borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA",
                      "&:hover": {
                        borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA"
                      }
                    })
                  }}
                />
                {current && current.users && current.users.length > 0 &&
                  <div style={styles.historyWrapper}>
                  {t('players on this photo')}
                    <div style={styles.history}>
                      {this.renderAvatars(current.users, { size: 40 })}
                    </div>
                  </div>
                }
                {
                  organizations && this.renderOrganizations(organizations)
                }
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  renderOrganizations = ({local, visitor}) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.organizations}>
        <TagOrganizer data={local} onClick={this.handleTag} isMobile={isMobile}/>
        <TagOrganizer data={visitor} onClick={this.handleTag} isMobile={isMobile}/>
      </div>
    );
  }

  renderAvatars = (data, config = {}) => {
    return data.map(user => <UserAvatar key={user.username} style={{ margin: 5 }} detailed={true} {...user} {...config} />);
  }

  renderBibnumberTagOverlay = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <Translation>
        {t => (
          <div style={styles.overlayWrapper} onClick={this.toggleBibnumberTagging}>
            <div style={styles.overlayTitle}>
              <Translation>
                {t('Tag bibnumbers')}
              </Translation>
              <motion.button
                onClick={this.toggleBibnumberTagging}
                style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
              >
                <Icon size={18} icon="close" color="#000" />
              </motion.button>
            </div>
            <div style={{ ...styles.overlay, padding: 10, flex: 'none' }} onClick={this.stopOverlayPropagation}>
              <input 
                style={styles.input} 
                type="text" 
                placeholder={t("Tag a competition number...")} 
                onChange={(e) => this.handleBibnumberTagChange(e.currentTarget.value)} 
              />
              <motion.button
                onClick={this.handleBibnumberTag}
                style={{ ...styles.button, marginTop: 10 }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              >
                {t('Tag')}
              </motion.button>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  renderFilterOverlay = () => {
    const { filterPhotographer, filterParticipant, filterBibnumber } = this.state;
    const { isMobile, data } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const username = JSON.parse(localStorage.getItem('userInfo')).username;
    const myData = data && data.users && data.users.find(u => u.username === username);
    const hasBibnumbers = this.hasBibnumbers();

    return (
      <Translation>
        {t => (
          <div style={styles.overlayWrapper} onClick={this.toggleFiltering}>
            <div style={{ ...styles.overlay, flex: 'none'}} onClick={this.stopOverlayPropagation}>
              <div style={styles.searchWrapper}>
                <div style={styles.searchTitle}>
                  {t('Use the following fields to filter the event gallery')}
                </div>
                <div style={styles.searchRow}>
                  <div style={styles.searchLabel}>
                    {t('Photographer')}
                    <motion.button
                      onClick={this.handleFilterMyPhotographer}
                      style={{...styles.searchLabelLink}}
                      whileHover={{ color: '#1fb162' }}
                    >
                      <div style={styles.author}>
                        {t('(Show pictures uploaded by me)')}
                      </div>
                    </motion.button>
                  </div>
                  <AsyncSelect
                    value={filterPhotographer}
                    cacheOptions
                    loadOptions={this.loadOptions}
                    onInputChange={this.handleInputChange}
                    onChange={this.handleFilterPhotographerChange}
                    placeholder={t("Search photographer")}
                    components={{ Option: this.customOption }}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        boxShadow: state.isFocused ? "0 0 0 0.1rem #00d663" : 0,
                        borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA",
                        "&:hover": {
                          borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA"
                        }
                      })
                    }}
                  />
                </div>
                {data.photographers && data.photographers.length > 0 && 
                <div style={styles.searchRow}>
                  <div style={styles.historyWrapper}>
                    <div style={styles.history}>
                      {this.renderAvatars(data.photographers, { onClick: this.handleFilterPhotographer, style: { margin: 5, width: 80 } })}
                    </div>
                  </div>
                </div>}
                <div style={styles.searchRow}>
                  <div style={styles.searchLabel}>
                    {t('Participant')}
                    <motion.button
                      onClick={this.handleFilterMyParticipant}
                      style={{ ...styles.searchLabelLink }}
                      whileHover={{ color: '#1fb162' }}
                    >
                      <div style={styles.author}>
                        {t('(Show my pictures)')}
                      </div>
                    </motion.button>
                  </div>
                  <AsyncSelect
                    value={filterParticipant}
                    cacheOptions
                    loadOptions={this.loadOptions}
                    onInputChange={this.handleInputChange}
                    onChange={this.handleFilterParticipantChange}
                    placeholder={t("Search participant")}
                    components={{ Option: this.customOption }}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        boxShadow: state.isFocused ? "0 0 0 0.1rem #00d663" : 0,
                        borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA",
                        "&:hover": {
                          borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA"
                        }
                      })
                    }}
                  />
                </div>
                {data.tags && data.tags.length > 0 && <div style={styles.searchRow}>
                  <div style={styles.historyWrapper}>
                    <div style={styles.history}>
                      {this.renderAvatars(data.tags, { onClick: this.handleFilterParticipant, style: { margin: 5, width: 80 } })}
                    </div>
                  </div>
                </div>}
                {hasBibnumbers && 
                  <div style={styles.searchRow}>
                    <div style={styles.searchLabel}>
                      {t('Bibnumber')}
                      {myData && myData.bibnumber && 
                      <motion.button
                        onClick={() => { this.handleFilterByBibumber(myData.bibnumber) }}
                        style={{ ...styles.searchLabelLink }}
                        whileHover={{ color: '#1fb162' }}
                      >
                        <div style={styles.author}>
                          {t('(Show pictures with my bibnumber)')}
                        </div>
                      </motion.button>
                      }
                    </div>
                    <input
                      style={styles.searchInput}
                      type="text"
                      value={filterBibnumber}
                      placeholder={t("Search bibnumber")}
                      onChange={(e) => this.handleFilterBibnumberChange(e.currentTarget.value)}
                    />
                  </div>
                }
                <motion.button
                  onClick={this.handleFilter}
                  style={{ ...styles.button, marginTop: 10 }}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  {t('Filter')}
                </motion.button>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  renderUsersOverlay = (picture) => {
    const { isMobile, data: { detail } } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const currentPicture = picture && detail.find(d => d._id === picture._id);

    return (
      <div style={styles.overlayWrapper} onClick={this.toggleUsers}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t('Tagged users')}
          </Translation>
          <motion.button
            onClick={this.toggleUsers}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={styles.overlay} onClick={this.stopOverlayPropagation}>
          <SizeMe monitorWidth monitorHeight>
            {({ size }) => (
              <List
                height={size.height}
                itemCount={currentPicture.users && currentPicture.users.length}
                itemSize={55}
                width={size.width}
                itemData={currentPicture.users}
              >
                {UserTaggedBox}
              </List>
            )}
          </SizeMe>
        </div>
      </div>
    );
  }

  renderBibnumbersOverlay = (picture) => {
    const { isMobile, data: { detail } } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const currentPicture = picture && detail.find(d => d._id === picture._id);

    return (
      <div style={styles.overlayWrapper} onClick={this.toggleBibnumbers}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t('Bibnumbers')}
          </Translation>
          <motion.button
            onClick={this.toggleBibnumbers}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={styles.overlay} onClick={this.stopOverlayPropagation}>
          <SizeMe monitorWidth monitorHeight>
            {({ size }) => (
              <List
                height={size.height}
                itemCount={currentPicture.bibnumbers && currentPicture.bibnumbers.length}
                itemSize={55}
                width={size.width}
                itemData={currentPicture.bibnumbers}
              >
                {BibnumberBox}
              </List>
            )}
          </SizeMe>
        </div>
      </div>
    );
  }

  renderLikesOverlay = (picture) => {
    const { isMobile, data: { detail } } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const currentPicture = picture && detail.find(d => d._id === picture._id);

    return (
      <div style={styles.overlayWrapper} onClick={this.toggleLikes}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t('Likes')}
          </Translation>
          <motion.button    
            onClick={this.toggleLikes}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={styles.overlay} onClick={this.stopOverlayPropagation}>
          <SizeMe monitorWidth monitorHeight>
            {({ size }) => (
              <List
                height={size.height}
                itemCount={currentPicture.likes && currentPicture.likes.length}
                itemSize={55}
                width={size.width}
                itemData={currentPicture.likes}
                >
                {UserTaggedBox}
              </List>
            )}
          </SizeMe>
        </div>
      </div>
    );
  }

  handleIdentifyChange = _.debounce((bibnumber) => {
    this.setState({
      bibnumber
    });
  }, 200)

  handleBibnumberTagChange = _.debounce((currentBibnumberTag) => {
    this.setState({
      currentBibnumberTag
    });
  }, 200)

  handleIdentify = () => {
    const { data } = this.props;
    const { bibnumber } = this.state;

    const body = { bibnumber };

    fetch(`${baseUrl}/events/${data._id}/subscribe`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      if (data) {
        this.toggleIdentify();
        AppDispatcher.dispatch({
          actionType: 'UPDATE_EVENT_USERS',
          data
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  renderIdentifyOverlay = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <Translation>
        {t => (
          <div style={styles.overlayWrapper} onClick={this.toggleIdentify}>
            <div style={styles.overlayTitle}>
              {t('Identify your bibnumber')}
              <motion.button
                onClick={this.toggleIdentify}
                style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
              >
                <Icon size={18} icon="close" color="#000" />
              </motion.button>
            </div>
            <div style={{ ...styles.overlay, padding: 10, flex: 'none' }} onClick={this.stopOverlayPropagation}>
              <input 
                style={styles.input} 
                type="text" 
                placeholder={t("Enter your number on this event..." )}
                onChange={(e) => this.handleIdentifyChange(e.currentTarget.value)} 
              />
              <motion.button
                onClick={this.handleIdentify}
                style={{ ...styles.button, marginTop: 10 }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              >
                {t('Identify')}
              </motion.button>
            </div>
          </div>
      )}
      </Translation>
    );
  }

  handleFilter = () => {
    const { filterPhotographer, filterParticipant, filterBibnumber } = this.state;

    this.toggleFiltering();

    if (!filterPhotographer && !filterParticipant && !filterBibnumber) {
      this.setState({
        filter: null
      });

      AppDispatcher.dispatch({
        actionType: 'UNSET_FILTER'
      });
    } else {
      this.setState({
        filter: {
          filterPhotographer: filterPhotographer || null,
          filterParticipant: filterParticipant || null,
          filterBibnumber: filterBibnumber || null
        }
      });

      AppDispatcher.dispatch({
        actionType: 'SET_FILTER'
      });
    }
  }

  handleFilterPhotographerChange = (selected) => {
    this.setState({
      filterPhotographer: selected
    });
  }

  handleFilterPhotographer = (user) => {
    const selected = {
      label: `${user.name} ${user.surname} (${user.username})`,
      value: user.username,
      thumb: user.thumb
    };

    this.setState({
      currentFiltering: false,
      filterPhotographer: selected,
      filterParticipant: '',
      filterBibnumber: '',
      filter: {
        filterPhotographer: selected
      }
    });

    AppDispatcher.dispatch({
      actionType: 'SET_FILTER'
    });
  }

  handleFilterMyPhotographer = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const selected = {
      label: `${userInfo.name} ${userInfo.surname} (${userInfo.username})`,
      value: userInfo.username,
      thumb: userInfo.thumb
    };

    this.toggleFiltering();
    this.setState({
      filterPhotographer: selected,
      filterParticipant: '',
      filterBibnumber: '',
      filter: {
        filterPhotographer: selected
      }
    });

    AppDispatcher.dispatch({
      actionType: 'SET_FILTER'
    });
  }

  handleFilterParticipantChange = (selected) => {
    this.setState({
      filterParticipant: selected
    });
  }

  handleFilterParticipant = (user) => {
    const selected = {
      label: `${user.name} ${user.surname} (${user.username})`,
      value: user.username,
      thumb: user.thumb
    };

    this.setState({
      currentFiltering: false,
      filterParticipant: selected,
      filterPhotographer: '',
      filterBibnumber: '',
      filter: {
        filterParticipant: selected
      }
    });

    AppDispatcher.dispatch({
      actionType: 'SET_FILTER'
    });
  }

  handleFilterMyParticipant = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const selected = {
      label: `${userInfo.name} ${userInfo.surname} (${userInfo.username})`,
      value: userInfo.username,
      thumb: userInfo.thumb
    };

    this.toggleFiltering();
    this.setState({
      filterParticipant: selected,
      filterPhotographer: '',
      filterBibnumber: '',
      filter: {
        filterParticipant: selected
      }
    });

    AppDispatcher.dispatch({
      actionType: 'SET_FILTER'
    });
  }

  handleFilterBibnumberChange = (bibnumber) => {
    this.setState({
      filterBibnumber: bibnumber
    });
  }

  handleFilterByBibumber = (bibnumber) => {
    this.toggleFiltering();
    this.setState({
      filterBibnumber: bibnumber,
      filterPhotographer: '',
      filterParticipant: '',
      filter: {
        filterBibnumber: bibnumber
      }
    });

    AppDispatcher.dispatch({
      actionType: 'SET_FILTER'
    });
  }

  handleBibnumberTag = () => {
    const { currentBibnumberTag, currentBibnumberTagging } = this.state;

    const body = { bibnumber: currentBibnumberTag };

    fetch(`${baseUrl}/images/${currentBibnumberTagging._id}/bibnumber/tag`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.toggleBibnumberTagging();
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE'
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleBibnumberUntag = (bibnumber) => {
    const { currentBibnumbers } = this.state;

    if (bibnumber) {
      const body = {
        bibnumber: bibnumber
      };

      fetch(`${baseUrl}/images/${currentBibnumbers._id}/bibnumber/untag`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((data) => {
        AppDispatcher.dispatch({
          actionType: 'UPDATE_EVENT_PICTURE'
        });
        if (data) {
          this.setState({
            currentBibnumbers: data.bibnumbers && data.bibnumbers.length > 0 ? data : null
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  handleTag = (selected) => {
    const { currentTagging } = this.state;

    if (selected) {
      const body = {
        userId: selected.value
      };

      fetch(`${baseUrl}/images/${currentTagging._id}/tag`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((data) => {
        this.getAllOrganizations();
        AppDispatcher.dispatch({
          actionType: 'UPDATE_EVENT_PICTURE'
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  handleUntag = (userId) => {
    const { currentUsers } = this.state;

    if (userId) {
      const body = {
        userId: userId
      };

      fetch(`${baseUrl}/images/${currentUsers._id}/untag`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((data) => {
        AppDispatcher.dispatch({
          actionType: 'UPDATE_EVENT_PICTURE'
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');

    this.setState({ inputValue });

    return inputValue;
  }

  customOption = (props) => {
    return (
      <CustomUserOption {...props} />
    );
  }

  getPictureDataByIndex = (index) => {
    const { data } = this.props;

    return data.detail && data.detail[index];
  }

  renderNewPicturesSlideshow = () => {
    const { isMobile } = this.props;
    const { currentNewPicturesModal, currentNewPicturesModalIndex } = this.state;

    if (currentNewPicturesModal) {
      return (
        <Slideshow
          HeaderComponent={NewPicturesHeader}
          data={currentNewPicturesModal[currentNewPicturesModalIndex]}
          handleClose={this.toggleNewPicturesModal}
          handleNext={this.selectNewPictureModal}
          handlePrevious={this.selectNewPictureModal}
          showComments={false}
          isMobile={isMobile}
          index={0}
          min={0}
          max={currentNewPicturesModal.length - 1}
        />
      );
    }

    return null;
  }

  hasBibnumbers = () => {
    const { data } = this.props;

    return data && data.type && data.type.bibnumbers;
  }

  getCurrentPhotos = ({sort = true} = {}) => {
    const { data: { detail } } = this.props;
    const { filter } = this.state;
    const filteredPhotos = this.filterPhotos(detail, filter);

    if (sort) {
      return this.sortPhotos(filteredPhotos);
    }

    return filteredPhotos;
  }

  sortPhotos = (photos) => {
    const { userInfo: { username } } = this.props;
    const { sort } = this.state;

    switch(sort) {
      case 'trending': return _.orderBy(photos, ['score'], ['desc']);
      case 'likes': return _.orderBy(photos, [p => p.likes.length, 'dateTime'], ['desc', 'desc']);
      case 'comments': return _.orderBy(photos, [p => p.comments.length, 'dateTime'], ['desc', 'desc']);
      case 'tags': return _.orderBy(photos, [p => p.users.length, 'dateTime'], ['desc', 'desc']);
      case 'visits': return _.orderBy(photos, [p => p.visits, 'dateTime'], ['desc','desc']);
      case 'news': return _.orderBy(photos, [p => !p.views.find(v => v.username === username), 'dateTime'], ['desc', 'desc']);
      case 'date': return _.orderBy(photos, ['dateTime'], ['desc']);
      case 'dateAsc': 
      default: 
        return _.orderBy(photos, ['dateTime'], ['asc']);
    }
  }

  renderSlideshow = () => {
    const { isMobile, data: { detail } } = this.props;
    const { currentModal, currentOrder } = this.state;
    const hasBibnumbers = this.hasBibnumbers();
    const currentPhotos = this.getCurrentPhotos({ sort: false });
    
    if (Number.isInteger(currentModal)) {
      const currentId = currentOrder && currentOrder[currentModal];
      const data = detail && detail.find(p => p._id === currentId);

      return (
        <Slideshow
          data={data}
          hasBibnumbers={hasBibnumbers}
          handleClose={this.toggleModal}
          handleNext={this.selectModalPicture}
          handlePrevious={this.selectModalPicture}
          handleView={this.handleView}
          isMobile={isMobile}
          index={currentModal}
          min={0}
          max={currentPhotos && currentPhotos.length-1}
        />
      );
    }

    return null;
  }

  renderDesktopContent = () => {
    const { isMobile } = this.props;
    const { dialog, currentTagging, currentBibnumberTagging, currentUsers, currentLikes, currentIdentify, currentFiltering, currentBibnumbers } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        {dialog && <Dialog {...dialog} />}
        {currentTagging && this.renderTagOverlay(currentTagging)}
        {currentBibnumberTagging && this.renderBibnumberTagOverlay(currentBibnumberTagging)}
        {currentFiltering && this.renderFilterOverlay(currentFiltering)}
        {currentUsers && this.renderUsersOverlay(currentUsers)}
        {currentBibnumbers && this.renderBibnumbersOverlay(currentBibnumbers)}
        {currentLikes && this.renderLikesOverlay(currentLikes)}
        {currentIdentify && this.renderIdentifyOverlay(currentIdentify)}
        {this.renderEventData()}
        {this.renderEventGallery()}
        {this.renderSlideshow()}
        {this.renderNewPicturesSlideshow()}
      </div>
    );
  }

  renderMobileContent = () => {
    const { isMobile } = this.props;
    const { dialog, currentTagging, currentBibnumberTagging, currentUsers, currentLikes, currentIdentify, currentFiltering, currentBibnumbers } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper} ref={this.eventGalleryRef}>
        {dialog && <Dialog {...dialog} />}
        {currentTagging && this.renderTagOverlay(currentTagging)}
        {currentBibnumberTagging && this.renderBibnumberTagOverlay(currentBibnumberTagging)}
        {currentFiltering && this.renderFilterOverlay(currentFiltering)}
        {currentUsers && this.renderUsersOverlay(currentUsers)}
        {currentBibnumbers && this.renderBibnumbersOverlay(currentBibnumbers)}
        {currentLikes && this.renderLikesOverlay(currentLikes)}
        {currentIdentify && this.renderIdentifyOverlay(currentIdentify)}
        {this.renderEventData()}
        {this.renderEventGallery()}
        {this.renderSlideshow()}
        {this.renderNewPicturesSlideshow()}
      </div>
    );
  }

  render() {
    const { isMobile } = this.props;

    return isMobile ? this.renderMobileContent() : this.renderDesktopContent();
  }
}

EventView.defaultProps = {
};

export default EventView;