import React from 'react';

class TabButton extends React.Component {
  getStyles() {
    const { tabId, selected, style } = this.props;
    const contentActiveStyle = style && style.button ? style.button.active || style.button : {};
    const contentDeactiveStyle = style && style.button ? style.button.deactive || style.button : {};

    return {
      deactive: {
        width: '100%',
        cursor: 'pointer',
        textAlign: 'center',
        padding: '6px 8px',
        color: '#9e9e9e',
        height: 30,
        boxSizing: 'border-box',
        overflow: 'hidden',
        transition: 'background 1s ease, color 1s ease',
        borderRadius: 2,
        fontSize: 16,
        border: "none",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(tabId !== selected && contentDeactiveStyle)
      },
      active: {
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        width: '100%',
        padding: '6px 8px',
        color: '#2b2b2b',
        borderRadius: 2,
        height: 30,
        boxSizing: 'border-box',
        overflow: 'hidden',
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 2px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
        ...(tabId === selected && contentActiveStyle)
      },
      label: {
        marginRight: 5
      }
    };
  }

  handleClick = () => {
    const { handleClick, tabId } = this.props;

    handleClick(tabId);
  }

  render() {
    const { label, tabId, count, selected } = this.props;
    const styles = this.getStyles();

    return (
      <div style={tabId === selected ? styles.active : styles.deactive} onClick={this.handleClick}>
        <div style={styles.label}>{label}</div>
        {count ? `(${count})` : null}
      </div>
    );
  }
}

export default TabButton;
