import React from 'react';
import TextTransition from "react-text-transition";
import './Welcome.css';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import WelcomeActions from './actions/WelcomeActions';
import Icon from '../Icon/Icon';
import { Translation } from 'react-i18next';
import { motion } from 'framer-motion';

class Welcome extends React.Component {
  constructor() {
    super();
    this.state = {
      active: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ active: this.state.active + 1 });
    }, 4000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
          background: '#fff',
          color: '#58595b'
        },
        contentWrapper: {
          padding: '45px 60px',
          flex: 0.5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          position: 'relative',
          boxSizing: 'border-box',
          overflow: 'auto',
        },
        content: {
          marginLeft: 15
        },
        title: {
          fontSize: 56,
          fontWeight: 600,
          margin: 0,
          lineHeight: 1
        },
        subTitle: {
          fontSize: 20,
          margin: 0,
          paddingLeft: 2,
          marginBottom: 20
        },
        text: {
          fontSize: 20,
          opacity: .8,
          marginTop: 40,
          paddingLeft: 2,
          fontWeight: 100
        },
        buttonWrapper: {
          marginLeft: 17,
          fontWeight: 'bold',
          display: 'flex'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 24,
          borderRadius: 25,
          boxShadow: '0px 3px 10px 0px rgba(2, 214, 97, 0.6)',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          zIndex: 1
        },
        logo: {
          height: 200,
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        },
        logoText: {
          fontWeight: 600,
          fontSize: 32,
          color: '#424242',
          position: 'relative',
          top: -5
        },
        pictureWrapper: {
          flex: 1,
          backgroundImage: 'url(background2.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          position: 'relative'
        },
        floatingBox: {
          height: 70,
          width: 350,
          background: '#02d662',
          borderRadius: 10,
          position: 'absolute',
          bottom: 40,
          left: -70,
          display: 'flex',
          boxSizing: 'border-box',
          padding: '10px 0',
          pointerEvents: 'none'
        },
        box: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          color: '#000',
          textTransform: 'uppercase'
        },
        boxTitle: {
          fontWeight: 600,
          fontSize: 14,
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        boxLabel: {
          fontSize: 10,
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        socialLoginWrapper: {
          marginTop: 10,
          display: 'flex',
        },
        socialLinks: {
          display: 'flex',
        },
        socialButton: {
          border: 'none', 
          background: 'transparent', 
          cursor: 'pointer', 
          padding: 0, 
          width: 35, 
          height: 35, 
          outline: 'none'
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          background: '#fff',
          color: '#58595b'
        },
        contentWrapper: {
          padding: '30px 10px',
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          boxSizing: 'border-box'
        },
        content: {
          marginTop: 30,
          marginLeft: 15
        },
        title: {
          fontSize: 36,
          fontWeight: 600,
          margin: 0,
          lineHeight: 1
        },
        subTitle: {
          fontSize: 18,
          margin: 0,
          paddingLeft: 2,
          marginBottom: 15
        },
        text: {
          fontSize: 18,
          opacity: .8,
          marginTop: 40,
          paddingLeft: 2,
          fontWeight: 100
        },
        buttonWrapper: {
          marginTop: 20,
          marginLeft: 17,
          fontWeight: 'bold',
          display: 'flex'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 20,
          borderRadius: 25,
          boxShadow: '0px 3px 10px 0px rgba(2, 214, 97, 0.6)',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          zIndex: 1
        },
        logo: {
          height: 110,
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          marginLeft: 7
        },
        logoText: {
          fontWeight: 600,
          fontSize: 32,
          color: '#424242',
          position: 'relative',
          top: -5
        },
        pictureWrapper: {
          flex: 'none',
          height: 400,
          backgroundImage: 'url(background2.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        floatingBox: {
          height: 70,
          width: '90%',
          background: '#02d662',
          borderRadius: 10,
          display: 'flex',
          boxSizing: 'border-box',
          padding: '10px 0',
          pointerEvents: 'none'
        },
        box: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          color: '#000',
          textTransform: 'uppercase'
        },
        boxTitle: {
          fontWeight: 600,
          fontSize: 14,
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        boxLabel: {
          fontSize: 10,
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        socialLoginWrapper: {
          marginTop: 10,
          marginLeft: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        },
        socialLinks: {
          display: 'flex',
          marginLeft: 15,
          marginTop: 50
        },
        socialButton: {
          border: 'none', 
          background: 'transparent', 
          cursor: 'pointer', 
          padding: 0, 
          width: 35, 
          height: 35, 
          outline: 'none'
        }
      }
    }
  }

  responseGoogleSuccess = (response) => {
    WelcomeActions.sendGoogleAuth(response);
  }

  responseGoogleFailure = (error) => {
    console.error(error);
  }

  responseFacebook = (response) => {
    if (response.accessToken) {
      WelcomeActions.sendFacebookAuth(response);
    }
  }

  handleTwitterClick = () => {
    window.open("https://twitter.com/reex_pro");
  }

  handleFacebookClick = () => {
    window.open("https://www.facebook.com/reex.pro/");
  }

  handleInstagramClick = () => {
    window.open("https://www.instagram.com/reex.pro/");
  }

  render() {
    const { active } = this.state;
    const { texts, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <Translation>
        { t => (
          <div style={styles.wrapper}>
            <div style={styles.contentWrapper}>
              <div style={styles.logo}>
                <img src="logo.svg" height="100%" alt="logo" />
              </div>
              <div style={styles.content}>
                <h3 style={styles.subTitle}>
                  {t('Organize your matches, inspire others.')}
              </h3>
                <h2 style={styles.title}>
                  {t('Relive')}
              <TextTransition className={isMobile && 'mobile'} overflow={true} text={t(texts[active % texts.length])} />
                </h2>
                <p style={styles.text}>
                  {t("Alone, it's a picture.")}<br />
                {t("Together, its amazing.")}
              </p>
              </div>
              <div style={styles.socialLoginWrapper}>
                <GoogleLogin
                  className="google-login"
                  clientId="975298191852-oiooksuckilp5fii0mn4rsocpe9p04o2.apps.googleusercontent.com"
                  onSuccess={this.responseGoogleSuccess}
                  onFailure={this.responseGoogleFailure}
                  cookiePolicy={'single_host_origin'}
                  buttonText={t("Login with Google")}
                />
                <FacebookLogin
                  appId="1163302760529284"
                  fields="name,email,picture"
                  callback={this.responseFacebook}
                  className="facebook-login"
                  textButton={t("Login with Facebook")}
                  icon={<Icon size={24} icon="facebookSolid" color="#fff" />}
                  isMobile={false}
                />
              </div>
              <div style={styles.socialLinks}>
                <motion.button
                  onClick={this.handleTwitterClick}
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.8 }}
                  style={styles.socialButton}
                >
                  <img src="twitter.svg" alt="twitter" width="100%" height="100%" />
                </motion.button>
                <motion.button
                  onClick={this.handleFacebookClick}
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.8 }}
                  style={styles.socialButton}
                >
                  <img src="facebook.svg" alt="facebook" width="100%" height="100%" />
                </motion.button>
                <motion.button
                  onClick={this.handleInstagramClick}
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.8 }}
                  style={styles.socialButton}
                >
                  <img src="instagram.svg" alt="instagram" width="100%" height="100%" />
                </motion.button>
              </div>
            </div>
            <div style={styles.pictureWrapper}>
              <div style={styles.floatingBox}>
                <div style={styles.box}>
                  <div style={styles.boxTitle}>{t('Organize')}</div>
                  <div style={styles.boxLabel}>{t('Matches')}</div>
                </div>
                <div style={{ ...styles.box, borderLeft: '1px solid #05b956', borderRight: '1px solid #05b956' }}>
                  <div style={styles.boxTitle}>{t('Connect')}</div>
                  <div style={styles.boxLabel}>{t('Friends')}</div>
                </div>
                <div style={styles.box}>
                  <div style={styles.boxTitle}>{t('Keep')}</div>
                  <div style={styles.boxLabel}>{t('Moments')}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

Welcome.defaultProps = {
  texts: [
    "experiences",
    "moments",
    "wins",
    "emotions",
    "adventures",
    "photos",
    "battles",
  ]
};

export default Welcome;

