import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import Spinner from 'react-spinkit';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { baseUrl } from '../../config';
import { Translation } from 'react-i18next';

const wrapper = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  background: '#fff'
};

const wrapperMobile = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'auto',
  background: '#fff'
};

const spinner = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgba(255, 255, 255, .5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const dropzone = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: 16,
  boxSizing: 'border-box',
  textAlign: 'center'
};

const dropzoneMobile = {
  flex: .5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: 16,
  boxSizing: 'border-box',
  textAlign: 'center'
};

const dropZoneImage = {
  width: 200,
  height: 200,
  marginBottom: 20
}

const dropZoneImageMobile = {
  width: 100,
  height: 100,
  marginBottom: 10
}

const thumbsContainer = {
  display: 'flex',
  padding: 16,
  overflow: 'auto',
  flex: .6,
  boxSizing: 'border-box',
  background: '#f9f9f9',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center'
};

const img = {
  objectFit: 'cover',
  width: '100%',
  height: '100%'
};

const thumbWrapper = {
  flex: 'none',
  width: 100,
  height: 100,
  overflow: 'hidden',
  margin: 5
}

const thumbPlaceholderWrapper = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const header = {
  background: '#00d663',
  color: '#000',
  flex: 'none',
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: 20,
  position: 'relative'
};

const headerMobile = {
  background: '#00d663',
  color: '#000',
  flex: 'none',
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: 12,
  position: 'relative'
};

const close = {
  position: 'absolute',
  right: 10
};

const closeButton = {
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  background: 'transparent'
};

const mainButtons = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center'
};

const buttons = {
  flex: 'none',
  height: 50,
  background: '#f0f0f0',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: 20
};

const button = {
  background: '#00d663',
  color: '#fff',
  padding: '5px 30px',
  fontWeight: 600,
  textTransform: 'uppercase',
  fontSize: 14,
  borderRadius: 4,
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
  border: 'none',
  cursor: 'pointer',
  outline: 'none'
};

const DropzonePicturesContainer = (props) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const thumbs = files.map(file => (
    <div key={file.name} style={thumbWrapper}>
      <img
        src={file.preview}
        style={img}
        alt={file.title}
      />
    </div>
  ));

  const thumbPlaceholder = () => {
    return (
      <div style={thumbPlaceholderWrapper}>
        <p>
          <Translation>
            {t => t('Selected pictures will appear here')}
          </Translation>
        </p>
      </div>
    )
  }

  const handleSubmit = () => {
    const newData = new FormData();
    let url;
    let onSuccess;
    let onError;

    if (props.origin === 'event') {
      url = `${baseUrl}/images/upload`;
      newData.append('eventId', props.event._id);
      newData.append('userId', props.userInfo.username);
      onSuccess = (data) => {
        if (data && data.length > 0) {
          props.handleClose();
          AppDispatcher.dispatch({
            actionType: "ADD_IMAGES",
            data
          });
        }
      }

      onError = (error) => {
        AppDispatcher.dispatch({
          actionType: "ERROR_IMAGES_UPLOAD",
          error
        });
      }
    } else if (props.origin === 'portfolio') {
      url = `${baseUrl}/users/${props.userInfo.username}/portfolio/upload`;
      onSuccess = (data) => {
        props.handleClose();
        AppDispatcher.dispatch({
          actionType: "UPDATE_EVENT_PICTURE",
          data
        });
      };

      onError = (error) => {
        AppDispatcher.dispatch({
          actionType: "ERROR_PORTFOLIO_UPLOAD",
          error
        });
      }
    }


    files.forEach(file => newData.append('files', file))

    setLoading(true);

    fetch(url, {
      method: "POST",
      body: newData,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (!response.ok) {
        throw new Error();
      }

      return response.json();
    }).then((data) => {
      onSuccess(data);
    }).catch((err) => {
      onError(err);
      setLoading(false);
    });
  }

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <Translation>
      {t => (
        <section style={props.isMobile ? wrapperMobile : wrapper}>
          {loading &&
            <div style={spinner}>
              <Spinner name='cube-grid' color="#00d663" size={24} />
            </div>
          }
          <div style={props.isMobile ? headerMobile : header}>
            {t(props.header)}
            <div style={close}>
              <motion.button
                type="button"
                onClick={props.handleClose}
                style={closeButton}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={18} icon="close" color="#000" />
              </motion.button>
            </div>
          </div>
          <div style={props.isMobile ? dropzoneMobile : dropzone} {...getRootProps()}>
            <input {...getInputProps()} />
            <div style={props.isMobile ? dropZoneImageMobile : dropZoneImage}><img src="empty-gallery.svg" alt="Upload pictures" /></div>
            <p>{t(props.description)}</p>
          </div>
          <aside style={thumbsContainer}>
            {thumbs && thumbs.length > 0 ? thumbs : thumbPlaceholder()}
          </aside>
          {files && files.length > 0 &&
            <div style={buttons}>
              <div style={mainButtons}>
                <motion.button
                  onClick={handleSubmit}
                  style={Object.assign({}, button, { color: '#000', background: '#00d066' })}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  {t('SUBMIT')}
              </motion.button>
              </div>
            </div>
          }
        </section>
      )}
    </Translation>
  );
}

DropzonePicturesContainer.defaultProps = {
  description: "Drag 'n' drop some pictures here, or click to select pictures from your computer"
};

export default DropzonePicturesContainer;
