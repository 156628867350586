import React, { useState, useEffect, useRef } from 'react';
import { baseUrl } from '../../config';
import MotionButton from '../Button/MotionButton';
import { useOnClickOutside } from '../../utils/hooks';
import Icon from '../Icon/Icon';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemGraphic,
  MenuListItemText,
} from '@material/react-menu';
import { Translation } from 'react-i18next';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import moment from 'moment';
import { isDateBetweenDateRange } from '../../utils/date';

const Toolbar = (props) => {
  const { filter = {}, isMobile, hideCategories } = props;
  const [federations, setFederations] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menu, setMenu] = useState(null);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));

  useEffect(() => {
    const abortController = new AbortController();
    const fetchFederations = async () => {
      try {
        await obtainFederations(abortController);
      } catch (e) {
        console.error(e.message);
      }
    }

    fetchFederations();
    return () => {
      abortController.abort();
    };
  }, [])

  useEffect(() => {
    if (filter.federationId && filter.federationId !== 'all') {
      const abortController = new AbortController();
      const fetchSeasons = async () => {
        try {
          await obtainSeasons(abortController);
        } catch (e) {
          console.error(e.message);
        }
      }
  
      fetchSeasons();
      return () => {
        abortController.abort();
      };
    }
  }, [filter.federationId])

  useEffect(() => {
    if (filter.seasonId && filter.seasonId !== "null" && seasons.length) {
      const abortController = new AbortController();
      const fetchCategories = async () => {
        try {
          await obtainCategories(abortController);
        } catch (e) {
          console.error(e.message);
        }
      }
  
      fetchCategories();
      return () => {
        abortController.abort();
      };
    }
  }, [filter.seasonId, seasons.length])

  const obtainFederations = async (controller) => {
    fetch(`${baseUrl}/federations`, {
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      setFederations(data);
      handleSelectDefaultFederation(data);
    }).catch((err) => {
      console.log(err);
    });
  }

  const obtainSeasons = async (controller) => {
    fetch(`${baseUrl}/seasons/?federationId=${filter.federationId}`, {
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      setSeasons(data);
      handleSelectCurrentSeason(data);
    }).catch((err) => {
      console.log(err);
    });
  }

  const obtainCategories = async (controller) => {
    fetch(`${baseUrl}/seasons/${filter.seasonId}`, {
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      setCategories(data);
    }).catch((err) => {
      console.log(err);
    });
  }

  const handleSelectDefaultFederation = (data) => {
    const userFederation = localStorage.getItem('federationId');

    if (userFederation && userFederation !== "null") return null;

    const defaultFederation = data.find(d => d.default) || data[0];
    handleItemClick({type: 'federation', _id: defaultFederation._id});
  }

  const handleSelectCurrentSeason = (data) => {
    const userSeason = localStorage.getItem('seasonId');

    if (userSeason && userSeason !== "null") return null;

    //mirar si la fecha esta incluida en alguna season
    //y si no, recorrer again mirando si el end está ANTES O IGUAL que hoy
    if (!data || !data.length) return null;
    
    const today = new Date();
    let currentSeason = data.find(season => isDateBetweenDateRange({ date: today, range: [season.begin, season.end]}));
    if (!currentSeason) {
      currentSeason = data.find(season => moment(season.end).isSameOrBefore(moment(today)));
    }

    handleItemClick({type: 'season', _id: currentSeason._id});
  }

  const getStyles = () => {
    return {
      desktop: {
        wrapper: {
          padding: '10px 25px 0px 25px',
          display: 'flex'
        },
        group: {
          marginRight: 10
        },
        label: {
          fontSize: 12,
          fontWeight: 600,
          color: '#a0a0a0'
        },
        thumbWrapper: {
          marginRight: 5,
          flex: 'none',
          width: 25,
          height: 25,
          overflow: 'hidden',
          borderRadius: '50%',
          background: '#e8fff3'
        },
        thumb: {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        }
      },
      mobile: {
        wrapper: {
          padding:  0,
          display: 'flex',
          marginBottom: 10
        },
        group: {
          padding: '0 5px',
          flex: 1,
          width: '33%'
        },
        label: {
          fontSize: 10,
          fontWeight: 600,
          color: '#a0a0a0'
        },
        value: {
          fontSize: 10,
          fontWeight: 600,
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        thumbWrapper: {
          marginRight: 5,
          flex: 'none',
          width: 25,
          height: 25,
          overflow: 'hidden',
          borderRadius: '50%',
          background: '#e8fff3'
        },
        thumb: {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        }
      }
    }
  }

  const styles = isMobile ? getStyles().mobile : getStyles().desktop;

  const handleClose = () => {
    setMenuOpen(false);
    setMenu(null);
  };
  
  const handleItemClick = option => {
    handleClose();
    switch(option.type) {
      case 'federation':
        AppDispatcher.dispatch({
          actionType: 'UPDATE_FILTER',
          filter: {
            federationId: option._id,
            seasonId: null,
            categoryId: 'all',
          }
        });
        break;
      case 'season':
        AppDispatcher.dispatch({
          actionType: 'UPDATE_FILTER',
          filter: {
            seasonId: option._id,
            categoryId: 'all'
          }
        });
        break;
      case 'category':
        AppDispatcher.dispatch({
          actionType: 'UPDATE_FILTER',
          filter: {
            categoryId: option._id
          }
        });
        break;
      default:
        break;
    }
  };

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className={isMobile ? "mdc-menu-device" : "mdc-menu-desktop"}
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleItemClick(menu[index])}
          >
            <MenuList>
              <div ref={ref}>
                {menu.map(option => (
                  <MenuListItem key={option.value}>
                    {option.type !== 'season' ? 
                    <MenuListItemGraphic 
                      graphic={
                        <div style={styles.thumbWrapper}>
                          {option.thumb && <img style={styles.thumb} src={option.thumb} alt="thumb" />}
                        </div>
                      } 
                    /> : null}
                    <MenuListItemText primaryText={t(option.label)} />
                  </MenuListItem>
                ))}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };

  const getFederationsActions = () => {
    return federations.map(f => ({_id: f._id, label: f.name, value: f.name, thumb: f.thumb, type: 'federation'}));
  }

  const getSeasonsActions = () => {
    if (!filter.federationId || !federations.length) return null

    return seasons.map(s => ({_id: s._id, label: s.name, value: s.name, type: 'season'}));
  }

  const getCategoriesActions = () => {
    if (filter.seasonId === 'all') return [{_id: 'all', label: 'All', value: 'all', type: 'category'}];

    return [{_id: 'all', label: 'All', value: 'all', type: 'category'}].concat(categories.map(c => ({_id: c.type._id, label: c.type.name, value: c.type.name, thumb: c.type.thumb, type: 'category'})));
  }

  const getFederationLabel = (federationId) => {
    if (federationId === 'all' || !federations.length) return 'All';
    return federations.find(f => f._id === federationId).name;
  }

  const getSeasonLabel = (seasonId) => {
    if (seasonId === 'all' || !seasons.length) return 'All';
    return seasons.find(s => s._id === seasonId).name;
  }

  const getCategoryLabel = (categoryId) => {
    if (categoryId === 'all' || !categories.length) return 'All';
    return categories.find(c => c.type._id === categoryId).type.name;
  }

  const handleShowFederations = (event) => {
    setMenuOpen(true);
    setMenu(getFederationsActions());
    if (isMobile) {
      setCoordinates({ x: 10, y: 70 });
    } else {
      setCoordinates({ x: event.clientX, y: event.clientY });
    }
  };

  const handleShowSeasons = (event) => {
    setMenuOpen(true);
    setMenu(getSeasonsActions());
    if (isMobile) {
      setCoordinates({ x: 10, y: 70 });
    } else {
      setCoordinates({ x: event.clientX, y: event.clientY });
    }
  };

  const handleShowCategories = (event) => {
    setMenuOpen(true);
    setMenu(getCategoriesActions());
    if (isMobile) {
      setCoordinates({ x: 10, y: 70 });
    } else {
      setCoordinates({ x: event.clientX, y: event.clientY });
    }
  };

  if (!filter.federationId || !filter.seasonId || filter.seasonId === "null") return null;

  if (isMobile) {
    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            {isMenuOpen && renderMenu()}
            <div style={styles.group} onClick={handleShowFederations}>
              <div style={styles.label}>{t('Federation')}</div>
              <div style={styles.value}>{t(getFederationLabel(filter.federationId))}</div>
            </div>
            <div style={styles.group} onClick={handleShowSeasons}>
              <div style={styles.label}>{t('Season')}</div>
              <div style={styles.value}>{t(getSeasonLabel(filter.seasonId))}</div>
            </div>
            {!hideCategories ? <div style={styles.group} onClick={handleShowCategories}>
              <div style={styles.label}>{t('Category')}</div>
              <div style={styles.value}>{t(getCategoryLabel(filter.categoryId))}</div>
            </div> : null}
          </div>
        )}
      </Translation>
    );
  }

  return (
    <Translation>
      {t => (
        <div style={styles.wrapper}>
          {isMenuOpen && renderMenu()}
          <div style={styles.group}>
            <div style={styles.label}>{t('Federation')}</div>
            <MotionButton 
              onClick={handleShowFederations} 
              dense={false} 
              background={null} 
              whileHover={{}}
              style={{ display: 'flex', alignItems: 'center', padding: '5px 0px', fontSize: 12}}
            >
              {t(getFederationLabel(filter.federationId))}
              <Icon size={isMobile ? 18 : 24} icon="menuDown" color="#000" />
            </MotionButton>
          </div>
          <div style={styles.group}>
            <div style={styles.label}>{t('Season')}</div>
            <MotionButton 
              onClick={handleShowSeasons} 
              dense={false} 
              background={null} 
              whileHover={{}}
              style={{ display: 'flex', alignItems: 'center', padding: '5px 0px', fontSize: 12}}
            >
              {t(getSeasonLabel(filter.seasonId))}
              <Icon size={isMobile ? 18 : 24} icon="menuDown" color="#000" />
            </MotionButton>
          </div>
          {!hideCategories ? <div style={styles.group}>
            <div style={styles.label}>{t('Category')}</div>
            <MotionButton 
              onClick={handleShowCategories} 
              dense={false} 
              background={null} 
              whileHover={{}}
              style={{ display: 'flex', alignItems: 'center', padding: '5px 0px', fontSize: 12}}
            >
              {t(getCategoryLabel(filter.categoryId))}
              <Icon size={isMobile ? 18 : 24} icon="menuDown" color="#000" />
            </MotionButton>
          </div> : null}
        </div>
      )}
    </Translation>
  );

}

export default Toolbar;