import React from 'react'
import moment from 'moment';
import Icon from '../Icon/Icon';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import UserAvatar from '../UserAvatar/UserAvatar';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import { Translation } from 'react-i18next';

const EventPictureBoxRanking = (data) => {
  const { _id, thumb, userId, alias, likes, name, surname, views, visits, style, organizer, position, colors, isMobile, onClick } = data;
  const username = JSON.parse(localStorage.getItem('userInfo')).username;
  const hasView = views && views.find(v => v.username === username);

  const desktopStyles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      ...style
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      borderRadius: 5,
      border: '1px solid #efefef',
    },
    header: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10
    },
    label: {
      flex: 'none',
      marginBottom: 5,
      color: '#888888',
    },
    imageWrapper: {
      flex: 1,
      cursor: 'pointer',
      position: 'relative',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'absolute',
    },
    contentWrapper: {
      position: 'relative',
      flex: 'none',
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      boxSizing: 'border-box',
      alignItems: 'center',
      justifyContent: 'center',
    },
    placeholder: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: '#e6e4e4',
      zIndex: 1
    },
    bottom: {
      padding: 5,
      flex: 'none',
      display: 'flex',
      flexDirection: 'column'
    },
    stats: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 10,
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 16,
      flex: 1,
    },
    stat: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      opacity: 0.5,
      fontSize: 14,
      flex: 'none',
      marginTop: 40,
    },
    badge: {
      padding: '2px 5px',
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 3,
      background: '#F44336',
      color: '#fff'
    },
    position: {
      flex: 'none',
      marginBottom: 20,
      display: 'flex',
      fontSize: 48,
      fontWeight: 600,
      boxSizing: 'border-box',
      alignItems: 'center'
    }
  }

  const mobileStyles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      ...style
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      borderRadius: 5,
      border: '1px solid #efefef',
      flexDirection: 'column',
    },
    header: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 5
    },
    label: {
      flex: 'none',
      marginBottom: 5,
      color: '#888888',
    },
    imageWrapper: {
      flex: 'none',
      height: 250,
      cursor: 'pointer',
      position: 'relative',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'absolute',
    },
    contentWrapper: {
      position: 'relative',
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      boxSizing: 'border-box',
      alignItems: 'center',
    },
    placeholder: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: '#e6e4e4',
      zIndex: 1
    },
    bottom: {
      padding: 5,
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    stats: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 12
    },
    stat: {
      flex: 'none',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      opacity: 0.5,
      fontSize: 10,
      flex: 'none',
      marginTop: 10,
    },
    badge: {
      padding: '2px 5px',
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 3,
      background: '#F44336',
      color: '#fff'
    },
    position: {
      flex: 'none',
      marginBottom: 5,
      display: 'flex',
      fontSize: 26,
      fontWeight: 600,
      boxSizing: 'border-box',
      alignItems: 'center',
    }
  }

  const styles = isMobile ? mobileStyles : desktopStyles;

  const checkIfLiked = () => {
    const username = JSON.parse(localStorage.getItem('userInfo')).username;

    return likes && likes.find(u => u.username === username);
  }

  const handleClick = () => {
    if (onClick) {
      onClick({ data });
    } else {
      AppDispatcher.dispatch({
        actionType: 'SHOW_PICTURE',
        data
      });
    }
  }

  const getUrl = () => {
    return `https://moments.fra1.digitaloceanspaces.com/web/${_id}.jpeg`;
  }

  const getPositionColor = () => {
    switch(position) {
      case 1: return colors.gold;
      case 2: return colors.silver;
      case 3: return colors.bronze;
      default: return 'blue';
    }
  }

  const getPositionIcon = () => {
    const color = getPositionColor();
    switch (position) {
      case 1: 
        return <Icon size={isMobile ? 20 : 32} icon="awardTrophy" color={color} style={{marginLeft: 10}}/>;
      case 2:
      case 3:
        return <Icon size={isMobile ? 20 : 32} icon="awardMedal" color={color} style={{marginLeft: 10}}/>;
      default: 
        return null;
    }
  }

  const isLiked = checkIfLiked();
  
  return (
    <Translation>
      {t => (
        <div style={styles.wrapper}>
          <div style={styles.card}>
            <div style={styles.imageWrapper} onClick={handleClick}>
              {!hasView && <div style={styles.badge}>{t('new')}</div>}
              <img src={getUrl()} style={styles.image} loading="lazy" alt={_id} />
              <div style={styles.background} />
            </div>
            <div style={styles.contentWrapper}>
              <div style={{ ...styles.position }}>#{position} {getPositionIcon()}</div>
              <div style={styles.header}>
                <UserAvatar 
                  detailed
                  size={80} 
                  width={isMobile ? 300 : 250}
                  thumb={thumb} 
                  username={userId} 
                  alias={alias} 
                  name={name}
                  surname={surname}
                  nameStyle={{ fontSize: 12 }}
                  usernameStyle={{ fontSize: 16 }} 
                />
              </div>
              {organizer && 
                <>
                  <div style={styles.label}>{t('Organizer')}</div>
                  <div style={styles.header}>
                    <OrganizationAvatar {...organizer} size={isMobile ? 30 : 50} style={{ padding: 5, boxSizing: 'content-box' }} />
                  </div>
                </>
              }
              <div style={styles.bottom}>
                <div style={styles.stats}>
                  <div style={{ ...styles.stat, color: isLiked ? '#f44336' : '#000' }}>
                    <Icon size={isMobile ? 18 : 24} icon={isLiked ? "heart" : "heartEmpty"} color={isLiked ? '#f44336' : '#000'}/>
                    {likes.length}
                  </div>
                  <div style={styles.stat}>
                    <Icon size={isMobile ? 18 : 24} icon="eyeEmpty" />
                    {visits}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
}

EventPictureBoxRanking.defaultProps = {
  colors: {
    bronze: '#cd7f32',
    silver: '#9E9E9E',
    gold: '#FFB300'
  }
}

export default EventPictureBoxRanking;
