import React from 'react';
import MotionButton from '../Button/MotionButton';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { Translation } from 'react-i18next';
import Footer from '../Footer/Footer';

const EventNotFound = ({isMobile}) => {
  const styles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: 15,
    },
    iconWrapper: {
      width: isMobile ? 100 : 250,
      height: isMobile ? 100 : 250,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      marginBottom: 50,
    },
    icon: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    title: {
      fontWeight: 600,
      marginBottom: 20,
      fontSize: 20
    },
    description: {
      fontSize: 14,
      marginBottom: 50,
    },
  }

  const handleClick = () => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: null
    });
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.iconWrapper}>
        <img style={styles.icon} src="head-bandage.svg" alt="Oops" />
      </div>
      <div style={styles.title}>
        <Translation>
          {t => t("Looks like we couldn't find that event.")}
        </Translation>
      </div>
      <div style={styles.description}>
        <Translation>
          {t => t("Maybe the event hasn't been published yet or has been deleted.")}
        </Translation>
      </div>
      <MotionButton onClick={handleClick}>
        <Translation>
          {t => t("Go to home")}
        </Translation>
      </MotionButton>
      <Footer isMobile={isMobile} />
    </div>
  )
}

export default EventNotFound;
