import React from 'react';
import TabButton from './TabButton';

class TabButtonWrapper extends React.Component {
  render() {
    return (
      <TabButton {...this.props} />
    );
  }
}

export default TabButtonWrapper;
