import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import { baseUrl } from '../../config'; 
import Select from "react-select";
import countryList from 'react-select-country-list';
import handleResponse from '../../utils/handleResponse';
import { Translation } from 'react-i18next';

const genders = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
const countries = countryList().getData();

const MySelect = ({ onChange, onBlur, id, label, components, options, value, error, touched, placeholder }) => {
  const handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    onChange(id, value);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    onBlur(id, true);
  };

  const getOption = () => {
    if (!value || !options) return '';

    return options.find(o => o.value === value);
  }

  return (
    <Translation>
      {t => (
        <>
          <label htmlFor={id}>{t(label)}</label>
          <Select
            id={id}
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            value={getOption()}
            placeholder={t(placeholder)}
            components={components}
          />
          {!!error && touched && (
            <div style={{ color: "#00d663", marginTop: ".5rem" }}>
              {t(error)}
            </div>
          )}
        </>
      )}
    </Translation>
  );
}

class FormOrganizationCreateMember extends Component {
  getStyles = () => {
    const { isMobile } = this.props;

    return {
      wrapper: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        background: '#fff',
      },
      form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      },
      fields: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        overflow: 'auto',
        padding: 20,
      },
      field: {
        marginBottom: 10,
        flexDirection: 'column',
        display: 'flex'
      },
      label: {
        marginBottom: 10,
        paddingLeft: 2
      },
      input: {
        border: '1px solid transparent',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40
      },
      checkbox: {
        fontSize: 16,
      },
      inputError: {
        border: '1px solid #00d663',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40
      },
      feedback: {
        marginBottom: 10,
        color: '#f44336',
        fontSize: 16,
        textAlign: 'center',
      },
      inputFeedback: {
        color: '#00d663',
        fontSize: 12,
        marginTop: 5
      },
      header: {
        background: '#00d663',
        color: '#000',
        flex: 'none',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: isMobile ? 16 : 20,
        position: 'relative'
      },
      buttons: {
        flex: 'none',
        height: 50,
        background: '#f9f8f8',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: isMobile ? 5 : 20
      },
      mainButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      gap: {
        flex: 1
      },
      secondaryButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      button: {
        background: '#00d663',
        color: '#fff',
        padding: isMobile ? '5px 15px' : '5px 30px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none'
      },
      close: {
        position: 'absolute',
        right: 10
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      }
    }
  }

  handleSubmit = (values,  actions) => {
    const { handleClose, data, organizationId } = this.props;
    const url = data && data.userId ? `${baseUrl}/users/${data.userId}` : `${baseUrl}/organizers/${organizationId}/members/create`;
    const body = {
      ...values,
      role: 'user',
      nationality: values.nationality && values.nationality.value,
      gender: values.gender && values.gender.value,
      positionId: (values.positionId && values.positionId.value) || values.positionId,
      userId: (values.userId && values.userId.value) || values.userId
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => {
      return handleResponse(response);
    }).then(([ok, status, data]) => {
      if (!ok) {
        throw new Error(data || 'Oops! Something went wrong');
      }

      handleClose({ reload: true });
    }).catch((error) => {
      actions.setSubmitting(false);
      actions.setFieldError('response', error.message);
    });
  }

  handleDelete = () => {
    const { handleClose, data } = this.props;
    let url = `${baseUrl}/users/${data.userId}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      handleClose({ reload: true });
    }).catch((err) => {
      console.log(err);
    });
  }

  getValidationSchema = () => {
    const { data } = this.props;

    if (data && data.userId) {
      return Yup.object().shape({
        username: Yup.string().required('You must select an username'),
        alias: Yup.string().required('You must select an alias'),
        email: Yup.string().email(),
        name: Yup.string().required('You must enter a name'),
        surname: Yup.string().required('You must enter a surname'),
        dob: Yup.string().required('You must enter the date of birth'),
        gender: Yup.string().required('You must select a gender'),
        nationality: Yup.string(),
        bio: Yup.string(),
        phone: Yup.string(),
        instagram: Yup.string(),
        twitter: Yup.string(),
        facebook: Yup.string(),
      });
    }

    return Yup.object().shape({
      username: Yup.string().required('You must select an username'),
      alias: Yup.string().required('You must select an alias'),
      email: Yup.string().email(),
      name: Yup.string().required('You must enter a name'),
      surname: Yup.string().required('You must enter a surname'),
      dob: Yup.string().required('You must enter the date of birth'),
      gender: Yup.string().required('You must select a gender'),
      nationality: Yup.string(),
      bio: Yup.string(),
      phone: Yup.string(),
      instagram: Yup.string(),
      twitter: Yup.string(),
      facebook: Yup.string(),
      positionId: Yup.string().required('You must select a position'),
      id: Yup.string()
    });
  }

  getInitialValues = () => {
    const { data } = this.props;

    if (data && data.userId) {
      return {
        username: '',
        alias: '',
        email: '',
        name: '',
        surname: '',
        dob: '',
        gender: '',
        bio: '',
        ...data,
        photo: null,
      }
    }

    return {
      username: '',
      alias: '',
      email: '',
      name: '',
      surname: '',
      dob: '',
      gender: '',
      bio: '',
      positionId: '',
      id: '',
      ...data,
      photo: null,
    }
  }

  render() {
    const { header, handleClose, positions, data } = this.props;
    const styles = this.getStyles();

    return (
      <Translation>
        {t => (
        <div style={styles.wrapper}>
          <Formik
            initialValues={this.getInitialValues()}
            onSubmit={this.handleSubmit}
            validationSchema={this.getValidationSchema()}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
                setFieldTouched
              } = props;
              return (
                <form onSubmit={handleSubmit} style={styles.form}>
                  <div style={styles.header}>
                    {t(header)}
                    {handleClose &&
                      <div style={styles.close}>
                        <motion.button
                          type="button"
                          onClick={handleClose}
                          style={styles.closeButton}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          <Icon size={18} icon="close" color="#000" />
                        </motion.button>
                      </div>
                    }
                  </div>

                  <div style={styles.fields}>
                    <div style={styles.field}>
                      <label htmlFor="username" style={styles.label}>
                        {t('Username')}
                      </label>
                      <input
                        id="username"
                        placeholder={t("Enter username")}
                        type="text"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.username && touched.username ? styles.inputError : styles.input}
                      />
                      {errors.username && touched.username && (
                        <div style={styles.inputFeedback}>{t(errors.username)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="alias" style={styles.label}>
                        {t('Alias')}
                      </label>
                      <input
                        id="alias"
                        placeholder={t("Enter alias")}
                        type="text"
                        value={values.alias}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.alias && touched.alias ? styles.inputError : styles.input}
                      />
                      {errors.alias && touched.alias && (
                        <div style={styles.inputFeedback}>{t(errors.alias)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="email" style={styles.label}>
                        {t('Email')}
                      </label>
                      <input
                        id="email"
                        placeholder={t("email@example.com")}
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.email && touched.email ? styles.inputError : styles.input}
                      />
                      {errors.email && touched.email && (
                        <div style={styles.inputFeedback}>{t(errors.email)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="name" style={styles.label}>
                        {t('Name')}
                      </label>
                      <input
                        id="name"
                        placeholder={t("Enter name")}
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.name && touched.name ? styles.inputError : styles.input}
                      />
                      {errors.name && touched.name && (
                        <div style={styles.inputFeedback}>{t(errors.name)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="surname" style={styles.label}>
                        {t('Surname')}
                      </label>
                      <input
                        id="surname"
                        placeholder={t("Enter surname")}
                        type="text"
                        value={values.surname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.surname && touched.surname ? styles.inputError : styles.input}
                      />
                      {errors.surname && touched.surname && (
                        <div style={styles.inputFeedback}>{t(errors.surname)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="dob" style={styles.label}>
                        {t('Date of birth')}
                      </label>
                      <input
                        id="dob"
                        type="date"
                        value={values.dob}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.dob && touched.dob ? styles.inputError : styles.input}
                      />
                      {errors.dob && touched.dob && (
                        <div style={styles.inputFeedback}>{t(errors.dob)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <MySelect
                        value={values.gender}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.gender}
                        touched={touched.gender}
                        options={genders}
                        id="gender"
                        label="Gender"
                        placeholder="Select gender"
                      />
                    </div>
                    <div style={styles.field}>
                      <MySelect
                        value={values.nationality}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.nationality}
                        touched={touched.nationality}
                        options={countries}
                        id="nationality"
                        label="Nationality"
                        placeholder="Select nationality"
                      />
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="bio" style={styles.label}>
                        {t('Bio')}
                      </label>
                      <textarea
                        id="bio"
                        type="text"
                        maxLength="250"
                        value={values.bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={styles.textArea}
                      />
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="phone" style={styles.label}>
                        {t('Phone')}
                      </label>
                      <input
                        id="phone"
                        placeholder={t("Enter contact phone")}
                        type="tel"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.phone && touched.phone ? styles.inputError : styles.input}
                      />
                      {errors.phone && touched.phone && (
                        <div style={styles.inputFeedback}>{t(errors.phone)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="instagram" style={styles.label}>
                        Instagram
                      </label>
                      <input
                        id="instagram"
                        placeholder={t("Enter instagram username")}
                        type="text"
                        value={values.instagram}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.instagram && touched.instagram ? styles.inputError : styles.input}
                      />
                      {errors.instagram && touched.instagram && (
                        <div style={styles.inputFeedback}>{t(errors.instagram)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="twitter" style={styles.label}>
                        Twitter
                      </label>
                      <input
                        id="twitter"
                        placeholder={t("Enter twitter username")}
                        type="text"
                        value={values.twitter}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.twitter && touched.twitter ? styles.inputError : styles.input}
                      />
                      {errors.twitter && touched.twitter && (
                        <div style={styles.inputFeedback}>{t(errors.twitter)}</div>
                      )}
                    </div>
                    <div style={styles.field}>
                      <label htmlFor="facebook" style={styles.label}>
                        Facebook
                      </label>
                      <input
                        id="facebook"
                        placeholder={t("Enter facebook username")}
                        type="text"
                        value={values.facebook}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={errors.facebook && touched.facebook ? styles.inputError : styles.input}
                      />
                      {errors.facebook && touched.facebook && (
                        <div style={styles.inputFeedback}>{t(errors.facebook)}</div>
                      )}
                    </div>
                    
                    {!data || !data.userId ?
                      <>
                        <div style={styles.field}>
                          <MySelect
                            value={values.positionId}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={errors.positionId}
                            touched={touched.positionId}
                            options={positions && positions.map(p => ({label: p.name, value: p._id}))}
                            id="positionId"
                            label="Position"
                            placeholder="Select a position"
                          />
                        </div>

                        <div style={styles.field}>
                          <label htmlFor="id" style={styles.label}>
                            {t('Identifier (optional)')}
                          </label>
                          <input
                            id="id"
                            placeholder={t("Enter id")}
                            type="string"
                            value={values.id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={errors.id && touched.id ? styles.inputError : styles.input}
                          />
                          {errors.id && touched.id && (
                            <div style={styles.inputFeedback}>{t(errors.id)}</div>
                          )}
                        </div>
                      </>
                    : null}
                  </div>

                  <div style={styles.buttons}>
                    <div style={styles.mainButtons}>
                      <motion.button
                        type="button"
                        onClick={handleReset}
                        style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                        disabled={!dirty || isSubmitting}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {t('RESET')}
                      </motion.button>
                      <motion.button
                        type="submit"
                        style={Object.assign({}, styles.button, { color: '#000', background: '#00d066' })}
                        disabled={isSubmitting}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {t('SUBMIT')}
                      </motion.button>
                    </div>
                    <div style={styles.gap}></div>
                    {data && data.userId && <div style={styles.secondaryButtons}>
                      <motion.button
                        type="button"
                        onClick={this.handleDelete}
                        style={Object.assign({}, styles.button, { color: '#000', background: '#00d663' })}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {t('DELETE USER')}
                      </motion.button>
                    </div>
                    }
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        )}
      </Translation>
    )
  }
}

FormOrganizationCreateMember.defaultProps = {
  header: 'ADD EVENT'
};

export default FormOrganizationCreateMember;