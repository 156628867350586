import React from 'react';
import moment from 'moment';
import { Translation } from 'react-i18next';

class User extends React.Component {
  getStyles() {
    const { style, data: { onClick, isMobile } } = this.props;

    return {
      wrapper: {
        ...style,
        display: 'flex',
        padding: 10,
        boxSizing: 'border-box',
        cursor: onClick ? 'pointer' : 'default',
        alignItems: 'center'
      },
      avatarWrapper: {
        width: isMobile ? 30 : 50,
        height: isMobile ? 30 : 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
      },
      circle: {
        background: '#00d663',
        borderRadius: '50%',
        width: isMobile ? 30 : 50,
        height: isMobile ? 30 : 50,
        overflow: 'hidden'
      },
      photo: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      },
      nameWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      },
      main: {
        flex: 1,
        fontWeight: 600,
        fontSize: isMobile ? 12 : 16,
        alignItems: 'center',
        display: 'flex'
      },
      description: {
        flex: 1,
        fontWeight: 100,
        fontSize: isMobile ? 10 : 14,
        alignItems: 'center',
        display: 'flex'
      },
      date: {
        flex: 1,
        fontWeight: 100,
        color: '#6f6f6f',
        fontSize: isMobile ? 10 : 14,
        alignItems: 'center',
        display: 'flex',
      },
      box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: isMobile ? 30 : 100
      },
      statusGreen: {
        fontWeight: 600,
        color: '#20ce78',
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 12
      },
      statusRed: {
        fontWeight: 600,
        color: '#00d663',
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 12
      },
      text: {
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 12
      }
    }
  }

  handleClick = () => {
    const { data, index } = this.props;
    const { onClick } = data;
    const current = data.data[index];

    onClick(current);
  }

  render() {
    const { data, index } = this.props;
    const { isMobile, onClick } = data;
    const styles = this.getStyles();
    const current = data.data[index];
    const { username, alias, name, surname, active, photo, role, createdAt, email } = current;
    const activeLabel = active ? 'Active' : 'Inactive';
    const activeLabelTranslated = <Translation>{t => t(activeLabel)}</Translation>;
    const roleLabelTranslated = <Translation>{t => t(role)}</Translation>;
    
    return (
      <div style={styles.wrapper} onClick={onClick ? this.handleClick : null}>
        <div style={styles.avatarWrapper}>
          <div style={styles.circle}>
            {photo && <img style={styles.photo} src={photo} alt={username} />}
          </div>
        </div>
        <div style={styles.nameWrapper}>
          <div style={styles.main}>{name} {surname}</div>
          <div style={styles.description}>{username} ({alias}) - {email}</div>
          <div style={styles.date}>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</div>
        </div>
        {role !== 'user' && <div style={styles.box}>
          <div style={styles.text}>{isMobile ? roleLabelTranslated[0] : roleLabelTranslated}</div>
        </div>
        }
        <div style={styles.box}>
          <div style={active ? styles.statusGreen : styles.statusRed}>{isMobile ? activeLabelTranslated[0] : activeLabelTranslated }</div>
        </div>
      </div>
    );
  }
}

export default User;
