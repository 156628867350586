import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import { baseUrl } from '../../config'; 
import { Translation } from 'react-i18next';

class FormEventType extends Component {
  getStyles = () => {
    const { isMobile } = this.props;

    return {
      wrapper: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        background: '#fff',
      },
      form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      },
      fields: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        overflow: 'auto',
        padding: 20,
      },
      field: {
        marginBottom: 10,
        flexDirection: 'column',
        display: 'flex'
      },
      label: {
        marginBottom: 10,
        paddingLeft: 2
      },
      input: {
        border: '1px solid transparent',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40
      },
      inputError: {
        border: '1px solid #00d663',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40
      },
      inputFeedback: {
        color: '#00d663',
        fontSize: 12,
        marginTop: 5
      },
      header: {
        background: '#00d663',
        color: '#000',
        flex: 'none',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: isMobile ? 16 : 20,
        position: 'relative'
      },
      buttons: {
        flex: 'none',
        height: 50,
        background: '#f9f8f8',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: isMobile ? 5 : 20
      },
      mainButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      gap: {
        flex: 1
      },
      secondaryButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      button: {
        background: '#00d663',
        color: '#fff',
        padding: isMobile ? '5px 15px' : '5px 30px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none'
      },
      close: {
        position: 'absolute',
        right: 10
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      }
    }
  }

  handleSubmit = (values, { setSubmitting }) => {
    const { handleClose, data } = this.props;
    const url = data ? `${baseUrl}/types/${data._id}` : `${baseUrl}/types`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      //setSubmitting(false);
      handleClose({ reload: true });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleDelete = () => {
    const { handleClose, data } = this.props;
    const url = `${baseUrl}/types/${data._id}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      handleClose({ reload: true });
    }).catch((err) => {
      console.log(err);
    });
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required('You must enter a name'),
      desc: Yup.string(),
      bibnumbers: Yup.boolean(),
      order: Yup.number()
    });
  }

  getInitialValues = () => {
    const { data } = this.props;

    if (data) return data;

    return {
      name: '',
      desc: '',
      order: '',
      bibnumbers: false,
    }
  }

  render() {
    const { header, handleClose, data } = this.props;
    const styles = this.getStyles();

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <Formik
              initialValues={this.getInitialValues()}
              onSubmit={this.handleSubmit}
              validationSchema={this.getValidationSchema()}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.header}>
                      {t(header)}
                      {handleClose &&
                        <div style={styles.close}>
                          <motion.button
                            type="button"
                            onClick={handleClose}
                            style={styles.closeButton}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.8 }}
                          >
                            <Icon size={18} icon="close" color="#000" />
                          </motion.button>
                        </div>
                      }
                    </div>

                    <div style={styles.fields}>
                      <div style={styles.field}>
                        <label htmlFor="name" style={styles.label}>
                          {t('Name')}
                        </label>
                        <input
                          id="name"
                          placeholder={t("Enter name")}
                          type="text"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.name && touched.name ? styles.inputError : styles.input}
                        />
                        {errors.name && touched.name && (
                          <div style={styles.inputFeedback}>{t(errors.name)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="desc" style={styles.label}>
                          {t('Description')}
                        </label>
                        <input
                          id="desc"
                          placeholder={t("Enter description")}
                          type="text"
                          value={values.desc}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.desc && touched.desc ? styles.inputError : styles.input}
                        />
                        {errors.desc && touched.desc && (
                          <div style={styles.inputFeedback}>{t(errors.desc)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="desc" style={styles.label}>
                          {t('Use bibnumbers')}
                        </label>
                        <input
                          id="bibnumbers"
                          type="checkbox"
                          checked={values.bibnumbers}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.bibnumbers && touched.bibnumbers ? styles.inputError : styles.input}
                        />
                        {errors.bibnumbers && touched.bibnumbers && (
                          <div style={styles.inputFeedback}>{t(errors.bibnumbers)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="order" style={styles.label}>
                          {t('Order')}
                        </label>
                        <input
                          id="order"
                          placeholder={t("Enter order")}
                          type="number"
                          value={values.order}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.order && touched.order ? styles.inputError : styles.input}
                        />
                        {errors.order && touched.order && (
                          <div style={styles.inputFeedback}>{t(errors.order)}</div>
                        )}
                      </div>
                    </div>

                    <div style={styles.buttons}>
                      <div style={styles.mainButtons}>
                        <motion.button
                          type="button"
                          onClick={handleReset}
                          style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                          disabled={!dirty || isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('RESET')}
                        </motion.button>
                        <motion.button
                          type="submit"
                          style={Object.assign({}, styles.button, { color: '#000', background: '#00d066' })}
                          disabled={isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('SUBMIT')}
                        </motion.button>
                      </div>
                      <div style={styles.gap}></div>
                      {data && <div style={styles.secondaryButtons}>
                        <motion.button
                          type="button"
                          onClick={this.handleDelete}
                          style={Object.assign({}, styles.button, { color: '#000', background: '#00d663' })}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('DELETE TYPE')}
                        </motion.button>
                      </div>
                      }
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}
      </Translation>
    )
  }
}

FormEventType.defaultProps = {
  header: 'ADD EVENT TYPE'
};

export default FormEventType;