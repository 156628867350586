import React, { Fragment } from 'react';
import AsyncSelect from 'react-select/async';
import AppDispatcher from '../../AppDispatcher/AppDispatcher';
import { motion } from 'framer-motion';
import Icon from '../../Icon/Icon';
import CustomUserOption from '../../Options/CustomUserOption';
import _ from 'lodash';
import { FixedSizeList as List } from 'react-window';
import { baseUrl } from '../../../config'; 
import { SizeMe } from 'react-sizeme';
import Slideshow from '../../Slideshow/Slideshow';
import UserAvatar from '../../UserAvatar/UserAvatar';
import EventGallery from '../../EventView/components/EventGallery';
import { Translation } from 'react-i18next';
import memoize from 'memoize-one';

const UserTaggedBox = ({ index, data, style }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const username = userInfo.username;
  const currentData = data[index];

  const row = {
    display: 'flex',
    borderBottom: '1px solid #ededed',
    boxSizing: 'border-box',
    padding: 5,
    alignItems: 'center',
  };

  const roundedButton = {
    padding: 0,
    borderRadius: '50%',
    boxShadow: 'none',
    background: 'rgba(255, 255, 255, .3)',
    cursor: 'pointer',
    outline: 'none',
    width: 45,
    height: 45,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    flex: 'none',
  };

  const handleUntagClick = (e) => {
    e.stopPropagation();
    AppDispatcher.dispatch({
      actionType: 'UNTAG_USER',
      userId: currentData.username
    });
  }

  const showButton = currentData.who === username || currentData.username === username || userInfo.role === "admin";
  return (
    <Fragment>
      <div style={{ ...style, ...row }}>
        <UserAvatar {...currentData} horizontal={true} style={{ flex: 1 }} />
        {
          showButton &&
          <motion.button
            onClick={handleUntagClick}
            style={{ ...roundedButton }}
            whileHover={{ color: '#1fb162' }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        }
      </div>
    </Fragment>
  );
}

const BibnumberBox = ({ index, data, style }) => {
  const username = JSON.parse(localStorage.getItem('userInfo')).username;

  const button = {
    padding: 16,
    fontWeight: 600,
    fontSize: 14,
    boxShadow: 'none',
    border: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    boxSizing: 'border-box',
    borderBottom: '1px solid #ededed',
    position: 'relative',
    background: '#fff',
    flex: 1,
  };

  const row = {
    display: 'flex',
    borderBottom: '1px solid #ededed',
  };

  const roundedButton = {
    padding: 0,
    borderRadius: '50%',
    boxShadow: 'none',
    background: 'rgba(255, 255, 255, .3)',
    cursor: 'pointer',
    outline: 'none',
    width: 45,
    height: 52,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    position: 'absolute',
    right: 0
  };

  const handleBibnumberUntagClick = (e) => {
    e.stopPropagation();
    AppDispatcher.dispatch({
      actionType: 'UNTAG_BIBNUMBER',
      bibnumber: data[index].bibnumber
    });
  }

  return (
    <Fragment>
      <div style={{ ...style, ...row }}>
        <div style={{ ...button }}>
          {data[index].bibnumber}
        </div>
        {
          data[index].who === username &&
          <motion.button
            onClick={handleBibnumberUntagClick}
            style={{ ...roundedButton }}
            whileHover={{ color: '#1fb162' }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        }
      </div>
    </Fragment>
  );
}

const memoizedData = memoize((data) => {
  return data && data.map(item => item._id);
});


class ProfileGallery extends React.Component {
  constructor(props) {
    super(props);
    this._dispatchToken = AppDispatcher.register(this._registerToActions);
    this.state = this.getInitialData();
    this.gridRef = React.createRef();
  }

  getInitialData = () => {
    const { columnCount } = this.getEventSizes();
    const currentPhotos = this.props.data ? _.orderBy(this.props.data, [p => p.likes.length, 'dateTime'], ['desc', 'desc']) : null;
    const currentOrder = currentPhotos ? currentPhotos.map(p => p._id) : null;
    const currentModal = this.props.selected && this.props.selected.selectedImageId && currentPhotos ? currentPhotos.findIndex(p => p._id === this.props.selected.selectedImageId) : null; 
    
    return {
      currentModal: currentModal,
      currentOrder: currentOrder,
      sort: 'likes',
      chunkPictures: currentOrder ? _.chunk(currentOrder, columnCount) : null
    };
  }

  _registerToActions = (action) => {
    switch (action.actionType) {
      case 'LIKE_PICTURE':
        this.handleLike(action.data);
        break;
      case 'REMOVE_PICTURE':
        this.handleRemove(action.data);
        break;
      case 'SHOW_TAG_PICTURE_OVERLAY':
        this.toggleTagging(action.data);
        break;
      case 'SHOW_TAG_BIBNUMBER_PICTURE_OVERLAY':
        this.toggleBibnumberTagging(action.data);
        break;
      case 'SHOW_BIBNUMBERS_PICTURE_OVERLAY':
        this.toggleBibnumbers(action.data);
        break;
      case 'SHOW_USERS_PICTURE_OVERLAY':
        this.toggleUsers(action.data);
        break;
      case 'SHOW_LIKES_PICTURE_OVERLAY':
        this.toggleLikes(action.data);
        break;
      case 'ADD_IMAGES':
        this.toggleNewPicturesModal(action.data);
        break;
      case 'UNTAG_USER':
        this.handleUntag(action.userId)
        break;
      case 'UNTAG_BIBNUMBER':
        this.handleBibnumberUntag(action.bibnumber)
        break;
      case 'IDENTIFY_USER':
        this.toggleIdentify();
        break;
      case 'FILTER_PICTURES':
        this.toggleFiltering();
        break;
      case 'CHANGE_VIEW':
      case 'GO_TO_USER_PROFILE':
        this.toggleModal();
        break;
      case 'SHOW_PICTURE':
        const index = this.getPictureIndex(action.data);
        this.toggleModal(index);
        break;
      default:
        break;
    }
  }

  stopOverlayPropagation = (e) => {
    e.stopPropagation();
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this._dispatchToken);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentModal } = this.state;
    let sortChanged = false;
    let filterChanged = false;
    if (prevState.sort !== this.state.sort) {
      sortChanged = true;
    }
    if (!_.isEqual(prevState.filter, this.state.filter)) {
      filterChanged = true;
    }

    if (sortChanged || filterChanged) {
      //si cambia la ordenación o el filtro, cambiar el array y chunk de ids
      const currentPhotos = this.getCurrentPhotos({ sort: true });
      const { columnCount } = this.getEventSizes();
      const currentOrder = currentPhotos && currentPhotos.map(p => p._id);

      this.setState({
        chunkPictures: _.chunk(currentOrder, columnCount)
      });

      if (!Number.isInteger(currentModal)) {
        this.setState({
          currentOrder: currentPhotos && currentPhotos.map(p => p._id)
        });
      }
    }

    if (!Number.isInteger(currentModal) && prevProps.data && this.props.data && JSON.stringify(memoizedData(prevProps.data)) !== JSON.stringify(memoizedData(this.props.data))) {
      this.setState({
        ...this.getInitialData()
      });

      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.scrollTo({ scrollLeft: 0, scrollTop: 0 });
      } else if (this.eventGalleryRef && this.eventGalleryRef.current) {
        this.eventGalleryRef.current.scrollTop = 0;
      }
    }
  }

  getPictureIndex = (data) => {
    const { currentOrder } = this.state;
    return currentOrder.findIndex(d => d === data._id);
  }

  handleLike = (data) => {
    const url = `${baseUrl}/images/${data._id}/like`;

    fetch(url, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE'
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleRemove = (data) => {
    const url = `${baseUrl}/images/${data._id}`;

    fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.toggleModal();
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE'
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex'
        },
        eventGallery: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          overflowY: 'auto',
        },
        eventPlaceholder: {
          overflow: 'auto',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        },
        eventPlaceholderImage: {
          width: 100
        },
        eventPlaceholderText: {
          textAlign: 'center',
          color: '#00d663',
          fontWeight: 600
        },
        overlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          padding: '40px 20%',
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column'
        },
        overlayTitle: {
          background: '#00d663',
          fontWeight: 600,
          flex: 'none',
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative'
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          boxSizing: 'border-box',
          background: '#fff',
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        },
        searchWrapper: {
          flex: 'none',
          padding: 20,
          display: 'flex',
          flexDirection: 'column'
        },
        searchList: {
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        },
        searchItem: {
          flex: 'none',
          padding: 16,
          boxSizing: 'border-box'
        },
        input: {
          border: 'none',
          fontSize: 16,
          outline: 'none'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none'
        },
        historyWrapper: {
          marginTop: 5
        },
        history: {
          marginTop: 5,
          display: 'flex',
          flexWrap: 'wrap',
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex'
        },
        eventGallery: {
          flex: 1,
          padding: 2
        },
        eventPlaceholder: {
          overflow: 'auto',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        },
        eventPlaceholderImage: {
          width: 100
        },
        eventPlaceholderText: {
          textAlign: 'center',
          color: '#00d663',
          fontWeight: 600
        },
        overlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
        },
        overlayTitle: {
          background: '#00d663',
          fontWeight: 600,
          flex: 'none',
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative'
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flex: 1,
          boxSizing: 'border-box',
          background: '#fff',
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        },
        searchWrapper: {
          flex: 'none',
          padding: 20,
          display: 'flex',
          flexDirection: 'column'
        },
        searchList: {
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        },
        searchItem: {
          flex: 'none',
          padding: 16,
          boxSizing: 'border-box'
        },
        input: {
          border: 'none',
          fontSize: 16,
          outline: 'none'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none'
        },
        historyWrapper: {
          marginTop: 5,
        },
        history: {
          marginTop: 5,
          flex: 'none',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 60px)',
          gridAutoFlow: 'column',
          alignItems: 'center',
          gridGap: 15,
          background: '#fff',
          position: 'relative',
          overflowX: 'auto',
          overflowY: 'hidden',
          boxSizing: 'border-box',
        }
      }
    }
  }

  renderPlaceholderGallery = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.eventPlaceholder}>
        <div style={styles.eventPlaceholderImage}><img src="empty-gallery.svg" alt="Galería vacía" /></div>
        <div style={styles.eventPlaceholderText}>
          <Translation>
            {t => t("You don't have pictures yet")}
          </Translation>
        </div>
      </div>
    );
  }

  filterPhotos = (photos, filter) => {
    if (!filter) return photos;

    let filteredPhotos = photos.slice();

    if (filter.filterPhotographer) {
      filteredPhotos = filteredPhotos.filter(p => p.userId === filter.filterPhotographer.value);
    }

    if (filter.filterParticipant) {
      filteredPhotos = filteredPhotos.filter(p => p.users && p.users.some(u => u.username === filter.filterParticipant.value));
    }

    if (filter.filterBibnumber) {
      filteredPhotos = filteredPhotos.filter(p => p.bibnumbers && p.bibnumbers.some(u => u.bibnumber === filter.filterBibnumber));
    }

    return filteredPhotos;
  }

  getCurrentPhotos = ({sort = true} = {}) => {
    const { data } = this.props;
    
    if (sort) {
      return this.sortPhotos(data);
    }

    return data;
  }

  sortPhotos = (photos) => {
    const { sort } = this.state;

    switch(sort) {
      case 'trending': return _.orderBy(photos, ['score'], ['desc']);
      case 'likes': return _.orderBy(photos, [p => p.likes.length, 'dateTime'], ['desc', 'desc']);
      case 'comments': return _.orderBy(photos, [p => p.comments.length, 'dateTime'], ['desc', 'desc']);
      case 'tags': return _.orderBy(photos, [p => p.users.length, 'dateTime'], ['desc', 'desc']);
      case 'visits': return _.orderBy(photos, [p => p.visits, 'dateTime'], ['desc','desc']);
      case 'date': return _.orderBy(photos, ['dateTime'], ['desc']);
      case 'dateAsc': 
      default: 
        return _.orderBy(photos, ['dateTime'], ['asc']);
    }
  }

  toggleModal = (index) => {
    const { currentModal } = this.state;

    this.setState({
      currentModal: !Number.isInteger(currentModal) ? index : null,
      currentTagging: null,
      currentLikes: null,
      currentUsers: null,
      currentBibnumbers: null,
      currentBibnumberTagging: null
    });
  }

  selectModalPicture = (index) => {
    this.setState({
      currentModal: index
    });
  }

  toggleTagging = (data) => {
    const { currentTagging } = this.state;

    if (!currentTagging) {
      this.getUsersHistory();
    }

    this.setState({
      currentTagging: !currentTagging ? data : null
    });
  }

  toggleBibnumberTagging = (data) => {
    const { currentBibnumberTagging } = this.state;

    this.setState({
      currentBibnumberTagging: !currentBibnumberTagging ? data : null
    });
  }

  toggleUsers = (data) => {
    const { currentUsers } = this.state;

    this.setState({
      currentUsers: !currentUsers ? data : null
    });
  }

  toggleBibnumbers = (data) => {
    const { currentBibnumbers } = this.state;

    this.setState({
      currentBibnumbers: !currentBibnumbers ? data : null
    });
  }

  toggleLikes = (data) => {
    const { currentLikes } = this.state;

    this.setState({
      currentLikes: !currentLikes ? data : null
    });
  }

  getUsersHistory = () => {
    fetch(`${baseUrl}/users/tag/history?limit=10`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      if (data) {
        this.setState({
          history: data
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  parseUsers = (users) => {
    return users.map((user) => {
      return { value: user.username, label: `${user.name} ${user.surname} (${user.username})`, thumb: user.thumb }
    });
  }

  loadOptions = _.debounce((inputValue, callback) => {
    const body = {
      filter: inputValue
    };

    fetch(`${baseUrl}/users/find`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      callback(this.parseUsers(data));
    }).catch((err) => {
      console.log(err);
    });
  }, 200);

  renderTagOverlay = () => {
    const { isMobile, data } = this.props;
    const { history, currentTagging } = this.state;
    const current = data.find(d => d._id === currentTagging._id);
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.overlayWrapper} onClick={this.toggleTagging}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t("Tag users")}
          </Translation>
          <motion.button
            onClick={this.toggleTagging}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={{ ...styles.overlay, flex: 'none' }} onClick={this.stopOverlayPropagation}>
          <div style={styles.searchWrapper}>
            <AsyncSelect
              value={null}
              cacheOptions
              loadOptions={this.loadOptions}
              onInputChange={this.handleInputChange}
              onChange={this.handleTag}
              placeholder="Search users to tag..."
              components={{ Option: this.customOption }}
              styles={{
                control: (styles, state) => ({
                  ...styles,
                  boxShadow: state.isFocused ? "0 0 0 0.1rem #00d663" : 0,
                  borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA",
                  "&:hover": {
                    borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA"
                  }
                })
              }}
            />
            {current && current.users && current.users.length > 0 &&
              <div style={styles.historyWrapper}>
                <Translation>
                  {t => t("players on this photo")}
                </Translation>
                <div style={styles.history}>
                  {this.renderTaggedAvatars(current.users)}
                </div>
              </div>
            }
            {history && this.renderHistoryAvatars(history)}
          </div>
        </div>
      </div>
    );
  }

  renderTaggedAvatars = (data) => {
    return data.map(user => <UserAvatar key={user.username} size={60} style={{ margin: 5 }} {...user} detailed={true} />);
  }

  renderHistoryAvatars = (users) => {
    const { isMobile, data } = this.props;
    const { currentTagging } = this.state;
    const current = data.find(d => d._id === currentTagging._id);
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const groupedTags = (current && _.keyBy(current.users, 'username')) || {};
    const filteredUsers = users.filter(user => !groupedTags[user.username]);

    if (!filteredUsers || filteredUsers.length === 0) return null;

    return (
      <div style={styles.historyWrapper}>
        <Translation>
          {t => t("last tagged by you")}
        </Translation>
        <div style={styles.history}>
          {filteredUsers.map(user => <UserAvatar key={user.username} size={40} style={{ margin: 5 }} onClick={() => { this.handleTag({ value: user.username }) }} {...user} detailed={true} />)}
        </div>
      </div>
    );
  }

  handleBibnumberTagChange = _.debounce((currentBibnumberTag) => {
    this.setState({
      currentBibnumberTag
    });
  }, 200)

  renderBibnumberTagOverlay = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.overlayWrapper} onClick={this.toggleBibnumberTagging}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t("Tag bibnumbers")}
          </Translation>
          <motion.button
            onClick={this.toggleBibnumberTagging}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={{ ...styles.overlay, padding: 10, flex: 'none' }} onClick={this.stopOverlayPropagation}>
          <input style={styles.input} type="text" placeholder="Tag a competition number..." onChange={(e) => this.handleBibnumberTagChange(e.currentTarget.value)} />
          <motion.button
            onClick={this.handleBibnumberTag}
            style={{ ...styles.button, marginTop: 10 }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            <Translation>
              {t => t("Tag")}
            </Translation>
          </motion.button>
        </div>
      </div>
    );
  }

  renderUsersOverlay = (picture) => {
    const { isMobile, data } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const currentPicture = picture && data.find(d => d._id === picture._id);

    return (
      <div style={styles.overlayWrapper} onClick={this.toggleUsers}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t("Tagged users")}
          </Translation>
          <motion.button
            onClick={this.toggleUsers}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={styles.overlay} onClick={this.stopOverlayPropagation}>
          <SizeMe monitorWidth monitorHeight>
            {({ size }) => (
              <List
                height={size.height}
                itemCount={currentPicture.users && currentPicture.users.length}
                itemSize={55}
                width={size.width}
                itemData={currentPicture.users}
              >
                {UserTaggedBox}
              </List>
            )}
          </SizeMe>
        </div>
      </div>
    );
  }

  renderBibnumbersOverlay = (picture) => {
    const { isMobile, data } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const currentPicture = picture && data.find(d => d._id === picture._id);

    return (
      <div style={styles.overlayWrapper} onClick={this.toggleBibnumbers}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t("Bibnumbers")}
          </Translation>
          <motion.button
            onClick={this.toggleBibnumbers}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={styles.overlay} onClick={this.stopOverlayPropagation}>
          <SizeMe monitorWidth monitorHeight>
            {({ size }) => (
              <List
                height={size.height}
                itemCount={currentPicture.bibnumbers && currentPicture.bibnumbers.length}
                itemSize={55}
                width={size.width}
                itemData={currentPicture.bibnumbers}
              >
                {BibnumberBox}
              </List>
            )}
          </SizeMe>
        </div>
      </div>
    );
  }

  renderLikesOverlay = (picture) => {
    const { isMobile, data } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const currentPicture = picture && data.find(d => d._id === picture._id);

    return (
      <div style={styles.overlayWrapper} onClick={this.toggleLikes}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t("Likes")}
          </Translation>
          <motion.button
            onClick={this.toggleLikes}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={styles.overlay} onClick={this.stopOverlayPropagation}>
          <SizeMe monitorWidth monitorHeight>
            {({ size }) => (
              <List
                height={size.height}
                itemCount={currentPicture.likes && currentPicture.likes.length}
                itemSize={55}
                width={size.width}
                itemData={currentPicture.likes}
              >
                {UserTaggedBox}
              </List>
            )}
          </SizeMe>
        </div>
      </div>
    );
  }

  handleBibnumberTag = () => {
    const { currentBibnumberTag, currentBibnumberTagging } = this.state;

    const body = { bibnumber: currentBibnumberTag };

    fetch(`${baseUrl}/images/${currentBibnumberTagging._id}/bibnumber/tag`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      this.toggleBibnumberTagging();
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE',
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleBibnumberUntag = (bibnumber) => {
    const { currentBibnumbers } = this.state;

    if (bibnumber) {
      const body = {
        bibnumber: bibnumber
      };

      fetch(`${baseUrl}/images/${currentBibnumbers._id}/bibnumber/untag`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((data) => {
        AppDispatcher.dispatch({
          actionType: 'UPDATE_EVENT_PICTURE'
        });
        if (data) {
          this.setState({
            currentBibnumbers: data.bibnumbers && data.bibnumbers.length > 0 ? data : null
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  handleTag = (selected) => {
    const { currentTagging } = this.state;

    if (selected) {
      const body = {
        userId: selected.value
      };

      fetch(`${baseUrl}/images/${currentTagging._id}/tag`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((data) => {
        AppDispatcher.dispatch({
          actionType: 'UPDATE_EVENT_PICTURE'
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  handleUntag = (userId) => {
    const { userInfo: { username } } = this.props;
    const { currentUsers } = this.state;

    if (userId) {
      const body = {
        userId: userId
      };

      fetch(`${baseUrl}/images/${currentUsers._id}/untag`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((data) => {
        if (userId === username) {
          this.toggleModal();
        }
        AppDispatcher.dispatch({
          actionType: 'UPDATE_EVENT_PICTURE'
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');

    this.setState({ inputValue });

    return inputValue;
  }

  customOption = (props) => {
    return (
      <CustomUserOption {...props} />
    );
  }

  getGalleryItemData = ({ rowIndex, columnIndex}) => {
    const { data } = this.props;
    const { chunkPictures } = this.state;
    const itemId = chunkPictures && chunkPictures[rowIndex] ? chunkPictures[rowIndex][columnIndex] : null;
    const item = data.find(d => d._id === itemId);

    if (!item) return null;
    
    return {
      ...item
    }
  }

  getEventSizes = () => {
    const { isMobile, isTablet } = this.props;

    if (isTablet) {
      return {
        columnCount: 2
      }
    }

    if (isMobile) {
      return {
        columnCount: 1
      }
    }

    return {
      columnCount: 3
    }
  }

  handleView = ({ _id, hasView }) => {
    fetch(`${baseUrl}/images/${_id}/view`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE',
        data
      });
      if (!hasView) {
        AppDispatcher.dispatch({
          actionType: 'DECREMENT_EVENT_UNVIEWS',
          data
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  renderSlideshow = () => {
    const { isMobile } = this.props;
    const { currentModal, currentOrder } = this.state;
    const currentPhotos = this.getCurrentPhotos({ sort: false });

    if (Number.isInteger(currentModal)) {
      const currentId = currentOrder && currentOrder[currentModal];
      const data = currentPhotos && currentPhotos.find(p => p._id === currentId);
      const currentData = {
        ...data
      }
      return (
        <Slideshow
          data={currentData}
          handleClose={this.toggleModal}
          handleNext={this.selectModalPicture}
          handlePrevious={this.selectModalPicture}
          handleView={this.handleView}
          isMobile={isMobile}
          index={currentModal}
          min={0}
          max={currentPhotos && currentPhotos.length-1}
        />
      );
    }

    return null;
  }

  renderGallery = () => {
    const { chunkPictures } = this.state;
    const { isMobile, data } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const { columnCount } = this.getEventSizes();

    if (!chunkPictures || chunkPictures.length === 0) return this.renderPlaceholderGallery();

    return (
      <div style={styles.eventGallery}>
        <EventGallery
          ref={this.gridRef}
          columnCount={columnCount}
          data={isMobile ? data : chunkPictures}
          getGalleryItemData={this.getGalleryItemData}
          handleView={this.handleView}
          isMobile={isMobile}
        />
      </div>
    );
  }

  handleUploadPicture = () => {
    AppDispatcher.dispatch({
      actionType: 'UPLOAD_PICTURE',
      origin: 'portfolio'
    });
  }

  renderUploadButton = () => {
    const { userInfo, isExternal, isMobile, portfolio } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (portfolio && userInfo.role === 'photographer' && !isExternal) {
      return (
        <motion.button
          onClick={this.handleUploadPicture}
          style={{ ...styles.button, position: 'absolute', bottom: 10, right: 10 }}
        >
          <Icon size={isMobile ? 18 : 24} icon="cameraAdd" color="#000" viewBox="16" />
        </motion.button>
      );
    }
  }

  render() {
    const { currentTagging, currentUsers, currentLikes, currentBibnumberTagging, currentBibnumbers } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        {currentTagging && this.renderTagOverlay(currentTagging)}
        {currentBibnumberTagging && this.renderBibnumberTagOverlay(currentBibnumberTagging)}
        {currentUsers && this.renderUsersOverlay(currentUsers)}
        {currentBibnumbers && this.renderBibnumbersOverlay(currentBibnumbers)}
        {currentLikes && this.renderLikesOverlay(currentLikes)}
        {this.renderGallery()}
        {this.renderSlideshow()}
        {this.renderUploadButton()}
      </div>
    );
  }
}

export default ProfileGallery;
