function getFilterQuery({ filter, include = ['federationId', 'seasonId', 'categoryId'] }) {
  const { federationId, seasonId, categoryId } = filter;

  const query = { federationId, seasonId, categoryId };

  const queryString = include.map(key => {
    const parsedKey = key === 'categoryId' ? 'type' : key;
    return query[key] && query[key] !== 'all' && query[key] !== 'null' ? parsedKey + '=' + query[key] : null;
  }).join('&');

  return queryString;
}

export { getFilterQuery };