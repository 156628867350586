import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import { baseUrl } from '../../config'; 
import handleResponse from '../../utils/handleResponse';
import { Translation } from 'react-i18next';

class FormOrganization extends Component {
  getStyles = () => {
    const { isMobile } = this.props;

    return {
      wrapper: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        background: '#fff',
      },
      form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      },
      fields: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        overflow: 'auto',
        padding: 20,
      },
      field: {
        marginBottom: 10,
        flexDirection: 'column',
        display: 'flex'
      },
      label: {
        marginBottom: 10,
        paddingLeft: 2
      },
      input: {
        border: '1px solid transparent',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40,
        width: '100%'
      },
      inputError: {
        border: '1px solid #00d663',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40,
        width: '100%'
      },
      inputFeedback: {
        color: '#00d663',
        fontSize: 12,
        marginTop: 5
      },
      header: {
        background: '#00d663',
        color: '#000',
        flex: 'none',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: isMobile ? 16 : 20,
        position: 'relative'
      },
      buttons: {
        flex: 'none',
        height: 50,
        background: '#f9f8f8',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: isMobile ? 5 : 20
      },
      mainButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      gap: {
        flex: 1
      },
      secondaryButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      button: {
        background: '#00d663',
        color: '#fff',
        padding: isMobile ? '5px 15px' : '5px 30px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none'
      },
      close: {
        position: 'absolute',
        right: 10
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      }
    }
  }

  handleSubmit = (values, actions) => {
    const { handleClose, data } = this.props;
    const url = data && data._id ? `${baseUrl}/organizers/${data._id}` : `${baseUrl}/organizers`;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => {
      return handleResponse(response);
    }).then(([ok, status, data]) => {
      if (!ok) {
        throw new Error(data || 'Oops! Something went wrong');
      }

      handleClose({ reload: true });
    }).catch((error) => {
      actions.setSubmitting(false);
      actions.setFieldError('name', error.message);
    });
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required('You must enter a name'),
    });
  }

  getInitialValues = () => {
    const { data } = this.props;

    return {
      name: '',
      instagram: '',
      twitter: '',
      facebook: '',
      email: '',
      web: '',
      visible: '',
      club: true,
      ...data
    }
  }

  render() {
    const { header, handleClose } = this.props;
    const styles = this.getStyles();

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <Formik
              initialValues={this.getInitialValues()}
              onSubmit={this.handleSubmit}
              validationSchema={this.getValidationSchema()}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  handleReset,
                } = props;
                return (
                  <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.header}>
                      {t(header)}
                      {handleClose &&
                        <div style={styles.close}>
                          <motion.button
                            type="button"
                            onClick={handleClose}
                            style={styles.closeButton}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.8 }}
                          >
                            <Icon size={18} icon="close" color="#000" />
                          </motion.button>
                        </div>
                      }
                    </div>

                    <div style={styles.fields}>
                      <div style={styles.field}>
                        <label htmlFor="name" style={styles.label}>
                          {t('Name')}
                        </label>
                        <input
                          id="name"
                          placeholder={t("Enter name")}
                          type="text"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.name && touched.name ? styles.inputError : styles.input}
                        />
                        {errors.name && touched.name && (
                          <div style={styles.inputFeedback}>{t(errors.name)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="email" style={styles.label}>
                          {t('Email')}
                        </label>
                        <input
                          id="email"
                          placeholder={t("email@example.com")}
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.email && touched.email ? styles.inputError : styles.input}
                        />
                        {errors.email && touched.email && (
                          <div style={styles.inputFeedback}>{t(errors.email)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="web" style={styles.label}>
                          {t('Website')}
                        </label>
                        <input
                          id="web"
                          placeholder="https://web.com"
                          type="text"
                          value={values.web}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.web && touched.web ? styles.inputError : styles.input}
                        />
                        {errors.web && touched.web && (
                          <div style={styles.inputFeedback}>{t(errors.web)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="instagram" style={styles.label}>
                          Instagram
                        </label>
                        <input
                          id="instagram"
                          placeholder={t("Enter instagram username")}
                          type="text"
                          value={values.instagram}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.instagram && touched.instagram ? styles.inputError : styles.input}
                        />
                        {errors.instagram && touched.instagram && (
                          <div style={styles.inputFeedback}>{t(errors.instagram)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="twitter" style={styles.label}>
                          Twitter
                        </label>
                        <input
                          id="twitter"
                          placeholder={t("Enter twitter username")}
                          type="text"
                          value={values.twitter}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.twitter && touched.twitter ? styles.inputError : styles.input}
                        />
                        {errors.twitter && touched.twitter && (
                          <div style={styles.inputFeedback}>{t(errors.twitter)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="facebook" style={styles.label}>
                          Facebook
                        </label>
                        <input
                          id="facebook"
                          placeholder={t("Enter facebook username")}
                          type="text"
                          value={values.facebook}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.facebook && touched.facebook ? styles.inputError : styles.input}
                        />
                        {errors.facebook && touched.facebook && (
                          <div style={styles.inputFeedback}>{t(errors.facebook)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="club" style={styles.label}>
                          {t('Club')}
                        </label>
                        <input
                          id="club"
                          type="checkbox"
                          checked={values.club}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.club && touched.club ? styles.inputError : styles.checkbox}
                        />
                        {errors.club && touched.club && (
                          <div style={styles.inputFeedback}>{t(errors.club)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="visible" style={styles.label}>
                          {t('Visible')}
                        </label>
                        <input
                          id="visible"
                          type="checkbox"
                          checked={values.visible}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.visible && touched.visible ? styles.inputError : styles.checkbox}
                        />
                        {errors.visible && touched.visible && (
                          <div style={styles.inputFeedback}>{t(errors.visible)}</div>
                        )}
                      </div>
                    </div>

                    <div style={styles.buttons}>
                      <div style={styles.mainButtons}>
                        <motion.button
                          type="button"
                          onClick={handleReset}
                          style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                          disabled={!dirty || isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('RESET')}
                        </motion.button>
                        <motion.button
                          type="submit"
                          style={Object.assign({}, styles.button, { color: '#000', background: '#00d066' })}
                          disabled={isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('SUBMIT')}
                        </motion.button>
                      </div>
                      <div style={styles.gap}></div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}
      </Translation>
    )
  }
}

export default FormOrganization;