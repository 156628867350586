import React from 'react';
import Icon from '../../Icon/Icon';
import UserAvatar from '../../UserAvatar/UserAvatar';

const TagOrganizer = ({data, isMobile, onClick}) => {
  const { photo, members, name } = data;
  
  const getStyles = () => {
    return {
      desktop: {
        wrapper: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: 10
        },
        header: {
          display: 'flex',
          flex: 'none',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: 14,
        },
        pictureWrapper: {
          width: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        emptyPicture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          border: '2px solid #000',
          width: 50,
          height: 50,
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        photo: {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        },
        picture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          width: 50,
          height: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        members: {
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: 10
        }
      },
      mobile: {
        wrapper: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: 10
        },
        header: {
          display: 'flex',
          flex: 'none',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: 14,
        },
        pictureWrapper: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        emptyPicture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          border: '6px solid #000',
          width: 50,
          height: 50,
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        photo: {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        },
        picture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          width: 50,
          height: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        members: {
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: 10,
          height: 150,
          overflow: 'auto'
        }
      }
    }
  }

  const styles = isMobile ? getStyles().mobile : getStyles().desktop;

  const renderPicture = () => {
    return (
      <div style={styles.pictureWrapper}>
        {photo ?
          <div style={styles.picture}>
            <img style={styles.photo} src={photo} alt="name" />
          </div>
          :
          <div style={styles.emptyPicture}>
            <Icon icon="user" color="#818c91" size={250} />
          </div>
        }
      </div>
    );
  }
  const renderMembers = () => {
    return members.map(user => <UserAvatar key={user.username} detailed={true} {...user} bibnumber={user.id} style={{margin: 5}} onClick={() => { onClick({value: user.username}) }} />);
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.header}>
        {renderPicture()}
        {name}
      </div>
      <div style={styles.members}>
        {renderMembers()}
      </div>
    </div>
  )
}

export default TagOrganizer
