import React from 'react';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { Tooltip } from 'react-tippy';

const UserAvatar = (props) => {
  const { name, surname, alias, username, thumb, size = 40, width, style, usernameStyle, nameStyle, onClick, detailed, horizontal, count, bibnumber, disabled } = props;
  const styles = {
    detailedWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      width: (width || size) + 20,
      ...(detailed && style),
    },
    horizontalWrapper: {
      ...(horizontal && style),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    wrapper: {
      flex: 'none',
      width: size,
      height: size,
      borderRadius: '50%',
      background: '#00d663',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontWeight: 600,
      color: '#000',
      padding: 2,
      boxSizing: 'border-box',
      cursor: 'pointer',
      position: 'relative',
      ...(!detailed && !horizontal && style),
    },
    picture: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '50%'
    },
    textWrapper: {
      marginTop: 5,
      display: 'flex',
      flexDirection: 'column',
      textAlign: !horizontal && 'center',
      width: !horizontal && '100%',
      overflow: 'hidden',
    },
    username: {
      fontWeight: 600,
      fontSize: 10,
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      ...usernameStyle,
    },
    name: {
      fontSize: 8,
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      ...nameStyle,
    },
    bubble: {
      background: '#f44336',
      fontSize: 12,
      fontWeight: 600,
      position: 'absolute',
      top: -5,
      right: -5,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 20,
      height: 20,
      boxSizing: 'border-box',
      padding: '0 4px',
      borderRadius: 25,
    },
    bibnumber: {
      color: '#000',
      background: 'linear-gradient(115deg, #ffec07, #ffb206)',
      borderRadius: 4,
      padding: '0px 5px',
      fontSize: 10,
      fontWeight: 600,
      marginTop: 2,
      visibility: bibnumber ? 'visible' : 'hidden'
    }
  };

  const handleClick = () => {
    if (disabled) return null;
    
    if (onClick) {
      onClick(props);
    } else {
      const localUsername = JSON.parse(localStorage.getItem('userInfo')).username;
      if (username === localUsername) {
        AppDispatcher.dispatch({
          actionType: 'CHANGE_VIEW',
          view: 'profile'
        });
      } else {
        AppDispatcher.dispatch({
          actionType: 'GO_TO_USER_PROFILE',
          userId: username
        });
      }
    }
  }

  if (detailed) {
    if (!thumb) {
      const initials = name && surname ? `${name[0]}${surname[0]}` : username[0];
      return (
        <div style={styles.detailedWrapper} onClick={handleClick}>
          <div style={styles.wrapper}>
            {initials}
            {count && count > 0 ? <div style={styles.bubble}>{count}</div> : null}
          </div>
          <div style={styles.textWrapper}>
            <div style={styles.username}>{alias || username}</div>
            <div style={styles.name}>{name} {surname}</div>
          </div>
          {<div style={styles.bibnumber}>{bibnumber || '-'}</div>}
        </div>
      );
    }

    return (
      <div style={styles.detailedWrapper} onClick={handleClick}>
        <div style={styles.wrapper}>
          <img src={thumb} alt="username" style={styles.picture} />
          {count && count > 0 ? <div style={styles.bubble}>{count}</div> : null}
        </div>
        <div style={styles.textWrapper}>
          <div style={styles.username}>{alias || username}</div>
          <div style={styles.name}>{name} {surname}</div>
        </div>
        {<div style={styles.bibnumber}>{bibnumber || '-'}</div>}
      </div>
    );
  }

  if (horizontal) {
    if (!thumb) {
      const initials = name && surname ? `${name[0]}${surname[0]}` : username[0];
      return (
        <div style={styles.horizontalWrapper} onClick={handleClick}>
          <div style={styles.wrapper}>
            {initials}
          </div>
          <div style={{...styles.textWrapper, marginTop: 0, marginLeft: 5}}>
            <div style={styles.username}>{alias || username}</div>
            {name && <div style={styles.name}>{name} {surname}</div>}
          </div>
        </div>
      );
    }

    return (
      <div style={styles.horizontalWrapper} onClick={handleClick}>
        <div style={styles.wrapper}>
          <img src={thumb} alt="username" style={styles.picture} />
        </div>
        <div style={{...styles.textWrapper, marginTop: 0, marginLeft: 5}}>
          <div style={styles.username}>{alias || username}</div>
          {name && <div style={styles.name}>{name} {surname}</div>}
        </div>
      </div>
    );
  }

  const title = name && surname ? `${name} ${surname} (${username})` : alias;
  if (!thumb) {
    const initials = name && surname ? `${name[0]}${surname[0]}` : username[0];
    return (
      <Tooltip
        title={title}
        position={'bottom'}
        trigger="mouseenter"
        theme="light"
        distance={15}
      >
        <div style={styles.wrapper} onClick={handleClick}>
          {initials}
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={title}
      position={'bottom'}
      trigger="mouseenter"
      theme="light"
      distance={15}
    >
      <div style={styles.wrapper} onClick={handleClick}>
        <img src={thumb} alt="username" style={styles.picture} />
      </div>
    </Tooltip>
  );
}

export default UserAvatar
