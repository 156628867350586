import React from 'react';

class Button extends React.Component {
  constructor() {
    super();
    this.clickTime = 0;
    this.state = {
      isOver: false
    };
  }

  getStyles = () => {
    const { isOver } = this.state;
    const { background, labelColor, raised, style } = this.props;

    return {
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: "pointer",
        background: isOver ? this.colorLuminance(background || '#000000', -0.2) : background,
        borderRadius: 4,
        padding: '5px 10px',
        textTransform: 'uppercase',
        fontSize: 12,
        color: isOver && !background ? '#FFF' : labelColor,
        boxShadow: raised ? '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)' : null,
        ...style
      }
    }
  }

  colorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#', c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ('00'+c).substr(c.length);
    }

    return rgb;
  }

  onClick = (e) => {
    const { onClick, action } = this.props;
    if (onClick) {
      if ((new Date() - this.clickTime) < 500){
        //do nothing or do a double click
      } else {
        this.clickTime = new Date();
        onClick(action || e);
      }
    }
  }

  handleMouseEnter = (e) => {
    e.preventDefault();
    this.setState({
      isOver: true
    });
  }

  handleMouseOut = (e) => {
    e.preventDefault();
    this.setState({
      isOver: false
    });
  }

  render() {
    const { description, disabled, children } = this.props;
    const styles = this.getStyles();

    return (
      <div style={styles.wrapper} onClick={disabled ? null : this.onClick} title={description} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseOut}>
        {children}
      </div>
    );
  }
}

Button.defaultProps = {
  size: 25,
  labelColor: '#000'
}

export default Button;
