import React, { Fragment, useState, useRef } from 'react';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import Icon from '../Icon/Icon';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tippy';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
} from '@material/react-menu';
import { useOnClickOutside } from '../../utils/hooks';
import { Translation } from 'react-i18next';

const ContextBar = (props) => {
  const { logged, isMobile, view, isFilteringEvent, event, userInfo, sortActions } = props;
  const hasBibnumbers = event && event.type && event.type.bibnumbers;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className="mdc-menu-desktop"
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleItemClick(sortActions[index])}
          >
            <MenuList>
              <div ref={ref}>
                {sortActions.map(option => (
                  <MenuListItem key={option.value}>
                    <MenuListItemGraphic graphic={<Icon size={18} icon={option.icon} color={option.label === 'Remove' && '#f44336'} wrapperStyle={{marginRight: 5}}/>} />
                    <MenuListItemText primaryText={t(option.label)} className={option.label === 'Remove' && 'mdc-text-red'}/>
                  </MenuListItem>
                ))}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };

  const handleShowMenu = event => {
    setMenuOpen(true);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleItemClick = option => {
    AppDispatcher.dispatch({
      actionType: option.value
    });
  };

  const getStyles = () => {
    return {
      desktop: {
        wrapper: {
          width: 85,
          flexDirection: 'column',
          background: 'rgba(255,255,255,.7)',
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: '15px 0'
        },
        button: {
          padding: '5px 20px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: '50%',
          width: 45,
          height: 45,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        gap: {
          flex: 1
        }
      },
      mobile: {
        wrapper: {
          height: 60,
          flex: 'none',
          background: 'rgba(255,255,255,.7)',
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: '0 16px'
        },
        button: {
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: '50%',
          width: 35,
          height: 35,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        gap: {
          flex: 1
        }
      }
    }
  }

  const styles = isMobile ? getStyles().mobile : getStyles().desktop;

  const renderContextActions = () => {
    if (isFilteringEvent) {
      return renderFilterActions();
    }

    switch (view) {
      case 'event':
        return renderEventActions();
      default:
        return null;
    }
  }

  const handleUploadPicture = () => {
    AppDispatcher.dispatch({
      actionType: 'UPLOAD_PICTURE',
      origin: 'event'
    });
  }

  const handleUploadZip = () => {
    AppDispatcher.dispatch({
      actionType: 'UPLOAD_ZIP',
      origin: 'event'
    });
  }

  const handleIdentify = () => {
    AppDispatcher.dispatch({
      actionType: 'IDENTIFY_USER'
    });
  }

  const handleFilter = () => {
    AppDispatcher.dispatch({
      actionType: 'FILTER_PICTURES'
    });
  }

  const handleDownload = () => {
    AppDispatcher.dispatch({
      actionType: 'DOWNLOAD_PICTURES'
    });
  }

  const handleDownloadSelected = () => {
    AppDispatcher.dispatch({
      actionType: 'DOWNLOAD_SELECTED_PICTURES'
    });
  }
  
  const handleRemoveSelected = () => {
    AppDispatcher.dispatch({
      actionType: 'REMOVE_SELECTED_PICTURES'
    });
  }

  const handleSelectMode = () => {
    AppDispatcher.dispatch({
      actionType: 'HANDLE_SELECT_MODE'
    });
  }

  const renderEventActions = () => {
    return (
      <Translation>
        {t => (
          <Fragment>
            <Tooltip
              title={t("Download gallery")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleDownload}
                style={{ ...styles.button, marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="download" color="#000" />
              </motion.button>
            </Tooltip>
            <Tooltip
              title={t("Sort")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleShowMenu}
                style={{ ...styles.button, marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="sort" color="#000" />
              </motion.button>
            </Tooltip>
            <Tooltip
              title={t("Filter")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleFilter}
                style={{ ...styles.button, marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="filter" color="#000" />
              </motion.button>
            </Tooltip>
            {hasBibnumbers && 
              <Tooltip
                title={t("Identify your bibnumber on this event")}
                position={isMobile ? 'top' : 'left'}
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <motion.button
                  onClick={handleIdentify}
                  style={{ ...styles.button, marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Icon size={isMobile ? 18 : 24} icon="idCard" color="#000" />
                </motion.button>
              </Tooltip>
            }
            {renderUploadButton()}
            {renderUploadZipButton()}
          </Fragment>
        )}
      </Translation>
    );
  }

  const renderUploadButton = () => {
    if ((event.photographers.length === 0 && event.organizers.length === 0) || (event.photographers && event.photographers.length > 0 && event.photographers.some(photographer => photographer.username === userInfo.username))) {
      return (
        <Translation>
          {t => (
            <Tooltip
              title={t("Upload pictures")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleUploadPicture}
                style={{ ...styles.button, marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="cameraAdd" color="#000" viewBox="16" />
              </motion.button>
            </Tooltip>
          )}
        </Translation>
      );
    }
  }

  const renderUploadZipButton = () => {
    if (event.photographers && event.photographers.length > 0 && event.photographers.some(photographer => photographer.username === userInfo.username)) {
      //event has photographers and you are one of them!

      return (
        <Translation>
          {t => (
            <Tooltip
              title={t("Upload zip of pictures")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleUploadZip}
                style={{ ...styles.button, marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="zipAdd" color="#000" />
              </motion.button>
            </Tooltip>
          )}
        </Translation>
      );
    }
  }

  const renderFilterActions = () => {
    return (
      <Translation>
        {t => (
          <Fragment>
            <Tooltip
              title={t("Filter")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleFilter}
                style={{ ...styles.button, background: '#00d663', marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="filter" color="#000" />
              </motion.button>
            </Tooltip>
            <Tooltip
              title={t("Download selected")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleDownloadSelected}
                style={{ ...styles.button, background: '#00d663', marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="download" color="#000" />
              </motion.button>
            </Tooltip>
            <Tooltip
              title={t("Remove selected")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleRemoveSelected}
                style={{ ...styles.button, background: '#f44336', marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="trash" color="#000" />
              </motion.button>
            </Tooltip>
            <Tooltip
              title={t("Select pictures")}
              position={isMobile ? 'top' : 'left'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleSelectMode}
                style={{ ...styles.button, background: '#00d663', marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 10 : 0 }}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={isMobile ? 18 : 24} icon="select" color="#000" />
              </motion.button>
            </Tooltip>
          </Fragment>
        )}
      </Translation>
    );
  }


  if (!logged || view !== 'event') return null;

  return (
    <div style={styles.wrapper}>
      {isMenuOpen && renderMenu()}
      <div style={styles.gap}></div>
      {logged && renderContextActions()}
    </div>
  );
}

ContextBar.defaultProps = {
  sortActions: [
    { label: 'Trending', value: 'SORT_TRENDING', icon: 'fire' },
    { label: 'New first', value: 'SORT_NEWS', icon: 'new' },
    { label: 'Most tagged', value: 'SORT_TAGS', icon: 'userEmpty' },
    { label: 'Most commented', value: 'SORT_COMMENTS', icon: 'chatEmpty' },
    { label: 'Most liked', value: 'SORT_LIKES', icon: 'heartEmpty' },
    { label: 'Most visited', value: 'SORT_VISITS', icon: 'eyeEmpty' },
    { label: 'Descending', value: 'SORT_DATE', icon: 'clockDownload' },
    { label: 'Ascending', value: 'SORT_DATE_ASC', icon: 'clockUpload' },
  ]
};

export default ContextBar;
