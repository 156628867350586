import React from 'react';
import Icon from '../../Icon/Icon';
import moment from 'moment';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tippy';
import UserAvatar from '../../UserAvatar/UserAvatar';
import { Translation } from 'react-i18next';

class Report extends React.Component {
  getStyles() {
    const { style, data: { onClick, isMobile } } = this.props;

    return {
      wrapper: {
        ...style,
        display: 'flex',
        padding: 10,
        boxSizing: 'border-box',
        cursor: onClick ? 'pointer' : 'default'
      },
      avatarWrapper: {
        width: isMobile ? 60 : 80,
        height: isMobile ? 60 : 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        position: 'relative'
      },
      circle: {
        background: '#00d663',
        borderRadius: 4,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
        overflow: 'hidden',
        border: '2px solid #00d663'
      },
      photo: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      },
      nameWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      },
      main: {
        flex: 1,
        fontWeight: 600,
        alignItems: 'center',
        display: 'flex'
      },
      description: {
        flex: 1,
        fontWeight: 100,
        color: '#6f6f6f',
        fontSize: 14,
        alignItems: 'center',
        display: 'flex'
      },
      box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 5px'
      },
      button: {
        background: '#00d663',
        color: '#000',
        padding: '5px 10px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'space-between',
        width: 'auto'
      }
    }
  }

  handleClick = () => {
    const { data, index } = this.props;
    const { onClick } = data;
    const current = data.data[index];

    onClick({ ...current, src: this.getUrl(current._id)});
  }

  handleAcceptClick = (e) => {
    e.stopPropagation();
    const { data, index } = this.props;
    const { onAcceptClick } = data;
    const current = data.data[index];

    onAcceptClick(current);
  }

  handleRejectClick = (e) => {
    e.stopPropagation();
    const { data, index } = this.props;
    const { onRejectClick } = data;
    const current = data.data[index];

    onRejectClick(current);
  }

  renderDates = () => {
    const { data, index } = this.props;
    const current = data.data[index];
    const { uploadedAt } = current;

    return `${moment(uploadedAt).format('DD/MM/YYYY')}`
  }

  getUrl = (_id) => {
    return `https://moments.fra1.digitaloceanspaces.com/web/${_id}.jpeg`;
  }

  renderReports = () => {
    const { data, index } = this.props;
    const current = data.data[index];

    return (
      <div>
        {current.reports.map(report => (
          <div key={report._id} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5}}>
            <div><UserAvatar thumb={report.thumb} username={report.username} alias={report.alias} horizontal={true} size={30} /></div>
            <div style={{textTransform: 'uppercase', fontSize: 12, marginLeft: 5}}>{report.report} ({moment(report.dateTime).format('DD/MM/YY HH:mm')})</div>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { data, index } = this.props;
    const { onClick, onAcceptClick, onRejectClick, isMobile } = data;
    const styles = this.getStyles();
    const current = data.data[index];
    const { userId, originalname, _id, reports } = current;

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper} onClick={onClick ? this.handleClick : null}>
            <div style={styles.avatarWrapper}>
              <div style={styles.circle}>
                {<img style={styles.photo} src={this.getUrl(_id)} alt="name" />}
              </div>
            </div>
            <div style={styles.nameWrapper}>
              <div style={styles.description}>{this.renderDates()}</div>
              <div style={styles.main}>
                <Tooltip
                  html={this.renderReports()}
                  position={'bottom'}
                  trigger="mouseenter"
                  theme="light"
                  arrow={false}
                  distance={15}
                >
                  {reports.length} {t('reports')}
            </Tooltip>
              </div>
              <div style={styles.description}>{originalname} by {userId}</div>
            </div>
            <div style={styles.box}>
              <Tooltip
                title={t("Accept report")}
                position={'bottom'}
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <motion.button
                  type="button"
                  onClick={onAcceptClick ? this.handleAcceptClick : null}
                  style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Icon icon="thumbsUp" size={isMobile ? 16 : 24} color="#00d663" />
                </motion.button>
              </Tooltip>
            </div>
            <div style={styles.box}>
              <Tooltip
                title={t("Reject report")}
                position={'bottom'}
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <motion.button
                  type="button"
                  onClick={onRejectClick ? this.handleRejectClick : null}
                  style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Icon icon="thumbsDown" size={isMobile ? 16 : 24} color="#f44336" />
                </motion.button>
              </Tooltip>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default Report;
