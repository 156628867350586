import React from 'react';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import { Translation } from 'react-i18next';

class DialogError extends React.Component {
  getStyles() {
    const { theme, style, fullScreen } = this.props;
    return {
      wrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: theme === 'light' ? 'rgba(255, 255, 255, .7)' : 'rgba(0, 0, 0, .2)',
        zIndex: 1000,
        padding: 5,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        ...style
      },
      content: {
        background: '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 60px, rgba(0, 0, 0, 0.22) 0px 15px 20px',
        borderRadius: 5,
        overflow: 'hidden',
        boxSizing: 'border-box',
        minHeight: 95,
        minWidth: '50%',
        width: fullScreen && '98%',
        height: fullScreen && '98%',
        display: 'flex',
        flexDirection: 'column'
      },
      buttons: {
        padding: '10px 10px 5px 10px',
        display: 'flex',
        justifyContent: 'flex-end',
        color: 'black',
        height: 36
      },
      button: {
        width: 120,
        background: '#FFF',
        padding: '0 8px',
        cursor: 'pointer',
        textAlign: 'center',
        minWidth: 64,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        border: 'none',
        fontWeight: 600,
        fontSize: 16
      },
      contentWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      },
      title: {
        flex: 'none',
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: 24,
        position: 'relative'
      },
      message: {
        flex: 1,
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 20
      },
      closeButtonWrapper: {
        position: 'absolute',
        right: 5
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      }
    }
  }

  render() {
    const { title, message, onClose, onAccept } = this.props;
    const styles = this.getStyles();

    return (
      <div style={styles.wrapper}>
        <div style={styles.content}>
          <div style={styles.contentWrapper}>
            <div style={{ ...styles.title, background: '#f44336' }}>
              <Translation>
                {t => t(title)}
              </Translation>
              <span style={styles.closeButtonWrapper}>
                <motion.button
                  type="button"
                  onClick={onClose}
                  style={styles.closeButton}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Icon size={24} icon="close" color="#000" />
                </motion.button>
              </span>
            </div>
            <div style={styles.message}>
              <Translation>
                {t => t(message)}
              </Translation>
            </div>
            <div style={styles.buttons}>
              <motion.button
                onClick={onAccept}
                style={{ ...styles.button, color: '#f44336'}}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              >
                <Translation>
                  {t => t('ACCEPT')}
                </Translation>
              </motion.button>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

DialogError.defaultProps = {
  theme: "dark"
};

export default DialogError;
