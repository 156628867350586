import AppDispatcher from '../../AppDispatcher/AppDispatcher.js';
import { baseUrl } from '../../../config'; 

const WelcomeActions = {
  getUserData: ({ token, expiresIn, userId }) => {
    fetch(`${baseUrl}/users/${userId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'LOGIN',
        data: {
          ...data,
          token,
          expiresIn
        }
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  sendGoogleAuth: (response) => {
    fetch(`${baseUrl}/users/login/google`, {
      method: "POST",
      body: JSON.stringify(response),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      const [userId] = response.profileObj && response.profileObj.email && response.profileObj.email.split('@');
      WelcomeActions.getUserData({
        token: data.access_token,
        userId: userId
      });
    }).catch((err) => {
      console.log(err);
    });
  },
  sendFacebookAuth: (response) => {
    fetch(`${baseUrl}/users/login/facebook`, {
      method: "POST",
      body: JSON.stringify(response),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      const [userId] = response.email && response.email.split('@');
      WelcomeActions.getUserData({
        token: data.access_token,
        userId: userId
      });
    }).catch((err) => {
      console.log(err);
    });
  }
};

export default WelcomeActions;
