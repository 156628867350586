import React, { useState, useRef, useEffect } from 'react'
import EventBoxRanking from '../EventBoxRanking/EventBoxRanking';
import EventPictureBoxRanking from '../EventPictureBoxRanking/EventPictureBoxRanking';
import Aside from './Aside';
import { baseUrl } from '../../config'; 
import Spinner from 'react-spinkit';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
} from '@material/react-menu';
import { useOnClickOutside } from '../../utils/hooks';
import Icon from '../Icon/Icon';
import MotionButton from '../Button/MotionButton';
import { Translation } from 'react-i18next';
import Footer from '../Footer/Footer';

const Trending = (props) => {
  const { isMobile, userInfo, rangeActions, viewActions, updated } = props;
  const [data, setData] = useState(null);
  const [view, setView] = useState('Pictures (top 50)');
  const [range, setRange] = useState('Month');
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menu, setMenu] = useState(null);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));

  useEffect(() => {
    getData();
  }, [range])

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className="mdc-menu-desktop"
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleItemClick(menu[index])}
          >
            <MenuList>
              <div ref={ref}>
                {menu.map(option => (
                  <MenuListItem key={option.value}>
                    <MenuListItemText primaryText={t(option.label)} />
                  </MenuListItem>
                ))}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };

  const handleViewShowMenu = (event) => {
    setMenuOpen(true);
    setMenu(viewActions);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleRangeShowMenu = (event) => {
    setMenuOpen(true);
    setMenu(rangeActions);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setMenuOpen(false);
    setMenu(null);
  };

  const handleItemClick = option => {
    if (option.type === 'view') {
      setView(option.value);
    } else if (option.type === 'range') {
      setRange(option.value);
    }
  };

  const getData = () => {
    setLoading(true);
    fetch(`${baseUrl}/activity/trending/${range.toLowerCase()}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (!response.ok) {
        throw new Error();
      }

      return response.json();
    }).then((data) => {
      setData(data);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  const desktopStyles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    content: {
      flex: 1,
      boxSizing: 'border-box',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    blockWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 40
    },
    categoriesBlockWrapper: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20
    },
    title: {
      fontWeight: 600,
      fontSize: 32,
      flex: 'none',
      marginBottom: 20,
      display: 'flex'
    },
    subTitle: {
      fontWeight: 500,
      fontSize: 24,
      flex: 'none',
      marginBottom: 20,
      display: 'flex'
    },
    placeholder: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontWeight: 600,
      fontSize: 32,
      opacity: 0.2,
      marginTop: 50
    },
    placeholderIcon: {
      width: 200,
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      filter: 'grayscale(1)'
    },
    eventsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 100%)',
      gridAutoRows: '500px',
      gridGap: 25
    },
    imagesWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 100%)',
      gridAutoRows: '500px',
      gridGap: 25
    },
    iconWrapper: {
      width: 45,
      height: 45,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      marginRight: 10
    },
    icon: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, .5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    dataWrapper: {
      flex: 1,
      display: 'flex',
    }
  };

  const mobileStyles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    content: {
      flex: 1,
      boxSizing: 'border-box',
      padding: 15,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    blockWrapper: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    categoriesBlockWrapper: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20
    },
    title: {
      fontWeight: 600,
      fontSize: 26,
      flex: 'none',
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center'
    },
    subTitle: {
      fontWeight: 500,
      fontSize: 16,
      flex: 'none',
      marginBottom: 10,
      display: 'flex'
    },
    placeholder: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontWeight: 600,
      fontSize: 24,
      textAlign: 'center',
      opacity: 0.2,
      marginTop: 50
    },
    placeholderIcon: {
      width: 150,
      height: 150,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      filter: 'grayscale(1)'
    },
    eventsWrapper: {
      flex: 1,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 100%)',
      gridGap: 5
    },
    imagesWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 100%)',
      gridGap: 25
    },
    iconWrapper: {
      width: 45,
      height: 45,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      marginRight: 10
    },
    icon: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, .5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    dataWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    }
  };

  const styles = isMobile ? mobileStyles : desktopStyles;

  const renderEventPictureBox = (image, position) => {
    return (
      <EventPictureBoxRanking key={image._id} {...image} isMobile={isMobile} position={position + 1} />
    );
  }

  const renderEventBox = (event, position) => {
    return (
      <EventBoxRanking key={event._id} {...event} isMobile={isMobile} position={position + 1} />
    );
  }

  const renderData = () => {
    return (
      <div style={styles.blockWrapper}>
        <div style={styles.title}>
          <div style={styles.iconWrapper}><img style={styles.icon} src="fire.svg" alt="trending" /></div>
          <Translation>
            {t => t('trending')}
          </Translation>
        </div>
        <div style={styles.dataWrapper}>
          {renderView()}
        </div>
      </div>
    );
  }

  const renderView = () => {
    switch (view) {
      case 'Pictures (top 50)': return renderPhotos();
      case 'Events (top 10)': return renderEvents();
      default: return null;
    }
  }

  const renderEvents = () => {
    if (!data || !data.events) return null;

    return (
      <Translation>
        {t => (
          <div style={styles.blockWrapper}>
            <div style={styles.subTitle}>
              <MotionButton onClick={handleViewShowMenu} dense={false} background={null} style={{ display: 'flex', alignItems: 'center', padding: '5px 10px' }}>
                {t(view)}
                <Icon size={isMobile ? 18 : 24} icon="menuDown" color="#000" />
              </MotionButton>
              <MotionButton onClick={handleRangeShowMenu} dense={false} background={null} style={{ display: 'flex', alignItems: 'center', padding: '5px 10px' }}>
                {t(range)}
                <Icon size={isMobile ? 18 : 24} icon="menuDown" color="#000" />
              </MotionButton>
            </div>
            {data.events && data.events.length === 0 ?
              <div style={styles.placeholder}>
                <div style={styles.placeholderIcon}><img style={styles.icon} src="fire.svg" alt="trending" /></div>
                {t('There is no trending events at the moment!')}
              </div>
              :
              <div style={styles.eventsWrapper}>
                {data.events.map((d, i) => renderEventBox(d, i))}
              </div>
            }
          </div>
        )}
      </Translation>
    );
  }

  const renderPhotos = () => {
    if (!data || !data.images) return null;

    return (
      <Translation>
        {t => (
          <div style={styles.blockWrapper}>
            <div style={styles.subTitle}>
              <MotionButton onClick={handleViewShowMenu} dense={false} background={null} style={{ display: 'flex', alignItems: 'center', padding: '5px 10px'}}>
                {t(view)}
                <Icon size={isMobile ? 18 : 24} icon="menuDown" color="#000" />
              </MotionButton>
              <MotionButton onClick={handleRangeShowMenu} dense={false} background={null} style={{ display: 'flex', alignItems: 'center', padding: '5px 10px' }}>
                {t(range)}
                <Icon size={isMobile ? 18 : 24} icon="menuDown" color="#000" />
              </MotionButton>
            </div>
            {data.images && data.images.length === 0 ?
              <div style={styles.placeholder}>
                <div style={styles.placeholderIcon}><img style={styles.icon} src="fire.svg" alt="trending" /></div>
                {t('There is no trending images at the moment!')}
              </div>
              :
              <div style={styles.imagesWrapper}>
                {data.images.map((d, i) => renderEventPictureBox(d, i))}
              </div>
            }
          </div>
        )}
      </Translation>
    );
  }

  const renderSpinner = () => {
    return (
      <div style={styles.overlay}>
        <Spinner name="folding-cube" color="#00d663" />
      </div>
    );
  }

  return (
    <div style={styles.wrapper}>
      <Aside isMobile={isMobile} userInfo={userInfo} updated={updated}/>
      {loading && renderSpinner()}
      {isMenuOpen && renderMenu()}
      <div style={styles.content}>
        {renderData()}
        <Footer isMobile={isMobile} />
      </div>
    </div>
  )
}

Trending.defaultProps = {
  viewActions: [
    { label: 'Pictures (top 50)', value: 'Pictures (top 50)', type: 'view' },
    { label: 'Events (top 10)', value: 'Events (top 10)', type: 'view' },
  ],
  rangeActions: [
    { label: 'Week', value: 'Week', type: 'range' },
    { label: 'Month', value: 'Month', type: 'range' },
    { label: 'Year', value: 'Year', type: 'range' },
  ]
};

export default Trending;