const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

/**
 * Returns a number (float) representing the luminance of a color.
 * See: https://polished.js.org
 * @param {String} color  - Color en exadecimal
 */
const getLuminance = (color) => {
  const rgbColor = hexToRgb(color)
  const [r, g, b] = Object.keys(rgbColor).map(key => {
    const channel = rgbColor[key] / 255
    return channel <= 0.03928
      ? channel / 12.92
      : ((channel + 0.055) / 1.055) ** 2.4
  })
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
};

/**
 * Selects black or white for best contrast depending on the luminosity of the given color.
 * Follows W3C specs for readability at https://www.w3.org/TR/WCAG20-TECHS/G18.html
 * See: https://polished.js.org
 */
const getReadableColor = ({ color, black = '#000', white = '#fff' }) => {
  return getLuminance(color) > 0.179 ? black : white;
};

export {
  hexToRgb,
  getLuminance,
  getReadableColor,
};
