import React, { Component } from 'react'
import Category from './Category';
import Aside from './Aside';
import { baseUrl } from '../../config'; 
import Spinner from 'react-spinkit';
import { Translation } from 'react-i18next';
import Footer from '../Footer/Footer';

class Categories extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: false
    };
  }

  componentDidMount() {
    this.getData();
  }  

  getData = () => {
    this.setState({ loading: true });
    fetch(`${baseUrl}/types/resume`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (!response.ok) {
        throw new Error();
      }

      return response.json();
    }).then((data) => {
      this.setState({ data, loading: false });
    }).catch((err) => {
      console.log(err);
    });
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 40
        },
        categoriesBlockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        },
        title: {
          fontWeight: 600,
          fontSize: 32,
          flex: 'none',
          marginBottom: 20,
          display: 'flex'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 24,
          flex: 'none',
          marginBottom: 20
        },
        placeholder: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontWeight: 600,
          fontSize: 32,
          opacity: 0.2,
          marginTop: 50
        },
        placeholderIcon: {
          width: 200,
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
          filter: 'grayscale(1)'
        },
        categoriesWrapper: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 260px)',
          gridTemplateRows: 'repeat(auto-fill, 120px)',
          gridGap: 15
        },
        iconWrapper: {
          width: 45,
          height: 45,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginRight: 10
        },
        icon: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        },
        categoriesBlockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        },
        title: {
          fontWeight: 600,
          fontSize: 26,
          flex: 'none',
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 20,
          flex: 'none',
          marginBottom: 20
        },
        placeholder: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontWeight: 600,
          fontSize: 24,
          textAlign: 'center',
          opacity: 0.2,
          marginTop: 50
        },
        placeholderIcon: {
          width: 200,
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
          filter: 'grayscale(1)'
        },
        categoriesWrapper: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 100%)',
          gridTemplateRows: 'repeat(auto-fill, 70px)',
          gridGap: 5
        },
        iconWrapper: {
          width: 45,
          height: 45,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginRight: 10
        },
        icon: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    }
  }

  renderCategoryBox = (category) => {
    const { isMobile } = this.props;
    return (
      <Category key={category.name} data={category} isMobile={isMobile} />
    );
  }

  renderCategories = () => {
    const { isMobile } = this.props;
    const { data } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!data) return null;

    return (
      <div style={styles.blockWrapper}>
        <div style={styles.title}>
          <Translation>
            {t => t('categories')}
          </Translation>
        </div>
        {data && data.length === 0 ? 
          <div style={styles.placeholder}>
            <Translation>
              {t => t('There is no categories yet!')}
            </Translation>
          </div>
        :
          <div style={styles.categoriesWrapper}>
            {data.map(d => this.renderCategoryBox(d))}
          </div>
        }
      </div>
    );
  }

  renderSpinner = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.overlay}>
        <Spinner name="folding-cube" color="#00d663" />
      </div>
    );
  }

  render() {
    const { isMobile, userInfo, updated } = this.props;
    const { loading } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        <Aside isMobile={isMobile} userInfo={userInfo} updated={updated} />
        {loading && this.renderSpinner()}
        <div style={styles.content}>
          {this.renderCategories()}
          <Footer isMobile={isMobile} />
        </div>
      </div>
    )
  }
}

export default Categories;