import React, { useState, useRef } from 'react';
import UserAvatar from '../UserAvatar/UserAvatar';
import Icon from '../Icon/Icon';
import moment from 'moment';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { Tooltip as CustomTooltip } from 'react-tippy';
import { motion } from 'framer-motion';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
} from '@material/react-menu';
import { useOnClickOutside } from '../../utils/hooks';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import _ from 'lodash';
import { Translation } from 'react-i18next';

const EventBox = ({ dateTime, beginDate, endDate, _id, stats, name, thumb, type, seasonId, style, actions, photographers, visible, organizers, organizerSize = 25, isOnEventScreen, isMobile, onRemoveOrganization }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));
  const handleMouseEnter = () => {
    setIsOver(true);
  }

  const handleMouseOut = () => {
    setIsOver(false);
  }

  const styles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      padding: 2.5,
      boxSizing: 'border-box',
      minHeight: 250,
      minWidth: 0,
      ...style
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 5,
      border: '1px solid #efefef',
      position: 'relative',
      boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
    },
    header: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center'
    },
    imageWrapper: {
      flex: 1,
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
    },
    image: {
      width: isOver ? '110%' : '100%',
      height: isOver ? '110%' : '100%',
      objectFit: 'cover',
      transition: 'all 0.5s ease',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'absolute',
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: '#e6e4e4',
      zIndex: 1
    },
    overlaySelection: {
      position: 'absolute',
      top: 2.5,
      right: 2.5,
      left: 2.5,
      bottom: 2.5,
      background: 'rgba(0,0,0,.5)',
      zIndex: 5
    },
    checkbox: {
      position: 'absolute',
      top: 10,
      left: 10
    },
    bottom: {
      paddingTop: 5,
      flex: 'none',
      display: 'flex',
      flexDirection: 'column'
    },
    stats: {
      padding: '0 5px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 12
    },
    stat: {
      flex: 'none',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    date: {
      padding: '0 5px',
      opacity: 0.5,
      fontSize: 12,
      marginBottom: 5,
      textTransform: 'capitalize'
    },
    name: {
      padding: '0 5px',
      fontWeight: 600,
      fontSize: 14,
      height: 38,
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    roundedButton: {
      padding: 0,
      borderRadius: '50%',
      boxShadow: 'none',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      width: 30,
      height: 30,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none'
    },
    badge: {
      padding: '2px 5px',
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 3,
      background: '#F44336',
      color: '#fff',
      cursor: 'pointer',
    },
    sponsors: {
      marginTop: isOnEventScreen && organizers && organizers.filter(organization => organization.mode !== 'participant').length === 0 ? 0 : 10,
      height: isOnEventScreen ? organizers && organizers.filter(organization => organization.mode !== 'participant').length === 0 ? 0 : 50 : 25,
      padding: 5,
      display: 'flex',
      alignItems: 'center',
    },
    sponsorsLabel: { 
      marginRight: 10, 
      fontSize: isOnEventScreen ? 16 : 10, 
      width: isOnEventScreen ? 93 : 53 
    }
  };

  const handleTypeClick = () => {
    AppDispatcher.dispatch({
      actionType: `CHANGE_VIEW`,
      view: 'category',
      data: type
    });
  };

  const handleItemClick = option => {
    AppDispatcher.dispatch({
      actionType: option.value,
      data: {
        _id,
        name,
        photographers,
        type,
        beginDate,
        endDate,
        visible,
        organizers,
        dateTime,
        seasonId,
      }
    });
  };

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className="mdc-menu-desktop"
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleItemClick(actions[index])}
          >
            <MenuList>
              <div ref={ref}>
                {actions.map(option => (
                  <MenuListItem key={option.value}>
                    <MenuListItemGraphic graphic={<Icon size={18} icon={option.icon} color={option.label === 'Remove' && '#f44336'} wrapperStyle={{marginRight: 5}}/>} />
                    <MenuListItemText primaryText={t(option.label)} className={option.label === 'Remove' && 'mdc-text-red'}/>
                  </MenuListItem>
                ))}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };
  
  const handleShowMenu = event => {
    setMenuOpen(true);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const renderDate = () => {
    if (dateTime) {
      return moment(dateTime).format('dddd, DD MMM YY HH:mm');
    }

    if (beginDate === endDate) {
      return moment(beginDate).format('dddd, DD MMM YY');
    }

    return `${moment(beginDate).format('dddd, DD MMM')} - ${moment(endDate).format('dddd, DD MMM YY')}`;
  };

  const handleClick = () => {
    AppDispatcher.dispatch({
      actionType: 'SELECT_EVENT',
      _id
    });
  }

  const handleNewsClick = () => {
    AppDispatcher.dispatch({
      actionType: 'SORT_NEWS'
    });
  }

  const renderOrganizers = () => {
    if (!organizers || organizers.length === 0) return null;

    return organizers.filter(organization => organization.mode === 'participant').map(o => <OrganizationAvatar key={o._id} {...o} onRemove={onRemoveOrganization ? () => onRemoveOrganization({ _id, organizerId: o._id }) : null} size={organizerSize} style={{marginRight: 5, boxSizing: 'content-box'}}/>);
  }

  const renderSponsors = () => {
    if (!organizers || organizers.length === 0) return null;
    
    const sponsors = organizers.filter(organization => organization.mode !== 'participant');

    if (sponsors.length === 0) return null;

    const clickData = { showEvents: true };
    const modes = {
      gold: { size: isOnEventScreen ? 50 : 25, style: { border: '2px solid #FFD54F' } },
      silver: { size: isOnEventScreen ? 50 : 25, style: { border: '2px solid #D7D7D7' } },
      bronze: { size: isOnEventScreen ? 50 : 25, style: { border: '2px solid #AD8A56' } },
    };

    const groupedSponsors = _.groupBy(sponsors, 'mode');
    let sortedSponsors = [];
    if (groupedSponsors.gold) sortedSponsors = sortedSponsors.concat(groupedSponsors.gold);
    if (groupedSponsors.silver) sortedSponsors = sortedSponsors.concat(groupedSponsors.silver);
    if (groupedSponsors.bronze) sortedSponsors = sortedSponsors.concat(groupedSponsors.bronze);

    return (
      <> 
        <span style={styles.sponsorsLabel}>
          <Translation>
            {t => t('Photo Sponsor')}
          </Translation>
        </span>
        {sortedSponsors.map(o => <OrganizationAvatar key={o._id} {...o} clickData={clickData} onRemove={onRemoveOrganization ? () => onRemoveOrganization({_id, organizerId: o._id}) : null} size={modes[o.mode].size} style={{ boxSizing: 'content-box', marginRight: 5}} />)}
      </>
    );
  }

  const renderActions = () => {
    if (!actions) return null;

    return (
      <div style={{ ...styles.header, borderBottom: '1px solid #eaeaea', justifyContent: 'center' }}>
        <motion.button
          onClick={handleShowMenu}
          style={styles.roundedButton}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <Icon size={18} icon="showMore" color="#000" />
        </motion.button>
      </div>
    );
  }

  const renderNewsButton = () => {
    if (stats && stats.unviews > 0) {
      if (!isOnEventScreen) {
        return (
          <Translation>
            {t => (
              <div style={styles.badge} className="blink-1">
                {stats.unviews} {t('new')}
              </div>
            )}
          </Translation>
        );
      }

      if (isMobile) {
        return (
          <Translation>
            {t => (
              <div style={styles.badge} onClick={handleNewsClick} className="blink-1">
                {stats.unviews} {t('new')}
              </div>
            )}
          </Translation>
        );
      }

      return (
        <Translation>
          {t => (
            <div style={styles.badge} className="blink-1">
              <CustomTooltip
                title={t('Click to show new first')}
                position="bottom"
                trigger="mouseenter"
                theme="light"
                arrow={false}
                distance={15}
              >
                <span onClick={handleNewsClick}>{stats.unviews} {t('new')}</span>
              </CustomTooltip>
            </div>
          )}
        </Translation>
      );
    }
  }

  return (
    <div style={styles.wrapper}>
      {isMenuOpen && renderMenu()}
      <div style={styles.card}>
        {renderActions()}
        <div style={styles.header}>
          {type && type.thumb && <UserAvatar thumb={type.thumb} username={type.name} horizontal={true} size={25} style={{ padding: 5, flex: 1 }} usernameStyle={{ whiteSpace: 'initial', display: '-webkit-box', WebkitLineClamp: 2}} onClick={handleTypeClick}/>}
          {renderOrganizers()}
        </div>
        <div style={styles.imageWrapper} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut}>
          {renderNewsButton()}
          {thumb && <img src={thumb} style={styles.image} loading="lazy" alt={name} />}
          <div style={styles.background} />
        </div>
        <div style={styles.bottom}>
          <div style={styles.name}>
            {name}
          </div>
          <div style={styles.date}>
            {renderDate()}
          </div>
          <div style={styles.stats}>
            <div style={styles.stat}>
              <Icon size={18} icon="cameraEmpty" color={'#000'} />
              {stats.photos}
            </div>
            <div style={styles.stat}>
              <Icon size={18} icon="heartEmpty" color={'#000'} />
              {stats.likes}
            </div>
            <div style={styles.stat}>
              <Icon size={18} icon="userEmpty" color={'#000'} />
              {stats.users}
            </div>
            <div style={styles.stat}>
              <Icon size={18} icon="eyeEmpty" color={'#000'} />
              {stats.visits}
            </div>
          </div>
          <div style={styles.sponsors}>
            {renderSponsors()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventBox;
