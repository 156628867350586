import React, { Fragment } from 'react';
import Icon from '../../Icon/Icon';
import { motion } from 'framer-motion';
import AppDispatcher from '../../AppDispatcher/AppDispatcher';
import FormUserProfile from '../../Form/FormUserProfile';
import Dialog from '../../Dialog/Dialog';
import Achievement from './Achievement';
import { baseUrl } from '../../../config'; 
import { SizeMe } from 'react-sizeme';
import { FixedSizeList as List } from 'react-window';
import { getReadableColor } from '../../../utils/color';
import handleResponse from '../../../utils/handleResponse';
import FormOrganization from '../../Form/FormOrganization';
import OrganizationAvatar from '../../OrganizationAvatar/OrganizationAvatar';
import UserAvatar from '../../UserAvatar/UserAvatar';
import ReactCountryFlag from "react-country-flag"
import Cropper from 'react-easy-crop';
import MotionButton from '../../Button/MotionButton';
import getCroppedImg from '../../../utils/crop';
import { Translation } from 'react-i18next';

const UserBox = ({ index, data, style }) => {
  const currentData = data[index];

  const row = {
    display: 'flex',
    borderBottom: '1px solid #ededed',
    boxSizing: 'border-box',
    padding: 5,
    alignItems: 'center',
  };

  return (
    <Fragment>
      <div style={{ ...style, ...row }}>
        <UserAvatar {...currentData} horizontal={true} style={{ flex: 1 }} />
      </div>
    </Fragment>
  );
}

class Header extends React.Component {
  constructor() {
    super();
    this._dispatchToken = AppDispatcher.register(this._registerToActions);
    this.state = {
      dialog: null,
      showMoreOverlay: false,
      showMoreFeedback: null,
      image: null,
      crop: { x: 0, y: 0 },
      croppedAreaPixels: null,
      zoom: 1,
      aspect: 1/1,
      rotation: 0,
    };
    this.listRef = React.createRef();
    this.handleUploadCropImage = this.handleUploadCropImage.bind(this);
  }

  _registerToActions = (action) => {
    switch (action.actionType) {
      case 'GO_TO_USER_PROFILE':
        this.handleCloseDialog({ reload: true });
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this._dispatchToken);
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 'none',
          background: '#f3f3f3',
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          width: 380,
          overflow: 'auto',
        },
        pictureWrapper: {
          width: 380,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginBottom: 30
        },
        emptyPicture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          border: '6px solid #000',
          width: 200,
          height: 200,
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 50
        },
        uploadButton: {
          padding: 0,
          borderRadius: '50%',
          boxShadow: 'none',
          border: '3px solid #fff',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'absolute',
          bottom: 10,
          right: 90
        },
        nationality: {
          position: 'absolute',
          bottom: 10,
          left: 90,
          pointerEvents: 'none',
        },
        nationalityText: {
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: -15,
          fontSize: 14,
          fontWeight: 600,
          color: '#000',
          textAlign: 'center'
        },
        contentWrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        },
        buttonsWrapper: {
          position: 'absolute',
          top: 0,
          right: 0
        },
        actionButton: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        actionLabelButton: {
          padding: '5px 30px',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          fontSize: 14,
          fontWeight: 600,
          borderRadius: 4
        },
        row: {
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10
        },
        username: {
          fontWeight: 600,
          fontSize: 22,
          color: '#1f1f1f'
        },
        coloredLabel: {
          boxSizing: 'border-box',
          padding: '4px 10px',
          fontSize: 14,
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: 1,
          background: '#FFC107',
          color: '#000',
          borderRadius: 4,
        },
        name: {
          fontWeight: 400,
          fontSize: 20,
          color: '#818c91'
        },
        socialWrapper: {
          display: 'flex',
          alignItems: 'baseline',
          marginRight: 25,
          cursor: 'pointer',
        },
        socialValue: {
          color: '#2a3033',
          fontWeight: 600,
          fontSize: 24,
          marginRight: 5
        },
        socialLabel: {
          color: '#818c8f',
          fontSize: 18
        },
        bio: {
          marginTop: 10,
          color: '#657279',
          fontSize: 16,
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        achievements: {
          display: 'flex'
        },
        achievement: {
          marginRight: 10
        },
        photo: {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        },
        picture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          width: 200,
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        overlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          padding: '40px 30%',
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column'
        },
        overlayTitle: {
          background: '#00d663',
          fontWeight: 600,
          flex: 'none',
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative'
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          boxSizing: 'border-box',
          background: '#fff',
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        },
        showMoreOverlay: {
          position: 'absolute',
          top: 65,
          right: 55,
          width: 200,
          background: '#fff',
          boxShadow: '0 0 13px 1px rgba(0, 0, 0, 0.10)',
          borderRadius: 4,
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
        },
        flatButton: {
          padding: '5px 20px',
          fontWeight: 600,
          fontSize: 14,
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          color: '#00d663',
          alignItems: 'center'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          marginTop: 10
        },
        organizationWrapper: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 5,
          color: '#969696',
          fontSize: 14,
          background: '#fff',
          borderRadius: 8,
          marginTop: 10,
          position: 'relative',
        },
        link: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: '#000',
          fontSize: 16,
        },
        bibnumber: {
          width: 40,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#000',
          background: 'linear-gradient(115deg, #ffec07, #ffb206)',
          borderRadius: 4,
          padding: '0px 5px',
          fontWeight: 600,
          visibility: 'visible',
          marginRight: 5
        },
        memberData: {
          display: 'flex',
          marginTop: 10,
          fontSize: 18,
        },
        closeButton: {
          position: 'absolute',
          right: 5,
          top: '50%',
          marginTop: -22
        }
      },
      mobile: {
        wrapper: {
          flex: 'none',
          background: '#f3f3f3',
          display: 'flex',
          padding: 20,
          flexDirection: 'column'
        },
        pictureWrapper: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginBottom: 40
        },
        emptyPicture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          border: '6px solid #000',
          width: 150,
          height: 150,
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 50
        },
        uploadButton: {
          padding: 0,
          borderRadius: '50%',
          boxShadow: 'none',
          border: '3px solid #fff',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'absolute',
          bottom: -5,
          right: -10
        },
        nationality: {
          position: 'absolute',
          bottom: -5,
          left: -10,
          pointerEvents: 'none',
        },
        nationalityText: {
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: -15,
          fontSize: 14,
          fontWeight: 600,
          color: '#000',
          textAlign: 'center'
        },
        contentWrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        },
        buttonsWrapper: {
          position: 'absolute',
          top: 0,
          right: 0
        },
        actionButton: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        actionLabelButton: {
          padding: '5px 30px',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          fontSize: 14,
          fontWeight: 600,
          borderRadius: 4
        },
        row: {
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10
        },
        username: {
          fontWeight: 600,
          fontSize: 18,
          color: '#1f1f1f'
        },
        coloredLabel: {
          boxSizing: 'border-box',
          padding: '2px 10px',
          fontSize: 14,
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: 1,
          background: '#FFC107',
          color: '#000',
          borderRadius: 4,
        },
        name: {
          fontWeight: 400,
          fontSize: 16,
          color: '#818c91'
        },
        socialWrapper: {
          display: 'flex',
          alignItems: 'baseline',
          marginRight: 25,
          cursor: 'pointer'
        },
        socialValue: {
          color: '#2a3033',
          fontWeight: 600,
          fontSize: 20,
          marginRight: 5
        },
        socialLabel: {
          color: '#818c8f',
          fontSize: 16
        },
        bio: {
          marginTop: 10,
          color: '#657279',
          fontSize: 14,
          display: '-webkit-box',
          WebkitLineClamp: '3',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        achievements: {
          display: 'flex'
        },
        achievement: {
          marginRight: 10
        },
        photo: {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        },
        picture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          width: 150,
          height: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        overlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
        },
        overlayTitle: {
          background: '#00d663',
          fontWeight: 600,
          flex: 'none',
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative'
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flex: 1,
          boxSizing: 'border-box',
          background: '#fff',
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        },
        showMoreOverlay: {
          position: 'absolute',
          top: 45,
          right: 10,
          left: 10,
          background: '#fff',
          boxShadow: '0 0 13px 1px rgba(0, 0, 0, 0.10)',
          borderRadius: 4,
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
        },
        flatButton: {
          padding: '5px 20px',
          fontWeight: 600,
          fontSize: 14,
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          color: '#00d663',
          alignItems: 'center'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 12,
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          marginTop: 10
        },
        organizationWrapper: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 5,
          color: '#969696',
          fontSize: 12,
          background: '#fff',
          borderRadius: 8,
          marginTop: 10,
          position: 'relative',
        },
        link: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: '#000',
          fontSize: 16,
        },
        bibnumber: {
          width: 40,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#000',
          background: 'linear-gradient(115deg, #ffec07, #ffb206)',
          borderRadius: 4,
          padding: '0px 5px',
          fontWeight: 600,
          visibility: 'visible',
          marginRight: 5
        },
        memberData: {
          display: 'flex',
          marginTop: 10,
          fontSize: 18,
        },
        closeButton: {
          position: 'absolute',
          right: 5,
          top: '50%',
          marginTop: -22
        }
      }
    }
  }

  renderFollowersDialog = () => {
    const { userInfo: { followers } } = this.props;

    this.setState({
      dialog: {
        children: this.renderUsersList,
        onClickOutside: this.handleCloseDialog,
        data: {
          title: 'FOLLOWERS',
          users: followers
        }
      }
    });
  }

  renderFollowingDialog = () => {
    const { userInfo: { following } } = this.props;

    this.setState({
      dialog: {
        fullScreen: true,
        children: this.renderUsersList,
        onClickOutside: this.handleCloseDialog,
        data: {
          title: 'FOLLOWING',
          users: following
        }
      }
    });
  }

  stopOverlayPropagation = (e) => {
    e.stopPropagation();
  }

  renderUsersList = ({ data }) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.overlayWrapper} onClick={this.handleCloseDialog}>
        <div style={styles.overlayTitle}>
          <Translation>
            {t => t(data.title)}
          </Translation>
          <motion.button
            onClick={this.handleCloseDialog}
            style={{ background: 'transparent', position: 'absolute', right: 10, border: 'none', width: 24 }}
          >
            <Icon size={18} icon="close" color="#000" />
          </motion.button>
        </div>
        <div style={styles.overlay} onClick={this.stopOverlayPropagation}>
          <SizeMe monitorWidth monitorHeight>
            {({ size }) => (
              <List
                height={size.height}
                itemCount={data && data.users.length}
                itemSize={55}
                width={size.width}
                itemData={data.users}
              >
                {UserBox}
              </List>
            )}
          </SizeMe>
        </div>
      </div>
    );
  }

  renderPicture = () => {
    const { userInfo: { photo }, isExternal, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.pictureWrapper}>
        {photo ?
          <div style={styles.picture}>
            <img style={styles.photo} src={photo} alt="name" />
          </div>
          :
          <div style={styles.emptyPicture}>
            <Icon icon="user" color="#818c91" size={250} />
          </div>
        }
        {!isExternal && this.renderUploadPictureButton()}
        {this.renderNationality()}
      </div>
    );
  }

  renderNationality = () => {
    const { userInfo: { nationality }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!nationality) return null;

    return (
      <div style={styles.nationality}>
        <ReactCountryFlag
          countryCode={nationality}
          svg
          style={{
            width: '3em',
            height: '3em',
          }}
          title={nationality}
        />
        <span style={styles.nationalityText}>{nationality}</span>
      </div>
    );
  }

  handleAddOrganizationClick = () => {
    const { isMobile } = this.props;
    const header = 'ADD ORGANIZATION';

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganization header={header} handleClose={this.handleCloseDialog} isMobile={isMobile} />
      }
    });
  }

  organizationPlaceholder = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <Translation>
        {t => (
          <div style={styles.organizationWrapper}>
            {t("You don't have organization yet")}
            <motion.button
              onClick={this.handleAddOrganizationClick}
              style={styles.button}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              {t("ADD")}
            </motion.button>
          </div>
        )}
      </Translation>
    );
  }

  organizationPreview = (organization) => {
    const { isMobile, userInfo } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const clickData = { fromUserProfile: userInfo };

    return (
      <div style={{...styles.organizationWrapper, alignItems: 'normal', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
        {organization.map(org => <OrganizationAvatar key={org._id} {...org} size={isMobile ? 40 : 60} clickData={clickData} detailed={true} style={{margin: 5}} nameStyle={{fontSize: 12}}/>)}
      </div>
    );
  }

  organizationMember = (organization) => {
    const { isMobile, isExternal } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const clickData = { showMembers: true };

    return (
      <div key={organization.organizerId} style={{ ...styles.organizationWrapper, alignItems: 'normal' }}>
        <OrganizationAvatar {...organization} _id={organization.organizerId} size={40} horizontal={true} clickData={clickData}/>
        <div style={styles.memberData}>
          {organization.id ? <span style={styles.bibnumber}>{organization.id}</span> : null}
          {organization.position ? <span>{organization.position}</span> : null}
        </div>
        {!isExternal ?
        <div style={styles.closeButton}>
          <motion.button
            onClick={() => this.handleRemoveMemberConfirmation(organization)}
            style={styles.actionButton}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            <Icon size={24} icon="close" color="#000" />
          </motion.button>
        </div> : null}
      </div>
    );
  }

  renderOrganization = () => {
    const { userInfo: { role, organizer }, isExternal, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if ((role !== 'organizer' && role !== 'admin') || isExternal) return null;
    if (!organizer || organizer.length === 0) return this.organizationPlaceholder();

    return (
      <div style={{...styles.row, flexDirection: 'column', alignItems: 'normal'}}>
        <Translation>
          {t => <div>{t('organizations')}</div>}
        </Translation>
        {this.organizationPreview(organizer)}
      </div>
    )
  }

  renderMember = () => {
    const { userInfo: { member }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!member || member.length === 0) return null;

    return (
      <div style={{ ...styles.row, flexDirection: 'column', alignItems: 'normal' }}>
        <Translation>
          {t => <div>{t('Player')}</div>}
        </Translation>
        {member.map(organizer => this.organizationMember(organizer))}
      </div>
    )
  }

  renderUserData = () => {
    const { showMoreOverlay } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.contentWrapper}>
        {this.renderSocialButtons()}
        {this.renderRoleLabel()}
        {this.renderMainData()}
        {this.renderSecondaryData()}
        {this.renderSocialMainData()}
        {this.renderPhone()}
        {this.renderInstagram()}
        {this.renderTwitter()}
        {this.renderFacebook()}
        {this.renderBio()}
        {this.renderOrganization()}
        {this.renderMember()}
        {this.renderActionButtons()}
        {showMoreOverlay && this.renderShowMoreOverlay({ isMobile })}
      </div>
    );
  }

  checkIfFollowing = () => {
    const { userInfo: { username } } = this.props;
    const myUserInfo = JSON.parse(localStorage.getItem('userInfo'));

    return myUserInfo.following && myUserInfo.following.find((f) => f.username === username);
  }

  renderSocialButtons = () => {
    const { isExternal, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    const isFollowing = this.checkIfFollowing();

    if (!isExternal) {
      return null;
    }

    return (
      <div style={{...styles.row, justifyContent: 'center', marginBottom: 30}}>
        <motion.button
          onClick={isFollowing ? this.handleUnfollow : this.handleFollow}
          style={{ ...styles.actionLabelButton, background: isFollowing ? '#fff' : '#22d05d' }}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <Translation>
            {t => isFollowing ? t('FOLLOWING') : t('FOLLOW')}
          </Translation>
        </motion.button>
      </div>
    );
  }

  renderActionButtons = () => {
    const { isExternal, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    
    if (isExternal) {
      return null;
    }

    return (
      <div style={styles.buttonsWrapper}>
        <motion.button
          onClick={this.handleShowMoreClick}
          style={styles.actionButton}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <Icon size={24} icon="showMore" color="#000" />
        </motion.button>
      </div>
    );
  }

  handleShowMoreClick = () => {
    const { showMoreOverlay } = this.state;
    this.setState({
      showMoreOverlay: !showMoreOverlay,
      showMoreFeedback: null,
    });

    if (showMoreOverlay) {
      this.removeListeners();
    } else {
      this.addListeners();
    }
  }

  handleRemoveMemberConfirmation = (data) => {
    this.setState({
      dialog: {
        type: "confirm",
        title: "Delete yourself from this organization",
        message: "Are you sure you want to delete yourself from this organization? You can't undo this action.",
        onClose: this.handleCloseDialog,
        onCancel: this.handleCloseDialog,
        onClickOutside: this.handleCloseDialog,
        onAccept: () => { this.handleRemoveMember(data); },
      }
    });
  }

  handleRemoveMember = (data) => {
    const { organizerId, memberId } = data
    const url = `${baseUrl}/organizers/${organizerId}/members/${memberId}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(() => {
      this.handleCloseDialog({ reload: true });
    }).catch((err) => {
      console.log(err);
    });
  }

  removeListeners = () => {
    document.removeEventListener('mouseup', this.handleListener);
  };

  addListeners = () => {
    document.addEventListener('mouseup', this.handleListener);
  };

  handleListener = e => {
    if (this.listRef.current && !this.listRef.current.contains(e.target)) {
      this.handleShowMoreClick();
    }
  };

  handleShowMoreOptionClick = (type) => {
    if (type === 'edit') {
      this.handleEditClick();
    } else {
      fetch(`${baseUrl}/users/promote/${type}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        return handleResponse(response);
      }).then(([ok, status, data]) => {
        if (!ok) {
          throw new Error(data || 'Oops! Something went wrong');
        }

        this.setState({
          showMoreFeedback: data || 'The request has been submitted'
        });
      }).catch((error) => {
        this.setState({
          showMoreFeedback: error.message
        });
      });
    }
  }

  renderShowMoreOverlay = ({ isMobile }) => {
    const { showMoreFeedback } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (showMoreFeedback) {
      return (
        <div style={{...styles.showMoreOverlay, textTransform: 'uppercase'}} ref={this.listRef}>
          <Translation>
            {t => t(showMoreFeedback)}
          </Translation>
        </div>
      );
    }

    return (
      <Translation>
        {t => (
          <div style={styles.showMoreOverlay} ref={this.listRef}>
            <motion.button
              onClick={() => this.handleShowMoreOptionClick('edit')}
              style={{...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
              whileHover={{ color: '#1fb162' }}
            >
              {t('Edit profile')}
            </motion.button>
            <motion.button
              onClick={() => this.handleShowMoreOptionClick('photographer')}
              style={{...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
              whileHover={{ color: '#1fb162' }}
            >
              {t('Request be photographer')}
            </motion.button>
            <motion.button
              onClick={() => this.handleShowMoreOptionClick('organizer')}
              style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600 }}
              whileHover={{ color: '#1fb162' }}
            >
              {t('Request be organizer')}
            </motion.button>
          </div>
        )}
      </Translation>
    );
  }

  getRoleColor = (role) => {
    const colors = { admin: '#FFC107', photographer: '#02c39a', organizer: '#8338ec'};

    return colors[role] || '#f3f3f3';
  }

  renderRoleLabel = () => {
    const { userInfo: { role }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (role === 'user') return null;

    const background = this.getRoleColor(role);
    return (
      <div style={styles.row}>
        <div style={{ ...styles.coloredLabel, background, color: getReadableColor({color: background}) }}>
          <Translation>
            {t => t(role)}
          </Translation>
        </div>
      </div>
    );
  }

  renderMainData = () => {
    const { userInfo: { alias }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.row}>
        <div style={styles.username}>{alias}</div>
      </div>
    );
  }

  renderSecondaryData = () => {
    const { userInfo: { name, surname }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.row}>
        <div style={styles.name}>{name} {surname}</div>
      </div>
    );
  }

  renderSocialMainData = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={Object.assign({}, styles.row, { margin: '10px 0 20px 0' })}>
        {this.renderFollowers()}
        {this.renderFollowing()}
      </div>
    );
  }
  
  renderFollowers = () => {
    const { userInfo: { followers }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.socialWrapper} onClick={this.renderFollowersDialog}>
        <span style={styles.socialValue}>{followers.length}</span>
        <span style={styles.socialLabel}>
          <Translation>
            {t => t('followers')}
          </Translation>
        </span>
      </div>
    );
  }

  renderFollowing = () => {
    const { userInfo: { following }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.socialWrapper} onClick={this.renderFollowingDialog}>
        <span style={styles.socialValue}>{following.length}</span>
        <span style={styles.socialLabel}>
          <Translation>
            {t => t('following')}
          </Translation>
        </span>
      </div>
    );
  }

  renderBio = () => {
    const { userInfo: { bio }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!bio) return null;

    return (
      <div style={styles.row}>
        <div style={styles.bio}>
          {bio}
        </div>
      </div>
    );
  }

  renderPhone = () => {
    const { userInfo: { phone }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!phone) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={`tel:{phone}`} style={styles.link}>
            <Icon icon="phone" size={24} style={{marginRight: 5}}/>
            {phone}
          </a>
        </div>
      </div>
    );
  }

  renderInstagram = () => {
    const { userInfo: { instagram }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!instagram) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
            <Icon icon="instagram" size={24} style={{ marginRight: 5 }}/>
            {instagram}
          </a>
        </div>
      </div>
    );
  }

  renderTwitter = () => {
    const { userInfo: { twitter }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!twitter) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={`https://www.twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
            <Icon icon="twitter" size={24} style={{ marginRight: 5 }}/>
            {twitter}
          </a>
        </div>
      </div>
    );
  }

  renderFacebook = () => {
    const { userInfo: { facebook }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!facebook) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={`https://www.facebook.com/${facebook}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
            <Icon icon="facebook" size={24} style={{ marginRight: 5 }}/>
            {facebook}
          </a>
        </div>
      </div>
    );
  }

  renderAchievements = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={Object.assign({}, styles.row, { margin: '10px 0 0 0' })}>
        <div style={styles.achievements}>
          <div style={styles.achievement}>
            <Achievement
              icon="awardTrophyEmpty"
              color="#FFC107"
              size={36}
              tooltip="Win an event"
            />
          </div>
          <div style={styles.achievement}>
            <Achievement
              icon="awardPedestal"
              color="#03A9F4"
              size={36}
              tooltip="Finish on the top three of an event"
            />
          </div>
          <div style={styles.achievement}>
            <Achievement
              icon="awardHeart"
              color="#673AB7"
              size={36}
              tooltip="Receive 1000 likes of your photos"
            />
          </div>
          <div style={styles.achievement}>
            <Achievement
              icon="awardMedal1"
              color="#19e267"
              size={36}
              tooltip="Participate in a event"
            />
          </div>
        </div>
      </div>
    );
  }

  renderUploadPictureButton = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <label htmlFor="upload">
        <motion.div
          style={styles.uploadButton}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <Icon size={32} icon="cameraAdd" color="#000" viewBox="16" />
        </motion.div>
        <input type="file" id="upload" style={{ display: 'none' }} onChange={this.handleUploadChange} />
      </label>
    );
  }

  handleCloseDialog = ({ reload } = {}) => {
    if (reload) {
      setTimeout(() => {
        AppDispatcher.dispatch({
          actionType: 'RELOAD_USER'
        });
      });
    }

    this.setState({
      dialog: null
    });
  }

  handleEditClick = () => {
    const { userInfo, isMobile } = this.props;
    const header = 'EDIT PROFILE';

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormUserProfile data={userInfo} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile}/>
      }
    });
  }

  handleFollow = () => {
    const { userInfo: { username } } = this.props;
    const url = `${baseUrl}/users/follow/${username}`;

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'RELOAD_USER'
      });
      AppDispatcher.dispatch({
        actionType: 'RELOAD_EXTERNAL_USER',
        userId: username
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleUnfollow = () => {
    const { userInfo: { username } } = this.props;
    const url = `${baseUrl}/users/unfollow/${username}`;

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'RELOAD_USER'
      });
      AppDispatcher.dispatch({
        actionType: 'RELOAD_EXTERNAL_USER',
        userId: username
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  handleUploadChange = (e) => {
    const { isMobile } = this.props;

    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      if (file) {
        const objectUrl = URL.createObjectURL(file);
        this.setState({
          image: objectUrl,
          dialog: {
            fullScreen: isMobile,
            contentStyle: isMobile ? { justifyContent: 'center' } : null,
            onClickOutside: this.handleCloseDialog,
            children: this.renderCropper
          }
        });
      }
    }
  } 

  handleUploadCancel = () => {
    this.setState({
      image: null,
      dialog: null,
    });
  }

  async handleUploadCropImage() {
    const { image, croppedAreaPixels, rotation } = this.state;
    const { userInfo: { username } } = this.props;

    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )

      if (croppedImage) {
        const url = `${baseUrl}/users/${username}/photo`;
        let newData = new FormData();
        newData.append('file', croppedImage);

        fetch(url, {
          method: "POST",
          body: newData,
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          }
        }).then(() => {
          this.handleCloseDialog({ reload: true });
        }).catch((err) => {
          console.log(err);
        });
      }
    } catch (e) {
      console.error(e)
    }
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }

  handleIncreaseZoom = () => {
    const { zoom } = this.state;

    if (zoom < 3) {
      this.setState({
        zoom: zoom + 0.1
      });
    }
  }

  handleDecreaseZoom = () => {
    const { zoom } = this.state;

    if (zoom > 1) {
      this.setState({
        zoom: zoom - 0.1
      });
    }
  }

  renderCropper = () => {
    const { image, crop, zoom, rotation, aspect } = this.state
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={{display: 'flex', flexDirection: 'column', background: '#fff', padding: 16}}>
        <div style={{position: 'relative', height: 300}}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            rotation={rotation}
            cropShape="round"
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
          <div style={{position: 'absolute', right: 5, bottom: 5, background: '#fff', borderRadius: 4}}>
            <motion.button
              onClick={this.handleIncreaseZoom}
              style={styles.actionButton}
            >
              <Icon size={24} icon="add" color="#000" />
            </motion.button>
            <motion.button
              onClick={this.handleDecreaseZoom}
              style={styles.actionButton}
            >
              <Icon size={24} icon="subtract" color="#000" />
            </motion.button>
          </div>
        </div>
        <div style={{marginTop: 10, display: 'flex', justifyContent: 'space-evenly'}}>
          <MotionButton 
            background="#ffffff" 
            onClick={this.handleUploadCancel}
          >
            <Translation>
              {t => t('Cancel')}
            </Translation>
          </MotionButton>
          <MotionButton
            onClick={this.handleUploadCropImage}
          >
            <Translation>
              {t => t('Submit')}
            </Translation>
          </MotionButton>
        </div>
      </div>
    )
  }

  render() {
    const { dialog } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        {dialog && <Dialog {...dialog} />}

        {this.renderPicture()}
        {this.renderUserData()}
      </div>
    );
  }
}

export default Header;
