import React from 'react';
import { baseUrl } from '../../config'; 
import { Translation } from 'react-i18next';

class InvolveScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null
    }
  }

  componentDidMount() {
    this.handleConfirmation();
  }

  handleConfirmation = () => {
    const { token, onFinish } = this.props;

    fetch(`${baseUrl}/events/involve/${token}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.status === 406) {
        throw new Error("You have already accepted this invitation");
      } else {
        if (response.ok) {
          return response.json();
        }
      }
    }).then((data) => {
      if (onFinish) {
        setTimeout(onFinish, 200);
      }
    }).catch((err) => {
      this.setState({
        error: err && err.message
      });

      if (onFinish) {
        setTimeout(onFinish, 2000);
      }
    });
  }

  getStyles() {
    return {
      wrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 30,
        position: 'relative',
        overflow: 'hidden'
      },
      background: {
        position: 'absolute',
        top: -5,
        left: -5,
        right: -5,
        bottom: -5,
        background: 'url(background.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: '#00ff76',
        backgroundBlendMode: 'darken',
        filter: 'blur(2px)',
        opacity: .2,
        pointerEvents: 'none'
      },
      title: {
        fontSize: 90,
        fontWeight: 600,
        margin: 0
      },
      subTitle: {
        fontSize: 36,
        margin: 0,
        opacity: .4,
        fontWeight: 100
      },
      logo: {
        width: 200,
        zIndex: 1
      }
    }
  }

  render() {
    const { error } = this.state;
    const styles = this.getStyles();

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <div style={styles.logo}>
              <img src="logo.svg" width="100%" alt="logo" />
            </div>
            <h2 style={styles.title}>
              {t('Accepting invitation')}
            </h2>
            <h3 style={styles.subTitle}>
              {t(error || "You'll be redirected when this process finish, please wait...")}
            </h3>
            <div style={styles.background} />
          </div>
        )}
      </Translation>
    );
  }
}

export default InvolveScreen;
