import React from 'react';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { Tooltip } from 'react-tippy';
import Icon from '../Icon/Icon';
import { motion } from 'framer-motion';

const OrganizationAvatar = ({ _id, name, thumb, web, size = 60, style, nameStyle, webStyle, onClick, onRemove, clickData, detailed, horizontal, disabled } = {}) => {
  const styles = {
    detailedWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      width: size + 40,
      ...(detailed && style),
    },
    horizontalWrapper: {
      ...(horizontal && style),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    wrapper: {
      flex: 'none',
      width: size,
      height: size,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontWeight: 600,
      color: '#000',
      boxSizing: 'border-box',
      cursor: 'pointer',
      position: 'relative',
      background: horizontal ? '#eaeaea': 'transparent',
      ...(!detailed && !horizontal && style),
    },
    picture: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      boxSizing: 'border-box',
      border: '1px solid gainsboro'
    },
    textWrapper: {
      marginTop: 5,
      display: 'flex',
      flexDirection: 'column',
      textAlign: !horizontal && 'center',
      width: !horizontal && '100%',
    },
    name: {
      fontWeight: 600,
      fontSize: detailed ? 10 : 16,
      color: '#000',
      WebkitLineClamp: detailed ? '2' : '1',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...nameStyle,
    },
    web: {
      fontSize: 14,
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      ...webStyle,
    },
    removeButtonWrapper: {
      position: 'absolute',
      top: -5,
      right: -5,
    },
    removeButton: {
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      background: '#f44336',
      borderRadius: '50%',
      width: 14,
      height: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
    },
  };

  const handleClick = () => {
    if (disabled) return null;
    
    if (onClick) {
      onClick();
    } else {
      AppDispatcher.dispatch({
        actionType: 'CHANGE_VIEW',
        view: 'organization',
        data: { _id, ...clickData },
      });
    }
  }

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    onRemove();
  }

  const renderRemoveButton = () => {
    if (!onRemove) return null;

    return (
      <div style={styles.removeButtonWrapper}>
        <motion.button
          type="button"
          onClick={handleRemoveClick}
          style={styles.removeButton}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <Icon size={10} icon="close" color="#000" />
        </motion.button>
      </div>
    );
  }

  if (detailed) {
    if (!thumb) {
      const initials = name[0];
      return (
        <div style={styles.detailedWrapper} onClick={handleClick}>
          <div style={{ ...styles.wrapper, background: '#efefef' }}>
            {initials}
          </div>
          <div style={styles.textWrapper}>
            <div style={styles.name}>{name}</div>
          </div>
        </div>
      );
    }

    return (
      <div style={styles.detailedWrapper} onClick={handleClick}>
        <div style={styles.wrapper}>
          <img src={thumb} alt="organization" style={styles.picture} />
        </div>
        <div style={styles.textWrapper}>
          <div style={styles.name}>{name}</div>
        </div>
      </div>
    );
  }

  if (horizontal) {
    if (!thumb) {
      const initials = name[0];
      return (
        <div style={styles.horizontalWrapper} onClick={handleClick}>
          <div style={{...styles.wrapper, background: '#efefef'}}>
            {initials}
          </div>
          <div style={{...styles.textWrapper, marginTop: 0, marginLeft: 5}}>
            <div style={styles.name}>{name}</div>
            {web && <div style={styles.web}>{web}</div>}
          </div>
        </div>
      );
    }

    return (
      <div style={styles.horizontalWrapper} onClick={handleClick}>
        <div style={styles.wrapper}>
          <img src={thumb} alt="organization" style={styles.picture} />
        </div>
        <div style={{...styles.textWrapper, marginTop: 0, marginLeft: 5}}>
          <div style={styles.name}>{name}</div>
          {web && <div style={styles.web}>{web}</div>}
        </div>
      </div>
    );
  }

  const title = name;
  if (!thumb) {
    const initials = name[0];
    return (
      <Tooltip
        title={title}
        position={'bottom'}
        trigger="mouseenter"
        theme="light"
        distance={15}
      >
        <div style={{ ...styles.wrapper, background: '#efefef' }} onClick={handleClick}>
          {initials}
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={title}
      position={'bottom'}
      trigger="mouseenter"
      theme="light"
      distance={15}
    >
      <div style={styles.wrapper} onClick={handleClick}>
        {renderRemoveButton()}
        <img src={thumb} alt="organization" style={styles.picture} />
      </div>
    </Tooltip>
  );
}

export default OrganizationAvatar
