import React from 'react';
import { motion } from 'framer-motion';
import Icon from '../../Icon/Icon';
import AppDispatcher from '../../AppDispatcher/AppDispatcher';
import { Translation } from 'react-i18next';

class NewPicturesHeader extends React.Component {
  getStyles() {
    return {
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        height: 54,
        background: '#fff',
        color: '#000',
        boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
        padding: 16,
        boxSizing: 'border-box'
      },
      gap: {
        flex: 1
      },
      infoWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      },
      info: {
        display: 'flex'
      },
      author: {
        fontWeight: 600
      },
      date: {
        opacity: 1,
        marginRight: 20
      },
      buttonValue: {
        marginRight: 3
      },
      flatButton: {
        padding: '5px 20px',
        fontWeight: 600,
        fontSize: 14,
        boxShadow: 'none',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        color: '#00d663'
      }
    }
  }

  handleTagClick = () => {
    const { data } = this.props;
    
    AppDispatcher.dispatch({
      actionType: 'SHOW_TAG_PICTURE_OVERLAY',
      data
    });
  }

  render() {
    const { handleClose } = this.props;
    const styles = this.getStyles();

    return (
      <div style={styles.wrapper}>
        <div style={styles.infoWrapper}>
          <Translation>
            {t => t('Tag your pictures')}
          </Translation>
        </div>
        <motion.button
          onClick={this.handleTagClick}
          style={Object.assign({}, styles.flatButton, { padding: '5px 10px 5px 0', background: 'transparent', color: '#000' })}
          whileHover={{ scale: 1.5 }}
          whileTap={{ scale: 0.8 }}
        >
          <Icon size={22} icon="tag" color="#000" />
        </motion.button>
        <motion.button
          onClick={handleClose}
          style={Object.assign({}, styles.flatButton, { padding: '5px 0 5px 10px', background: 'transparent', borderLeft: '1px solid rgba(0, 0, 0, 0.14)' })}
          whileHover={{ color: '#1fb162' }}
        >
          <Icon size={24} icon="close" color="#000" />
        </motion.button>
      </div>
    );
  }
}

export default NewPicturesHeader;
