import React from 'react';
import Icon from '../../Icon/Icon';
import { motion } from 'framer-motion';
import { baseUrl } from '../../../config'; 
import Dialog from '../../Dialog/Dialog';
import AppDispatcher from '../../AppDispatcher/AppDispatcher';
import MotionButton from '../../Button/MotionButton';
import FormOrganization from '../../Form/FormOrganization';
import getCroppedImg from '../../../utils/crop';
import Cropper from 'react-easy-crop';
import { Tooltip } from 'react-tippy';
import { Translation } from 'react-i18next';
import UserAvatar from '../../UserAvatar/UserAvatar';

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      dialog: null,
      showMoreOverlay: false,
      showMoreFeedback: null,
      image: null,
      crop: { x: 0, y: 0 },
      croppedAreaPixels: null,
      zoom: 1,
      aspect: 1 / 1,
      rotation: 0,
    };
    this.listRef = React.createRef();
    this.handleUploadCropImage = this.handleUploadCropImage.bind(this);
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          width: 280,
          wordBreak: 'break-word'
        },
        pictureWrapper: {
          width: 250,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginBottom: 30
        },
        emptyPicture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          border: '6px solid #000',
          width: 200,
          height: 200,
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 50
        },
        uploadButton: {
          padding: 0,
          borderRadius: '50%',
          boxShadow: 'none',
          border: '3px solid #fff',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'absolute',
          bottom: 10,
          right: 30
        },
        contentWrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        },
        buttonsWrapper: {
          marginBottom: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        actionButton: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        actionLabelButton: {
          padding: '5px 30px',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          fontSize: 14,
          fontWeight: 600,
          borderRadius: 4
        },
        main: {
          flex: 1,
          fontWeight: 600,
          fontSize: 24,
          color: '#1f1f1f',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          marginBottom: 10,
        },
        group: {
          textTransform: 'uppercase',
        },
        username: {
          fontWeight: 600,
          fontSize: 22,
          color: '#1f1f1f'
        },
        coloredLabel: {
          boxSizing: 'border-box',
          padding: '4px 10px',
          fontSize: 14,
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: 1,
          background: '#FFC107',
          color: '#000',
          borderRadius: 4,
        },
        name: {
          fontWeight: 400,
          fontSize: 20,
          color: '#818c91',
        },
        socialWrapper: {
          display: 'flex',
          alignItems: 'baseline',
          marginRight: 25,
          cursor: 'pointer',
        },
        socialValue: {
          color: '#2a3033',
          fontWeight: 600,
          fontSize: 24,
          marginRight: 5
        },
        socialLabel: {
          color: '#818c8f',
          fontSize: 18
        },
        bio: {
          color: '#657279',
          fontSize: 20,
        },
        achievements: {
          display: 'flex'
        },
        achievement: {
          marginRight: 10
        },
        photo: {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        },
        picture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          width: 200,
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        overlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          padding: 60,
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex',
          flexDirection: 'column'
        },
        overlayTitle: {
          background: '#00d663',
          fontWeight: 600,
          flex: 'none',
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative'
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          boxSizing: 'border-box',
          background: '#fff',
          borderRadius: 5,
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        },
        showMoreOverlay: {
          position: 'absolute',
          top: 100,
          right: 10,
          width: 200,
          background: '#fff',
          boxShadow: '0 0 13px 1px rgba(0, 0, 0, 0.10)',
          borderRadius: 4,
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
        },
        flatButton: {
          padding: '5px 20px',
          fontWeight: 600,
          fontSize: 14,
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          color: '#00d663',
          alignItems: 'center'
        },
        button: {
          background: '#00d663',
          color: '#000',
          padding: '5px 30px',
          fontWeight: 600,
          textTransform: 'uppercase',
          fontSize: 14,
          borderRadius: 4,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          marginTop: 10
        },
        organizationWrapper: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 15,
          color: '#969696',
          fontSize: 14,
          background: '#fff',
          borderRadius: 8,
        },
        link: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: '#000',
          fontSize: 16,
        }
      },
      mobile: {
        wrapper: {
          flex: 'none',
          display: 'flex',
          padding: 20,
          flexDirection: 'column'
        },
        pictureWrapper: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginBottom: 40
        },
        emptyPicture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          border: '6px solid #000',
          width: 150,
          height: 150,
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 50
        },
        uploadButton: {
          padding: 0,
          borderRadius: '50%',
          boxShadow: 'none',
          border: '3px solid #fff',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'absolute',
          bottom: -5,
          right: 110
        },
        contentWrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        },
        buttonsWrapper: {
          marginBottom: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        actionButton: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          width: 45,
          height: 45,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        actionLabelButton: {
          padding: '5px 30px',
          boxShadow: 'none',
          background: '#fff',
          cursor: 'pointer',
          outline: 'none',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          fontSize: 14,
          fontWeight: 600,
          borderRadius: 4
        },
        main: {
          flex: 1,
          fontWeight: 600,
          fontSize: 24,
          color: '#1f1f1f',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 10,
        },
        group: {
          textTransform: 'uppercase',
        
        },
        username: {
          fontWeight: 600,
          fontSize: 18,
          color: '#1f1f1f'
        },
        coloredLabel: {
          boxSizing: 'border-box',
          padding: '2px 10px',
          fontSize: 14,
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: 1,
          background: '#FFC107',
          color: '#000',
          borderRadius: 4,
        },
        name: {
          fontWeight: 400,
          fontSize: 16,
          color: '#818c91'
        },
        socialWrapper: {
          display: 'flex',
          alignItems: 'baseline',
          marginRight: 25,
          cursor: 'pointer'
        },
        socialValue: {
          color: '#2a3033',
          fontWeight: 600,
          fontSize: 20,
          marginRight: 5
        },
        socialLabel: {
          color: '#818c8f',
          fontSize: 16
        },
        bio: {
          color: '#657279',
          fontSize: 14,
          display: '-webkit-box',
          WebkitLineClamp: '3',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        achievements: {
          display: 'flex'
        },
        achievement: {
          marginRight: 10
        },
        photo: {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        },
        picture: {
          overflow: 'hidden',
          borderRadius: '50%',
          boxSizing: 'border-box',
          width: 150,
          height: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        overlayWrapper: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(255,255,255,.3)',
          display: 'flex'
        },
        overlayTitle: {
          background: '#00d663',
          fontWeight: 600,
          flex: 'none',
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative'
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          width: '95%',
          boxSizing: 'border-box',
          background: '#fff',
          borderRadius: 5,
          boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        },
        showMoreOverlay: {
          position: 'absolute',
          top: 100,
          right: 10,
          left: 10,
          background: '#fff',
          boxShadow: '0 0 13px 1px rgba(0, 0, 0, 0.10)',
          borderRadius: 4,
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
          zIndex: 100
        },
        flatButton: {
          padding: '5px 20px',
          fontWeight: 600,
          fontSize: 14,
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          color: '#00d663',
          alignItems: 'center'
        },
        link: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: '#000',
          fontSize: 16,
        }
      }
    }
  }

  handleUploadChange = (e) => {
    const { isMobile } = this.props;

    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      if (file) {
        const objectUrl = URL.createObjectURL(file);
        this.setState({
          image: objectUrl,
          dialog: {
            onClickOutside: this.handleCloseDialog,
            fullScreen: isMobile,
            contentStyle: isMobile ? { justifyContent: 'center' } : null,
            children: this.renderCropper
          }
        });
      }
    }
  }

  handleUploadCancel = () => {
    this.setState({
      image: null,
      dialog: null,
    });
  }

  async handleUploadCropImage() {
    const { image, croppedAreaPixels, rotation } = this.state;
    const { data: { _id } } = this.props;

    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )

      if (croppedImage) {
        const url = `${baseUrl}/organizers/${_id}/photo`;
        let newData = new FormData();
        newData.append('file', croppedImage);

        fetch(url, {
          method: "POST",
          body: newData,
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          }
        }).then(() => {
          this.handleCloseDialog({ reload: true });
        }).catch((err) => {
          console.log(err);
        });
      }
    } catch (e) {
      console.error(e)
    }
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }

  handleIncreaseZoom = () => {
    const { zoom } = this.state;

    if (zoom < 3) {
      this.setState({
        zoom: zoom + 0.1
      });
    }
  }

  handleDecreaseZoom = () => {
    const { zoom } = this.state;

    if (zoom > 1) {
      this.setState({
        zoom: zoom - 0.1
      });
    }
  }

  renderCropper = () => {
    const { image, crop, zoom, rotation, aspect } = this.state
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', background: '#fff', padding: 16 }}>
        <div style={{ position: 'relative', height: 300 }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            rotation={rotation}
            cropShape="round"
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
          <div style={{ position: 'absolute', right: 5, bottom: 5, background: '#fff', borderRadius: 4 }}>
            <motion.button
              onClick={this.handleIncreaseZoom}
              style={styles.actionButton}
            >
              <Icon size={24} icon="add" color="#000" />
            </motion.button>
            <motion.button
              onClick={this.handleDecreaseZoom}
              style={styles.actionButton}
            >
              <Icon size={24} icon="subtract" color="#000" />
            </motion.button>
          </div>
        </div>
        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-evenly' }}>
          <MotionButton
            background="#ffffff"
            onClick={this.handleUploadCancel}
          >
            <Translation>
              {t => t('Cancel')}
            </Translation>
          </MotionButton>
          <MotionButton
            onClick={this.handleUploadCropImage}
          >
            <Translation>
              {t => t('Submit')}
            </Translation>
          </MotionButton>
        </div>
      </div>
    )
  }

  renderUploadPictureButton = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <label htmlFor="uploadPicture">
        <motion.div
          style={styles.uploadButton}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <Icon size={32} icon="cameraAdd" color="#000" viewBox="16" />
        </motion.div>
        <input type="file" id="uploadPicture" style={{ display: 'none' }} onChange={this.handleUploadChange} />
      </label>
    );
  }

  renderPicture = () => {
    const { data: { photo }, isExternal, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.pictureWrapper}>
        {photo ?
          <div style={styles.picture}>
            <img style={styles.photo} src={photo} alt="name" />
          </div>
          :
          <div style={styles.emptyPicture}>
            <Icon icon="user" color="#818c91" size={250} />
          </div>
        }
        {!isExternal && this.renderUploadPictureButton()}
      </div>
    );
  }

  renderData = () => {
    const { showMoreOverlay } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.contentWrapper}>
        {this.renderName()}
        {this.renderActionButtons()}
        {this.renderWeb()}
        {this.renderEmail()}
        {this.renderInstagram()}
        {this.renderTwitter()}
        {this.renderFacebook()}
        {showMoreOverlay && this.renderShowMoreOverlay({ isMobile })}
      </div>
    );
  }

  renderHintTooltip = () => {
    const { hint, onRemoveHint, isMobile } = this.props;
    
    if (hint === 'events') {
      return (
        <div style={{ width: !isMobile ? 250 : 'auto', color: '#000', padding: 16, background: 'linear-gradient(45deg, #00d662, #24d8ea)', borderRadius: 4 }}>
          <Translation>
            {t => t("You haven't created any events yet, to start click here and then click")}
          </Translation>
          &nbsp;
          <strong>
            <Translation>
              {t => t("Create event")}
            </Translation>
          </strong>!<br /><br />
          <strong onClick={onRemoveHint} style={{ cursor: 'pointer' }}>
            <Translation>
              {t => t("GOT IT")}
            </Translation>
          </strong>
        </div>
      );
    }

    if (hint === 'members') {
      return (
        <div style={{ width: !isMobile ? 250 : 'auto', color: '#000', padding: 16, background: 'linear-gradient(45deg, #00d662, #24d8ea)', borderRadius: 4 }}>
          <Translation>
            {t => t("You haven't added any members yet, to start click here and then click")}
          </Translation>
          &nbsp;
          <strong> 
            <Translation>
              {t => t("Add member")}
            </Translation>
          </strong>!<br /><br />
          <strong onClick={onRemoveHint} style={{ cursor: 'pointer' }}>
            <Translation>
              {t => t("GOT IT")}
            </Translation>
          </strong>
        </div>
      );
    }

    if (hint === 'full') {
      return (
        <div style={{ width: !isMobile ? 250 : 'auto', color: '#000', padding: 16, background: 'linear-gradient(45deg, #00d662, #24d8ea)', borderRadius: 4}}>
          <Translation>
            {t => t("Welcome to your organization!")}
          </Translation>
          <br/><br/>
          <Translation>{t => t("You can")}</Translation>
          &nbsp;
          <strong>
            <Translation>{t => t("create events")}</Translation>
          </strong>
          &nbsp;
          <Translation>
            {t => t("or")}
          </Translation>
          &nbsp;
          <strong>
            <Translation>
              {t => t("add members")}
            </Translation>
          </strong>
          &nbsp;
          <Translation>
            {t => t("clicking on this button!")}
          </Translation>
          <br/><br/>
          <strong onClick={onRemoveHint} style={{ cursor: 'pointer' }}>
            <Translation>
              {t => t("GOT IT")}
            </Translation>
          </strong>
        </div>
      );
    }

    return null;
  }

  renderActionButtons = () => {
    const { isExternal, isMobile, hint } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (isExternal) {
      return null;
    }

    return (
      <div style={styles.buttonsWrapper}>
        <Tooltip
          html={this.renderHintTooltip()}
          interactive
          open={!!hint}
          position="top"
          theme="reex"
          arrow={true}
          distance={5}
          >
          <motion.button
            onClick={this.handleShowMoreClick}
            style={styles.actionButton}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
            >
            <Icon size={24} icon="showMore" color="#000" />
          </motion.button>
        </Tooltip>
      </div>
    );
  }
  
  handleShowMoreClick = () => {
    const { onRemoveHint } = this.props;
    const { showMoreOverlay } = this.state;

    if (onRemoveHint) {
      onRemoveHint();
    }

    this.setState({
      showMoreOverlay: !showMoreOverlay,
      showMoreFeedback: null,
    });

    if (showMoreOverlay) {
      this.removeListeners();
    } else {
      this.addListeners();
    }
  }

  removeListeners = () => {
    document.removeEventListener('mouseup', this.handleListener);
  };

  addListeners = () => {
    document.addEventListener('mouseup', this.handleListener);
  };

  handleListener = e => {
    if (this.listRef.current && !this.listRef.current.contains(e.target)) {
      this.handleShowMoreClick();
    }
  };

  handleCloseDialog = ({ reload } = {}) => {
    if (reload) {
      setTimeout(() => {
        AppDispatcher.dispatch({
          actionType: 'RELOAD_ORGANIZATION'
        });
      });
    }

    this.setState({
      dialog: null
    });
  }

  handleEditClick = () => {
    const { data, isMobile } = this.props;
    const header = 'EDIT ORGANIZATION';

    this.setState({
      dialog: {
        fullScreen: true,
        onClickOutside: this.handleCloseDialog,
        children: () => <FormOrganization data={data} header={header} handleClose={this.handleCloseDialog} isMobile={isMobile}/>
      }
    });
  }

  handleShowMoreOptionClick = (type) => {
    const { onAddEvent, onAddOwner, onAddPosition, onAddMember, onCreateMember, onAddMembership } = this.props;

    if (type === 'edit') {
      this.handleEditClick();
    } else if (type === 'add' && onAddEvent) {
      onAddEvent();
    } else if (type === 'addOwner' && onAddOwner) {
      onAddOwner();
    } else if (type === 'addPosition' && onAddPosition) {
      onAddPosition();
    } else if (type === 'addMember' && onAddMember) {
      onAddMember();
    } else if (type === 'createMember' && onCreateMember) {
      onCreateMember();
    } else if (type === 'addOrganization' && onAddMembership) {
      onAddMembership();
    }
  }

  renderShowMoreOverlay = ({ isMobile }) => {
    const { showMoreFeedback } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (showMoreFeedback) {
      return (
        <div style={{ ...styles.showMoreOverlay, textTransform: 'uppercase' }} ref={this.listRef}>
          <Translation>
            {t => t(showMoreFeedback)}
          </Translation>
        </div>
      );
    }

    return (
      <div style={styles.showMoreOverlay} ref={this.listRef}>
        <motion.button
          onClick={() => this.handleShowMoreOptionClick('edit')}
          style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
          whileHover={{ color: '#1fb162' }}
        >
          <Translation>
            {t => t('Edit organization')}
          </Translation>
        </motion.button>
        <motion.button
          onClick={() => this.handleShowMoreOptionClick('add')}
          style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
          whileHover={{ color: '#1fb162' }}
        >
          <Translation>
            {t => t('Create event')}
          </Translation>
        </motion.button>
        <motion.button
          onClick={() => this.handleShowMoreOptionClick('addPosition')}
          style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
          whileHover={{ color: '#1fb162' }}
        >
          <Translation>
            {t => t('Create position')}
          </Translation>
        </motion.button>
        <motion.button
          onClick={() => this.handleShowMoreOptionClick('createMember')}
          style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
          whileHover={{ color: '#1fb162' }}
        >
          <Translation>
            {t => t('Create member')}
          </Translation>
        </motion.button>
        <motion.button
          onClick={() => this.handleShowMoreOptionClick('addMember')}
          style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
          whileHover={{ color: '#1fb162' }}
        >
          <Translation>
            {t => t('Add member')}
          </Translation>
        </motion.button>
        <motion.button
          onClick={() => this.handleShowMoreOptionClick('addOrganization')}
          style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600, marginBottom: 10 }}
          whileHover={{ color: '#1fb162' }}
        >
          <Translation>
            {t => t('Add organization')}
          </Translation>
        </motion.button>
        <motion.button
          onClick={() => this.handleShowMoreOptionClick('addOwner')}
          style={{ ...styles.flatButton, padding: 0, background: 'transparent', color: '#000', fontWeight: 600 }}
          whileHover={{ color: '#1fb162' }}
        >
          <Translation>
            {t => t('Add owner')}
          </Translation>
        </motion.button>
      </div>
    );
  }

  renderWeb = () => {
    const { data: { web }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!web) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={web} target="_blank" rel="noopener noreferrer" style={styles.link}>
            <Icon icon="web" size={24} style={{ marginRight: 5 }} />
            {web}
          </a>
        </div>
      </div>
    );
  }

  renderEmail = () => {
    const { data: { email }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!email) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
            <Icon icon="envelope" size={24} style={{ marginRight: 5 }} />
            {email}
          </a>
        </div>
      </div>
    );
  }

  renderInstagram = () => {
    const { data: { instagram }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!instagram) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
            <Icon icon="instagram" size={24} style={{ marginRight: 5 }}/>
            {instagram}
          </a>
        </div>
      </div>
    );
  }

  renderTwitter = () => {
    const { data: { twitter }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!twitter) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={`https://www.twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
            <Icon icon="twitter" size={24} style={{ marginRight: 5 }}/>
            {twitter}
          </a>
        </div>
      </div>
    );
  }

  renderFacebook = () => {
    const { data: { facebook }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!facebook) return null;

    return (
      <div style={styles.row}>
        <div style={styles.name}>
          <a href={`https://www.facebook.com/${facebook}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
            <Icon icon="facebook" size={24} style={{ marginRight: 5 }}/>
            {facebook}
          </a>
        </div>
      </div>
    );
  }

  renderName = () => {
    const { data: { name }, isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.row}>
        <div style={styles.main}>{name}</div>
      </div>
    );
  }

  handleSwitchUser = () => {
    const { fromUserProfile: { username } } = this.props;

    const localUsername = JSON.parse(localStorage.getItem('userInfo')).username;
    if (username === localUsername) {
      AppDispatcher.dispatch({
        actionType: 'CHANGE_VIEW',
        view: 'profile'
      });
    } else {
      AppDispatcher.dispatch({
        actionType: 'GO_TO_USER_PROFILE',
        userId: username
      });
    }
  }

  renderBackToProfileBar = () => {
    const { isMobile, fromUserProfile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;
    if (!fromUserProfile) return null;

    return (
      <div onClick={this.handleSwitchUser} style={{ cursor: 'pointer', alignItems: 'center', padding: 10, background: 'linear-gradient(45deg, rgb(0, 214, 98), rgb(36, 216, 234))', display: 'flex', borderRadius: 8, marginBottom: 20}}>
        <UserAvatar {...fromUserProfile} style={{ flex: 1, marginRight: 10 }} usernameStyle={{fontSize: 12}} nameStyle={{fontSize: 10}}/>
        <Icon size={24} icon="accountSwitch" color="#000" />
        <div style={{fontWeight: 600, marginLeft: 10}}>
          <Translation>
            {t => t('Switch to User')}
          </Translation>
        </div>
      </div>
    );
  }

  render() {
    const { dialog } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        {dialog && <Dialog {...dialog} />}
        {this.renderBackToProfileBar()}
        {this.renderPicture()}
        {this.renderData()}
      </div>
    );
  }
}

export default Header;
