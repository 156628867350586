import React, { useState } from 'react';
import Icon from '../../Icon/Icon';
import AppDispatcher from '../../AppDispatcher/AppDispatcher';
import { motion } from 'framer-motion';

export const viewCSS = () => ({
  lineHeight: 0,
  position: 'relative',
  textAlign: 'center',
});

const View = (props) => {
  const { data, src, onFullScreen } = props;
  if (!data) return null;
  const [showHeart, setShowHeart] = useState(false);

  const overlay = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: showHeart ? 0.7 : 0,
    pointerEvents: 'none',
    display: 'flex',
    transition: 'opacity 0.5s ease'
  };

  const image = {
    objectFit: 'cover',
    height: 'auto',
    maxHeight: 'calc(100vh - 54px)',
    maxWidth: '100%',
  };

  const wrapper = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative'
  };

  const buttonWrapper = {
    position: 'absolute',
    top: 5,
    right: 5,
    borderRadius: 4,
    background: 'rgba(0,0,0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const button = {
    fontWeight: 600,
    fontSize: 14,
    boxShadow: 'none',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    color: '#000', 
    padding: 5,
    background: 'transparent',
  }

  const handleLike = () => {
    AppDispatcher.dispatch({
      actionType: 'LIKE_PICTURE',
      data
    });

    setShowHeart(true);
    setTimeout(() => {
      setShowHeart(false);
    }, 1000);
  }

  return (
    <div style={wrapper} onDoubleClick={handleLike}>
      <div style={buttonWrapper}>
        <motion.button
          onClick={onFullScreen}
          style={button}
        >
          <Icon size={22} icon="expand" />
        </motion.button>
      </div>
      <img
        style={image}
        alt={data._id}
        src={src}
        loading="lazy"
      />
      <div style={overlay}>
        <Icon icon="heart" color="#fff" size={156} />
      </div>
    </div>
  );
};

export default View;