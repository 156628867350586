import React, { useState, useEffect } from 'react'
import moment from 'moment';
import Icon from '../Icon/Icon';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import UserAvatar from '../UserAvatar/UserAvatar';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import { Translation } from 'react-i18next';
import { motion } from 'framer-motion';
import { baseUrl } from '../../config';
import handleResponse from '../../utils/handleResponse';

const modes = {
  gold: { color: '#FFD54F' },
  silver: { color: '#D7D7D7' },
  bronze: { color: '#AD8A56' },
  participant: {color: 'transparent'}
};

//time to wait for the second click
const CLICK_DELAY = 250;

const EventPictureMobileBox = (data) => {
  if (!data || !data._id) return null;
  const { _id, thumb, userId, alias, likes, users, views, visits, comments, uploadedAt, style, isSelecting, organizerSize = 25, organizer, hideAuthor, handleView } = data;
  const username = JSON.parse(localStorage.getItem('userInfo')).username;
  const hasView = views && views.find(v => v.username === username);
  const [isSelected, setIsSelected] = useState(false);
  const [timer, setTimer] = useState(null);
  const [showHeart, setShowHeart] = useState(false);
  const checkIfLiked = () => {
    const username = JSON.parse(localStorage.getItem('userInfo')).username;

    return likes && likes.find(u => u.username === username);
  }
  const isLiked = checkIfLiked();
  const [liked, setLiked] = useState(isLiked);
  const [likeCount, setLikeCount] = useState(likes.length);
  
  useEffect(() => {
    setLikeCount(likes.length);
    setLiked(isLiked);
  }, [likes, isLiked])
  
  const styles = {
    wrapper: {
      display: 'flex',
      padding: 2.5,
      boxSizing: 'border-box',
      ...style
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 5,
      border: '1px solid #efefef'
    },
    header: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      height: 40
    },
    imageWrapper: {
      cursor: 'pointer',
      position: 'relative',
      display: 'flex'
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
      position: 'relative',
      zIndex: 1
    },
    placeholder: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: '#e6e4e4',
      zIndex: 1
    },
    overlaySelection: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: 'rgba(0,0,0,.5)',
      zIndex: 5
    },
    checkbox: {
      position: 'absolute',
      top: 10,
      left: 10
    },
    bottom: {
      padding: 5,
      flex: 'none',
      display: 'flex',
      flexDirection: 'column'
    },
    stats: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 12
    },
    stat: {
      flex: 'none',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    date: {
      opacity: 0.5,
      fontSize: 10
    },
    badge: {
      padding: '2px 5px',
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 3,
      background: '#F44336',
      color: '#fff',
      display: 'flex'
    },
    flex: {
      flex: 1
    },
    sponsorWrapper: {
      display: 'flex',
      textAlign: 'right',
      flexDirection: 'column',
    },
    sponsorLabel: {
      flex: 'none',
      fontSize: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    sponsorName: {
      fontWeight: 600,
      fontSize: 10,
      flex: 1,
    },
    sponsorMedal: {
      width: 7,
      height: 7,
      borderRadius: '50%',
      marginRight: 5,
      background: organizer && modes[organizer.mode].color,
      display: 'inline-block',
    },
    flatButton: {
      padding: 0,
      fontWeight: 600,
      fontSize: 14,
      boxShadow: 'none',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      background: 'transparent', 
      color: '#000'
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      alignItems: 'center',
      justifyContent: 'center',
      opacity: showHeart ? 0.7 : 0,
      pointerEvents: 'none',
      display: 'flex',
      transition: 'opacity 0.5s ease'
    }
  }

  const handleClick = () => {
    if (!timer) {
      setTimer(setTimeout(() => {
        AppDispatcher.dispatch({
          actionType: 'SHOW_PICTURE',
          data
        });
        setTimer(null);
      }, CLICK_DELAY));
    } else {
      setTimer(null);
      clearTimeout(timer);
      if (!isLiked) {
        handleLike();
      }
      handleShowHeart();
    }
  }

  const handleSelect = () => {
    setIsSelected(!isSelected);
    AppDispatcher.dispatch({
      actionType: 'SELECT_PICTURE',
      data
    });
  }

  const handleViewClick = (e) => {
    e.stopPropagation();
    
    handleView({_id, hasView});
  }

  const handleLike = () => {
    const url = `${baseUrl}/images/${_id}/like`;
    const action = liked ? 'subtract' : 'add';

    setLikeCount(current => action === 'add' ? current + 1 : current - 1);
    setLiked(current => !current);

    fetch(url, {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }).then(response => {
      return handleResponse(response);
    }).then(([ok, status, data]) => {
      if (!ok) {
        throw new Error(data || 'Oops! Something went wrong');
      }

      AppDispatcher.dispatch({
        actionType: 'UPDATE_EVENT_PICTURE'
      });
    }).catch((err) => {
      setLiked(current => !current);
      setLikeCount(current => action === 'add' ? current - 1 : current + 1);
      console.log(err);
    });
  }

  const handleShowHeart = () => {
    setShowHeart(true);
    setTimeout(() => {
      setShowHeart(false);
    }, 1000);
  }

  const getUrl = () => {
    return `https://moments.fra1.digitaloceanspaces.com/thumb/${_id}.jpeg`;
  }

  return (
    <Translation>
      {t => (
        <div style={styles.wrapper}>
          <div style={styles.card}>
            <div style={styles.header}>
              {!hideAuthor ? <UserAvatar thumb={thumb} username={userId} alias={alias} horizontal={true} size={30} style={{ padding: 5, flex: 1 }} /> : null}
              <div style={styles.flex}/>
              {organizer && organizer.mode !== 'participant' &&
                <div style={styles.sponsorWrapper}>
                  <div style={styles.sponsorLabel}>
                    <span style={styles.sponsorMedal}/>
                    {t('Photo Sponsor')}
                  </div>
                  <div style={styles.sponsorName}>{organizer.name}</div>
                </div>
              }
              {organizer && <OrganizationAvatar {...organizer} size={organizerSize} style={{ padding: 5, boxSizing: 'content-box' }} />}
            </div>
            <div style={styles.imageWrapper} onClick={isSelecting ? handleSelect : handleClick}>
              {isSelecting &&
                <div style={styles.overlaySelection}>
                  <div style={styles.checkbox}><Icon size={32} icon={isSelected ? 'checkboxSolid' : 'checkboxEmpty'} color={isSelected ? '#00d663' : '#fff'} /></div>
                </div>
              }
              <div style={styles.overlay}>
                <Icon icon="heart" color="#fff" size={64} />
              </div>
              {!hasView && 
                <div style={styles.badge}>
                  <span style={{borderRight: '1px solid rgba(255,255,255, 0.3)', paddingRight: 5, marginRight: 5 }}>
                    {t('new')}
                  </span>
                  <motion.button
                    onClick={handleViewClick}
                    style={styles.flatButton}
                    whileHover={{ scale: 1.5 }}
                    whileTap={{ scale: 0.8 }}
                  >
                    <Icon size={14} icon="eyeEmpty" color="#fff" />
                  </motion.button>
                </div>
              }
              <div style={styles.background} />
              <img src={getUrl()} style={styles.image} loading="lazy" alt={_id} />
            </div>
            <div style={styles.bottom}>
              <div style={styles.stats}>
                <motion.button
                  onClick={handleLike}
                  style={styles.flatButton}
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <div style={{ ...styles.stat, color: liked ? '#f44336' : '#000'}}>
                    <Icon size={18} icon={liked ? "heart" : "heartEmpty"} color={liked ? '#f44336' : '#000'} />
                    {likeCount}
                  </div>
                </motion.button>
                <div style={styles.stat}>
                  <Icon size={18} icon="userEmpty" color={'#000'} />
                  {users.length}
                </div>
                <div style={styles.stat}>
                  <Icon size={18} icon="eyeEmpty" color={'#000'} />
                  {visits}
                </div>
                <div style={styles.stat}>
                  <Icon size={18} icon="chatEmpty" color={'#000'} />
                  {comments.length}
                </div>
              </div>
              <div style={styles.date}>
                {moment(uploadedAt).fromNow()}
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
}

export default EventPictureMobileBox;
