/**
 * Convierte un numero a una string corta
 * @param {number} number
 * @param {number} decimalPoint
 * @returns {string}
 */
const formatNumberShortcuts = (number) => {
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return number;
};

export { formatNumberShortcuts };