import { EventEmitter } from 'events';
import AppDispatcher from '../components/AppDispatcher/AppDispatcher.js';

export default class BaseStore extends EventEmitter {
  subscribe(actionSubscribe) {
    this._dispatchToken = AppDispatcher.register(actionSubscribe());
  }

  get dispatchToken() {
    return this._dispatchToken;
  }

  unsubscribe() {
    AppDispatcher.unregister(this._dispatchToken);
  }

  emitChange() {
    this.emit('CHANGE');
  }

  addChangeListener(cb) {
    this.on('CHANGE', cb);
  }

  removeChangeListener(cb) {
    this.removeListener('CHANGE', cb);
  }
}
