import React, { useState } from 'react';
import UserAvatar from '../UserAvatar/UserAvatar';
import moment from 'moment';
import Icon from '../Icon/Icon';

const Comment = ({ _id, username, alias, thumb, comment, dateTime, isMobile, onRemove }) => {
  const [isOver, setIsOver] = useState(false);

  const handleMouseEnter = () => {
    setIsOver(true);
  }

  const handleMouseOut = () => {
    setIsOver(false);
  }

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const isOwner = userInfo.username === username;
  const styles = {
    wrapper: {
      flex: 'none',
      width: '100%',
      display: 'flex',
      marginBottom: 20,
    },
    avatarWrapper: {
      flex: 'none',
      marginRight: 10
    },
    textWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    contentWrapper: {
      flex: 1,
    },
    buttonsWrapper: {
      marginLeft: 5,
      padding: 5,
      flex: 'none',
      display: 'flex'
    },
    button: {
      cursor: 'pointer'
    },
    username: {
      fontWeight: 600,
      marginRight: 5
    },
    date: {
      marginTop: 5,
      opacity: 0.5,
      fontSize: 12,
    }
  };

  const handleRemove = () => {
    if (!onRemove) return null;

    onRemove({ commentId: _id });
  }

  const renderUserButtons = () => {
    return (
      <div style={styles.buttonsWrapper}>
        <div style={styles.button} onClick={handleRemove}>
          <Icon size={14} icon="close" color="#f44336" />
        </div>
      </div>
    );
  }

  const renderDate = () => {
    if (moment(dateTime).isSame(moment(), 'day')) {
      return (
        <div style={styles.date}>{moment(dateTime).format('HH:mm')}</div>
      );
    }

    return (<div style={styles.date}>{moment(dateTime).format('DD/MM/YY HH:mm')}</div>);
  }

  return (
    <div style={styles.wrapper} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut}>
      <div style={styles.avatarWrapper}>
        <UserAvatar thumb={thumb} username={username} alias={alias} size={35} />
      </div>
      <div style={styles.textWrapper}>
        <div style={styles.contentWrapper}>
          <span style={styles.username}>{username}</span>
          <span style={styles.comment}>
            {comment}
          </span>
        </div>
        {renderDate()}
      </div>
      {(isOver && !isMobile) && isOwner && renderUserButtons()}
    </div>
  )
}

export default Comment;
