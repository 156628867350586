import React from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';
import { Translation } from 'react-i18next';
import Icon from '../../Icon/Icon';

class Season extends React.Component {
  getStyles() {
    const { style, data: { onClick, isMobile } } = this.props;

    return {
      wrapper: {
        ...style,
        display: 'flex',
        padding: 10,
        boxSizing: 'border-box',
        cursor: onClick ? 'pointer' : 'default'
      },
      avatarWrapper: {
        width: isMobile ? 40 : 60,
        height: isMobile ? 40 : 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        position: 'relative'
      },
      circle: {
        background: '#00d663',
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
        overflow: 'hidden',
        border: '2px solid #00d663',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      photo: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      },
      nameWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      },
      main: {
        flex: 1,
        fontWeight: 600,
        alignItems: 'center',
        display: 'flex'
      },
      description: {
        flex: 1,
        fontWeight: 100,
        color: '#6f6f6f',
        fontSize: 14,
        alignItems: 'center',
        display: 'flex'
      },
      box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 5px'
      },
      status: {
        fontWeight: 600,
        color: '#20ce78',
        textTransform: 'uppercase',
        fontSize: 12
      },
      text: {
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 12
      },
      button: {
        background: '#00d663',
        color: '#000',
        padding: '5px 10px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'space-between',
        width: 'auto'
      }
    }
  }

  handleCategoriesClick = () => {
    const { data, index } = this.props;
    const { onCategoriesClick } = data;
    const current = data.data[index];

    onCategoriesClick(current);
  }

  handleClick = () => {
    const { data, index } = this.props;
    const { onClick } = data;
    const current = data.data[index];

    onClick(current);
  }

  renderDates = () => {
    const { data, index } = this.props;
    const current = data.data[index];
    const { begin, end } = current;

    if (!end) {
      return moment(begin).format('DD/MM/YYYY')
    }

    return `${moment(begin).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`
  }

  render() {
    const { data, index } = this.props;
    const { onClick, onCategoriesClick } = data;
    const styles = this.getStyles();
    const current = data.data[index];
    const { name } = current;

    return (
      <div style={styles.wrapper}>
        <div style={styles.avatarWrapper}>
          <div style={styles.circle}>
            <Icon size={36} icon="calendarSport" />
          </div>
        </div>
        <div style={styles.nameWrapper} onClick={onClick ? this.handleClick : null}>
          <div style={styles.description}>{this.renderDates()}</div>
          <div style={styles.main}>{name}</div>
        </div>
        <div style={styles.box}>
          <motion.button
            type="button"
            onClick={onCategoriesClick ? this.handleCategoriesClick : null}
            style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            <Translation>
              {t => t('Categories')}
            </Translation>
          </motion.button>
        </div>
      </div>
    );
  }
}

export default Season;
