import React from 'react'
import UserAvatar from '../UserAvatar/UserAvatar'
import ReactCountryFlag from "react-country-flag"
import { Translation } from 'react-i18next';

const Player = (props) => {
  const { nationality, count } = props;

  const styles = {
    wrapper: {
      display: 'flex',
      boxSizing: 'border-box',
      minWidth: 0,
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    },
    nationality: {
      position: 'absolute',
      bottom: 85,
      left: 25,
      pointerEvents: 'none',
    },
    nationalityText: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -15,
      fontSize: 14,
      fontWeight: 600,
      color: '#000',
      textAlign: 'center'
    },
    username: {
      fontSize: 20,
    },
    name: {
      fontSize: 14,
    }
  }

  const renderNationality = () => {
    if (!nationality) return null;

    return (
      <div style={styles.nationality}>
        <ReactCountryFlag
          countryCode={nationality}
          svg
          style={{
            width: '3em',
            height: '3em',
          }}
          title={nationality}
        />
        <span style={styles.nationalityText}>{nationality}</span>
      </div>
    );
  }

  return (
    <div style={styles.wrapper}>
      <Translation>
        {t => (
          <UserAvatar
            {...props}
            size={200}
            detailed
            usernameStyle={styles.username}
            nameStyle={styles.name}
            bibnumber={`${count} ${t('photos')}`}
            count={null}
          />
        )}
      </Translation>
      {renderNationality()}
    </div>
  )
}

export default Player
