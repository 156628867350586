import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip, } from 'react-tippy';
import Icon from '../../Icon/Icon';

const Achievement = (props) => {
  const { icon, color, size, tooltip } = props;
  const styles = {
    wrapper: {
      width: size,
      height: size,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      overflow: 'hidden',
      position: 'relative',
      boxSizing: 'border-box',
      border: '3px solid white',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px'
    },
    circle: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: color,
      opacity: .2
    }
  };

  return (
    <Tooltip
      title={tooltip}
      position="bottom"
      trigger="mouseenter"
      arrow={true}
    > 
      <div style={styles.wrapper}>
        <div style={styles.circle}/>
        <Icon 
          size={size-15} 
          icon={icon}
          color={color}
        />
      </div>
    </Tooltip>
  );
}

export default Achievement;