import React, { useState, useRef, useEffect } from 'react'
import PhotographerBoxRanking from '../PhotographerBoxRanking/PhotographerBoxRanking';
import Aside from './Aside';
import { baseUrl } from '../../config'; 
import Spinner from 'react-spinkit';
import { Translation } from 'react-i18next';
import Footer from '../Footer/Footer';

const Photographers = (props) => {
  const { isMobile, userInfo, updated } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [])


  const getData = () => {
    setLoading(true);
    fetch(`${baseUrl}/activity/photographers/trending`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (!response.ok) {
        throw new Error();
      }

      return response.json();
    }).then((data) => {
      setData(data);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  const desktopStyles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    content: {
      flex: 1,
      boxSizing: 'border-box',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    blockWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 40
    },
    categoriesBlockWrapper: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20
    },
    title: {
      fontWeight: 600,
      fontSize: 32,
      flex: 'none',
      marginBottom: 20,
      display: 'flex'
    },
    subTitle: {
      fontWeight: 500,
      fontSize: 24,
      flex: 'none',
      marginBottom: 20,
      display: 'flex'
    },
    placeholder: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontWeight: 600,
      fontSize: 32,
      opacity: 0.2,
      marginTop: 50
    },
    placeholderIcon: {
      width: 200,
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      filter: 'grayscale(1)'
    },
    eventsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 100%)',
      gridGap: 25,
      justifyContent: 'center',
    },
    iconWrapper: {
      width: 45,
      height: 45,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      marginRight: 10
    },
    icon: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, .5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    dataWrapper: {
      flex: 1,
      display: 'flex',
    }
  };

  const mobileStyles = {
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    content: {
      flex: 1,
      boxSizing: 'border-box',
      padding: 15,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    blockWrapper: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    categoriesBlockWrapper: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20
    },
    title: {
      fontWeight: 600,
      fontSize: 26,
      flex: 'none',
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center'
    },
    subTitle: {
      fontWeight: 500,
      fontSize: 16,
      flex: 'none',
      marginBottom: 10,
      display: 'flex'
    },
    placeholder: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontWeight: 600,
      fontSize: 24,
      textAlign: 'center',
      opacity: 0.2,
      marginTop: 50
    },
    placeholderIcon: {
      width: 150,
      height: 150,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      filter: 'grayscale(1)'
    },
    eventsWrapper: {
      flex: 1,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 100%)',
      gridGap: 5
    },
    iconWrapper: {
      width: 45,
      height: 45,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      marginRight: 10
    },
    icon: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, .5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    dataWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    }
  };

  const styles = isMobile ? mobileStyles : desktopStyles;

  const renderBox = (data, position) => {
    return (
      <PhotographerBoxRanking key={data._id} {...data} isMobile={isMobile} position={position + 1} />
    );
  }

  const renderData = () => {
    return (
      <div style={styles.blockWrapper}>
        <div style={styles.title}>
          <div style={styles.iconWrapper}><img style={styles.icon} src="camera.svg" alt="photographers trending" /></div>
          <Translation>
            {t => t('photographers')}
          </Translation>
        </div>
        <div style={styles.dataWrapper}>
          {renderPhotographers()}
        </div>
      </div>
    );
  }

  const renderPhotographers = () => {
    if (!data) return null;

    return (
      <div style={styles.blockWrapper}>
        {data && data.length === 0 ?
          <div style={styles.placeholder}>
            <div style={styles.placeholderIcon}><img style={styles.icon} src="fire.svg" alt="trending" /></div>
            <Translation>
              {t => t('There is no trending photographers at the moment!')}
            </Translation>
          </div>
          :
          <div style={styles.eventsWrapper}>
            {data.map((d, i) => renderBox(d, i))}
          </div>
        }
      </div>
    );
  }

  const renderSpinner = () => {
    return (
      <div style={styles.overlay}>
        <Spinner name="folding-cube" color="#00d663" />
      </div>
    );
  }

  return (
    <div style={styles.wrapper}>
      <Aside isMobile={isMobile} userInfo={userInfo} updated={updated}/>
      {loading && renderSpinner()}
      <div style={styles.content}>
        {renderData()}
        <Footer isMobile={isMobile} />
      </div>
    </div>
  )
}

export default Photographers;