import React from 'react';
import PropTypes from 'prop-types';
import Tabs from './Tabs';
import { isObject } from '../../utils/object';

class TabsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected || 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({
        selected: this.props.selected || 0
      });
    }
  }
  

  handleClick = (index) => {
    this.setState({
      selected: index
    });
  }

  shouldShowTab = (index) => {
    const { showTabs } = this.props;

    if (!showTabs) {
      return true;
    }
    return showTabs[index];
  }

  render() {
    const { mode, style, selectStyle, wrapperStyle, children } = this.props;
    const { selected } = this.state;
    let parsedChildren = [];

    if (children && isObject(children) && children.hasOwnProperty('0')) {
      Object.keys(children).forEach((o, index) => {
        if (this.shouldShowTab(index)) {
          parsedChildren.push(React.cloneElement(children[o], { selected: selected }));
        }
      });
    } else {
      parsedChildren = children;
    }

    return (
      <Tabs
        {...this.state}
        mode={mode}
        handleClick={this.handleClick}
        style={style}
        selectStyle={selectStyle}
        wrapperStyle={wrapperStyle}
        hideUnselected={true}
      >
        {parsedChildren}
      </Tabs>
    );
  }
}

TabsContainer.propTypes = {
  mode: PropTypes.oneOf(['tabs', 'select']),
};

TabsContainer.defaultProps = {
  mode: 'tabs',
  selected: 0,
};

export default TabsContainer;
