import React from 'react';
import App from './App';
import { useMediaQuery } from 'react-responsive';

const ResponsiveApp = () => {
  const isTablet = useMediaQuery({ maxWidth: 1224, minWidth: 751 })
  const isMobile = useMediaQuery({ maxWidth: 750})
  const isDevice = useMediaQuery({ maxDeviceWidth: 1224 })

  return (
    <App isTablet={isTablet} isMobile={isMobile} isDevice={isDevice}/>
  );
}

export default ResponsiveApp;