import AppDispatcher from '../components/AppDispatcher/AppDispatcher.js';
import { baseUrl } from '../config'; 

const AppActions = {
  getUserData: ({ userId, ...rest }) => {
    fetch(`${baseUrl}/users/${userId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'LOGIN',
        data: {
          ...data,
          ...rest
        }
      });
    }).catch((err) => {
      console.log(err);
    });
  },

  getYearData: ({ data: { year } }) => {
    fetch(`${baseUrl}/events/resume/${year}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      AppDispatcher.dispatch({
        actionType: 'GET_YEAR',
        year,
        data
      });
    }).catch((err) => {
      console.log(err);
    });
  },

  getEventData: ({ data: { _id, selectedImageId } }) => {
    fetch(`${baseUrl}/events/${_id}/details`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.status === 404) {
        AppDispatcher.dispatch({
          actionType: 'CHANGE_VIEW',
          view: 'eventNotFound'
        });
      } else {
        if (response.ok) {
          return response.json();
        }
      }
    }).then((data) => {
      if (data) {
        AppDispatcher.dispatch({
          actionType: 'GET_EVENT',
          data: {
            ...data,
            selectedImageId
          }
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  },

  getExternalUserData: ({ userId, onSuccess }) => {
    fetch(`${baseUrl}/users/${userId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    }).catch((err) => {
      console.log(err);
    });
  },
};

export default AppActions;
