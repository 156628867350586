import React from 'react';
import { isObject } from '../../utils/object';

class Tab extends React.Component {
  getStyles() {
    const { tabId, selected, style } = this.props;
    const contentStyle = (style && style.content) || {};

    return {
      tab: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flex: 1,
        maxHeight: '100%',
        ...(tabId === selected && contentStyle)
      },
      tabHidden: {
        height: 0,
        opacity: 0,
        display: 'none',
        overflow: 'hidden',
        ...(tabId !== selected && contentStyle)
      }
    };
  }

  renderChildren = () => {
    const { children } = this.props;

    if (children && isObject(children) && children.hasOwnProperty('0')) {
      return Object.keys(children).map((c, i) => {
        return React.cloneElement(children[c]);
      });
    } else if (children) {
      return React.Children.map(children, (c, i) => {
        return React.cloneElement(c);
      });
    }
  }

  render() {
    const { selected, tabId } = this.props;
    const styles = this.getStyles();

    return (
      <div style={tabId === selected ? styles.tab : styles.tabHidden}>
        { this.renderChildren() }
      </div>
    )
  }
}

export default Tab;
