import React, { useEffect } from 'react';
import DialogConfirm from './DialogConfirm';
import DialogSuccess from './DialogSuccess';
import DialogError from './DialogError';
import { useKeyPress } from "../../utils/hooks";

const Dialog = (props) => {
  const { children, type, theme, style, contentStyle, fullScreen, onClickOutside } = props;

  const escapePress = useKeyPress("Escape");

  useEffect(() => {
    if (escapePress && onClickOutside) {
      onClickOutside();
    }
  }, [escapePress, onClickOutside]);

  const handleOutsideClick = () => {
    if (onClickOutside) {
      onClickOutside();
    }
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const styles = {
    wrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: theme === 'light' ? 'rgba(255, 255, 255, .7)' : 'rgba(0, 0, 0, .2)',
      zIndex: 1000,
      padding: 5,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      ...style
    },
    content: {
      overflow: 'hidden',
      boxSizing: 'border-box',
      minHeight: 95,
      minWidth: '50%',
      width: fullScreen && '98%',
      height: fullScreen && '98%',
      display: 'flex',
      flexDirection: 'column',
      ...contentStyle
    },
    buttons: {
      margin: '8px 0px 8px 24px',
      display: 'flex',
      justifyContent: 'flex-end',
      color: 'black',
      height: 36
    },
    button: {
      marginRight: 8,
      width: 80,
      background: '#FFF',
      padding: '0 8px',
      cursor: 'pointer',
      textAlign: 'center',
      minWidth: 64,
      height: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase'
    }
  };

  const renderContent = () => {
    if (!children) return null;

    return children({ ...props });
  }

  const renderTyppedDialog = () => {
    switch (type) {
      case 'confirm': return <DialogConfirm {...props} />;
      case 'success': return <DialogSuccess {...props} />;
      case 'error': return <DialogError {...props} />;
      default: return null;
    }
  }

  if (type) {
    return renderTyppedDialog();
  }

  return (
    <div style={styles.wrapper} onClick={handleOutsideClick}>
      <div style={styles.content} onClick={handleContentClick}>
        {renderContent()}
      </div>
    </div>
  );
}

Dialog.defaultProps = {
  theme: "dark"
};

export default Dialog;
