import React from 'react';
import { motion } from 'framer-motion';
import { Translation } from 'react-i18next';

class SeasonCategory extends React.Component {
  getStyles() {
    const { style, data: { onClick, isMobile } } = this.props;

    return {
      wrapper: {
        ...style,
        display: 'flex',
        padding: 10,
        boxSizing: 'border-box',
        cursor: onClick ? 'pointer' : 'default'
      },
      avatarWrapper: {
        width: isMobile ? 40 : 50,
        height: isMobile ? 40 : 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        position: 'relative'
      },
      circle: {
        background: '#00d663',
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
        overflow: 'hidden',
        border: '2px solid #00d663'
      },
      photo: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      },
      nameWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      },
      main: {
        flex: 1,
        fontWeight: 600,
        alignItems: 'center',
        display: 'flex'
      },
      description: {
        flex: 1,
        fontWeight: 100,
        color: '#6f6f6f',
        fontSize: 14,
        alignItems: 'center',
        display: 'flex'
      },
      box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100
      },
      status: {
        fontWeight: 600,
        color: '#20ce78',
        textTransform: 'uppercase',
        fontSize: 12
      },
      text: {
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 12
      },
      button: {
        background: '#00d663',
        color: '#000',
        padding: '5px 10px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'auto'
      },
      order: {
        flex: 'none',
        marginRight: 10,
        background: '#00d663',
        padding: '0px 10px',
        fontWeight: 600,
        fontSize: 22,
      },
    }
  }

  handleTeamsClick = () => {
    const { data, index } = this.props;
    const { onTeamsClick } = data;
    const current = data.data[index];

    onTeamsClick(current);
  }

  handleRemoveClick = () => {
    const { data, index } = this.props;
    const { onRemoveClick } = data;
    const current = data.data[index];

    onRemoveClick(current);
  }

  render() {
    const { data, index } = this.props;
    const { onTeamsClick, onRemoveClick, types } = data;
    const styles = this.getStyles();
    const currentIndex = data.data[index];
    const current = types && types.find(t => t._id === currentIndex.type);
    const { desc, name, photo, order = '-' } = current;

    return (
      <div style={styles.wrapper}>
        <div style={styles.avatarWrapper}>
          <div style={styles.circle}>
            {photo && <img style={styles.photo} src={photo} alt="name" />}
          </div>
        </div>
        <div style={styles.nameWrapper}>
          <div style={styles.main}><span style={styles.order}>{order}</span> {name}</div>
          <div style={styles.description}>{desc}</div>
        </div>
        <div style={styles.box}>
          <motion.button
            type="button"
            onClick={onTeamsClick ? this.handleTeamsClick : null}
            style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            <Translation>
              {t => t('clubs')}
            </Translation>
          </motion.button>
        </div>
        <div style={styles.box}>
          <motion.button
            type="button"
            onClick={onRemoveClick ? this.handleRemoveClick : null}
            style={Object.assign({}, styles.button, { color: '#fff', background: '#f44336' })}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            <Translation>
              {t => t('Remove')}
            </Translation>
          </motion.button>
        </div>
      </div>
    );
  }
}

export default SeasonCategory;
