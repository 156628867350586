import React from 'react'
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import moment from 'moment';

const NextMatch = ({ style, dateTime, organizers }) => {
  
  const styles = {
    wrapper: {
      flex: 1,
      borderRadius: 25,
      padding: 10,
      boxSizing: 'border-box',
      overflow: 'hidden',
      position: 'relative',
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
      ...style
    },
    date: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 'none',
      fontWeight: 600,
      marginBottom: 5,
      fontSize: 12,
    },
    organizersWrapper: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      color: '#B1B1B1',
      fontSize: 18
    }
  };

  if (!organizers || organizers.length === 0) return null;
  const [left, right] = organizers.filter(organization => organization.mode === 'participant');

  return (
    <div style={styles.wrapper}>
      <div style={styles.date}>{moment(dateTime).format('DD/MM/YY HH:mm')}</div>
      <div style={styles.organizersWrapper}>
        <OrganizationAvatar 
          {...left} 
          size={30}   
        />
        vs
        <OrganizationAvatar
          {...right}
          size={30}
        />
      </div>
    </div>
  )
}

export default NextMatch
