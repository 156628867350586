import React from 'react';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { Translation } from 'react-i18next';

const Category = ({ data, style, isMobile, size = 'normal' }) => {
  const { name, picture, thumb, events } = data;

  const sizeStyles = {
    normal: {
      wrapper: {
        height: 100,
        padding: 15
      },
      imageWrapper: {
        width: 70,
        height: 70
      }
    },
    tiny: {
      wrapper: {
        height: 60,
        padding: 10
      },
      imageWrapper: {
        width: 40,
        height: 40
      }
    }
  };

  const desktopStyles = {
    wrapper: {
      minWidth: 240,
      borderRadius: 15,
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
      background: '#fff',
      boxShadow: '0 0 13px 1px rgba(0, 0, 0, 0.10)',
      boxSizing: 'border-box',
      display: 'flex',
      cursor: 'pointer',
      ...sizeStyles[size].wrapper,
      ...style
    },
    imageWrapper: {
      flex: 'none',
      borderRadius: '50%',
      background: 'linear-gradient(45deg, rgb(0, 214, 98), rgb(36, 216, 234))',
      overflow: 'hidden',
      boxSizing: 'border-box',
      ...sizeStyles[size].imageWrapper,
    },
    photo: {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    },
    contentWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 15,
      boxSizing: 'border-box',
      padding: '5px 0'
    },
    name: {
      fontWeight: 600,
      flex: 1,
      display: 'flex',
      alignItems: 'center'
    },
    total: {
      fontWeight: 100,
      fontSize: 14,
      opacity: .8,
      flex: 1,
      display: 'flex',
      alignItems: 'center'
    }
  };

  const mobileStyles = {
    wrapper: {
      width: '100%',
      height: 70,
      borderRadius: 15,
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
      background: '#fff',
      boxShadow: '0 0 13px 1px rgba(0, 0, 0, 0.10)',
      boxSizing: 'border-box',
      padding: 15,
      display: 'flex',
      cursor: 'pointer',
      ...style
    },
    imageWrapper: {
      flex: 'none',
      borderRadius: '50%',
      width: 40,
      height: 40,
      background: 'linear-gradient(45deg, rgb(0, 214, 98), rgb(36, 216, 234))',
      overflow: 'hidden',
      boxSizing: 'border-box'
    },
    photo: {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    },
    contentWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10,
      boxSizing: 'border-box',
      padding: '2px 0'
    },
    name: {
      fontWeight: 600,
      fontSize: 12,
      flex: 1,
      alignItems: 'center',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    total: {
      fontWeight: 100,
      fontSize: 10,
      opacity: .8,
      flex: 1,
      display: 'flex',
      alignItems: 'center'
    }
  };
  
  const styles = isMobile ? mobileStyles : desktopStyles;

  const handleClick = () => {
    AppDispatcher.dispatch({
      actionType: `CHANGE_VIEW`,
      view: 'category',
      data
    });
  }

  return (
    <Translation>
      {t => (
        <div style={styles.wrapper} onClick={handleClick}>
          <div style={styles.imageWrapper}>{ (picture || thumb) && <img src={picture || thumb} alt={name} style={styles.photo}/>}</div>
          <div style={styles.contentWrapper}>
            <div style={styles.name}>{name}</div>
            {Number.isInteger(events) ? <div style={styles.total}>{events} {t('events')}</div>: null}
          </div>
        </div>
      )}
    </Translation>
  )
}

export default Category;
