import React, { Component } from 'react'
import EventBox from '../EventBox/EventBox';
import Aside from './Aside';
import { baseUrl } from '../../config'; 
import Spinner from 'react-spinkit';
import { Translation } from 'react-i18next';
import Footer from '../Footer/Footer';
import Toolbar from '../Toolbar/Toolbar';
import _ from 'lodash';
import { getFilterQuery } from '../../utils/filter';

class LastUpdated extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: false
    };
  }

  componentDidMount() {
    this.getData();
  }  

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filter, this.props.filter)) {
      this.getData();
    }
  }

  getData = () => {
    const { filter } = this.props;

    this.setState({ loading: true });
    const query = getFilterQuery({ filter });

    fetch(`${baseUrl}/activity/updated?${query}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (!response.ok) {
        throw new Error();
      }

      return response.json();
    }).then((data) => {
      this.setState({ data, loading: false });
    }).catch((err) => {
      console.log(err);
    });
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 40,
          padding: 20
        },
        categoriesBlockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        },
        title: {
          fontWeight: 600,
          fontSize: 32,
          flex: 'none',
          marginBottom: 20,
          display: 'flex'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 24,
          flex: 'none',
          marginBottom: 20
        },
        placeholder: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontWeight: 600,
          fontSize: 32,
          opacity: 0.2,
          marginTop: 50
        },
        placeholderIcon: {
          width: 200,
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
          filter: 'grayscale(1)'
        },
        eventsWrapper: {
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
          gridAutoRows: 300,
          gridGap: 25
        },
        iconWrapper: {
          width: 45,
          height: 45,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginRight: 10
        },
        icon: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
        },
        categoriesBlockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        },
        title: {
          fontWeight: 600,
          fontSize: 26,
          flex: 'none',
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 20,
          flex: 'none',
          marginBottom: 20
        },
        placeholder: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontWeight: 600,
          fontSize: 24,
          textAlign: 'center',
          opacity: 0.2,
          marginTop: 50
        },
        placeholderIcon: {
          width: 150,
          height: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
          filter: 'grayscale(1)'
        },
        eventsWrapper: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 100%)',
          gridAutoRows: 300,
          gridGap: 5
        },
        iconWrapper: {
          width: 45,
          height: 45,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginRight: 10
        },
        icon: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    }
  }

  renderEventBox = (event) => {
    const { isMobile } = this.props;

    return (
      <EventBox key={event._id} {...event} isMobile={isMobile} />
    );
  }

  renderEvents = () => {
    const { isMobile } = this.props;
    const { data } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!data) return null;

    return (
      <div style={styles.blockWrapper}>
        <div style={styles.title}>
          <div style={styles.iconWrapper}><img style={styles.icon} src="watch.svg" alt="trending" /></div>
          <Translation>
            {t => t('last updated')}
          </Translation>
        </div>
        {data && data.length === 0 ?
          <div style={styles.placeholder}>
            <div style={styles.placeholderIcon}><img style={styles.icon} src="watch.svg" alt="trending" /></div>
            <Translation>
              {t => t('Oops, there is not events yet!')}
            </Translation>
          </div>
        :
          <div style={styles.eventsWrapper}>
            {data.map(d => this.renderEventBox(d))}
          </div>
        }
      </div>
    );
  }

  renderSpinner = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.overlay}>
        <Spinner name="folding-cube" color="#00d663" />
      </div>
    );
  }

  render() {
    const { isMobile, userInfo, updated, filter } = this.props;
    const { loading } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        <Aside isMobile={isMobile} userInfo={userInfo} updated={updated}/>
        {loading && this.renderSpinner()}
        <div style={styles.content}>
          <Toolbar isMobile={isMobile} filter={filter} />
          {this.renderEvents()}
          <Footer isMobile={isMobile} />
        </div>
      </div>
    )
  }
}

export default LastUpdated;