/**
 * Flatten a deep object into a one level object with it’s path as key
 * @see https://gist.github.com/penguinboy/762197
 *
 * @param  {Object} object          [description]
 * @param  {String} [separator='.'] [description]
 * @return {[type]}                 [description]
 */
function flatten(object, separator = '.') {
  return Object.assign({}, ...function _flatten(child, path = []) {
    return [].concat(...Object.keys(child).map(key => typeof child[key] === 'object' && child[key] !== null
      ? _flatten(child[key], path.concat([key]))
      : ({ [path.concat([key]).join(separator)]: child[key] })
    ));
  }(object));
}

const isObject = (value) => {
  return value && typeof value === 'object' && value.constructor === Object;
}

export {
  flatten,
  isObject,
};
