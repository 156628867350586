import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import { baseUrl } from '../../config'; 
import Select from "react-select";
import { Translation } from 'react-i18next';

class MySelect extends React.Component {
  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange("photographers", value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur("photographers", true);
  };

  filterPhotographerUsers = () => {
    const { users } = this.props;

    return users.filter(user => user.role === 'photographer').map((user) => {
      return { label: user.username, value: user.username }
    });
  }

  render() {
    return (
      <div style={{ margin: "1rem 0" }}>
        <label htmlFor="photographers">
          <Translation>
            {t => t('Photographers')}
          </Translation>
        </label>
        <Select
          id="photographers"
          options={this.filterPhotographerUsers()}
          isMulti
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
        />
        {!!this.props.error && this.props.touched && (
          <div style={{ color: "#00d663", marginTop: ".5rem" }}>
            <Translation>
              {t => t(this.props.error)}
            </Translation>
          </div>
        )}
      </div>
    );
  }
}

class FormEventPhotographer extends Component {
  getStyles = () => {
    const { isMobile } = this.props;

    return {
      wrapper: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        background: '#fff',
      },
      form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      },
      fields: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        overflow: 'auto',
        padding: 20,
      },
      field: {
        marginBottom: 10,
        flexDirection: 'column',
        display: 'flex'
      },
      label: {
        marginBottom: 10,
        paddingLeft: 2
      },
      input: {
        border: '1px solid transparent',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40,
        width: '100%'
      },
      inputError: {
        border: '1px solid #00d663',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40,
        width: '100%'
      },
      inputFeedback: {
        color: '#00d663',
        fontSize: 12,
        marginTop: 5
      },
      header: {
        background: '#00d663',
        color: '#000',
        flex: 'none',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: isMobile ? 16 : 20,
        position: 'relative'
      },
      buttons: {
        flex: 'none',
        height: 50,
        background: '#f9f8f8',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: isMobile ? 5 : 20
      },
      mainButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      gap: {
        flex: 1
      },
      secondaryButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      button: {
        background: '#00d663',
        color: '#fff',
        padding: isMobile ? '5px 15px' : '5px 30px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none'
      },
      close: {
        position: 'absolute',
        right: 10
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      }
    }
  }

  handleSubmit = (values, { setSubmitting }) => {
    const { handleClose, data } = this.props;
    let url = `${baseUrl}/events/${data._id}/photographers`;
    const parsedValues = {
      ...values,
      photographers: values.photographers.map(t => t.value)
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(parsedValues),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      //setSubmitting(false);
      handleClose({ reload: true });
    }).catch((err) => {
      console.log(err);
    });
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      photographers: Yup.array()
    });
  }

  getInitialValues = () => {
    const { data } = this.props;

    if (data) return {
      photographers: data.photographers.map(photographer => ({label: photographer.username, value: photographer.username}))
    };

    return {
      photographers: []
    }
  }

  render() {
    const { header, handleClose, users } = this.props;
    const styles = this.getStyles();

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <Formik
              initialValues={this.getInitialValues()}
              onSubmit={this.handleSubmit}
              validationSchema={this.getValidationSchema()}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  setFieldTouched
                } = props;
                return (
                  <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.header}>
                      {t(header)}
                      {handleClose &&
                        <div style={styles.close}>
                          <motion.button
                            type="button"
                            onClick={handleClose}
                            style={styles.closeButton}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.8 }}
                          >
                            <Icon size={18} icon="close" color="#000" />
                          </motion.button>
                        </div>
                      }
                    </div>

                    <div style={styles.fields}>
                      <div style={styles.field}>
                        <MySelect
                          value={values.photographers}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.photographer}
                          touched={touched.photographer}
                          users={users}
                        />
                      </div>
                    </div>

                    <div style={styles.buttons}>
                      <div style={styles.mainButtons}>
                        <motion.button
                          type="button"
                          onClick={handleReset}
                          style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                          disabled={!dirty || isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('RESET')}
                        </motion.button>
                        <motion.button
                          type="submit"
                          style={Object.assign({}, styles.button, { color: '#000', background: '#00d066' })}
                          disabled={isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('SUBMIT')}
                        </motion.button>
                      </div>
                      <div style={styles.gap}></div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}
      </Translation>
    )
  }
}

FormEventPhotographer.defaultProps = {
  header: 'ADD EVENT TYPE'
};

export default FormEventPhotographer;