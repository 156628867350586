import React from 'react';
import { Translation } from 'react-i18next';

const NextMatchNoContent = ({ style, dateTime, organizers }) => {
  const styles = {
    wrapper: {
      flex: 1,
      borderRadius: 25,
      padding: 10,
      boxSizing: 'border-box',
      overflow: 'hidden',
      position: 'relative',
      background: '#fff',
      display: 'flex',
      boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
      minHeight: 100,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: '#c3c3c3',
      fontWeight: 600,
      ...style
    }
  };

  return (
    <div style={styles.wrapper}>
      <Translation>
        {t => t('There are no upcoming matches')}
      </Translation>
    </div>
  )
}

export default NextMatchNoContent
