import React from 'react'
import EventPictureBox from '../../EventPictureBox/EventPictureBox';

const GalleryImage = (props) => {
  const { columnIndex, rowIndex, style, data, index } = props;
  const isSelecting = data && data.isSelecting;
  const handleView = data && data.handleView;
  const itemData = data && data.getGalleryItemData && data.getGalleryItemData({columnIndex, rowIndex});

  if (!itemData) return null;

  return (
    <EventPictureBox 
      key={index} 
      isSelecting={isSelecting} 
      handleView={handleView}
      style={style} 
      {...itemData} 
    />
  );
}

export default GalleryImage;