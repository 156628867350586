const handleResponse = (response) => {
  if (response.headers) {
    const contentType = response.headers.get('Content-Type');
    if (contentType.includes('application/pdf')) {
      return Promise.all([response.ok, response.status, response.blob(), response.statusText]);
    }
    if (contentType.includes('application/json')) {
      return Promise.all([response.ok, response.status, response.json(), response.statusText]);
    }
  }
};

export default handleResponse;
