import React, { Component } from 'react'
import EventBox from '../EventBox/EventBox';
import Aside from './Aside';
import { baseUrl } from '../../config';
import Spinner from 'react-spinkit';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { Translation } from 'react-i18next';
import Carousel from '../Carousel/Carousel';
import Player from '../Carousel/Player';
import EventPictureBoxRanking from '../EventPictureBoxRanking/EventPictureBoxRanking';
import PhotographerBoxRanking from '../PhotographerBoxRanking/PhotographerBoxRanking';
import NextMatch from '../Card/NextMatch';
import Stats from '../Card/Stats';
import NextMatchNoContent from '../Card/NextMatchNoContent';
import Footer from '../Footer/Footer';
import Toolbar from '../Toolbar/Toolbar';
import { getFilterQuery } from '../../utils/filter';
import _ from 'lodash';

class Explore extends Component {
  constructor() {
    super();
    this.state = {
      last: null,
      loading: false
    };
  }

  componentDidMount() {
    this.getData();
  }  

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filter, this.props.filter)) {
      this.getData();
    }
  }

  getLastEvents = async () => {
    const { filter } = this.props;
    const query = getFilterQuery({ filter });
    const response = await fetch(`${baseUrl}/activity/last?${query}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  }

  getNextEvents = async () => {
    const { filter } = this.props;
    const query = getFilterQuery({ filter });
    const response = await fetch(`${baseUrl}/activity/next?${query}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  }

  getUpdated = async () => {
    const { filter } = this.props;
    const query = getFilterQuery({ filter });

    const response = await fetch(`${baseUrl}/activity/updated/number?${query}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  }

  getLastTags = async () => {
    const response = await fetch(`${baseUrl}/activity/tags`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  }

  getStats = async () => {
    const response = await fetch(`${baseUrl}/activity/stats`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  }

  getTrendingPhotos = async () => {
    const response = await fetch(`${baseUrl}/activity/images/trending/month`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  }

  getTrendingPhotographers = async () => {
    const response = await fetch(`${baseUrl}/activity/photographers/trending`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  }

  getData = () => {
    this.setState({loading: true});

    Promise.all([
      this.getLastEvents(),
      this.getUpdated(),
      this.getStats(),
      this.getLastTags(),
      this.getTrendingPhotos(),
      this.getTrendingPhotographers(),
      this.getNextEvents(),
    ]).then(responses => {
      if (responses) {
        const [last, updated, stats, tags, trending, photographers, next] = responses;

        if (updated) {
          AppDispatcher.dispatch({
            actionType: 'SET_UPDATED',
            data: updated
          });
        }

        this.setState({
          last,
          tags,
          stats,
          next: next && next.reverse(),
          trending: trending && trending.map((t, i) => ({...t, position: i+1})),
          photographers: photographers && photographers.map((t, i) => ({ ...t, position: i + 1 })),
          loading: false
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
        },
        columnsWrapper: {
          flex: 'none',
          boxSizing: 'border-box',
          display: 'flex',
        },
        blockWrapper: {
          display: 'flex',
          flex: 'none',
          flexDirection: 'column',
          padding: 20,
        },
        title: {
          fontWeight: 600,
          fontSize: 32,
          flex: 'none',
          marginBottom: 20,
          marginLeft: 5,
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 18,
          flex: 'none',
          marginBottom: 20,
          marginLeft: 5,
        },
        centered: {
          marginLeft: 0,
          marginRight: 0,
          textAlign: 'center'
        },
        eventsWrapper: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
          gridAutoRows: 300,
          gridGap: 5,
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        column: {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        rightColumn: {
          display: 'flex',
          flexDirection: 'column',
          flex: 'none', 
          width: 300
        },
        carouselWrapper: {
          boxSizing: 'border-box',
        },
        nextMatchesWrapper: {
          padding: 20,
          boxSizing: 'border-box',
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column-reverse',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 40
        },
        title: {
          fontWeight: 600,
          fontSize: 26,
          flex: 'none',
          marginBottom: 20,
          alignItems: 'center'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 20,
          flex: 'none',
          marginBottom: 20
        },
        eventsWrapper: {
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 100%)',
          gridAutoRows: 300,
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        column: {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        rightColumn: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1
        },
        carouselWrapper: {
          boxSizing: 'border-box',
        },
        nextMatchesWrapper: {
          padding: 20,
          boxSizing: 'border-box',
        }
      }
    }
  }

  renderEventBox = (event) => {
    const { isMobile } = this.props;
    return (
      <EventBox key={event._id} {...event} isMobile={isMobile} />
    );
  }

  renderLastEvents = () => {
    const { isMobile } = this.props;
    const { last } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!last) return null;

    return (
      <div style={styles.blockWrapper}>
        <div style={styles.title}>
          <Translation>
            {t => t('last matches')}
          </Translation>
        </div>
        <div style={styles.eventsWrapper}>
          {last.map(e => this.renderEventBox(e))}
        </div>
      </div>
    );
  }

  renderPlayerCarousel = () => {
    const { tags } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.blockWrapper}>
        <div style={{...styles.subTitle, ...styles.centered, marginBottom: 40}}>
          <Translation>
            {t => t('last taggeds')}
          </Translation>
        </div>
        <div style={styles.carouselWrapper}>
          <Carousel
            itemKey="_id"
            height={260}
            items={tags}
            ItemComponent={Player}
            style={{ padding: 15, boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)', background: 'linear-gradient(45deg, #00d662, #24d8ea)'}}
          />
        </div>
      </div>
    );
  }

  renderNextMatches = () => {
    const { next } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.blockWrapper}>
        <div style={{ ...styles.subTitle, ...styles.centered }}>
          <Translation>
            {t => t('next matches')}
          </Translation>
        </div>
        {next && next.length > 0 ?
          <div style={styles.nextMatchesWrapper}>
            {next.map(match => <NextMatch key={match._id} {...match} style={{marginBottom: 15}}/>)}
          </div> 
          : <NextMatchNoContent />}
      </div>
    );
  }

  renderStats = () => {
    const { stats } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.blockWrapper}>
        <div style={{ ...styles.subTitle, ...styles.centered }}>
          <Translation>
            {t => t('stats')}
          </Translation>
        </div>
        <Stats data={stats} />
      </div>
    );
  }

  handleTrendingPictureClick = () => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: 'trending'
    });
  }

  handleTrendingPhotographerClick = () => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: 'photographers'
    });
  }

  renderTrendingCarousel = () => {
    const { trending } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!trending || !trending.length) return null;

    return (
      <div style={styles.blockWrapper}>
        <div style={styles.title}>
          <Translation>
            {t => t('trending pictures of the month')}
          </Translation>
        </div>
        <div style={styles.carouselWrapper}>
          <Carousel
            itemKey="position"
            height={isMobile ? 465 : 340}
            items={trending}
            ItemComponent={EventPictureBoxRanking}
            itemProps={{ isMobile, onClick: this.handleTrendingPictureClick }}
            speed={5000}
            style={{ boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)'}}
          />
        </div>
      </div>
    );
  }

  renderTopPhotographersCarousel = () => {
    const { photographers } = this.state;
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!photographers || !photographers.length) return null;

    return (
      <div style={styles.blockWrapper}>
        <div style={styles.title}>
          <Translation>
            {t => t('top photographers')}
          </Translation>
        </div>
        <div style={styles.carouselWrapper}>
          <Carousel
            itemKey="position"
            height={isMobile ? 360 : 320}
            items={photographers}
            ItemComponent={PhotographerBoxRanking}
            itemProps={{ isMobile, onClick: this.handleTrendingPhotographerClick }}
            speed={4500}
            style={{ boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)'}}
          />
        </div>
      </div>
    );
  }

  renderSpinner = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.overlay}>
        <Spinner name="folding-cube" color="#00d663" />
      </div>
    );
  }

  render() {
    const { isMobile, userInfo, updated, filter } = this.props;
    const { loading } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        <Aside isMobile={isMobile} userInfo={userInfo} updated={updated} />
        <div style={styles.content}>
          <Toolbar isMobile={isMobile} filter={filter} />
          {loading ? 
            <div style={styles.wrapper}>
              {loading && this.renderSpinner()}
            </div>
            : 
            <div style={styles.columnsWrapper}>
              <div style={styles.column}>
                {this.renderLastEvents()}
                {this.renderTrendingCarousel()}
                {this.renderTopPhotographersCarousel()}
              </div>
              <div style={styles.rightColumn}>
                {this.renderPlayerCarousel()}
                {this.renderNextMatches()}
                {this.renderStats()}
              </div>
            </div> 
          }
          <Footer isMobile={isMobile} filter={filter}/>
        </div>
      </div>
    )
  }
}

export default Explore;