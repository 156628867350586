import React from 'react';
import EventPictureMobileBox from '../../EventPictureBox/EventPictureMobileBox';
import GalleryImage from './GalleryImage';
import { FixedSizeGrid as Grid } from 'react-window';
import List from '../../List/List';
import { AutoSizer } from 'react-virtualized';

const EventGallery = React.forwardRef(({ columnCount, data, isSelecting, isMobile, getGalleryItemData, handleView }, ref) => {
  if (isMobile) {
    const itemStyle = { minHeight: 300 };
    return (
      <List
        ItemComponent={EventPictureMobileBox}
        itemStyle={itemStyle}
        data={data}
        isSelecting={isSelecting}
        handleView={handleView}
      />
    );
  }

  const style = { flex: 1 };

  return (
    <div style={style}>
      <AutoSizer>
        {({height, width}) => (
          <Grid
            className="noscroll"
            ref={ref}
            columnCount={columnCount}
            columnWidth={(width / columnCount) - 5}
            height={height}
            rowCount={data.length}
            rowHeight={width / columnCount}
            width={width}
            itemData={{
              getGalleryItemData,
              isSelecting,
              handleView
            }}
          >
            {GalleryImage}
          </Grid>
        )}
      </AutoSizer>
    </div>
  )
});

export default EventGallery;
