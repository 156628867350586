import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import { baseUrl } from '../../config'; 
import Select from "react-select";
import { Translation } from 'react-i18next';
import handleResponse from '../../utils/handleResponse';
import moment from 'moment';

const modes = [
  { label: 'Gold', value: 'gold' },
  { label: 'Silver', value: 'silver' },
  { label: 'Bronze', value: 'bronze' },
];

const MySelect = ({ onChange, onBlur, id, label, components, options, value, error, touched, placeholder, isMulti, isClearable }) => {
  const handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    onChange(id, value);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    onBlur(id, true);
  };

  const getOption = () => {
    if (!options) return null;

    if (isMulti) {
      return value && value.map(v => options.find(o => {
        if (v.value) {
          return o.value === v.value
        }
        return o.value === v
      }));
    }

    return options.find(o => o.value === value);
  }

  return (
    <Translation>
      {t => (
        <>
          <label htmlFor={id}>{t(label)}</label>
          <Select
            id={id}
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            value={getOption()}
            placeholder={t(placeholder)}
            components={components}
            isMulti={isMulti}
            isClearable={isClearable}
          />
          {!!error && touched && (
            <div style={{ color: "#00d663", marginTop: ".5rem" }}>
              {t(error)}
            </div>
          )}
        </>
      )}
    </Translation>
  );
}


class FormOrganizationEvent extends Component {
  getStyles = () => {
    const { isMobile } = this.props;

    return {
      wrapper: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        background: '#fff',
      },
      form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      },
      fields: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        overflow: 'auto',
        padding: 20,
      },
      field: {
        marginBottom: 10,
        flexDirection: 'column',
        display: 'flex'
      },
      label: {
        marginBottom: 10,
        paddingLeft: 2
      },
      input: {
        border: '1px solid transparent',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40
      },
      checkbox: {
        fontSize: 16,
      },
      inputError: {
        border: '1px solid #00d663',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40
      },
      inputFeedback: {
        color: '#00d663',
        fontSize: 12,
        marginTop: 5
      },
      header: {
        background: '#00d663',
        color: '#000',
        flex: 'none',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: isMobile ? 16 : 20,
        position: 'relative'
      },
      buttons: {
        flex: 'none',
        height: 50,
        background: '#f9f8f8',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: isMobile ? 5 : 20
      },
      mainButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      gap: {
        flex: 1
      },
      secondaryButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      button: {
        background: '#00d663',
        color: '#fff',
        padding: isMobile ? '5px 15px' : '5px 30px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none'
      },
      close: {
        position: 'absolute',
        right: 10
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      },
      feedback: {
        marginBottom: 10,
        color: '#f44336',
        fontSize: 16,
        textAlign: 'center',
      },
    }
  }

  handleSubmit = (values, actions) => {
    const { handleClose, data, organizerId } = this.props;
    const url = data ? `${baseUrl}/events/${data._id}` : `${baseUrl}/events`;
    const body = {
      ...values,
      mode: values.mode && values.mode.value,
      type: values.type && values.type.value ? values.type.value : values.type,
      seasonId: values.seasonId && values.seasonId.value ? values.seasonId.value : values.seasonId,
      participants: values.participants && values.participants.map(v => v.value ? v.value : v),
      organizerId
    }
    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => {
      return handleResponse(response);
    }).then(([ok, status, data]) => {
      if (!ok) {
        throw new Error(data || 'Oops! Something went wrong');
      }

      handleClose({ reload: true });
    }).catch((error) => {
      actions.setSubmitting(false);
      actions.setFieldError('response', error.message);
    });
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      type: Yup.string().required('You must select a type'),
      dateTime: Yup.string().required('You must enter a date'),
      seasonId: Yup.string().required('You must select a season'),
      participants: Yup.string().required('You must select participants').nullable(),
      mode: Yup.string().required('You must select a level'),
    });
  }

  getInitialValues = () => {
    const { data } = this.props;

    if (data) return {
      ...data,
      type: data.type && data.type._id,
      dateTime: data.dateTime && moment(data.dateTime).format('YYYY-MM-DDTHH:mm')
    };

    return {
      type: '',
      dateTime: '',
      seasonId: '',
      participants: [],
      visible: true
    }
  }

  render() {
    const { header, handleClose, eventTypes, seasons, organizations } = this.props;
    const styles = this.getStyles();

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <Formik
              initialValues={this.getInitialValues()}
              onSubmit={this.handleSubmit}
              validationSchema={this.getValidationSchema()}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  setFieldTouched
                } = props;
                return (
                  <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.header}>
                      {t(header)}
                      {handleClose &&
                        <div style={styles.close}>
                          <motion.button
                            type="button"
                            onClick={handleClose}
                            style={styles.closeButton}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.8 }}
                          >
                            <Icon size={18} icon="close" color="#000" />
                          </motion.button>
                        </div>
                      }
                    </div>

                    <div style={styles.fields}>
                    {errors.response && (
                      <div style={styles.feedback}>{t(errors.response)}</div>
                    )}
                    <div style={styles.field}>
                        <label htmlFor="dateTime" style={styles.label}>
                          {t('Date')}
                        </label>
                        <input
                          id="dateTime"
                          type="datetime-local"
                          value={values.dateTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.dateTime && touched.dateTime ? styles.inputError : styles.input}
                        />
                        {errors.dateTime && touched.dateTime && (
                          <div style={styles.inputFeedback}>{t(errors.dateTime)}</div>
                        )}
                      </div>
                      <div style={styles.field}>
                        <MySelect
                          value={values.seasonId}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.seasonId}
                          touched={touched.seasonId}
                          options={seasons}
                          id="seasonId"
                          label="Season"
                          placeholder="Select season"
                          isClearable={false}
                        />
                      </div>
                      {values.seasonId && eventTypes && 
                        (<div style={styles.field}>
                          <MySelect
                            value={values.type}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={errors.type}
                            touched={touched.type}
                            options={eventTypes.filter(type => {
                              const currentValue = values.seasonId.value ? values.seasonId.value : values.seasonId;
                              const currentSeason = seasons.find(season => season.value === currentValue);
                              return currentSeason && currentSeason.types.includes(type.value);
                            })}
                            id="type"
                            label="Category"
                            placeholder="Select category"
                            isClearable={false}
                          />
                        </div>)
                      }
                      <div style={styles.field}>
                        <MySelect
                          value={values.participants}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.participants}
                          touched={touched.participants}
                          options={organizations}
                          isMulti
                          id="participants"
                          label="Participants"
                          placeholder="Select participants"
                          isClearable={false}
                        />
                      </div>
                      <div style={styles.field}>
                        <label htmlFor="visible" style={styles.label}>
                          {t('Visible')}
                        </label>
                        <input
                          id="visible"
                          type="checkbox"
                          checked={values.visible}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.visible && touched.visible ? styles.inputError : styles.checkbox}
                        />
                        {errors.visible && touched.visible && (
                          <div style={styles.inputFeedback}>{t(errors.visible)}</div>
                        )}
                      </div>

                      <div style={styles.field}>
                        <MySelect
                          value={values.mode}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.mode}
                          touched={touched.mode}
                          options={modes}
                          id="mode"
                          label="Level of organization"
                          placeholder="Select a level"
                        />
                      </div>

                    </div>

                    <div style={styles.buttons}>
                      <div style={styles.mainButtons}>
                        <motion.button
                          type="button"
                          onClick={handleReset}
                          style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                          disabled={!dirty || isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('RESET')}
                        </motion.button>
                        <motion.button
                          type="submit"
                          style={Object.assign({}, styles.button, { color: '#000', background: '#00d066' })}
                          disabled={isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('SUBMIT')}
                        </motion.button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}
      </Translation>
    )
  }
}

FormOrganizationEvent.defaultProps = {
  header: 'ADD EVENT'
};

export default FormOrganizationEvent;