import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ResponsiveApp from './ResponsiveApp';
import * as serviceWorker from './serviceWorker';
import '@material/react-list/dist/list.css';
import '@material/react-menu-surface/dist/menu-surface.css';
import '@material/react-menu/dist/menu.css';
import './i18n';

ReactDOM.render(<ResponsiveApp />, document.getElementById('root'));

serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: async registration => {
//     // We want to run this code only if we detect a new service worker is
//     // waiting to be activated.
//     // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
//     if (registration && registration.waiting) {
//       await registration.unregister();
//       // Once the service worker is unregistered, we can reload the page to let
//       // the browser download a fresh copy of our app (invalidating the cache)
//       window.location.reload();
//     }
//   }
// });
