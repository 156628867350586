import React, { Component } from 'react'
import Aside from './Aside';
import { baseUrl } from '../../config'; 
import Spinner from 'react-spinkit';
import OrganizationAvatar from '../OrganizationAvatar/OrganizationAvatar';
import Category from './Category';
import { Translation } from 'react-i18next';
import Footer from '../Footer/Footer';
import Toolbar from '../Toolbar/Toolbar';
import _ from 'lodash';

class Organizations extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: false
    };
  }

  componentDidMount() {
    this.getData();
  }  

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filter, this.props.filter)) {
      this.getData();
    }
  }

  getData = () => {
    const { filter } = this.props;

    this.setState({ loading: true });

    if (filter.seasonId === 'all') {
      this.setState({ loading: false, data: null });
      return null;
    }

    fetch(`${baseUrl}/seasons/${filter.seasonId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (!response.ok) {
        throw new Error();
      }

      return response.json();
    }).then((data) => {
      this.setState({ data, loading: false });
    }).catch((err) => {
      console.log(err);
    });
  }

  getStyles() {
    return {
      desktop: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 40,
          padding: 20
        },
        categoriesBlockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        },
        title: {
          fontWeight: 600,
          fontSize: 32,
          flex: 'none',
          marginBottom: 20,
          display: 'flex'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 24,
          flex: 'none',
          marginBottom: 20
        },
        placeholder: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontWeight: 600,
          fontSize: 32,
          opacity: 0.2,
          marginTop: 50
        },
        placeholderIcon: {
          width: 200,
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
          filter: 'grayscale(1)'
        },
        grid: {
          marginTop: 25,
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 60px)',
          gridTemplateRows: 'repeat(auto-fill, 60px)',
          gridGap: 15,
          alignItems: 'center',
          justifyContent: 'center',
        },
        iconWrapper: {
          width: 45,
          height: 45,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginRight: 10
        },
        icon: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
        },
        group: {
          marginBottom: 40,
        }
      },
      mobile: {
        wrapper: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          flex: 1,
          boxSizing: 'border-box',
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
        blockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column'
        },
        categoriesBlockWrapper: {
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        },
        title: {
          fontWeight: 600,
          fontSize: 26,
          flex: 'none',
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center'
        },
        subTitle: {
          fontWeight: 500,
          fontSize: 20,
          flex: 'none',
          marginBottom: 20
        },
        placeholder: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          fontWeight: 600,
          fontSize: 24,
          textAlign: 'center',
          opacity: 0.2,
          marginTop: 50
        },
        placeholderIcon: {
          width: 200,
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
          filter: 'grayscale(1)'
        },
        grid: {
          marginTop: 25,
          flex: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 60px)',
          gridTemplateRows: 'repeat(auto-fill, 60px)',
          gridGap: 15,
          alignItems: 'center',
          justifyContent: 'center',
        },
        iconWrapper: {
          width: 45,
          height: 45,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginRight: 10
        },
        icon: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, .5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        group: {
          marginBottom: 40,
        }
      }
    }
  }

  renderBoxes = (data) => {
    const { isMobile } = this.props;
    return (
      <OrganizationAvatar key={data.name} {...data} isMobile={isMobile} size={60} />
    );
  }

  renderGroup = (group) => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!group.organizers || !group.organizers.length) return null;

    return (
      <div style={styles.group} key={group.type._id}>
        <Category data={group.type} isMobile={isMobile} size="tiny" />
        <div style={styles.grid}>
          {group.organizers.map(organizer => this.renderBoxes(organizer))}
        </div>
      </div>
    );
  }

  renderOrganizations = () => {
    const { isMobile } = this.props;
    const { data } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    if (!data) return (
      <div style={styles.placeholder}>
        <Translation>
          {t => t('Choose a season!')}
        </Translation>
      </div>
    );

    return (
      <div style={styles.blockWrapper}>
        <div style={styles.title}>
        <div style={styles.iconWrapper}><img style={styles.icon} src="competitions.svg" alt="competitions" /></div>
          <Translation>
            {t => t('competitions')}
          </Translation>
        </div>
        {data && data.length === 0 ? 
          <div style={styles.placeholder}>
            <Translation>
              {t => t('There is no competitions yet!')}
            </Translation>
          </div>
        :
          <div style={styles.groupWrapper}>
            {data.map(d => this.renderGroup(d))}
          </div>
        }
      </div>
    );
  }

  renderSpinner = () => {
    const { isMobile } = this.props;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.overlay}>
        <Spinner name="folding-cube" color="#00d663" />
      </div>
    );
  }

  render() {
    const { isMobile, userInfo, updated, filter } = this.props;
    const { loading } = this.state;
    const styles = isMobile ? this.getStyles().mobile : this.getStyles().desktop;

    return (
      <div style={styles.wrapper}>
        <Aside isMobile={isMobile} userInfo={userInfo} updated={updated} />
        {loading && this.renderSpinner()}
        <div style={styles.content}>
          <Toolbar isMobile={isMobile} filter={filter} hideCategories/>
          {this.renderOrganizations()}
          <Footer isMobile={isMobile} />
        </div>
      </div>
    )
  }
}

export default Organizations;