import React, { useState, useRef } from 'react';
import UserAvatar from '../UserAvatar/UserAvatar';
import Icon from '../Icon/Icon';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import { motion } from 'framer-motion';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
} from '@material/react-menu';
import { useOnClickOutside } from '../../utils/hooks';
import { Translation } from 'react-i18next';

const PhotographerBoxRanking = ({ top, photos, likes, visits, events, _id, alias, username, surname, name, thumb, style, actions, isMobile, position, colors, onClick }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));

  const desktopStyles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      minHeight: 250,
      minWidth: 0,
      ...style
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      borderRadius: 5,
      border: '1px solid #efefef',
      position: 'relative',
      cursor: 'pointer',
    },
    contentWrapper: {
      position: 'relative',
      flex: 'none',
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    header: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10
    },
    label: {
      flex: 'none',
      marginBottom: 5,
      color: '#888888',
    },
    imageWrapper: {
      flex: 1,
      position: 'relative',
      overflow: 'hidden',
      borderRight: '1px solid #fff'
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'all 0.5s ease',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'absolute',
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: '#e6e4e4',
      zIndex: 1
    },
    
    checkbox: {
      position: 'absolute',
      top: 10,
      left: 10
    },
    bottom: {
      padding: 5,
      flex: 'none',
      display: 'flex',
      flexDirection: 'column'
    },
    stats: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 10,
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 16,
      flex: 1,
    },
    stat: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      opacity: 0.5,
      fontSize: 14,
      flex: 'none',
      marginTop: 10,
    },
    name: {
      fontWeight: 600,
      fontSize: 18,
      marginBottom: 10,
    },
    roundedButton: {
      padding: 0,
      borderRadius: '50%',
      boxShadow: 'none',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      width: 30,
      height: 30,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none'
    },
    badge: {
      padding: '2px 5px',
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 3,
      background: '#F44336',
      color: '#fff',
      cursor: 'pointer',
    },
    position: {
      flex: 'none',
      marginBottom: 20,
      display: 'flex',
      fontSize: 48,
      fontWeight: 600,
      boxSizing: 'border-box',
      alignItems: 'center'
    },
    topWrapper: {
      flex: 1,
      display: 'flex',
    }
  };

  const mobileStyles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      minHeight: 250,
      minWidth: 0,
      ...style
    },
    card: {
      flex: 1,
      background: '#fff',
      display: 'flex',
      borderRadius: 5,
      border: '1px solid #efefef',
      position: 'relative',
      flexDirection: 'column',
    },
    contentWrapper: {
      position: 'relative',
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      boxSizing: 'border-box',
      alignItems: 'center',
    },
    header: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5
    },
    label: {
      flex: 'none',
      marginBottom: 5,
      color: '#888888',
    },
    imageWrapper: {
      flex: 1,
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      borderRight: '1px solid #fff'
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'all 0.5s ease',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'absolute',
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: '#e6e4e4',
      zIndex: 1
    },
    bottom: {
      padding: 5,
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    stats: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 12
    },
    stat: {
      flex: 'none',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      opacity: 0.5,
      fontSize: 10,
      flex: 'none',
      marginTop: 10,
    },
    name: {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 10,
    },
    roundedButton: {
      padding: 0,
      borderRadius: '50%',
      boxShadow: 'none',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      width: 30,
      height: 30,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none'
    },
    badge: {
      padding: '2px 5px',
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase',
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 3,
      background: '#F44336',
      color: '#fff',
      cursor: 'pointer',
    },
    position: {
      flex: 'none',
      marginBottom: 5,
      display: 'flex',
      fontSize: 26,
      fontWeight: 600,
      boxSizing: 'border-box',
      alignItems: 'center',
    },
    topWrapper: {
      height: 120,
      display: 'flex',
    }
  };

  const styles = isMobile ? mobileStyles : desktopStyles;

  const handleItemClick = option => {
    AppDispatcher.dispatch({
      actionType: option.value,
      data: {
        _id,
        name
      }
    });
  };

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className="mdc-menu-desktop"
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleItemClick(actions[index])}
          >
            <MenuList>
              <div ref={ref}>
                {actions.map(option => (
                  <MenuListItem key={option.value}>
                    <MenuListItemGraphic graphic={<Icon size={18} icon={option.icon} color={option.label === 'Remove' && '#f44336'} wrapperStyle={{marginRight: 5}}/>} />
                    <MenuListItemText primaryText={t(option.label)} className={option.label === 'Remove' && 'mdc-text-red'}/>
                  </MenuListItem>
                ))}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };
  
  const handleShowMenu = event => {
    setMenuOpen(true);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handlePictureClick = (data) => {
    if (onClick) {
      onClick();
    } else {
      const localUsername = JSON.parse(localStorage.getItem('userInfo')).username;
      if (username === localUsername) {
        AppDispatcher.dispatch({
          actionType: 'CHANGE_VIEW',
          view: 'profile',
          data: {selectedImageId: data._id, tab: 0},
        });
      } else {
        AppDispatcher.dispatch({
          actionType: 'GO_TO_USER_PROFILE',
          userId: username,
          data: {selectedImageId: data._id, tab: 1},
        });
      }
    }
  }

  const renderActions = () => {
    if (!actions) return null;

    return (
      <div style={{ ...styles.header, borderBottom: '1px solid #eaeaea', justifyContent: 'center' }}>
        <motion.button
          onClick={handleShowMenu}
          style={styles.roundedButton}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <Icon size={18} icon="showMore" color="#000" />
        </motion.button>
      </div>
    );
  }

  const getPositionColor = () => {
    switch (position) {
      case 1: return colors.gold;
      case 2: return colors.silver;
      case 3: return colors.bronze;
      default: return 'blue';
    }
  }

  const getPositionIcon = () => {
    const color = getPositionColor();
    switch (position) {
      case 1:
        return <Icon size={isMobile ? 20 : 32} icon="awardTrophy" color={color} style={{ marginLeft: 10 }} />;
      case 2:
      case 3:
        return <Icon size={isMobile ? 20 : 32} icon="awardMedal" color={color} style={{ marginLeft: 10 }} />;
      default:
        return null;
    }
  }

  const renderTopPictures = () => {
    const lastIndex = top.length - 1;
    return top.map((picture, index) => {
      return (
        <div key={picture._id} style={{...styles.imageWrapper, borderWidth: lastIndex === index ? 0 : 1}} onClick={() => handlePictureClick(picture)}>
          {picture._id && <img src={getUrl(picture._id)} style={styles.image} loading="lazy" alt={name} />}
          <div style={styles.background} />
        </div>
      );
    });
  }

  const getUrl = (_id) => {
    return `https://moments.fra1.digitaloceanspaces.com/thumb/${_id}.jpeg`;
  }

  return (
    <Translation>
      {t => (
        <div style={styles.wrapper}>
          {isMenuOpen && renderMenu()}
          <div style={styles.card}>
            <div style={styles.topWrapper}>
              {renderTopPictures()}
            </div>
            <div style={styles.contentWrapper}>
              {renderActions()}
              <div style={{ ...styles.position }}>#{position} {getPositionIcon()}</div>
              <div style={styles.name}>
                <UserAvatar 
                  detailed
                  size={80} 
                  width={isMobile ? 300 : 250}
                  username={username} 
                  alias={alias} 
                  thumb={thumb} 
                  name={name} 
                  surname={surname} 
                  nameStyle={{fontSize: 12}} 
                  usernameStyle={{fontSize: 16}} 
                />
              </div>
              <div style={styles.bottom}>
                <div style={styles.stats}>
                  <div style={styles.stat}>
                    <Icon size={isMobile ? 18 : 24} icon="cameraEmpty" />
                    {photos}
                  </div>
                  <div style={styles.stat}>
                    <Icon size={isMobile ? 18 : 24} icon="heartEmpty" />
                    {likes}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  )
}

PhotographerBoxRanking.defaultProps = {
  colors: {
    bronze: '#cd7f32',
    silver: '#9E9E9E',
    gold: '#FFB300'
  }
}

export default PhotographerBoxRanking;
