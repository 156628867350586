import React from 'react';

class Icon extends React.Component {
  getStyles = () => {
    const { style, size } = this.props;

    return {
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        width: size,
        height: size,
        ...style
      },
      label: {
        marginLeft: 2,
        lineHeight: 1
      }
    }
  }

  getGraphics() {
    const { icon } = this.props;

    switch (icon) {
      case 'calendarSport':
        return (
          <g> 
            <path d="M19 19H5V8H19V19ZM16 1V3H8V1H6V3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 3.89 20.1 3 19 3H18V1" fill="black"/>
            <path d="M14.9167 12C13.3084 12 12 13.3084 12 14.9167C12 16.5249 13.3084 17.8333 14.9167 17.8333C16.5249 17.8333 17.8333 16.5249 17.8333 14.9167C17.8333 13.3084 16.5249 12 14.9167 12ZM16.9213 16.1052L16.1729 16.0343L15.6461 15.4256L15.752 14.8962L16.6083 14.641L17.2433 15.0491C17.2227 15.4219 17.1122 15.7843 16.9213 16.1052V16.1052ZM13.6605 16.0343L12.912 16.1052C12.721 15.7842 12.6106 15.4218 12.59 15.0488L13.225 14.6407L14.0813 14.896L14.1872 15.4253L13.6605 16.0343V16.0343ZM16.8452 13.6053L16.4815 14.2216L15.5335 14.5045L15.1354 14.2059V13.2396L15.666 12.709C16.1457 12.8723 16.5594 13.1868 16.8452 13.6053V13.6053ZM14.1674 12.709L14.6979 13.2396V14.2059L14.2995 14.5045L13.3519 14.2216L12.9882 13.6053C13.2739 13.1868 13.6877 12.8723 14.1674 12.709V12.709ZM14.0478 17.0805L13.9527 16.3654L14.4488 15.7917H15.3842L15.8806 16.3654L15.7855 17.0802C15.2284 17.3066 14.605 17.3067 14.0478 17.0805V17.0805Z" fill="currentColor"/> 
          </g>
        );
      case 'federation':
        return (
          <g fill="currentColor">
            <path fill="none" d="M0 0h24v24H0z"/>
            <path d="M12 0C5.383 0 0 5.385 0 12c0 6.617 5.383 12 12 12s12-5.383 12-12c0-6.615-5.383-12-12-12zm9.959 12.814l-2.012-.335a2.981 2.981 0 0 0-.683-1.426l1.825-3.195A9.899 9.899 0 0 1 22 12c0 .275-.02.545-.041.814zM19.906 5.9l-2.374 4.154A3.008 3.008 0 0 0 17 10c-.463 0-.895.113-1.286.301L13.7 8.287c.187-.391.3-.824.3-1.287 0-.462-.113-.894-.3-1.285l2.687-2.688A10.057 10.057 0 0 1 19.906 5.9zm-5.599-3.621L12.286 4.3A2.965 2.965 0 0 0 11 4a2.99 2.99 0 0 0-2.438 1.264l-3.039-.868A9.954 9.954 0 0 1 12 2c.795 0 1.564.104 2.307.279zM3.991 6.038l4.028 1.15A2.991 2.991 0 0 0 11 10c.463 0 .895-.113 1.286-.299l2.014 2.014a2.967 2.967 0 0 0-.294 1.229l-3.651 1.218A2.986 2.986 0 0 0 8 13a2.96 2.96 0 0 0-1.076.208L2.76 8.35l-.088.074c.332-.86.777-1.659 1.319-2.386zM2 12a9.8 9.8 0 0 1 .102-1.345l3.307 3.858A2.976 2.976 0 0 0 5 16c0 .898.401 1.694 1.025 2.243l-.533 1.334A9.97 9.97 0 0 1 2 12zm5.176 8.754l.706-1.766c.04.003.077.012.118.012a2.995 2.995 0 0 0 2.994-2.943l3.651-1.217c.28.357.636.649 1.05.85l-.648 5.831A9.935 9.935 0 0 1 12 22a9.922 9.922 0 0 1-4.824-1.246zm9.992-.208l.514-4.63a2.991 2.991 0 0 0 1.93-1.466l1.987.332a10.035 10.035 0 0 1-4.431 5.764z" fill="currentColor" id="Line_Icons"/>
          </g>
        );
      case 'paper':
        return (
          <g fill="currentColor"> <path fill="none" d="M0 0h24v24H0z"/> <g id="Line_Icons"><path fill="currentColor" d="M9 6h6v2H9z"/> <path fill="currentColor" d="M9 10h6v2H9z"/> <path d="M19 2H9C7.346 2 6 3.346 6 5v9c-2.206 0-4 1.794-4 4s1.794 4 4 4h8c2.206 0 4-1.794 4-4V8h3a1 1 0 0 0 1-1V5c0-1.654-1.346-3-3-3zm-8.463 18H6c-1.103 0-2-.897-2-2s.897-2 2-2h4.537A3.97 3.97 0 0 0 10 18c0 .728.195 1.411.537 2zM16 18c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2a1 1 0 0 0 0-2H8V5c0-.551.449-1 1-1h7.184A2.966 2.966 0 0 0 16 5v13zm4-12h-2V5c0-.551.449-1 1-1s1 .449 1 1v1z" fill="currentColor"/> </g> </g>
        );
      case 'menu':
        return (
          <g> <path d="M0,0h24v24h-24Z" fill="none"></path> <path d="M5,16h14v3h-14Z"></path> <path d="M5,11h14v3h-14Z"></path> <path d="M5,6h14v3h-14Z"></path> </g>
        );
      case 'accountSwitch':
        return (
          <path fillOpacity="1" strokeLinejoin="round" d="M 16,9C 18.333,9 23,10.168 23,12.5L 23,15L 17,15L 17,12.5C 17,11.017 16.194,9.891 15.035,9.054L 16,9 Z M 8,9C 10.333,9 15,10.168 15,12.5L 15,15L 1,15L 1,12.5C 1,10.168 5.66601,9 8,9 Z M 8,7C 6.34302,7 5,5.656 5,4C 5,2.34401 6.34302,1.00001 8,1.00001C 9.65702,1.00001 10.99,2.34401 10.99,4C 10.99,5.656 9.65702,7 8,7 Z M 16,7C 14.343,7 13,5.656 13,4C 13,2.34401 14.343,1.00001 16,1.00001C 17.657,1.00001 18.99,2.34401 18.99,4C 18.99,5.656 17.657,7 16,7 Z M 9,16.75L 9,19L 15,19L 15,16.75L 18.25,20L 15,23.25L 15,21L 9,21L 9,23.25L 5.75,20L 9,16.75 Z " />
        );
      case 'dollarCoin':
        return (
          <g> <path d="M13,7h-2v1.051C9.86,8.283,9,9.293,9,10.5c0,1.378,1.122,2.5,2.5,2.5h1c0.276,0,0.5,0.224,0.5,0.5 c0,0.276-0.224,0.5-0.5,0.5H9v2h2v1h2v-1.051c1.14-0.232,2-1.243,2-2.449c0-1.378-1.121-2.5-2.5-2.5h-1 c-0.275,0-0.5-0.224-0.5-0.5c0-0.276,0.225-0.5,0.5-0.5H15V8h-2V7z" /> <path d="M12,2C6.486,2,2,6.486,2,12c0,5.515,4.486,10,10,10c5.515,0,10-4.485,10-10C22,6.486,17.515,2,12,2 z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8S16.411,20,12,20z" /> </g>
        );
      case 'organization':
        return (
          <path d="M18,14c-0.287,0-0.566,0.033-0.836,0.091L14.618,9C15.458,8.266,16,7.2,16,6c0-2.205-1.795-4-4-4 S8,3.795,8,6c0,1.2,0.542,2.266,1.382,3l-2.546,5.091C6.566,14.033,6.287,14,6,14c-2.205,0-4,1.795-4,4s1.795,4,4,4 c1.858,0,3.41-1.279,3.857-3h4.285c0.447,1.721,1.999,3,3.857,3c2.205,0,4-1.795,4-4S20.205,14,18,14z M12,4c1.104,0,2,0.896,2,2 s-0.896,2-2,2s-2-0.896-2-2S10.896,4,12,4z M11.164,9.908C11.434,9.967,11.713,10,12,10s0.566-0.033,0.836-0.092L15.382,15 c-0.597,0.521-1.034,1.211-1.239,2H9.857c-0.205-0.789-0.643-1.479-1.239-2L11.164,9.908z M6,20c-1.104,0-2-0.896-2-2s0.896-2,2-2 s2,0.896,2,2S7.104,20,6,20z M18,20c-1.104,0-2-0.896-2-2s0.896-2,2-2s2,0.896,2,2S19.104,20,18,20z" /> 
        );
      case 'add':
        return (
          <polygon points="21,11.001 13,11.001 13,3.001 11,3.001 11,11.001 3,11.001 3,13.001 11,13.001 11,21.001 13,21.001 13,13.001 21,13.001 	"/>
        );
      case 'subtract':
        return (
          <rect x="3" y="11.001" width="18" height="2" />
        );
      case 'web':
        return (
          <g> <path d="M23,6h-3.022C18.152,3.577,15.262,2,12,2C8.739,2,5.848,3.577,4.022,6H1C0.448,6,0,6.448,0,7v10 c0,0.553,0.448,1,1,1h3.022c1.825,2.424,4.717,4,7.978,4c3.262,0,6.152-1.576,7.978-4H23c0.553,0,1-0.447,1-1V7 C24,6.448,23.553,6,23,6z M17.263,6H16V5.09C16.451,5.354,16.873,5.659,17.263,6z M14,4.263V6h-4V4.263 C10.641,4.097,11.309,4,12,4S13.36,4.097,14,4.263z M8,5.09V6H6.737C7.128,5.659,7.549,5.354,8,5.09z M6.737,18H8v0.911 C7.549,18.648,7.128,18.344,6.737,18z M10,19.738V18h4v1.738C13.36,19.904,12.691,20,12,20S10.641,19.904,10,19.738z M16,18.911 V18h1.263C16.873,18.344,16.451,18.648,16,18.911z M22,16H2V8h20V16z" /> <polygon points="5.24,14 6.24,14 6.262,14 6.251,13.958 6.744,12.008 7.232,14 7.24,14 8.24,14 8.262,14 8.251,13.958 9.251,10 8.223,10 7.744,11.951 7.251,10 6.251,10 6.223,10 5.744,11.951 5.251,10 4.251,10 5.232,14 		" /> <polygon points="10.24,14 11.24,14 11.261,14 11.251,13.96 11.743,12.008 12.232,14 12.24,14 13.24,14 13.261,14 13.251,13.96 14.25,10 13.222,10 12.743,11.951 12.25,10 11.251,10 11.222,10 10.743,11.951 10.251,10 9.251,10 10.232,14 		" /> <polygon points="15.24,14 16.24,14 16.261,14 16.251,13.96 16.743,12.008 17.232,14 17.24,14 18.24,14 18.261,14 18.251,13.96 19.25,10 18.222,10 17.743,11.951 17.25,10 16.25,10 16.222,10 15.743,11.951 15.25,10 14.25,10 15.232,14 		" /> </g>
        );
      case 'expand':
        return (
          <g> <polygon points="14.707,10.707 20,5.414 20,8 22,8 22,2 16,2 16,4 18.586,4 13.293,9.293 		" /> <polygon points="8,20 5.414,20 10.707,14.707 9.293,13.293 4,18.586 4,16 2,16 2,22 8,22 		" /> <polygon points="4,5.414 9.293,10.707 10.707,9.293 5.414,4 8,4 8,2 2,2 2,8 4,8 		" /> <polygon points="16,20 16,22 22,22 22,16 20,16 20,18.586 14.707,13.293 13.293,14.707 18.586,20 		" /> </g>
        );
      case 'crown':
        return (
          <g> <path d="M21.596,8.197c-0.325-0.241-0.764-0.262-1.11-0.054l-4.183,2.51l-3.471-5.207 c-0.038-0.057-0.101-0.083-0.148-0.128l0.963-0.964c0.194-0.194,0.194-0.513,0-0.707l-1.293-1.293 c-0.194-0.194-0.513-0.194-0.707,0l-1.293,1.293c-0.194,0.194-0.194,0.513,0,0.707l0.963,0.964 c-0.048,0.045-0.11,0.071-0.148,0.128l-3.471,5.207l-4.183-2.51C3.168,7.936,2.73,7.957,2.404,8.197 C2.08,8.439,1.932,8.851,2.029,9.243l2,8C4.141,17.688,4.541,18.001,5,18.001h14c0.459,0,0.859-0.312,0.971-0.758l2-8 C22.068,8.851,21.92,8.439,21.596,8.197z" /> <rect x="4" y="19.001" width="16" height="2" /> </g>
        );
      case 'menuDown':
        return (
          <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
        );
      case 'fire':
        return (
          <path d="M17.55,11.2C17.32,10.9 17.05,10.64 16.79,10.38C16.14,9.78 15.39,9.35 14.76,8.72C13.3,7.26 13,4.85 13.91,3C13,3.23 12.16,3.75 11.46,4.32C8.92,6.4 7.92,10.07 9.12,13.22C9.16,13.32 9.2,13.42 9.2,13.55C9.2,13.77 9.05,13.97 8.85,14.05C8.63,14.15 8.39,14.09 8.21,13.93C8.15,13.88 8.11,13.83 8.06,13.76C6.96,12.33 6.78,10.28 7.53,8.64C5.89,10 5,12.3 5.14,14.47C5.18,14.97 5.24,15.47 5.41,15.97C5.55,16.57 5.81,17.17 6.13,17.7C7.17,19.43 9,20.67 10.97,20.92C13.07,21.19 15.32,20.8 16.93,19.32C18.73,17.66 19.38,15 18.43,12.72L18.3,12.46C18.1,12 17.83,11.59 17.5,11.21L17.55,11.2M14.45,17.5C14.17,17.74 13.72,18 13.37,18.1C12.27,18.5 11.17,17.94 10.5,17.28C11.69,17 12.39,16.12 12.59,15.23C12.76,14.43 12.45,13.77 12.32,13C12.2,12.26 12.22,11.63 12.5,10.94C12.67,11.32 12.87,11.7 13.1,12C13.86,13 15.05,13.44 15.3,14.8C15.34,14.94 15.36,15.08 15.36,15.23C15.39,16.05 15.04,16.95 14.44,17.5H14.45Z" />
        );
      case 'sort':
        return (
          <g> <rect x="3" y="16" width="14" height="3" /> <rect x="5" y="11" width="14" height="3" /> <rect x="7" y="6" width="14" height="3" /> </g>
        );
      case 'phone':
        return (
          <path d="M15.999,1.999h-8c-1.103,0-2,0.896-2,2v16c0,1.104,0.897,2,2,2h8c1.103,0,2-0.896,2-2v-16 C17.999,2.896,17.102,1.999,15.999,1.999z M15.999,3.999v2h-8v-2H15.999z M16,7.999l0,8H7.999v-8H16z M7.999,19.999v-2H16l0,2 H7.999z" />
        );
      case 'twitter':
        return (
          <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" fill="none" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
        );
      case 'facebook':
        return (
          <path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" fill="none" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
        );
      case 'instagram':
        return (
          <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
        );
      case 'envelope':
        return (
          <path d="M20,7H4C2.897,7,2,7.897,2,9v9c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V9 C22,7.897,21.103,7,20,7z M18.113,9L12,12.82L5.887,9H18.113z M4,18v-7.821l7.47,4.669C11.632,14.949,11.816,15,12,15 s0.368-0.051,0.53-0.152L20,10.178L20.001,18H4z" />
        );
      case 'showMore':
        return (
          <g> <circle cx="12" cy="5.001" r="2" /> <circle cx="12" cy="12.001" r="2" /> <circle cx="12" cy="19.001" r="2" /> </g>
        );
      case 'thumbsUp':
        return (
          <path d="M22.768,7.359C22.578,7.132,22.297,7,22,7h-6V3c0-0.552-0.447-1-1-1h-3 c-0.4,0-0.762,0.238-0.919,0.605L8.341,9H8c0-0.552-0.447-1-1-1H3C2.447,8,2,8.448,2,9v10c0,0.552,0.447,1,1,1h4 c0.553,0,1-0.448,1-1v-0.522l3.629,1.451C11.746,19.976,11.873,20,12,20h8c0.483,0,0.897-0.346,0.984-0.821l2-11 C23.037,7.887,22.957,7.587,22.768,7.359z M6,18H4v-8h2V18z M19.166,18h-6.974L8,16.323V11h1c0.4,0,0.762-0.238,0.919-0.606 L12.659,4H14v4c0,0.552,0.447,1,1,1h5.802L19.166,18z" />
        );
      case 'thumbsDown':
        return (
          <path d="M21,2.001h-4c-0.553,0-1,0.448-1,1v0.522l-3.629-1.451C12.254,2.025,12.127,2.001,12,2.001H4 c-0.483,0-0.897,0.346-0.984,0.821l-2,11c-0.053,0.292,0.027,0.592,0.217,0.819S1.703,15.001,2,15.001h6v4c0,0.552,0.447,1,1,1h3 c0.4,0,0.762-0.238,0.919-0.606l2.74-6.394H16c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1v-10C22,2.449,21.553,2.001,21,2.001z M15,11.001c-0.4,0-0.762,0.238-0.919,0.605l-2.74,6.395H10v-4c0-0.552-0.447-1-1-1H3.198l1.636-9h6.974L16,5.679v5.322H15z M20,12.001h-2v-8h2V12.001z" />
        );
      case 'userUp':
        return (
          <g> <path d="M8.002,16.007c2.205,0,4-1.795,4-4s-1.795-4-4-4s-4,1.795-4,4S5.797,16.007,8.002,16.007z M8.002,10.007c1.104,0,2,0.896,2,2c0,1.103-0.896,2-2,2s-2-0.897-2-2C6.002,10.903,6.899,10.007,8.002,10.007z" /> <path d="M8.002,17.007c-4.711,0-8,2.467-8,6v1h16v-1C16.002,19.474,12.713,17.007,8.002,17.007z M2.161,22.007c0.599-1.808,2.834-3,5.842-3s5.243,1.192,5.842,3H2.161z" /> <polygon points="21.709,5.3 17.002,0.593 12.295,5.3 13.709,6.714 16.002,4.421 16.002,10.008 18.002,10.008 18.002,4.421 20.295,6.714 		" /> </g>
        );
      case 'report':
        return (
          <path d="M20.707,8.294l-6-6c-0.391-0.391-1.023-0.391-1.414,0L10,5.587L8.707,4.294 c-0.391-0.391-1.023-0.391-1.414,0l-5,5c-0.391,0.391-0.391,1.023,0,1.414l6,6l5,5l1.414-1.414l-4.293-4.293L12,14.416l1.293,1.293 c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l6-6C21.098,9.318,21.098,8.685,20.707,8.294z M4.414,10.001L8,6.416 l4.586,4.586L9,14.587L4.414,10.001z M15,12.587v-1.586c0-0.256-0.098-0.512-0.293-0.707l-3.293-3.293L14,4.416l4.586,4.586 L15,12.587z" />        );
      case 'starEmpty':
        return (
          <path d="M18,22.001c-0.193,0-0.387-0.056-0.555-0.168L12,18.203l-5.445,3.63 c-0.348,0.232-0.805,0.224-1.145-0.024c-0.338-0.247-0.486-0.679-0.371-1.082l1.838-6.435L2.293,9.708 c-0.286-0.285-0.371-0.716-0.217-1.09C2.23,8.245,2.596,8.001,3,8.001h5.382l2.724-5.447c0.339-0.677,1.45-0.677,1.789,0 l2.724,5.447H21c0.404,0,0.77,0.244,0.924,0.617c0.154,0.374,0.069,0.805-0.217,1.09l-4.584,4.584l1.838,6.435 c0.115,0.403-0.033,0.835-0.371,1.082C18.414,21.937,18.207,22.001,18,22.001z M12,16.001c0.193,0,0.387,0.057,0.555,0.168 l3.736,2.491l-1.252-4.384c-0.101-0.35-0.003-0.726,0.254-0.982l3.293-3.293H15c-0.379,0-0.725-0.214-0.895-0.553L12,5.237 L9.895,9.448C9.725,9.787,9.379,10.001,9,10.001H5.414l3.293,3.293c0.257,0.257,0.354,0.633,0.254,0.982L7.709,18.66l3.736-2.491 C11.613,16.058,11.807,16.001,12,16.001z" />
        );
      case 'star':
        return (
          <path d="M21.924,8.618C21.77,8.245,21.404,8.001,21,8.001h-5.382l-2.724-5.447c-0.339-0.677-1.45-0.677-1.789,0 L8.382,8.001H3c-0.404,0-0.77,0.244-0.924,0.617c-0.154,0.373-0.069,0.805,0.217,1.09l4.584,4.584l-1.838,6.435 c-0.115,0.403,0.033,0.835,0.371,1.082c0.34,0.248,0.797,0.257,1.144,0.024L12,18.203l5.445,3.63 c0.168,0.112,0.361,0.168,0.555,0.168c0.207,0,0.414-0.064,0.59-0.192c0.338-0.247,0.486-0.679,0.371-1.082l-1.838-6.435 l4.584-4.584C21.993,9.423,22.078,8.991,21.924,8.618z" />
        );
      case 'chatEmpty':
        return (
          <path d="M11.998,21h-10C1.6,21,1.24,20.764,1.082,20.4c-0.159-0.365-0.087-0.789,0.184-1.08l2.306-2.484 C2.539,15.392,1.998,13.735,1.998,12c0-4.963,4.486-9,10-9s10,4.037,10,9S17.512,21,11.998,21z M4.291,19h7.707 c4.411,0,8-3.14,8-7c0-3.859-3.589-7-8-7s-8,3.141-8,7c0,1.537,0.568,3,1.642,4.232c0.336,0.386,0.327,0.963-0.021,1.338L4.291,19 z"/>
        );
      case 'chat':
        return (
          <path d="M11.998,3c-5.514,0-10,4.037-10,9c0,1.735,0.541,3.392,1.573,4.836C0.998,21,0.998,21,0.998,21 s6.583,0,11,0c5.514,0,10-4.037,10-9S17.512,3,11.998,3z"/>
        );
      case 'eyeEmpty':
        return (
          <path d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
        );
      case 'eye':
        return (
          <path d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
        );
      case 'arrowLeft':
        return (
          <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
        );
      case 'arrowRight':
        return (
          <path d="M8.59,16.34l4.58,-4.59l-4.58,-4.59l1.41,-1.41l6,6l-6,6Z" />
        );
      case 'pin':
        return (
          <path d="M22,12l-9.899-9.899l-1.414,1.413l1.415,1.415l0,0.001L7.151,9.879l0,0.001L5.736,8.465L4.322,9.88 l4.243,4.242l-5.657,5.656l1.414,1.414l5.657-5.656l4.243,4.242l1.414-1.414l-1.414-1.415l0,0l4.95-4.95h0l1.414,1.414L22,12z M12.809,15.535l-0.001,0.001l-4.242-4.243l4.949-4.95l4.242,4.243L12.809,15.535z" /> 
        );
      case 'facebookSolid':
        return (
          <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
        );
      case 'checkboxSolid':
        return (
          <path d="M12.042,2c-5.523,0-10,4.477-10,10s4.477,10,10,10s10-4.477,10-10S17.564,2,12.042,2z M11.292,17.75 l-5-3.75l1.5-2l3,2.25l5.25-7l2,1.5L11.292,17.75z" />
        );
      case 'checkboxEmpty':
        return (
          <g> <path d="M12.042,22c-5.514,0-10-4.486-10-10s4.486-10,10-10s10,4.486,10,10S17.556,22,12.042,22z M12.042,4c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.453,4,12.042,4z" /> </g>
        );
      case 'checkbox':
        return (
          <g> <path d="M12.042,22c-5.514,0-10-4.486-10-10s4.486-10,10-10s10,4.486,10,10S17.556,22,12.042,22z M12.042,4c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.453,4,12.042,4z" /> <polygon points="11.201,16.928 7.158,12.885 8.925,11.117 10.882,13.073 15.024,7.274 17.059,8.727 			" /> </g>
        );
      case 'select':
        return (
          <g> <path d="M18,22h-7c-0.352,0-0.677-0.185-0.857-0.485l-3-5c-0.186-0.309-0.19-0.694-0.013-1.007 C7.307,15.194,7.64,15,8,15h2V9c0-0.321,0.154-0.623,0.416-0.812c0.26-0.188,0.596-0.239,0.9-0.137l3,1 C14.725,9.188,15,9.57,15,10v4.219l3.242,0.811C18.688,15.142,19,15.542,19,16v5C19,21.553,18.553,22,18,22z M11.566,20H17 v-3.219l-3.242-0.81C13.313,15.859,13,15.459,13,15v-4.279l-1-0.333V16c0,0.552-0.447,1-1,1H9.766L11.566,20z" /> <path d="M17,8c0,0.91-0.255,1.8-0.73,2.582l1.89,0.698C18.7,10.268,19,9.149,19,8c0-3.86-3.141-7-7-7 s-7,3.14-7,7c0,1.277,0.358,2.522,1.014,3.608l1.938-0.715C7.346,10.047,7,9.042,7,8c0-2.757,2.243-5,5-5S17,5.244,17,8z" /> </g>
        );
      case 'trash':
        return (
          <g> <path d="M5,20c0,1.103,0.897,2,2,2h10c1.103,0,2-0.897,2-2V8H5V20z M7,10h10l0.001,10H7V10z" /> <polygon points="15,5 15,3 9,3 9,5 3,5 3,7 21,7 21,5 		" /> <rect x="9" y="12" width="2" height="6" /> <rect x="13" y="12" width="2" height="6" /> </g>
        );
      case 'login':
        return (
          <path fillOpacity="1" strokeWidth="0.2" strokeLinejoin="round" d="M 10,17.25L 10,14L 3.00002,14L 3.00002,10L 10,10L 10,6.75L 15.25,12L 10,17.25 Z M 7.99999,2.00003L 17,2.00005C 18.1045,2.00005 19,2.89546 19,4.00003L 19,20C 19,21.1046 18.1045,22 17,22L 7.99999,22C 6.89542,22 5.99999,21.1046 5.99999,20L 6,16L 7.99999,16L 7.99999,20L 17,20L 17,4.00003L 7.99999,4.00002L 7.99999,8.00001L 6,8.00001L 5.99999,4.00002C 5.99999,2.89545 6.89542,2.00003 7.99999,2.00003 Z " />
        );
      case 'download':
        return (
          <g> <polygon points="17.707,10.708 16.293,9.294 13,12.587 13,2.001 11,2.001 11,12.587 7.707,9.294 6.293,10.708 12,16.415 		" /> <path d="M18,18.001v2H6v-2H4v2c0,1.102,0.896,2,2,2h12c1.104,0,2-0.898,2-2v-2H18z" /> </g>
        );
      case 'filter':
        return (
          <path d="M18,2H6C4.896,2,4,2.898,4,4v4c0,0.256,0.098,0.512,0.293,0.707L9,13.415V21 c0,0.404,0.244,0.769,0.617,0.924C9.741,21.976,9.871,22,10,22c0.26,0,0.516-0.102,0.707-0.293l4-4C14.895,17.52,15,17.266,15,17 v-3.586l4.707-4.707C19.902,8.512,20,8.257,20,8V4C20,2.898,19.104,2,18,2z M13.293,12.293C13.105,12.481,13,12.735,13,13v3.586 l-2,2V13c0-0.265-0.105-0.52-0.293-0.707L6,7.586V4h12v3.586L13.293,12.293z" />
        );
      case 'idCard':
        return (
          <g> <path d="M20.001,6v12h-16V6H20.001 M20.001,4h-16c-1.1,0-2,0.9-2,2v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2 V6C22.001,4.9,21.101,4,20.001,4L20.001,4z" /> <rect x="14.001" y="11" width="4" height="2" /> <rect x="14.001" y="14" width="4" height="2" /> <path d="M6.001,16c0-1.841,1.159-3,3-3l0,0c1.841,0,3,1.159,3,3H6.001z" /> <circle cx="9.001" cy="10" r="2" /> </g>
        );
      case 'tagAdd':
        return (
          <g> <path d="M20,8.006v-7c0-0.553-0.447-1-1-1h-7c-0.266,0-0.52,0.105-0.707,0.293l-11,11 c-0.391,0.391-0.391,1.023,0,1.414l7,7C7.488,19.908,7.744,20.006,8,20.006s0.512-0.098,0.707-0.293l11-11 C19.895,8.525,20,8.271,20,8.006z M18,7.592l-10,10l-5.586-5.586l10-10H18V7.592z"/> <circle cx="15" cy="5.006" r="2"/> <polygon points="24,19 21,19 21,16 19,16 19,19 16,19 16,21 19,21 19,24 21,24 21,21 24,21 		"/> </g>
        );
      case 'tag':
        return (
          <g> <path d="M14,22c-0.256,0-0.512-0.098-0.707-0.293l-11-11C2.105,10.52,2,10.266,2,10V3c0-0.553,0.447-1,1-1 h7c0.266,0,0.52,0.105,0.707,0.293l11,11c0.391,0.391,0.391,1.023,0,1.414l-7,7C14.512,21.902,14.256,22,14,22z M4,9.586l10,10 L19.586,14l-10-10H4V9.586z" /> <circle cx="7" cy="7" r="2" /> </g>
        );
      case 'search':
        return (
          <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
        );
      case 'awardMedal':
        return (
          <path d="M22.851,1.475C22.668,1.18,22.347,1,22,1h-6c-0.446,0-0.839,0.296-0.961,0.726l-1.825,6.387 C12.818,8.043,12.414,8,12,8s-0.818,0.043-1.214,0.112L8.961,1.726C8.839,1.296,8.446,1,8,1H2C1.653,1,1.332,1.18,1.149,1.475 C0.967,1.769,0.951,2.137,1.105,2.447L5.781,11.8C5.285,12.76,5,13.847,5,15c0,3.86,3.141,7,7,7s7-3.14,7-7 c0-1.153-0.285-2.24-0.781-3.2l4.676-9.352C23.049,2.137,23.033,1.769,22.851,1.475z M3.618,3h3.628l1.64,5.74 C8.231,9.066,7.634,9.489,7.115,9.995L3.618,3z M15,18.5l-3-2l-3,2l1-3l-2-2h3l1-3l1,3h3l-2,2L15,18.5z M16.885,9.995 c-0.519-0.506-1.116-0.929-1.771-1.255L16.754,3h3.628L16.885,9.995z" />
        );
      case 'awardTrophy':
        return (
          <g> <rect x="7" y="20.001" width="10" height="2" /> <path d="M21,3.001h-3.278c-0.347-0.595-0.984-1-1.722-1H8c-0.737,0-1.375,0.405-1.722,1H3c-0.553,0-1,0.448-1,1v4 c0,0.304,0.139,0.592,0.375,0.781l4.929,3.942C8.206,13.86,9.51,14.659,11,14.911v1.09H9c-0.553,0-1,0.448-1,1v2h8v-2 c0-0.552-0.447-1-1-1h-2v-1.09c1.49-0.252,2.794-1.051,3.696-2.187l4.929-3.942C21.861,8.593,22,8.305,22,8.001v-4 C22,3.449,21.553,3.001,21,3.001z M4,7.521v-2.52h2v4c0,0.042,0.006,0.082,0.006,0.125L4,7.521z M20,7.521l-2.006,1.606 C17.994,9.083,18,9.043,18,9.001v-4h2V7.521z" /> </g>
        );
      case 'awardTrophyEmpty':
        return (
          <path d="M21,3.001h-3.278c-0.347-0.595-0.984-1-1.722-1H8c-0.737,0-1.375,0.405-1.722,1H3 c-0.553,0-1,0.447-1,1V7.04c0,0.916,0.41,1.77,1.126,2.343l4.179,3.343C8.207,13.86,9.51,14.659,11,14.911v2.09H9 c-0.553,0-1,0.447-1,1v2H7v2h2h6h2v-2h-1v-2c0-0.553-0.447-1-1-1h-2v-2.09c1.49-0.252,2.793-1.051,3.695-2.186l4.18-3.344 C21.59,8.81,22,7.956,22,7.04V4.001C22,3.448,21.553,3.001,21,3.001z M14,20.001h-4v-1h4V20.001z M4.376,7.821 C4.137,7.631,4,7.345,4,7.04V5.001h2v4c0,0.042,0.006,0.083,0.006,0.125L4.376,7.821z M12,13.001c-2.205,0-4-1.794-4-4v-5h8v5 C16,11.207,14.205,13.001,12,13.001z M20,7.04c0,0.305-0.137,0.591-0.375,0.781l-1.631,1.305C17.994,9.084,18,9.043,18,9.001v-4h2 V7.04z"/>
        );
      case 'awardPedestal':
        return (
          <path d="M21,11.001h-5v-4c0-0.553-0.447-1-1-1H9c-0.553,0-1,0.447-1,1v3H3c-0.553,0-1,0.447-1,1v7 c0,0.552,0.447,1,1,1h6h6h6c0.553,0,1-0.448,1-1v-6C22,11.448,21.553,11.001,21,11.001z M4,12.001h4v5H4V12.001z M10,8.001h4v9h-4 V8.001z M20,17.001h-4v-4h4V17.001z"/>
        );
      case 'awardMedal1':
        return (
          <g> <path d="M22.6,15.201l-3.466-2.6C19.684,11.518,20,10.297,20,9.001c0-4.411-3.589-8-8-8s-8,3.589-8,8 c0,1.296,0.316,2.517,0.866,3.601l-3.466,2.6c-0.309,0.231-0.457,0.62-0.381,0.997c0.076,0.379,0.363,0.68,0.738,0.773 l3.418,0.854l0.854,3.418c0.099,0.392,0.424,0.687,0.824,0.746C6.902,21.998,6.951,22.001,7,22.001 c0.348,0,0.676-0.182,0.857-0.485l2.783-4.639c0.443,0.076,0.895,0.124,1.359,0.124s0.916-0.048,1.359-0.124l2.783,4.639 c0.183,0.304,0.51,0.485,0.857,0.485c0.049,0,0.098-0.003,0.146-0.011c0.4-0.06,0.726-0.354,0.824-0.746l0.854-3.418l3.418-0.854 c0.375-0.094,0.662-0.395,0.738-0.773C23.057,15.821,22.908,15.433,22.6,15.201z M7.385,18.417l-0.414-1.658 c-0.09-0.358-0.37-0.639-0.729-0.729l-1.961-0.489l1.706-1.28c0.74,0.845,1.653,1.532,2.687,2.007L7.385,18.417z M6,9.001 c0-3.309,2.691-6,6-6s6,2.691,6,6s-2.691,6-6,6S6,12.31,6,9.001z M17.758,16.03c-0.358,0.09-0.639,0.37-0.729,0.729l-0.414,1.658 l-1.289-2.149c1.033-0.475,1.946-1.162,2.687-2.007l1.706,1.28L17.758,16.03z"/> <path d="M13,13.001h-2V8.619L9.553,9.343L8.658,7.554l2.895-1.447c0.311-0.154,0.678-0.139,0.973,0.044 S13,6.654,13,7.001V13.001z"/> </g>
        );
      case 'awardHeart':
        return (
          <g> <path d="M12,21.999c-5.514,0-10-4.486-10-10c0-5.513,4.486-9.998,10-9.998s10,4.485,10,9.998 C22,17.513,17.514,21.999,12,21.999z M12,4.001c-4.411,0-8,3.588-8,7.998c0,4.411,3.589,8,8,8s8-3.589,8-8 C20,7.589,16.411,4.001,12,4.001z"/> <path d="M12,17.999c-0.178,0-0.355-0.048-0.515-0.143C10.924,17.52,6,14.465,6,10.999 c0-2.391,1.607-3.998,4-3.998c0.735,0,1.451,0.332,2,0.846c0.549-0.514,1.265-0.846,2-0.846c2.393,0,4,1.607,4,3.998 c0,3.466-4.924,6.521-5.485,6.857C12.355,17.951,12.178,17.999,12,17.999z M10,9.001c-1.805,0-2,1.397-2,1.998 c0,1.738,2.578,3.846,4,4.811c1.422-0.965,4-3.072,4-4.811c0-0.601-0.195-1.998-2-1.998c-0.449,0-1,0.551-1,0.998 c0,0.552-0.447,1-1,1s-1-0.448-1-1C11,9.552,10.449,9.001,10,9.001z"/> </g>
        );
      case 'new':
        return (
          <g> <path d="M12,0C5.384,0,0,5.383,0,12s5.384,12,12,12c6.617,0,12-5.383,12-12S18.617,0,12,0z M12,22 C6.486,22,2,17.514,2,12S6.486,2,12,2c5.515,0,10,4.486,10,10S17.515,22,12,22z" /> <path d="M8.666,11.335v1.333c0,1.103,0.898,2,2,2h2v-1.333h-2c-0.367,0-0.666-0.3-0.666-0.667h2v-1.333h-2 c0-0.368,0.299-0.667,0.666-0.667h2V9.335h-2C9.564,9.335,8.666,10.231,8.666,11.335z" /> <path d="M6.666,11.801L5.221,9.633c-0.162-0.246-0.467-0.356-0.747-0.27C4.192,9.448,4,9.706,4,10v4.668 h1.334v-2.465l1.445,2.168c0.125,0.188,0.336,0.296,0.555,0.296c0.063,0,0.129-0.009,0.191-0.028C7.807,14.554,8,14.295,8,14 V9.335H6.666V11.801z" /> <polygon points="18.629,9.335 17.99,11.936 17.334,9.335 16,9.335 15.962,9.335 15.324,11.936 14.666,9.335 13.334,9.335 14.643,14.668 14.653,14.668 15.986,14.668 16.014,14.668 16,14.615 16.657,12.012 17.309,14.668 17.32,14.668 18.653,14.668 18.681,14.668 18.666,14.615 20,9.335 		" /> </g>
        );
      case 'clock':
        return (
          <path d="M 11.9994,19.998C 16.4194,19.998 19.9994,16.4161 19.9994,11.998C 19.9994,7.58 16.4194,3.99805 11.9994,3.99805C 7.57939,3.99805 3.99939,7.58 3.99939,11.998C 3.99939,16.4161 7.57939,19.998 11.9994,19.998 Z M 11.9944,1.99813C 17.5204,1.99813 21.9994,6.4751 21.9994,11.9981C 21.9994,17.5212 17.5204,21.9981 11.9944,21.9981C 6.46735,21.9981 1.99937,17.5212 1.99937,11.9981C 1.99937,6.4751 6.46735,1.99813 11.9944,1.99813 Z M 12.4994,6.99813L 12.4994,12.2481L 16.9994,14.9172L 16.2464,16.1482L 10.9994,12.9981L 10.9994,6.99813L 12.4994,6.99813 Z " />
        );
      case 'clockUpload':
        return (
          <g> <polygon points="8,4 8,9.535 11.446,11.832 12.555,10.168 10,8.465 10,4 		" /> <path d="M15.315,12h2.16C17.809,11.06,18,10.053,18,9c0-4.962-4.037-9-9-9S0,4.038,0,9s4.038,9,9,9 c1.053,0,2.06-0.191,3-0.524v-2.161C11.089,15.75,10.075,16,9,16c-3.86,0-7-3.14-7-7c0-3.86,3.14-7,7-7c3.859,0,7,3.14,7,7 C16,10.075,15.75,11.09,15.315,12z" /> <polygon points="19,14.585 14.293,19.292 15.707,20.706 18,18.413 18,24 20,24 20,18.413 22.293,20.706 23.707,19.292 		" /> </g>
        );
      case 'clockDownload':
        return (
          <g> <polygon points="8,4 8,9.535 11.445,11.832 12.555,10.168 10,8.465 10,4 		" /> <path d="M15.314,12h2.16C17.809,11.06,18,10.053,18,9c0-4.962-4.037-9-9-9S0,4.038,0,9s4.038,9,9,9 c1.053,0,2.06-0.191,3-0.525v-2.16C11.089,15.75,10.074,16,9,16c-3.86,0-7-3.14-7-7c0-3.86,3.14-7,7-7c3.859,0,7,3.14,7,7 C16,10.075,15.749,11.09,15.314,12z" /> <polygon points="22.293,17.293 20,19.586 20,14 18,14 18,19.586 15.707,17.293 14.293,18.707 19,23.414 23.707,18.707 		" /> </g>
        );
      case 'edit':
        return (
          <path d="M20.821,3.18c-0.761-0.76-1.771-1.178-2.847-1.178c-1.074,0-2.086,0.418-2.846,1.179L3.72,14.59 c-0.128,0.128-0.22,0.289-0.263,0.464l-1.427,5.705c-0.084,0.34,0.016,0.702,0.264,0.948c0.247,0.249,0.608,0.349,0.949,0.264 l5.705-1.427c0.175-0.043,0.336-0.135,0.464-0.263L20.82,8.872c0.76-0.76,1.178-1.772,1.179-2.846c0-1.076-0.418-2.087-1.179-2.847 L20.821,3.18z M9.077,16.338l7.471-7.471l0.727,0.725l-7.955,7.955L9.077,16.338z M7.663,14.923l-1.209-0.241l7.956-7.955 l0.725,0.726L7.663,14.923z M5.166,16.464l1.976,0.395l0.396,1.977l-3.163,0.791L5.166,16.464z M15.823,5.313l0.72-0.718 c0.766-0.764,2.1-0.764,2.864,0l-0.002-0.001c0.765,0.765,0.765,2.099,0,2.864l-0.718,0.72L15.823,5.313z"/>        
        );
      case 'shutdown':
        return (
          <g> <path d="M15,5.589v2.223c1.791,1.04,3,2.974,3,5.188c0,3.309-2.691,6-6,6s-6-2.691-6-6 c0-2.215,1.209-4.148,3-5.188V5.589C6.072,6.779,4,9.65,4,13c0,4.411,3.589,8,8,8s8-3.589,8-8C20,9.65,17.928,6.779,15,5.589z"/> <rect x="11" y="3" width="2" height="8"/> </g>
        );
      case 'zip':
        return (
          <g> <path d="M0,0h24v24h-24Z" fill="none"></path> <path d="M18,2h-12c-1.103,0 -2,0.896 -2,2v16c0,1.103 0.897,2 2,2h12c1.103,0 2,-0.897 2,-2v-16c0,-1.104 -0.897,-2 -2,-2Zm-12,18v-16h6v2h2v-2h4l0.001,16h-12.001Z"></path> <path d="M14,6h2v2h-2Z"></path> <path d="M12,8h2v2h-2Z"></path> <path d="M14,10h2v2h-2Z"></path> <path d="M12,12h2v2h-2Z"></path> </g>
        );
      case 'zipAdd':
        return (
          <g> <path d="M0,0h24v24h-24Z" fill="none"></path> <path d="M6,4h2v2h-2Z"></path> <path d="M4,6h2v2h-2Z"></path> <path d="M6,8h2v2h-2Z"></path> <path d="M4,10h2v2h-2Z"></path> <path d="M16,2c0,-1.104 -0.897,-2 -2,-2h-12c-1.103,0 -2,0.896 -2,2v16c0,1.103 0.897,2 2,2h12v-2h-12v-16h2v2h2v-2h8l0.001,12h1.999v-12Z"></path> <path d="M21,19v-3h-2v3h-3v2h3v3h2v-3h3v-2Z"></path> </g>
        );
      case 'cameraAdd':
        return (
          <path d="M 6.2910156 2 C 5.7850156 2 5.3285625 2.3143125 5.1015625 2.8203125 L 5.0214844 3 L 2.5 3 C 1.673 3 1 3.673 1 4.5 L 1 11.5 C 1 12.327 1.673 13 2.5 13 L 6.2128906 13 C 6.1218906 12.677 6.0563906 12.344 6.0253906 12 L 2.5 12 C 2.224 12 2 11.775 2 11.5 L 2 4.5 C 2 4.225 2.224 4 2.5 4 L 5.6679688 4 L 6.0136719 3.2304688 C 6.0766719 3.0904687 6.1860156 3 6.2910156 3 L 9.7089844 3 C 9.8139844 3 9.9233281 3.0914688 9.9863281 3.2304688 L 10.332031 4 L 13.5 4 C 13.776 4 14 4.225 14 4.5 L 14 6.6054688 C 14.357 6.7884687 14.694 7.0048125 15 7.2578125 L 15 4.5 C 15 3.673 14.327 3 13.5 3 L 10.978516 3 L 10.898438 2.8183594 C 10.670438 2.3133594 10.213984 2 9.7089844 2 L 6.2910156 2 z M 3 5 L 3 6 L 4 6 L 4 5 L 3 5 z M 8 5 C 6.346 5 5 6.346 5 8 C 5 8.943 5.4459062 9.7761719 6.1289062 10.326172 C 6.2079063 9.9611719 6.3236562 9.6093906 6.4726562 9.2753906 C 6.1816563 8.9273906 6 8.487 6 8 C 6 6.897 6.897 6 8 6 C 8.487 6 8.9273906 6.1816562 9.2753906 6.4726562 C 9.6093906 6.3246563 9.9611719 6.2089062 10.326172 6.1289062 C 9.7761719 5.4459063 8.943 5 8 5 z M 11.5 7 C 9.0206409 7 7 9.0206409 7 11.5 C 7 13.979359 9.0206409 16 11.5 16 C 13.979359 16 16 13.979359 16 11.5 C 16 9.0206409 13.979359 7 11.5 7 z M 11.5 8 C 13.438919 8 15 9.5610811 15 11.5 C 15 13.438919 13.438919 15 11.5 15 C 9.5610811 15 8 13.438919 8 11.5 C 8 9.5610811 9.5610811 8 11.5 8 z M 11 9 L 11 11 L 9 11 L 9 12 L 11 12 L 11 14 L 12 14 L 12 12 L 14 12 L 14 11 L 12 11 L 12 9 L 11 9 z" />
        );
      case 'heartEmpty':
        return (
          <path d="M12,20.001c-0.184,0-0.367-0.051-0.529-0.152C11.124,19.633,3,14.492,3,9.001c0-2.757,2.243-5,5-5 c1.594,0,3.07,0.837,4,2.08c0.93-1.243,2.406-2.08,4-2.08c2.757,0,5,2.243,5,5c0,5.491-8.124,10.632-8.471,10.848 C12.367,19.95,12.184,20.001,12,20.001z M8,6.001c-1.654,0-3,1.346-3,3c0,3.363,4.643,7.207,7,8.806c2.357-1.599,7-5.442,7-8.806 c0-1.654-1.346-3-3-3c-1.689,0-3,1.612-3,3c0,0.552-0.447,1-1,1s-1-0.448-1-1C11,7.613,9.689,6.001,8,6.001z" />
        );
      case 'heart':
        return (
          <path d="M16,4.001c-1.594,0-3.07,0.837-4,2.08c-0.93-1.243-2.406-2.08-4-2.08c-2.757,0-5,2.243-5,5 c0,5.491,8.124,10.632,8.471,10.848c0.162,0.101,0.346,0.152,0.529,0.152s0.367-0.051,0.529-0.152 C12.876,19.633,21,14.492,21,9.001C21,6.244,18.757,4.001,16,4.001z" />
        );
      case 'heartAdd':
        return (
          <g> <path d="M9,16c-0.184,0-0.367-0.051-0.529-0.152C8.124,15.632,0,10.491,0,5c0-2.757,2.243-5,5-5 c1.594,0,3.07,0.837,4,2.08C9.93,0.837,11.406,0,13,0c2.757,0,5,2.243,5,5c0,5.491-8.124,10.632-8.471,10.848 C9.367,15.949,9.184,16,9,16z M5,2C3.346,2,2,3.346,2,5c0,3.363,4.643,7.207,7,8.806c2.357-1.599,7-5.442,7-8.806 c0-1.654-1.346-3-3-3c-1.689,0-3,1.613-3,3c0,0.552-0.447,1-1,1S8,5.552,8,5C8,3.613,6.689,2,5,2z" /> <polygon points="24,19 21,19 21,16 19,16 19,19 16,19 16,21 19,21 19,24 21,24 21,21 24,21 		" /> </g>
        );
      case 'userCamera':
        return (
          <g><path d="M8.00201 16.006C5.79701 16.006 4.00201 14.211 4.00201 12.006C4.00201 9.80101 5.79701 8.00601 8.00201 8.00601C10.207 8.00601 12.002 9.80101 12.002 12.006C12.002 14.211 10.208 16.006 8.00201 16.006ZM8.00201 10.006C6.89801 10.006 6.00201 10.902 6.00201 12.006C6.00201 13.109 6.89801 14.006 8.00201 14.006C9.10601 14.006 10.002 13.109 10.002 12.006C10.002 10.902 9.10601 10.006 8.00201 10.006Z" /> <path d="M16.002 24.006H0.00201416V23.006C0.00201416 19.473 3.29101 17.006 8.00201 17.006C12.713 17.006 16.002 19.473 16.002 23.006V24.006ZM2.16101 22.006H13.845C13.246 20.198 11.011 19.006 8.00301 19.006C4.99501 19.006 2.75901 20.198 2.16101 22.006V22.006Z" /> <path d="M19.5 3C18.1214 3 17 4.12143 17 5.5C17 6.87929 18.1214 8 19.5 8C20.8786 8 22 6.87929 22 5.5C22 4.12143 20.8786 3 19.5 3ZM19.5 6.57143C18.9093 6.57143 18.4286 6.09143 18.4286 5.5C18.4286 4.91 18.9093 4.42857 19.5 4.42857C20.0907 4.42857 20.5714 4.91 20.5714 5.5C20.5714 6.09143 20.0907 6.57143 19.5 6.57143Z" /> <path d="M22.8 1.33333H22.2V0H19.8V1.33333H13.2C12.5382 1.33333 12 1.93133 12 2.66667V8.66667C12 9.402 12.5382 10 13.2 10H22.8C23.4618 10 24 9.402 24 8.66667V2.66667C24 1.93133 23.4618 1.33333 22.8 1.33333ZM13.2 8.66667V2.66667H22.8L22.8006 8.66667H13.2Z" /> <path d="M14 3H16V5H14V3Z" /> </g>
        );
      case 'cameraEmpty':
        return (
          <g> <path d="M12,7.999c-2.757,0-5,2.243-5,5c0,2.758,2.243,5,5,5s5-2.242,5-5C17,10.241,14.757,7.999,12,7.999z M12,15.999c-1.654,0-3-1.346-3-3c0-1.654,1.346-3,3-3s3,1.346,3,3C15,14.653,13.654,15.999,12,15.999z" /> <path d="M20,5.999h-3.465l-1.406-2.109c-0.372-0.558-0.994-0.891-1.664-0.891h-2.93 c-0.67,0-1.292,0.333-1.664,0.891L7.465,5.999H4c-1.103,0-2,0.897-2,2v11c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-11 C22,6.896,21.103,5.999,20,5.999z M4,18.999v-11h4.535l2-3h2.93l2,3H20l0.001,11H4z" /> </g>
        );
      case 'camera':
        return (
          <g> <path d="M12,9c-2.206,0-4,1.794-4,4c0,2.207,1.794,4,4,4s4-1.793,4-4C16,10.795,14.206,9,12,9z" /> <path d="M20,6.001h-1.93c-0.668,0-1.293-0.334-1.664-0.89l-0.812-1.22C15.223,3.335,14.598,3,13.93,3H10.07 c-0.668,0-1.293,0.335-1.664,0.89L7.594,5.11c-0.371,0.557-0.996,0.89-1.664,0.89H4c-1.105,0-2,0.896-2,2v11c0,1.104,0.895,2,2,2 h16c1.104,0,2-0.896,2-2V8C22,6.897,21.104,6.001,20,6.001z M12,19c-3.309,0-6-2.691-6-6c0-3.308,2.691-6,6-6s6,2.692,6,6 C18,16.31,15.309,19,12,19z" /> </g>
        );
      case 'user':
        return (
          <g> <path d="M12.002,12.006c2.206,0,4-1.795,4-4c0-2.205-1.794-4-4-4c-2.206,0-4,1.795-4,4 C8.002,10.211,9.797,12.006,12.002,12.006z" /> <path d="M12.002,13.006c-4.71,0-8,2.467-8,6v1h16v-1C20.002,15.473,16.713,13.006,12.002,13.006z" /> </g>
        );
      case 'userEmpty':
        return (
          <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z" />
        );
      case 'calendar':
        return (
          <path d="M18.9994,18.9981h-14v-11h14m-3,-7v2h-8v-2h-2v2h-1c-1.106,0 -1.99001,0.89499 -1.99001,2l-0.00999,14c0,1.104 0.894,2 2,2h14c1.103,0 2,-0.896 2,-2v-14c0,-1.10501 -0.897,-2 -2,-2h-1v-2m-1,11h-5v5h5v-5Z"></path>
        );
      case 'calendarBlank':
        return (
          <path d="M18.9994,18.9981h-14v-11h14m-3,-7v2h-8.00003v-2h-2v2h-1c-1.106,0 -1.99001,0.89499 -1.99001,2l-0.00999,14c0,1.104 0.894,2 2,2h14c1.103,0 2,-0.896 2,-2v-14c0,-1.10501 -0.897,-2 -2,-2h-1v-2"></path>
        );
      case 'forward':
        return (
          <path d="M 5.586,7.41398L 7,6L 13,12L 7,18L 5.586,16.586L 10.172,12L 5.586,7.41398 Z M 11.586,7.41399L 13,6.00001L 19,12L 13,18L 11.586,16.586L 16.172,12L 11.586,7.41399 Z " />
        );
      case 'back':
        return (
          <path d="M 18.414,7.41398L 17,6L 11,12L 17,18L 18.414,16.586L 13.828,12L 18.414,7.41398 Z M 12.414,7.41398L 11,6L 5.00001,12L 11,18L 12.414,16.586L 7.82801,12L 12.414,7.41398 Z " />
        );
      case 'close':
        return (
          <polygon points="20.749,4.707 19.334,3.293 12.042,10.586 4.749,3.293 3.334,4.707 10.627,12 3.334,19.293 4.749,20.707 12.042,13.414 19.334,20.707 20.749,19.293 13.456,12 	" />
        );
      case 'wrench':
        return (
          <path d="M19,9c0-2.956-1.875-5.606-4.666-6.593L13,1.934V8c0,0.553-0.449,1-1,1s-1-0.447-1-1V1.934 L9.666,2.407C6.875,3.394,5,6.044,5,9c0,2.786,1.639,5.188,4,6.315V22h2v-6h2v6h2v-6.685C17.361,14.188,19,11.785,19,9z M12,14 c-2.757,0-5-2.243-5-5c0-1.595,0.766-3.065,2-3.993V8c0,1.654,1.346,3,3,3s3-1.346,3-3V5.006C16.234,5.934,17,7.404,17,9 C17,11.756,14.757,14,12,14z"/>
        );
      default:
        return null;
    }
  }

  render() {
    const { wrapperStyle, color, description, size, background, label, viewBox } = this.props;
    const styles = this.getStyles();
    let style = Object.assign({ fill: color, display: 'flex', flex: 'none' }, wrapperStyle, { width: size }, { height: size }, background ? { background } : null);

    return (
      <div style={styles.wrapper}>
        <div style={style} title={description}>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%">
            <svg preserveAspectRatio="xMidYMid meet" viewBox={`0 0 ${viewBox} ${viewBox}`} width="100%" height="100%">
              {this.getGraphics()}
            </svg>
          </svg>
        </div>
        {label !== null ? <span style={styles.label}>{label}</span> : null}
      </div>
    );
  }
}

Icon.defaultProps = {
  color: 'currentColor',
  viewBox: '24'
};

export default Icon;
