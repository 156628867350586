import React, { useState, useRef } from 'react';
import AppDispatcher from '../AppDispatcher/AppDispatcher';
import Icon from '../Icon/Icon';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tippy';
import { Translation } from 'react-i18next';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
  MenuListDivider
} from '@material/react-menu';
import { useOnClickOutside } from '../../utils/hooks';
import get from 'lodash/get';

const AppBar = (props) => {
  const { isMobile, userInfo, logged, options, shutdownOption, updated } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(undefined);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));

  const desktopStyles = {
    wrapper: {
      width: 85,
      flex: 'none',
      flexDirection: 'column',
      background: '#fefefe',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box'
    },
    logo: {
      fontWeight: 'bold',
      fontSize: 22,
      color: '#000',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      height: 85
    },
    flex: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 16
    },
    button: {
      padding: '5px 10px',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 14,
      borderRadius: 4,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      border: 'none',
      cursor: 'pointer',
      outline: 'none'
    },
    flatButton: {
      padding: '5px 20px',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 14,
      boxShadow: 'none',
      border: 'none',
      cursor: 'pointer',
      outline: 'none'
    },
    number: {
      color: '#00d663'
    },
    profileButton: {
      padding: 0,
      borderRadius: '50%',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      border: '3px solid black',
      cursor: 'pointer',
      outline: 'none',
      width: 45,
      height: 45,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      marginTop: 15,
      flex: 'none'
    },
    profilePhotoButton: {
      padding: 0,
      border: 'none',
      borderRadius: '50%',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      cursor: 'pointer',
      outline: 'none',
      width: 45,
      height: 45,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      marginTop: 15,
      flex: 'none'
    },
    photo: {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    }
  };

  const mobileStyles = {
    wrapper: {
      height: 60,
      flex: 'none',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '0 8px'
    },
    logo: {
      fontWeight: 'bold',
      fontSize: 22,
      color: '#000',
      cursor: 'pointer',
      width: 50,
      height: 60,
      display: 'flex',
      alignItems: 'center',
    },
    flex: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      width: 133,
    },
    button: {
      padding: '5px 10px',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 14,
      borderRadius: 4,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      border: 'none',
      cursor: 'pointer',
      outline: 'none'
    },
    flatButton: {
      padding: '5px 20px',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 14,
      boxShadow: 'none',
      border: 'none',
      cursor: 'pointer',
      outline: 'none'
    },
    number: {
      color: '#00d663'
    },
    profileButton: {
      padding: 0,
      borderRadius: '50%',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      border: '3px solid black',
      cursor: 'pointer',
      outline: 'none',
      width: 45,
      height: 45,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      flex: 'none'
    },
    profilePhotoButton: {
      padding: 0,
      border: 'none',
      borderRadius: '50%',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      cursor: 'pointer',
      outline: 'none',
      width: 45,
      height: 45,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      flex: 'none'
    },
    photo: {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    },
    bubble: {
      background: '#f44336',
      fontSize: 10,
      fontWeight: 600,
      position: 'absolute',
      top: -5,
      right: -5,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 15,
      height: 15,
      boxSizing: 'border-box',
      padding: '0 4px',
      borderRadius: 25,
    },
    smallBubble: {
      background: '#f44336',
      position: 'absolute',
      top: 6,
      right: 10,
      minWidth: 10,
      height: 10,
      borderRadius: 10,
    }
  };

  const styles = isMobile ? mobileStyles : desktopStyles;

  const renderMenu = () => {
    return (
      <Translation>
        {t => (
          <Menu
            className="mdc-menu-desktop"
            open={isMenuOpen}
            onClose={handleClose}
            coordinates={coordinates}
            onSelected={index => handleLinkClick(options[index])}
          >
            <MenuList>
              <div ref={ref}>
                {options.map(option => {
                  const hasBubble = get(props, option.bubble);

                  return (
                    <MenuListItem key={option.id}>
                      <MenuListItemGraphic 
                        graphic={
                          <div style={{marginRight: 12, position: 'relative'}}>
                            {hasBubble ? <span className="blink-1" style={styles.bubble}>!</span> : null}
                            <img src={option.icon} alt={option.label} style={{marginRight: 8, width: '100%', height: '100%'}} />
                          </div>} 
                        />
                      <MenuListItemText primaryText={t(option.label)} className={option.label === 'Remove' && 'mdc-text-red'} />
                    </MenuListItem>
                  )
                })}
                {isMobile ? 
                <>
                  <MenuListDivider />
                  <MenuListItem>
                    <MenuListItemGraphic graphic={<img src={shutdownOption.icon} alt={shutdownOption.label} style={{ marginRight: 8 }} />} />
                    <MenuListItemText primaryText={t(shutdownOption.label)} />
                  </MenuListItem>
                </> : null}
              </div>
            </MenuList>
          </Menu>
        )}
      </Translation>
    );
  };

  const handleLinkClick = ({ id } = {}) => {
    if (!id) {
      handleLogoutClick();
    } else {
      AppDispatcher.dispatch({
        actionType: 'CHANGE_VIEW',
        view: id
      });
    }
  }

  const handleShowMenu = event => {
    setMenuOpen(true);
    setCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleLogoClick = () => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: 'home'
    });
  }

  const handleLoginClick = () => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: 'login'
    });
  }

  const handleAdminClick = () => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: 'admin'
    });
  }

  const handleLogoutClick = () => {
    AppDispatcher.dispatch({
      actionType: 'LOGOUT'
    });
  }

  const handleProfileClick = () => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: 'profile'
    });
  }

  const handleSearchClick = () => {
    AppDispatcher.dispatch({
      actionType: 'CHANGE_VIEW',
      view: 'search'
    });
  }

  const renderAdminButton = () => {
    return (
      <Translation>
        {t => (
          <Tooltip
            title={t("Admin area")}
            position={isMobile ? 'bottom' : 'right'}
            trigger="mouseenter"
            theme="light"
            arrow={false}
            distance={15}
          >
            <motion.button
              onClick={handleAdminClick}
              style={Object.assign({}, styles.flatButton, { padding: '5px 10px', background: 'transparent', marginTop: 0 })}
              whileHover={{ scale: 1.5 }}
              whileTap={{ scale: 0.8 }}
            >
              <Icon size={24} icon="wrench" color="#000000" />
            </motion.button>
          </Tooltip>
        )}
      </Translation>
    );
  }

  const renderLoggedButtons = () => {
    return (
      <div style={{...styles.buttons, justifyContent: isMobile ? 'flex-end' : 'initial'}}>
        {userInfo.role === 'admin' && renderAdminButton()}
        {renderProfileButton()}
        {isMobile ? renderMenuButton() : renderLogoutButton()}
      </div>
    );
  }

  const renderProfileButton = () => {
    const { photo } = userInfo;

    return (
      <motion.button
        onClick={handleProfileClick}
        style={photo ? styles.profilePhotoButton : styles.profileButton}
        whileHover={{ scale: 1.5 }}
        whileTap={{ scale: 0.8 }}
      >
        {photo ? <img style={styles.photo} src={photo} alt="name" /> : <Icon size={50} icon="user" color="#ababab" />}
      </motion.button>
    );
  }

  const renderSearchButton = () => {
    return (
      <motion.button
        onClick={handleSearchClick}
        style={Object.assign({}, styles.flatButton, { padding: '5px 10px', background: 'transparent', marginTop: 0 })}
        whileHover={{ scale: 1.5 }}
        whileTap={{ scale: 0.8 }}
      >
        <Icon size={24} icon="search" color="#000000" />
      </motion.button>
    );
  }

  const renderLogoutButton = () => {
    return (
      <motion.button
        onClick={handleLogoutClick}
        style={Object.assign({}, styles.flatButton, { padding: '5px 10px', background: 'transparent', marginTop: isMobile ? 0 : 10 })}
        whileHover={{ scale: 1.5 }}
        whileTap={{ scale: 0.8 }}
      >
        <Icon size={24} icon="shutdown" color="#000000" />
      </motion.button>
    );
  }

  const renderMenuButton = () => {
    return (
      <motion.button
        onClick={handleShowMenu}
        style={{...styles.flatButton, padding: '5px 10px', background: 'transparent', marginTop: isMobile ? 0 : 10, position: 'relative' }}
        whileHover={{ scale: 1.5 }}
        whileTap={{ scale: 0.8 }}
      >
        {updated && updated.events ? <span style={styles.smallBubble}/> : null}
        <Icon size={24} icon="menu" color="#000000" />
      </motion.button>
    );
  }

  const renderGuestButtons = () => {
    return (
      <Translation>
        {t => (
          <div style={styles.buttons}>
            <Tooltip
              title={t("Login")}
              position={isMobile ? 'bottom' : 'right'}
              trigger="mouseenter"
              theme="light"
              arrow={false}
              distance={15}
            >
              <motion.button
                onClick={handleLoginClick}
                style={Object.assign({}, styles.flatButton, { padding: '5px 10px', background: 'transparent' })}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
              >
                <Icon size={24} icon="login" color="#000" />
              </motion.button>
            </Tooltip>
          </div>
        )}
      </Translation>
    );
  }

  const renderButtons = () => {
    if (logged && userInfo) return renderLoggedButtons();

    return renderGuestButtons();
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.buttons}>
        <div style={styles.logo} onClick={handleLogoClick}>
          <img src="logo.svg" width="100%" height="60" alt="logo" />
        </div>
        {renderSearchButton()}
      </div>
      <div style={styles.flex}>
      </div>
      {renderButtons()}
      {isMenuOpen && renderMenu()}
    </div>
  );
}

AppBar.defaultProps = {
  options: [
    { id: 'trending', icon: 'fire.svg', color: '#ff6242', label: 'Trending' },
    { id: 'photographers', icon: 'camera.svg', color: '#77849D', label: 'Photographers' },
    { id: 'organizations', icon: 'competitions.svg', color: '#ffb000', label: 'Competitions' },
    { id: 'lastUpdated', icon: 'watch.svg', color: '#000000', label: 'Updated', bubble: 'updated.events' },
    { id: 'year', icon: 'telescope.svg', color: '#666666', label: 'Explore' },
    { id: 'search', icon: 'search.svg', color: '#00b8f0', label: 'Search' },
  ],
  shutdownOption: { id: 'shutdown', icon: 'shutdown.svg', color: '#000000', label: 'Exit' },
};

export default AppBar;
