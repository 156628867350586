import React from 'react';
import GalleryEvent from './GalleryEvent';
import { FixedSizeGrid as Grid } from 'react-window';
import { SizeMe } from 'react-sizeme';
import _ from 'lodash';
import List from '../../List/List';
import EventBox from '../../EventBox/EventBox';
import { Translation } from 'react-i18next';

class ProfileEventGallery extends React.Component {
  constructor(props) {
    super(props);
    const { columnCount } = this.getEventSizes();
    this.state = {
      chunkData: props.data && _.chunk(props.data, columnCount)
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const { columnCount } = this.getEventSizes();
      this.setState({
        chunkData: this.props.data ? _.chunk(this.props.data, columnCount) : null
      });
    }
  }

  getStyles() {
    return {
      wrapper: {
        flex: 1,
        display: 'flex'
      },
      eventGallery: {
        flex: 1,
        padding: 2
      },
      eventPlaceholder: {
        overflow: 'auto',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      eventPlaceholderImage: {
        width: 100
      },
      eventPlaceholderText: {
        textAlign: 'center',
        color: '#00d663',
        fontWeight: 600
      }
    }
  }

  renderPlaceholderGallery = () => {
    const styles = this.getStyles();

    return (
      <div style={styles.eventPlaceholder}>
        <div style={styles.eventPlaceholderImage}><img src="empty-gallery.svg" alt="Galería vacía" /></div>
        <div style={styles.eventPlaceholderText}>
          <Translation>
            {t => t("You don't have participate yet")}
          </Translation>
        </div>
      </div>
    );
  }

  toggleModal = (index) => {
    const { currentModal } = this.state;

    this.setState({
      currentModal: !currentModal ? index : null
    });
  }

  parseEvents = (data) => {
    if (!data) return null;

    return data.map(event => ({
      ...event,
      src: event.photo,
      width: 2,
      height: 2
    }));
  }

  getGalleryItemData = ({ rowIndex, columnIndex }) => {
    const { chunkData } = this.state;

    return chunkData && chunkData[rowIndex] ? chunkData[rowIndex][columnIndex] : null;
  }

  getEventSizes = () => {
    const { isMobile, isTablet } = this.props;

    if (isTablet) {
      return {
        columnCount: 2
      }
    }

    if (isMobile) {
      return {
        columnCount: 1
      }
    }

    return {
      columnCount: 2
    }
  }

  renderGallery = () => {
    const { chunkData } = this.state;
    const { isMobile, data } = this.props;
    const styles = this.getStyles();
    const { columnCount } = this.getEventSizes();

    if (!chunkData || chunkData.length === 0) return this.renderPlaceholderGallery();

    if (isMobile) {
      return (
        <List
          ItemComponent={EventBox}
          data={data}
          isMobile={isMobile}
        />
      );
    }

    return (
      <div style={styles.eventGallery}>
        <SizeMe monitorWidth monitorHeight>
          {({ size }) =>
            <Grid
              columnCount={columnCount}
              columnWidth={size.width / columnCount}
              height={(size.width / columnCount) * chunkData.length}
              rowCount={chunkData.length}
              rowHeight={size.width / columnCount}
              width={size.width}
              itemData={{
                getGalleryItemData: this.getGalleryItemData,
                isMobile,
              }}
            >
              {GalleryEvent}
            </Grid>
          }
        </SizeMe>
      </div>
    );
  }

  render() {
    const styles = this.getStyles();

    return (
      <div style={styles.wrapper}>
        {this.renderGallery()}
      </div>
    );
  }
}

export default ProfileEventGallery;
