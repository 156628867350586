import React from 'react';
import EventBox from '../../EventBox/EventBox';

const GalleryEvent = (props) => {
  const { columnIndex, rowIndex, data, index, style } = props;
  const itemData = data && data.getGalleryItemData && data.getGalleryItemData({ columnIndex, rowIndex });

  if (!itemData) return null;

  return (
    <EventBox key={index} style={style} {...itemData} />
  );
}

export default GalleryEvent;
