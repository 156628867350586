import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import Icon from '../Icon/Icon';
import { baseUrl } from '../../config'; 
import handleResponse from '../../utils/handleResponse';
import { Translation } from 'react-i18next';
import Select from "react-select";
import moment from 'moment';

const MySelect = ({ onChange, onBlur, id, label, components, options, value, error, touched, placeholder, isMulti }) => {
  const handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    onChange(id, value);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    onBlur(id, true);
  };

  const getOption = () => {
    if (isMulti) {
      return value && value.map(v => options.find(o => {
        if (v.value) {
          return o.value === v.value
        }
        return o.value === v
      }));
    }

    return options.find(o => o.value === value);
  }

  return (
    <Translation>
      {t => (
        <>
          <label htmlFor={id}>{t(label)}</label>
          <Select
            id={id}
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            value={getOption()}
            placeholder={t(placeholder)}
            components={components}
            isMulti={isMulti}
          />
          {!!error && touched && (
            <div style={{ color: "#00d663", marginTop: ".5rem" }}>
              {t(error)}
            </div>
          )}
        </>
      )}
    </Translation>
  );
}

class FormSeason extends Component {
  getStyles = () => {
    const { isMobile } = this.props;

    return {
      wrapper: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        background: '#fff',
      },
      form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      },
      fields: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        overflow: 'auto',
        padding: 20,
      },
      field: {
        marginBottom: 10,
        flexDirection: 'column',
        display: 'flex'
      },
      label: {
        marginBottom: 10,
        paddingLeft: 2
      },
      input: {
        border: '1px solid transparent',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40,
        width: '100%'
      },
      inputError: {
        border: '1px solid #00d663',
        background: '#f9f8f8',
        padding: 10,
        borderRadius: 5,
        fontSize: 16,
        boxSizing: 'border-box',
        height: 40,
        width: '100%'
      },
      inputFeedback: {
        color: '#00d663',
        fontSize: 12,
        marginTop: 5
      },
      header: {
        background: '#00d663',
        color: '#000',
        flex: 'none',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: isMobile ? 16 : 20,
        position: 'relative'
      },
      buttons: {
        flex: 'none',
        height: 50,
        background: '#f9f8f8',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: isMobile ? 5 : 20
      },
      mainButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      gap: {
        flex: 1
      },
      secondaryButtons: {
        width: isMobile ? 'auto' : 250,
        display: 'flex',
        justifyContent: 'space-around'
      },
      button: {
        background: '#00d663',
        color: '#fff',
        padding: isMobile ? '5px 15px' : '5px 30px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: isMobile ? 10 : 14,
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        border: 'none',
        cursor: 'pointer',
        outline: 'none'
      },
      close: {
        position: 'absolute',
        right: 10
      },
      closeButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'transparent'
      }
    }
  }

  handleSubmit = (values, actions) => {
    const { handleClose, data } = this.props;
    const url = data && data._id ? `${baseUrl}/seasons/${data._id}` : `${baseUrl}/seasons`;
    const body = {
      ...values,
      federationId: values.federationId && values.federationId.value ? values.federationId.value : values.federationId
    };
    
    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => {
      return handleResponse(response);
    }).then(([ok, status, data]) => {
      if (!ok) {
        throw new Error(data || 'Oops! Something went wrong');
      }

      handleClose({ reload: true });
    }).catch((error) => {
      actions.setSubmitting(false);
      actions.setFieldError('name', error.message);
    });
  }

  handleDelete = () => {
    const { handleClose, data } = this.props;
    let url = `${baseUrl}/seasons/${data._id}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      handleClose({ reload: true });
    }).catch((err) => {
      console.log(err);
    });
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      begin: Yup.string().required('You must enter a begin date'),
      end: Yup.string().required('You must enter a end date'),
      federationId: Yup.string().required('You must select a federation')
    });
  }

  getInitialValues = () => {
    const { data } = this.props;

    return {
      federationId: '',
      ...data,
      begin: data && data.begin ? moment(data.begin).format('YYYY-MM-DD') : '',
      end: data && data.end ? moment(data.end).format('YYYY-MM-DD') : '',
    }
  }

  render() {
    const { header, handleClose, federations, data } = this.props;
    const styles = this.getStyles();

    return (
      <Translation>
        {t => (
          <div style={styles.wrapper}>
            <Formik
              initialValues={this.getInitialValues()}
              onSubmit={this.handleSubmit}
              validationSchema={this.getValidationSchema()}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  handleReset,
                  setFieldValue,
                  setFieldTouched
                } = props;
                return (
                  <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.header}>
                      {t(header)}
                      {handleClose &&
                        <div style={styles.close}>
                          <motion.button
                            type="button"
                            onClick={handleClose}
                            style={styles.closeButton}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.8 }}
                          >
                            <Icon size={18} icon="close" color="#000" />
                          </motion.button>
                        </div>
                      }
                    </div>

                    <div style={styles.fields}>
                      
                      <div style={styles.field}>
                        <MySelect
                          value={values.federationId}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.federationId}
                          touched={touched.federationId}
                          options={federations}
                          id="federationId"
                          label="Federation"
                          placeholder="Select federation"
                        />
                      </div>

                      <div style={styles.field}>
                        <label htmlFor="begin" style={styles.label}>
                          {t('Begin date')}
                        </label>
                        <input
                          id="begin"
                          type="date"
                          value={values.begin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.begin && touched.begin ? styles.inputError : styles.input}
                        />
                        {errors.begin && touched.begin && (
                          <div style={styles.inputFeedback}>{t(errors.begin)}</div>
                        )}
                      </div>

                      <div style={styles.field}>
                        <label htmlFor="end" style={styles.label}>
                          {t('End date')}
                        </label>
                        <input
                          id="end"
                          type="date"
                          value={values.end}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={errors.end && touched.end ? styles.inputError : styles.input}
                        />
                        {errors.end && touched.end && (
                          <div style={styles.inputFeedback}>{t(errors.end)}</div>
                        )}
                      </div>
                    </div>

                    <div style={styles.buttons}>
                      <div style={styles.mainButtons}>
                        <motion.button
                          type="button"
                          onClick={handleReset}
                          style={Object.assign({}, styles.button, { color: '#000', background: '#fff' })}
                          disabled={!dirty || isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('RESET')}
                        </motion.button>
                        <motion.button
                          type="submit"
                          style={Object.assign({}, styles.button, { color: '#000', background: '#00d066' })}
                          disabled={isSubmitting}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('SUBMIT')}
                        </motion.button>
                      </div>
                      <div style={styles.gap}></div>
                      {data && <div style={styles.secondaryButtons}>
                        <motion.button
                          type="button"
                          onClick={this.handleDelete}
                          style={Object.assign({}, styles.button, { color: '#000', background: '#00d663' })}
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          {t('DELETE SEASON')}
                        </motion.button>
                      </div>
                      }
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}
      </Translation>
    )
  }
}

export default FormSeason;