import BaseStore from './BaseStore.js';
import i18n from "i18next";
import moment from 'moment';
import es from 'moment/locale/es'

class AppStore extends BaseStore {
  constructor() {
    super();
    this.subscribe(() => this._registerToActions);
    this._token = localStorage.getItem('token');
    this._userInfo = localStorage.getItem('userInfo');
    this._view = null;
    this._viewData = null;

    this._currentYear = null;
    this._currentYearData = null;
    this._selectedEvent = null;
    this._externalUserInfo = null;
    this._updated = null;
  }

  _registerToActions = (action) => {
    switch (action.actionType) {
      case 'LOGIN':
        this.saveToken(action);
        this.emitChange();
        break;
      case 'LOGOUT':
        this.clearData();
        this.emitChange();
        break;
      case 'CHANGE_VIEW':
        this._view = action.view;
        this._viewData = action.data;
        this._selectedEvent = null;
        this.emitChange();
        break;
      case 'BACK_TO_YEAR_VIEW':
        this._view = 'year';
        this._selectedMonth = null;
        this._selectedMonthEvents = null;
        this._selectedEvent = null;
        this._externalUserInfo = null;
        this.emitChange();
        break;
      case 'BACK_TO_MONTH_VIEW':
        this._view = 'month';
        this._selectedEvent = null;
        this.emitChange();
        break;
      case 'GET_YEAR':
        this._view = 'year';
        this._currentYear = action.year;
        this._currentYearData = action.data;
        this._selectedEvent = null;
        this.emitChange();
        break;
      case 'GET_EVENT':
        this._view = 'event';
        this._selectedEvent = action.data;
        this.emitChange();
        break;
      case 'GET_USER':
        localStorage.setItem('userInfo', JSON.stringify(action.data));
        this._userInfo = action.data;
        this.emitChange();
        break;
      case 'GET_EXTERNAL_USER_INFO_AND_GO_TO_PROFILE':
        this._view = 'externalProfile';
        this._viewData = action.data;
        this._selectedEvent = null;
        this._externalUserInfo = action.externalUserInfo;
        this.emitChange();
        break;
      case 'GET_EXTERNAL_USER_INFO':
        this._externalUserInfo = action.externalUserInfo;
        this.emitChange();
        break;
      case 'UPDATE_EVENT_PICTURE':
        if (this._selectedEvent && action.data) {
          this._selectedEvent = this.updateEventPicture(action.data);
          this.emitChange();
        }
        break;
      case 'DECREMENT_EVENT_UNVIEWS':
        if (this._selectedEvent && action.data) {
          this._selectedEvent = this.decrementEventUnviews(action.data);
          this.emitChange();
        }
        break;
      case 'REMOVE_EVENT_PICTURE':
        if (this._selectedEvent) {
          this._selectedEvent = this.removeEventPicture(action.data);
          this.emitChange();
        }
        break;
      case 'UPDATE_EVENT_USERS':
        if (this._selectedEvent) {
          this._selectedEvent = this.updateEventUsers(action.data);
          this.emitChange();
        }
        break;
      case 'ADD_IMAGES':
        if (this._selectedEvent && action.data) {
          this._selectedEvent = this.addEventPictures(action.data);
          this.emitChange();
        }
        break;
      case 'SET_UPDATED':
        this._updated = action.data;
        this.emitChange();
        break;
      default:
        break;
    }
  }

  addEventPictures = (items) => {
    return {
      ...this._selectedEvent,
      detail: this.addItems(this._selectedEvent.detail, items)
    }
  }

  updateEventPicture = (item) => {
    return {
      ...this._selectedEvent,
      detail: this.updateItem(this._selectedEvent.detail, item)
    }
  }

  removeEventPicture = (item) => {
    return {
      ...this._selectedEvent,
      detail: this.removeItem(this._selectedEvent.detail, item)
    }
  }

  decrementEventUnviews = () => {
    return {
      ...this._selectedEvent,
      stats: {
        ...this._selectedEvent.stats,
        unviews: this._selectedEvent.stats.unviews - 1
      }
    }
  }

  updateEventUsers = (item) => {
    return {
      ...this._selectedEvent,
      users: item.users
    }
  }

  addItems = (data, items) => {
    return [
      ...data,
      ...items
    ]
  }

  updateItem = (data, item) => {
    return data && data.map(d => d._id === item._id ? { ...item } : d);
  }

  removeItem(data, item) {
    return data && data.filter(d => d._id !== item._id);
  }
  
  saveToken = (action) => {
    if (action && action.data) {
      const { token, ...userInfo } = action.data;

      if (token) {
        localStorage.setItem('token', token);
        this._token = token;
      }

      if (userInfo) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        this._userInfo = userInfo;
        i18n.changeLanguage(this._userInfo.language);
        moment.locale(this._userInfo.language);
      }

      if (this._view !== 'admin' && this._view !== 'profile' && this._view !== 'externalProfile' && this._view !== 'event') {
        this._view = null;
        this._viewData = null;
      }
    }
  }

  clearData() {
    this._view = null;
    this._viewData = null;
    this._token = null;
    this._userInfo = null;
    this._selectedMonthEvents = null;
    localStorage.clear();
  }
  // Just getters for the properties it got from the action.
  isLoggedIn() {
    return !!this._token;
  }

  view() {
    return this._view;
  }

  viewData() {
    return this._viewData;
  }

  userInfo() {
    return this._userInfo;
  }

  currentYear() {
    return this._currentYear;
  }

  currentYearData() {
    return this._currentYearData;
  }

  selectedEvent() {
    return this._selectedEvent;
  }

  externalUserInfo() {
    return this._externalUserInfo;
  }

  updated() {
    return this._updated;
  }
}
export default new AppStore();
